export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  GeneratingPriority: { input: any; output: any; }
  JsSafeInt: { input: any; output: any; }
  Json: { input: any; output: any; }
  Long: { input: any; output: any; }
};

export type Alias = {
  __typename?: 'Alias';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  speaker?: Maybe<Speaker>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AliasCreateManyWithoutSpeakerInput = {
  connect?: InputMaybe<Array<AliasWhereUniqueInput>>;
  create?: InputMaybe<Array<AliasCreateWithoutSpeakerInput>>;
};

export type AliasCreateWithoutSpeakerInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum AliasOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type AliasScalarWhereInput = {
  AND?: InputMaybe<Array<AliasScalarWhereInput>>;
  NOT?: InputMaybe<Array<AliasScalarWhereInput>>;
  OR?: InputMaybe<Array<AliasScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type AliasUpdateManyDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AliasUpdateManyWithWhereNestedInput = {
  data: AliasUpdateManyDataInput;
  where: AliasScalarWhereInput;
};

export type AliasUpdateManyWithoutSpeakerInput = {
  connect?: InputMaybe<Array<AliasWhereUniqueInput>>;
  create?: InputMaybe<Array<AliasCreateWithoutSpeakerInput>>;
  delete?: InputMaybe<Array<AliasWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AliasScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AliasWhereUniqueInput>>;
  set?: InputMaybe<Array<AliasWhereUniqueInput>>;
  update?: InputMaybe<Array<AliasUpdateWithWhereUniqueWithoutSpeakerInput>>;
  updateMany?: InputMaybe<Array<AliasUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<AliasUpsertWithWhereUniqueWithoutSpeakerInput>>;
};

export type AliasUpdateWithWhereUniqueWithoutSpeakerInput = {
  data: AliasUpdateWithoutSpeakerDataInput;
  where: AliasWhereUniqueInput;
};

export type AliasUpdateWithoutSpeakerDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AliasUpsertWithWhereUniqueWithoutSpeakerInput = {
  create: AliasCreateWithoutSpeakerInput;
  update: AliasUpdateWithoutSpeakerDataInput;
  where: AliasWhereUniqueInput;
};

export type AliasWhereInput = {
  AND?: InputMaybe<Array<AliasWhereInput>>;
  NOT?: InputMaybe<Array<AliasWhereInput>>;
  OR?: InputMaybe<Array<AliasWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  speaker?: InputMaybe<SpeakerWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type AliasWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ApprovedInvoiceDate = {
  __typename?: 'ApprovedInvoiceDate';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isApproved: Scalars['Boolean']['output'];
  sessions?: Maybe<Array<Session>>;
  totalTimeSec: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};


export type ApprovedInvoiceDateSessionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SessionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionWhereInput>;
};

export type ApprovedInvoiceDateCreateInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isApproved: Scalars['Boolean']['input'];
  sessions?: InputMaybe<SessionCreateManyInput>;
  totalTimeSec: Scalars['Int']['input'];
  user: UserCreateOneInput;
};

export enum ApprovedInvoiceDateOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsApprovedAsc = 'isApproved_ASC',
  IsApprovedDesc = 'isApproved_DESC',
  TotalTimeSecAsc = 'totalTimeSec_ASC',
  TotalTimeSecDesc = 'totalTimeSec_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ApprovedInvoiceDateWhereInput = {
  AND?: InputMaybe<Array<ApprovedInvoiceDateWhereInput>>;
  NOT?: InputMaybe<Array<ApprovedInvoiceDateWhereInput>>;
  OR?: InputMaybe<Array<ApprovedInvoiceDateWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  sessions_every?: InputMaybe<SessionWhereInput>;
  sessions_none?: InputMaybe<SessionWhereInput>;
  sessions_some?: InputMaybe<SessionWhereInput>;
  totalTimeSec?: InputMaybe<Scalars['Int']['input']>;
  totalTimeSec_gt?: InputMaybe<Scalars['Int']['input']>;
  totalTimeSec_gte?: InputMaybe<Scalars['Int']['input']>;
  totalTimeSec_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalTimeSec_lt?: InputMaybe<Scalars['Int']['input']>;
  totalTimeSec_lte?: InputMaybe<Scalars['Int']['input']>;
  totalTimeSec_not?: InputMaybe<Scalars['Int']['input']>;
  totalTimeSec_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  user?: InputMaybe<UserWhereInput>;
};

export type ApprovedInvoiceDateWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum AssignableRoleForTranslatedVideoEnumType {
  Editor = 'Editor',
  EditorQc = 'EditorQC',
  QAer = 'QAer',
  Qc = 'QC',
  VoiceActor = 'VoiceActor'
}

export enum AudioEffect {
  Stretch = 'Stretch'
}

export enum AudioFileSource {
  EffectUnit = 'EffectUnit',
  ThirdPartyTts = 'ThirdPartyTts',
  VocoderOutput = 'VocoderOutput'
}

export enum AudioRefinementProvider {
  Auphonic = 'Auphonic',
  NoProvider = 'NoProvider'
}

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count: Scalars['Long']['output'];
};

export enum ChannelType {
  Default = 'Default',
  YouTube = 'YouTube'
}

export type Context = {
  __typename?: 'Context';
  contentType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  fullSummaryText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keyTopics?: Maybe<Scalars['String']['output']>;
  styleToneGenre?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ContextCreateInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  fullSummaryText?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keyTopics?: InputMaybe<Scalars['String']['input']>;
  styleToneGenre?: InputMaybe<Scalars['String']['input']>;
};

export type ContextCreateOneInput = {
  connect?: InputMaybe<ContextWhereUniqueInput>;
  create?: InputMaybe<ContextCreateInput>;
};

export type ContextUpdateDataInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  fullSummaryText?: InputMaybe<Scalars['String']['input']>;
  keyTopics?: InputMaybe<Scalars['String']['input']>;
  styleToneGenre?: InputMaybe<Scalars['String']['input']>;
};

export type ContextUpdateOneInput = {
  connect?: InputMaybe<ContextWhereUniqueInput>;
  create?: InputMaybe<ContextCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ContextUpdateDataInput>;
  upsert?: InputMaybe<ContextUpsertNestedInput>;
};

export type ContextUpsertNestedInput = {
  create: ContextCreateInput;
  update: ContextUpdateDataInput;
};

export type ContextWhereInput = {
  AND?: InputMaybe<Array<ContextWhereInput>>;
  NOT?: InputMaybe<Array<ContextWhereInput>>;
  OR?: InputMaybe<Array<ContextWhereInput>>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  contentType_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_ends_with?: InputMaybe<Scalars['String']['input']>;
  contentType_gt?: InputMaybe<Scalars['String']['input']>;
  contentType_gte?: InputMaybe<Scalars['String']['input']>;
  contentType_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contentType_lt?: InputMaybe<Scalars['String']['input']>;
  contentType_lte?: InputMaybe<Scalars['String']['input']>;
  contentType_not?: InputMaybe<Scalars['String']['input']>;
  contentType_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contentType_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contentType_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contentType_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  fullSummaryText?: InputMaybe<Scalars['String']['input']>;
  fullSummaryText_contains?: InputMaybe<Scalars['String']['input']>;
  fullSummaryText_ends_with?: InputMaybe<Scalars['String']['input']>;
  fullSummaryText_gt?: InputMaybe<Scalars['String']['input']>;
  fullSummaryText_gte?: InputMaybe<Scalars['String']['input']>;
  fullSummaryText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  fullSummaryText_lt?: InputMaybe<Scalars['String']['input']>;
  fullSummaryText_lte?: InputMaybe<Scalars['String']['input']>;
  fullSummaryText_not?: InputMaybe<Scalars['String']['input']>;
  fullSummaryText_not_contains?: InputMaybe<Scalars['String']['input']>;
  fullSummaryText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  fullSummaryText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  fullSummaryText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  fullSummaryText_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  keyTopics?: InputMaybe<Scalars['String']['input']>;
  keyTopics_contains?: InputMaybe<Scalars['String']['input']>;
  keyTopics_ends_with?: InputMaybe<Scalars['String']['input']>;
  keyTopics_gt?: InputMaybe<Scalars['String']['input']>;
  keyTopics_gte?: InputMaybe<Scalars['String']['input']>;
  keyTopics_in?: InputMaybe<Array<Scalars['String']['input']>>;
  keyTopics_lt?: InputMaybe<Scalars['String']['input']>;
  keyTopics_lte?: InputMaybe<Scalars['String']['input']>;
  keyTopics_not?: InputMaybe<Scalars['String']['input']>;
  keyTopics_not_contains?: InputMaybe<Scalars['String']['input']>;
  keyTopics_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  keyTopics_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  keyTopics_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  keyTopics_starts_with?: InputMaybe<Scalars['String']['input']>;
  styleToneGenre?: InputMaybe<Scalars['String']['input']>;
  styleToneGenre_contains?: InputMaybe<Scalars['String']['input']>;
  styleToneGenre_ends_with?: InputMaybe<Scalars['String']['input']>;
  styleToneGenre_gt?: InputMaybe<Scalars['String']['input']>;
  styleToneGenre_gte?: InputMaybe<Scalars['String']['input']>;
  styleToneGenre_in?: InputMaybe<Array<Scalars['String']['input']>>;
  styleToneGenre_lt?: InputMaybe<Scalars['String']['input']>;
  styleToneGenre_lte?: InputMaybe<Scalars['String']['input']>;
  styleToneGenre_not?: InputMaybe<Scalars['String']['input']>;
  styleToneGenre_not_contains?: InputMaybe<Scalars['String']['input']>;
  styleToneGenre_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  styleToneGenre_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  styleToneGenre_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  styleToneGenre_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type ContextWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Correction = {
  __typename?: 'Correction';
  applicationLevel?: Maybe<CorrectionApplicationLevel>;
  appliedCorrection?: Maybe<CorrectionAttempt>;
  applyToAllSpeakers?: Maybe<Scalars['Boolean']['output']>;
  channel?: Maybe<TranslatedChannel>;
  correctionDrafts?: Maybe<Array<CorrectionDraft>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  doesFinalDraftHaveLanguageFlag?: Maybe<Scalars['Boolean']['output']>;
  finalDraft?: Maybe<CorrectionDraft>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  isDatamodelV3: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  originalSample?: Maybe<Sample>;
  originalSpeechOutput?: Maybe<SpeechOutput>;
  originalText?: Maybe<Scalars['String']['output']>;
  originalWord: Scalars['String']['output'];
  pronunciationLanguage?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  segment?: Maybe<Segment>;
  speakers?: Maybe<Array<Speaker>>;
  ttsSourceEnum: TtsSource;
  updatedAt: Scalars['DateTime']['output'];
  video?: Maybe<TranslatedVideo>;
};


export type CorrectionCorrectionDraftsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CorrectionDraftOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CorrectionDraftWhereInput>;
};


export type CorrectionSpeakersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpeakerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpeakerWhereInput>;
};

export enum CorrectionApplicationLevel {
  All = 'All',
  Channel = 'Channel',
  Segment = 'Segment',
  Video = 'Video'
}

export type CorrectionAttempt = {
  __typename?: 'CorrectionAttempt';
  charPhones: Array<Scalars['String']['output']>;
  correctedWord: Scalars['String']['output'];
  correction: Correction;
  createdAt: Scalars['DateTime']['output'];
  endTime?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  sample?: Maybe<Sample>;
  startTime?: Maybe<Scalars['Float']['output']>;
  state: Scalars['Json']['output'];
  stateValue: CorrectionAttemptState;
  text: Scalars['String']['output'];
};

export type CorrectionAttemptCreateOneWithoutCorrectionInput = {
  connect?: InputMaybe<CorrectionAttemptWhereUniqueInput>;
  create?: InputMaybe<CorrectionAttemptCreateWithoutCorrectionInput>;
};

export type CorrectionAttemptCreateWithoutCorrectionInput = {
  charPhones?: InputMaybe<CorrectionAttemptCreatecharPhonesInput>;
  correctedWord: Scalars['String']['input'];
  endTime?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  sample?: InputMaybe<SampleCreateOneInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<CorrectionAttemptState>;
  text: Scalars['String']['input'];
};

export type CorrectionAttemptCreatecharPhonesInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum CorrectionAttemptState {
  Complete = 'Complete',
  GeneratingPhones = 'GeneratingPhones',
  Initial = 'Initial'
}

export type CorrectionAttemptUpdateOneWithoutCorrectionInput = {
  connect?: InputMaybe<CorrectionAttemptWhereUniqueInput>;
  create?: InputMaybe<CorrectionAttemptCreateWithoutCorrectionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CorrectionAttemptUpdateWithoutCorrectionDataInput>;
  upsert?: InputMaybe<CorrectionAttemptUpsertWithoutCorrectionInput>;
};

export type CorrectionAttemptUpdateWithoutCorrectionDataInput = {
  charPhones?: InputMaybe<CorrectionAttemptUpdatecharPhonesInput>;
  correctedWord?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  sample?: InputMaybe<SampleUpdateOneInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<CorrectionAttemptState>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CorrectionAttemptUpdatecharPhonesInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CorrectionAttemptUpsertWithoutCorrectionInput = {
  create: CorrectionAttemptCreateWithoutCorrectionInput;
  update: CorrectionAttemptUpdateWithoutCorrectionDataInput;
};

export type CorrectionAttemptWhereInput = {
  AND?: InputMaybe<Array<CorrectionAttemptWhereInput>>;
  NOT?: InputMaybe<Array<CorrectionAttemptWhereInput>>;
  OR?: InputMaybe<Array<CorrectionAttemptWhereInput>>;
  correctedWord?: InputMaybe<Scalars['String']['input']>;
  correctedWord_contains?: InputMaybe<Scalars['String']['input']>;
  correctedWord_ends_with?: InputMaybe<Scalars['String']['input']>;
  correctedWord_gt?: InputMaybe<Scalars['String']['input']>;
  correctedWord_gte?: InputMaybe<Scalars['String']['input']>;
  correctedWord_in?: InputMaybe<Array<Scalars['String']['input']>>;
  correctedWord_lt?: InputMaybe<Scalars['String']['input']>;
  correctedWord_lte?: InputMaybe<Scalars['String']['input']>;
  correctedWord_not?: InputMaybe<Scalars['String']['input']>;
  correctedWord_not_contains?: InputMaybe<Scalars['String']['input']>;
  correctedWord_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  correctedWord_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  correctedWord_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  correctedWord_starts_with?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<CorrectionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  endTime_gt?: InputMaybe<Scalars['Float']['input']>;
  endTime_gte?: InputMaybe<Scalars['Float']['input']>;
  endTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  endTime_lt?: InputMaybe<Scalars['Float']['input']>;
  endTime_lte?: InputMaybe<Scalars['Float']['input']>;
  endTime_not?: InputMaybe<Scalars['Float']['input']>;
  endTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  sample?: InputMaybe<SampleWhereInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  startTime_gt?: InputMaybe<Scalars['Float']['input']>;
  startTime_gte?: InputMaybe<Scalars['Float']['input']>;
  startTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTime_lt?: InputMaybe<Scalars['Float']['input']>;
  startTime_lte?: InputMaybe<Scalars['Float']['input']>;
  startTime_not?: InputMaybe<Scalars['Float']['input']>;
  startTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stateValue?: InputMaybe<CorrectionAttemptState>;
  stateValue_in?: InputMaybe<Array<CorrectionAttemptState>>;
  stateValue_not?: InputMaybe<CorrectionAttemptState>;
  stateValue_not_in?: InputMaybe<Array<CorrectionAttemptState>>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_not?: InputMaybe<Scalars['String']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  text_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type CorrectionAttemptWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CorrectionCreateOneWithoutCorrectionDraftsInput = {
  connect?: InputMaybe<CorrectionWhereUniqueInput>;
  create?: InputMaybe<CorrectionCreateWithoutCorrectionDraftsInput>;
};

export type CorrectionCreateWithoutCorrectionDraftsInput = {
  applicationLevel?: InputMaybe<CorrectionApplicationLevel>;
  appliedCorrection?: InputMaybe<CorrectionAttemptCreateOneWithoutCorrectionInput>;
  applyToAllSpeakers?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<TranslatedChannelCreateOneInput>;
  createdBy?: InputMaybe<UserCreateOneInput>;
  doesFinalDraftHaveLanguageFlag?: InputMaybe<Scalars['Boolean']['input']>;
  finalDraft?: InputMaybe<CorrectionDraftCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  originalSample?: InputMaybe<SampleCreateOneInput>;
  originalSpeechOutput?: InputMaybe<SpeechOutputCreateOneInput>;
  originalText?: InputMaybe<Scalars['String']['input']>;
  originalWord: Scalars['String']['input'];
  pronunciationLanguage?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  segment?: InputMaybe<SegmentCreateOneInput>;
  speakers?: InputMaybe<SpeakerCreateManyInput>;
  ttsSourceEnum?: InputMaybe<TtsSource>;
  video?: InputMaybe<TranslatedVideoCreateOneInput>;
};

export type CorrectionDraft = {
  __typename?: 'CorrectionDraft';
  correctedWord: Scalars['String']['output'];
  correctedWordEndTime?: Maybe<Scalars['Float']['output']>;
  correctedWordLanguageCode?: Maybe<Scalars['String']['output']>;
  correctedWordStartTime?: Maybe<Scalars['Float']['output']>;
  correction: Correction;
  createdAt: Scalars['DateTime']['output'];
  hasLanguageFlag: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isMigratedFromCorrectionAttempt: Scalars['Boolean']['output'];
  /** Has the audio been generated, and correctedWord start and end time set for the correction draft? */
  isReady: Scalars['Boolean']['output'];
  segment?: Maybe<Segment>;
  speechOutput?: Maybe<SpeechOutput>;
  text: Scalars['String']['output'];
  type: CorrectionType;
  updatedAt: Scalars['DateTime']['output'];
};

export type CorrectionDraftCreateInput = {
  correctedWord: Scalars['String']['input'];
  correctedWordEndTime?: InputMaybe<Scalars['Float']['input']>;
  correctedWordLanguageCode?: InputMaybe<Scalars['String']['input']>;
  correctedWordStartTime?: InputMaybe<Scalars['Float']['input']>;
  correction: CorrectionCreateOneWithoutCorrectionDraftsInput;
  hasLanguageFlag: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isMigratedFromCorrectionAttempt?: InputMaybe<Scalars['Boolean']['input']>;
  segment?: InputMaybe<SegmentCreateOneInput>;
  speechOutput?: InputMaybe<SpeechOutputCreateOneWithoutCorrectionDraftInput>;
  text: Scalars['String']['input'];
  type?: InputMaybe<CorrectionType>;
};

export type CorrectionDraftCreateOneInput = {
  connect?: InputMaybe<CorrectionDraftWhereUniqueInput>;
  create?: InputMaybe<CorrectionDraftCreateInput>;
};

export type CorrectionDraftCreateOneWithoutSpeechOutputInput = {
  connect?: InputMaybe<CorrectionDraftWhereUniqueInput>;
  create?: InputMaybe<CorrectionDraftCreateWithoutSpeechOutputInput>;
};

export type CorrectionDraftCreateWithoutCorrectionInput = {
  correctedWord: Scalars['String']['input'];
  correctedWordEndTime?: InputMaybe<Scalars['Float']['input']>;
  correctedWordLanguageCode?: InputMaybe<Scalars['String']['input']>;
  correctedWordStartTime?: InputMaybe<Scalars['Float']['input']>;
  hasLanguageFlag: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isMigratedFromCorrectionAttempt?: InputMaybe<Scalars['Boolean']['input']>;
  segment?: InputMaybe<SegmentCreateOneInput>;
  speechOutput?: InputMaybe<SpeechOutputCreateOneWithoutCorrectionDraftInput>;
  text: Scalars['String']['input'];
  type?: InputMaybe<CorrectionType>;
};

export type CorrectionDraftCreateWithoutSpeechOutputInput = {
  correctedWord: Scalars['String']['input'];
  correctedWordEndTime?: InputMaybe<Scalars['Float']['input']>;
  correctedWordLanguageCode?: InputMaybe<Scalars['String']['input']>;
  correctedWordStartTime?: InputMaybe<Scalars['Float']['input']>;
  correction: CorrectionCreateOneWithoutCorrectionDraftsInput;
  hasLanguageFlag: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isMigratedFromCorrectionAttempt?: InputMaybe<Scalars['Boolean']['input']>;
  segment?: InputMaybe<SegmentCreateOneInput>;
  text: Scalars['String']['input'];
  type?: InputMaybe<CorrectionType>;
};

export enum CorrectionDraftOrderByInput {
  CorrectedWordEndTimeAsc = 'correctedWordEndTime_ASC',
  CorrectedWordEndTimeDesc = 'correctedWordEndTime_DESC',
  CorrectedWordLanguageCodeAsc = 'correctedWordLanguageCode_ASC',
  CorrectedWordLanguageCodeDesc = 'correctedWordLanguageCode_DESC',
  CorrectedWordStartTimeAsc = 'correctedWordStartTime_ASC',
  CorrectedWordStartTimeDesc = 'correctedWordStartTime_DESC',
  CorrectedWordAsc = 'correctedWord_ASC',
  CorrectedWordDesc = 'correctedWord_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  HasLanguageFlagAsc = 'hasLanguageFlag_ASC',
  HasLanguageFlagDesc = 'hasLanguageFlag_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsMigratedFromCorrectionAttemptAsc = 'isMigratedFromCorrectionAttempt_ASC',
  IsMigratedFromCorrectionAttemptDesc = 'isMigratedFromCorrectionAttempt_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CorrectionDraftScalarWhereInput = {
  AND?: InputMaybe<Array<CorrectionDraftScalarWhereInput>>;
  NOT?: InputMaybe<Array<CorrectionDraftScalarWhereInput>>;
  OR?: InputMaybe<Array<CorrectionDraftScalarWhereInput>>;
  correctedWord?: InputMaybe<Scalars['String']['input']>;
  correctedWordEndTime?: InputMaybe<Scalars['Float']['input']>;
  correctedWordEndTime_gt?: InputMaybe<Scalars['Float']['input']>;
  correctedWordEndTime_gte?: InputMaybe<Scalars['Float']['input']>;
  correctedWordEndTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  correctedWordEndTime_lt?: InputMaybe<Scalars['Float']['input']>;
  correctedWordEndTime_lte?: InputMaybe<Scalars['Float']['input']>;
  correctedWordEndTime_not?: InputMaybe<Scalars['Float']['input']>;
  correctedWordEndTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  correctedWordLanguageCode?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_contains?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_gt?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_gte?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_in?: InputMaybe<Array<Scalars['String']['input']>>;
  correctedWordLanguageCode_lt?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_lte?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_not?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  correctedWordLanguageCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  correctedWordStartTime?: InputMaybe<Scalars['Float']['input']>;
  correctedWordStartTime_gt?: InputMaybe<Scalars['Float']['input']>;
  correctedWordStartTime_gte?: InputMaybe<Scalars['Float']['input']>;
  correctedWordStartTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  correctedWordStartTime_lt?: InputMaybe<Scalars['Float']['input']>;
  correctedWordStartTime_lte?: InputMaybe<Scalars['Float']['input']>;
  correctedWordStartTime_not?: InputMaybe<Scalars['Float']['input']>;
  correctedWordStartTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  correctedWord_contains?: InputMaybe<Scalars['String']['input']>;
  correctedWord_ends_with?: InputMaybe<Scalars['String']['input']>;
  correctedWord_gt?: InputMaybe<Scalars['String']['input']>;
  correctedWord_gte?: InputMaybe<Scalars['String']['input']>;
  correctedWord_in?: InputMaybe<Array<Scalars['String']['input']>>;
  correctedWord_lt?: InputMaybe<Scalars['String']['input']>;
  correctedWord_lte?: InputMaybe<Scalars['String']['input']>;
  correctedWord_not?: InputMaybe<Scalars['String']['input']>;
  correctedWord_not_contains?: InputMaybe<Scalars['String']['input']>;
  correctedWord_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  correctedWord_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  correctedWord_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  correctedWord_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  hasLanguageFlag?: InputMaybe<Scalars['Boolean']['input']>;
  hasLanguageFlag_not?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isMigratedFromCorrectionAttempt?: InputMaybe<Scalars['Boolean']['input']>;
  isMigratedFromCorrectionAttempt_not?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_not?: InputMaybe<Scalars['String']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  text_starts_with?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CorrectionType>;
  type_in?: InputMaybe<Array<CorrectionType>>;
  type_not?: InputMaybe<CorrectionType>;
  type_not_in?: InputMaybe<Array<CorrectionType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type CorrectionDraftUpdateDataInput = {
  correctedWord?: InputMaybe<Scalars['String']['input']>;
  correctedWordEndTime?: InputMaybe<Scalars['Float']['input']>;
  correctedWordLanguageCode?: InputMaybe<Scalars['String']['input']>;
  correctedWordStartTime?: InputMaybe<Scalars['Float']['input']>;
  correction?: InputMaybe<CorrectionUpdateOneRequiredWithoutCorrectionDraftsInput>;
  hasLanguageFlag?: InputMaybe<Scalars['Boolean']['input']>;
  isMigratedFromCorrectionAttempt?: InputMaybe<Scalars['Boolean']['input']>;
  segment?: InputMaybe<SegmentUpdateOneInput>;
  speechOutput?: InputMaybe<SpeechOutputUpdateOneWithoutCorrectionDraftInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CorrectionType>;
};

export type CorrectionDraftUpdateManyDataInput = {
  correctedWord?: InputMaybe<Scalars['String']['input']>;
  correctedWordEndTime?: InputMaybe<Scalars['Float']['input']>;
  correctedWordLanguageCode?: InputMaybe<Scalars['String']['input']>;
  correctedWordStartTime?: InputMaybe<Scalars['Float']['input']>;
  hasLanguageFlag?: InputMaybe<Scalars['Boolean']['input']>;
  isMigratedFromCorrectionAttempt?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CorrectionType>;
};

export type CorrectionDraftUpdateManyWithWhereNestedInput = {
  data: CorrectionDraftUpdateManyDataInput;
  where: CorrectionDraftScalarWhereInput;
};

export type CorrectionDraftUpdateManyWithoutCorrectionInput = {
  connect?: InputMaybe<Array<CorrectionDraftWhereUniqueInput>>;
  create?: InputMaybe<Array<CorrectionDraftCreateWithoutCorrectionInput>>;
  delete?: InputMaybe<Array<CorrectionDraftWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CorrectionDraftScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CorrectionDraftWhereUniqueInput>>;
  set?: InputMaybe<Array<CorrectionDraftWhereUniqueInput>>;
  update?: InputMaybe<Array<CorrectionDraftUpdateWithWhereUniqueWithoutCorrectionInput>>;
  updateMany?: InputMaybe<Array<CorrectionDraftUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<CorrectionDraftUpsertWithWhereUniqueWithoutCorrectionInput>>;
};

export type CorrectionDraftUpdateOneInput = {
  connect?: InputMaybe<CorrectionDraftWhereUniqueInput>;
  create?: InputMaybe<CorrectionDraftCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CorrectionDraftUpdateDataInput>;
  upsert?: InputMaybe<CorrectionDraftUpsertNestedInput>;
};

export type CorrectionDraftUpdateOneWithoutSpeechOutputInput = {
  connect?: InputMaybe<CorrectionDraftWhereUniqueInput>;
  create?: InputMaybe<CorrectionDraftCreateWithoutSpeechOutputInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CorrectionDraftUpdateWithoutSpeechOutputDataInput>;
  upsert?: InputMaybe<CorrectionDraftUpsertWithoutSpeechOutputInput>;
};

export type CorrectionDraftUpdateWithWhereUniqueWithoutCorrectionInput = {
  data: CorrectionDraftUpdateWithoutCorrectionDataInput;
  where: CorrectionDraftWhereUniqueInput;
};

export type CorrectionDraftUpdateWithoutCorrectionDataInput = {
  correctedWord?: InputMaybe<Scalars['String']['input']>;
  correctedWordEndTime?: InputMaybe<Scalars['Float']['input']>;
  correctedWordLanguageCode?: InputMaybe<Scalars['String']['input']>;
  correctedWordStartTime?: InputMaybe<Scalars['Float']['input']>;
  hasLanguageFlag?: InputMaybe<Scalars['Boolean']['input']>;
  isMigratedFromCorrectionAttempt?: InputMaybe<Scalars['Boolean']['input']>;
  segment?: InputMaybe<SegmentUpdateOneInput>;
  speechOutput?: InputMaybe<SpeechOutputUpdateOneWithoutCorrectionDraftInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CorrectionType>;
};

export type CorrectionDraftUpdateWithoutSpeechOutputDataInput = {
  correctedWord?: InputMaybe<Scalars['String']['input']>;
  correctedWordEndTime?: InputMaybe<Scalars['Float']['input']>;
  correctedWordLanguageCode?: InputMaybe<Scalars['String']['input']>;
  correctedWordStartTime?: InputMaybe<Scalars['Float']['input']>;
  correction?: InputMaybe<CorrectionUpdateOneRequiredWithoutCorrectionDraftsInput>;
  hasLanguageFlag?: InputMaybe<Scalars['Boolean']['input']>;
  isMigratedFromCorrectionAttempt?: InputMaybe<Scalars['Boolean']['input']>;
  segment?: InputMaybe<SegmentUpdateOneInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CorrectionType>;
};

export type CorrectionDraftUpsertNestedInput = {
  create: CorrectionDraftCreateInput;
  update: CorrectionDraftUpdateDataInput;
};

export type CorrectionDraftUpsertWithWhereUniqueWithoutCorrectionInput = {
  create: CorrectionDraftCreateWithoutCorrectionInput;
  update: CorrectionDraftUpdateWithoutCorrectionDataInput;
  where: CorrectionDraftWhereUniqueInput;
};

export type CorrectionDraftUpsertWithoutSpeechOutputInput = {
  create: CorrectionDraftCreateWithoutSpeechOutputInput;
  update: CorrectionDraftUpdateWithoutSpeechOutputDataInput;
};

export type CorrectionDraftWhereInput = {
  AND?: InputMaybe<Array<CorrectionDraftWhereInput>>;
  NOT?: InputMaybe<Array<CorrectionDraftWhereInput>>;
  OR?: InputMaybe<Array<CorrectionDraftWhereInput>>;
  correctedWord?: InputMaybe<Scalars['String']['input']>;
  correctedWordEndTime?: InputMaybe<Scalars['Float']['input']>;
  correctedWordEndTime_gt?: InputMaybe<Scalars['Float']['input']>;
  correctedWordEndTime_gte?: InputMaybe<Scalars['Float']['input']>;
  correctedWordEndTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  correctedWordEndTime_lt?: InputMaybe<Scalars['Float']['input']>;
  correctedWordEndTime_lte?: InputMaybe<Scalars['Float']['input']>;
  correctedWordEndTime_not?: InputMaybe<Scalars['Float']['input']>;
  correctedWordEndTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  correctedWordLanguageCode?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_contains?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_gt?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_gte?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_in?: InputMaybe<Array<Scalars['String']['input']>>;
  correctedWordLanguageCode_lt?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_lte?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_not?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  correctedWordLanguageCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  correctedWordLanguageCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  correctedWordStartTime?: InputMaybe<Scalars['Float']['input']>;
  correctedWordStartTime_gt?: InputMaybe<Scalars['Float']['input']>;
  correctedWordStartTime_gte?: InputMaybe<Scalars['Float']['input']>;
  correctedWordStartTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  correctedWordStartTime_lt?: InputMaybe<Scalars['Float']['input']>;
  correctedWordStartTime_lte?: InputMaybe<Scalars['Float']['input']>;
  correctedWordStartTime_not?: InputMaybe<Scalars['Float']['input']>;
  correctedWordStartTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  correctedWord_contains?: InputMaybe<Scalars['String']['input']>;
  correctedWord_ends_with?: InputMaybe<Scalars['String']['input']>;
  correctedWord_gt?: InputMaybe<Scalars['String']['input']>;
  correctedWord_gte?: InputMaybe<Scalars['String']['input']>;
  correctedWord_in?: InputMaybe<Array<Scalars['String']['input']>>;
  correctedWord_lt?: InputMaybe<Scalars['String']['input']>;
  correctedWord_lte?: InputMaybe<Scalars['String']['input']>;
  correctedWord_not?: InputMaybe<Scalars['String']['input']>;
  correctedWord_not_contains?: InputMaybe<Scalars['String']['input']>;
  correctedWord_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  correctedWord_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  correctedWord_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  correctedWord_starts_with?: InputMaybe<Scalars['String']['input']>;
  correction?: InputMaybe<CorrectionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  hasLanguageFlag?: InputMaybe<Scalars['Boolean']['input']>;
  hasLanguageFlag_not?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isMigratedFromCorrectionAttempt?: InputMaybe<Scalars['Boolean']['input']>;
  isMigratedFromCorrectionAttempt_not?: InputMaybe<Scalars['Boolean']['input']>;
  segment?: InputMaybe<SegmentWhereInput>;
  speechOutput?: InputMaybe<SpeechOutputWhereInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_not?: InputMaybe<Scalars['String']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  text_starts_with?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CorrectionType>;
  type_in?: InputMaybe<Array<CorrectionType>>;
  type_not?: InputMaybe<CorrectionType>;
  type_not_in?: InputMaybe<Array<CorrectionType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type CorrectionDraftWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum CorrectionOrderByInput {
  ApplicationLevelAsc = 'applicationLevel_ASC',
  ApplicationLevelDesc = 'applicationLevel_DESC',
  ApplyToAllSpeakersAsc = 'applyToAllSpeakers_ASC',
  ApplyToAllSpeakersDesc = 'applyToAllSpeakers_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DoesFinalDraftHaveLanguageFlagAsc = 'doesFinalDraftHaveLanguageFlag_ASC',
  DoesFinalDraftHaveLanguageFlagDesc = 'doesFinalDraftHaveLanguageFlag_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsArchivedAsc = 'isArchived_ASC',
  IsArchivedDesc = 'isArchived_DESC',
  IsDatamodelV3Asc = 'isDatamodelV3_ASC',
  IsDatamodelV3Desc = 'isDatamodelV3_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  OriginalTextAsc = 'originalText_ASC',
  OriginalTextDesc = 'originalText_DESC',
  OriginalWordAsc = 'originalWord_ASC',
  OriginalWordDesc = 'originalWord_DESC',
  PronunciationLanguageAsc = 'pronunciationLanguage_ASC',
  PronunciationLanguageDesc = 'pronunciationLanguage_DESC',
  PublishedAsc = 'published_ASC',
  PublishedDesc = 'published_DESC',
  TtsSourceEnumAsc = 'ttsSourceEnum_ASC',
  TtsSourceEnumDesc = 'ttsSourceEnum_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export enum CorrectionType {
  Intonation = 'Intonation',
  Pronunciation = 'Pronunciation',
  Unknown = 'Unknown'
}

export type CorrectionUpdateInput = {
  applicationLevel?: InputMaybe<CorrectionApplicationLevel>;
  appliedCorrection?: InputMaybe<CorrectionAttemptUpdateOneWithoutCorrectionInput>;
  applyToAllSpeakers?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<TranslatedChannelUpdateOneInput>;
  correctionDrafts?: InputMaybe<CorrectionDraftUpdateManyWithoutCorrectionInput>;
  createdBy?: InputMaybe<UserUpdateOneInput>;
  doesFinalDraftHaveLanguageFlag?: InputMaybe<Scalars['Boolean']['input']>;
  finalDraft?: InputMaybe<CorrectionDraftUpdateOneInput>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  originalSample?: InputMaybe<SampleUpdateOneInput>;
  originalSpeechOutput?: InputMaybe<SpeechOutputUpdateOneInput>;
  originalText?: InputMaybe<Scalars['String']['input']>;
  originalWord?: InputMaybe<Scalars['String']['input']>;
  pronunciationLanguage?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  segment?: InputMaybe<SegmentUpdateOneInput>;
  speakers?: InputMaybe<SpeakerUpdateManyInput>;
  ttsSourceEnum?: InputMaybe<TtsSource>;
  video?: InputMaybe<TranslatedVideoUpdateOneInput>;
};

export type CorrectionUpdateOneRequiredWithoutCorrectionDraftsInput = {
  connect?: InputMaybe<CorrectionWhereUniqueInput>;
  create?: InputMaybe<CorrectionCreateWithoutCorrectionDraftsInput>;
  update?: InputMaybe<CorrectionUpdateWithoutCorrectionDraftsDataInput>;
  upsert?: InputMaybe<CorrectionUpsertWithoutCorrectionDraftsInput>;
};

export type CorrectionUpdateWithoutCorrectionDraftsDataInput = {
  applicationLevel?: InputMaybe<CorrectionApplicationLevel>;
  appliedCorrection?: InputMaybe<CorrectionAttemptUpdateOneWithoutCorrectionInput>;
  applyToAllSpeakers?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<TranslatedChannelUpdateOneInput>;
  createdBy?: InputMaybe<UserUpdateOneInput>;
  doesFinalDraftHaveLanguageFlag?: InputMaybe<Scalars['Boolean']['input']>;
  finalDraft?: InputMaybe<CorrectionDraftUpdateOneInput>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  originalSample?: InputMaybe<SampleUpdateOneInput>;
  originalSpeechOutput?: InputMaybe<SpeechOutputUpdateOneInput>;
  originalText?: InputMaybe<Scalars['String']['input']>;
  originalWord?: InputMaybe<Scalars['String']['input']>;
  pronunciationLanguage?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  segment?: InputMaybe<SegmentUpdateOneInput>;
  speakers?: InputMaybe<SpeakerUpdateManyInput>;
  ttsSourceEnum?: InputMaybe<TtsSource>;
  video?: InputMaybe<TranslatedVideoUpdateOneInput>;
};

export type CorrectionUpsertWithoutCorrectionDraftsInput = {
  create: CorrectionCreateWithoutCorrectionDraftsInput;
  update: CorrectionUpdateWithoutCorrectionDraftsDataInput;
};

export type CorrectionWhereInput = {
  AND?: InputMaybe<Array<CorrectionWhereInput>>;
  NOT?: InputMaybe<Array<CorrectionWhereInput>>;
  OR?: InputMaybe<Array<CorrectionWhereInput>>;
  applicationLevel?: InputMaybe<CorrectionApplicationLevel>;
  applicationLevel_in?: InputMaybe<Array<CorrectionApplicationLevel>>;
  applicationLevel_not?: InputMaybe<CorrectionApplicationLevel>;
  applicationLevel_not_in?: InputMaybe<Array<CorrectionApplicationLevel>>;
  appliedCorrection?: InputMaybe<CorrectionAttemptWhereInput>;
  applyToAllSpeakers?: InputMaybe<Scalars['Boolean']['input']>;
  applyToAllSpeakers_not?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<TranslatedChannelWhereInput>;
  correctionDrafts_every?: InputMaybe<CorrectionDraftWhereInput>;
  correctionDrafts_none?: InputMaybe<CorrectionDraftWhereInput>;
  correctionDrafts_some?: InputMaybe<CorrectionDraftWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  doesFinalDraftHaveLanguageFlag?: InputMaybe<Scalars['Boolean']['input']>;
  doesFinalDraftHaveLanguageFlag_not?: InputMaybe<Scalars['Boolean']['input']>;
  finalDraft?: InputMaybe<CorrectionDraftWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived_not?: InputMaybe<Scalars['Boolean']['input']>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isDatamodelV3_not?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  language_contains?: InputMaybe<Scalars['String']['input']>;
  language_ends_with?: InputMaybe<Scalars['String']['input']>;
  language_gt?: InputMaybe<Scalars['String']['input']>;
  language_gte?: InputMaybe<Scalars['String']['input']>;
  language_in?: InputMaybe<Array<Scalars['String']['input']>>;
  language_lt?: InputMaybe<Scalars['String']['input']>;
  language_lte?: InputMaybe<Scalars['String']['input']>;
  language_not?: InputMaybe<Scalars['String']['input']>;
  language_not_contains?: InputMaybe<Scalars['String']['input']>;
  language_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  language_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  language_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  language_starts_with?: InputMaybe<Scalars['String']['input']>;
  originalSample?: InputMaybe<SampleWhereInput>;
  originalSpeechOutput?: InputMaybe<SpeechOutputWhereInput>;
  originalText?: InputMaybe<Scalars['String']['input']>;
  originalText_contains?: InputMaybe<Scalars['String']['input']>;
  originalText_ends_with?: InputMaybe<Scalars['String']['input']>;
  originalText_gt?: InputMaybe<Scalars['String']['input']>;
  originalText_gte?: InputMaybe<Scalars['String']['input']>;
  originalText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  originalText_lt?: InputMaybe<Scalars['String']['input']>;
  originalText_lte?: InputMaybe<Scalars['String']['input']>;
  originalText_not?: InputMaybe<Scalars['String']['input']>;
  originalText_not_contains?: InputMaybe<Scalars['String']['input']>;
  originalText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  originalText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  originalText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  originalText_starts_with?: InputMaybe<Scalars['String']['input']>;
  originalWord?: InputMaybe<Scalars['String']['input']>;
  originalWord_contains?: InputMaybe<Scalars['String']['input']>;
  originalWord_ends_with?: InputMaybe<Scalars['String']['input']>;
  originalWord_gt?: InputMaybe<Scalars['String']['input']>;
  originalWord_gte?: InputMaybe<Scalars['String']['input']>;
  originalWord_in?: InputMaybe<Array<Scalars['String']['input']>>;
  originalWord_lt?: InputMaybe<Scalars['String']['input']>;
  originalWord_lte?: InputMaybe<Scalars['String']['input']>;
  originalWord_not?: InputMaybe<Scalars['String']['input']>;
  originalWord_not_contains?: InputMaybe<Scalars['String']['input']>;
  originalWord_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  originalWord_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  originalWord_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  originalWord_starts_with?: InputMaybe<Scalars['String']['input']>;
  pronunciationLanguage?: InputMaybe<Scalars['String']['input']>;
  pronunciationLanguage_contains?: InputMaybe<Scalars['String']['input']>;
  pronunciationLanguage_ends_with?: InputMaybe<Scalars['String']['input']>;
  pronunciationLanguage_gt?: InputMaybe<Scalars['String']['input']>;
  pronunciationLanguage_gte?: InputMaybe<Scalars['String']['input']>;
  pronunciationLanguage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pronunciationLanguage_lt?: InputMaybe<Scalars['String']['input']>;
  pronunciationLanguage_lte?: InputMaybe<Scalars['String']['input']>;
  pronunciationLanguage_not?: InputMaybe<Scalars['String']['input']>;
  pronunciationLanguage_not_contains?: InputMaybe<Scalars['String']['input']>;
  pronunciationLanguage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pronunciationLanguage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pronunciationLanguage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pronunciationLanguage_starts_with?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  published_not?: InputMaybe<Scalars['Boolean']['input']>;
  segment?: InputMaybe<SegmentWhereInput>;
  speakers_every?: InputMaybe<SpeakerWhereInput>;
  speakers_none?: InputMaybe<SpeakerWhereInput>;
  speakers_some?: InputMaybe<SpeakerWhereInput>;
  ttsSourceEnum?: InputMaybe<TtsSource>;
  ttsSourceEnum_in?: InputMaybe<Array<TtsSource>>;
  ttsSourceEnum_not?: InputMaybe<TtsSource>;
  ttsSourceEnum_not_in?: InputMaybe<Array<TtsSource>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  video?: InputMaybe<TranslatedVideoWhereInput>;
};

export type CorrectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Creator = {
  __typename?: 'Creator';
  authId: Scalars['String']['output'];
  authProvider: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CreatorCreateInput = {
  authId: Scalars['String']['input'];
  authProvider: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreatorCreateOneInput = {
  connect?: InputMaybe<CreatorWhereUniqueInput>;
  create?: InputMaybe<CreatorCreateInput>;
};

export type CreatorUpdateDataInput = {
  authId?: InputMaybe<Scalars['String']['input']>;
  authProvider?: InputMaybe<Scalars['String']['input']>;
};

export type CreatorUpdateOneInput = {
  connect?: InputMaybe<CreatorWhereUniqueInput>;
  create?: InputMaybe<CreatorCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CreatorUpdateDataInput>;
  upsert?: InputMaybe<CreatorUpsertNestedInput>;
};

export type CreatorUpsertNestedInput = {
  create: CreatorCreateInput;
  update: CreatorUpdateDataInput;
};

export type CreatorWhereInput = {
  AND?: InputMaybe<Array<CreatorWhereInput>>;
  NOT?: InputMaybe<Array<CreatorWhereInput>>;
  OR?: InputMaybe<Array<CreatorWhereInput>>;
  authId?: InputMaybe<Scalars['String']['input']>;
  authId_contains?: InputMaybe<Scalars['String']['input']>;
  authId_ends_with?: InputMaybe<Scalars['String']['input']>;
  authId_gt?: InputMaybe<Scalars['String']['input']>;
  authId_gte?: InputMaybe<Scalars['String']['input']>;
  authId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  authId_lt?: InputMaybe<Scalars['String']['input']>;
  authId_lte?: InputMaybe<Scalars['String']['input']>;
  authId_not?: InputMaybe<Scalars['String']['input']>;
  authId_not_contains?: InputMaybe<Scalars['String']['input']>;
  authId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  authId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  authId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  authId_starts_with?: InputMaybe<Scalars['String']['input']>;
  authProvider?: InputMaybe<Scalars['String']['input']>;
  authProvider_contains?: InputMaybe<Scalars['String']['input']>;
  authProvider_ends_with?: InputMaybe<Scalars['String']['input']>;
  authProvider_gt?: InputMaybe<Scalars['String']['input']>;
  authProvider_gte?: InputMaybe<Scalars['String']['input']>;
  authProvider_in?: InputMaybe<Array<Scalars['String']['input']>>;
  authProvider_lt?: InputMaybe<Scalars['String']['input']>;
  authProvider_lte?: InputMaybe<Scalars['String']['input']>;
  authProvider_not?: InputMaybe<Scalars['String']['input']>;
  authProvider_not_contains?: InputMaybe<Scalars['String']['input']>;
  authProvider_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  authProvider_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  authProvider_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  authProvider_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type CreatorWhereUniqueInput = {
  authId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum DaysOfWeek {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export enum DueDateFieldOnTranslatedVideoEnumType {
  DueDate = 'dueDate',
  EditorDueDate = 'editorDueDate',
  EditorQcDueDate = 'editorQCDueDate',
  QcDueDate = 'qcDueDate',
  VoiceActorDueDate = 'voiceActorDueDate'
}

export type Edit = {
  __typename?: 'Edit';
  createdAt: Scalars['DateTime']['output'];
  endTime: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  sourceVideo: SourceVideo;
  startTime: Scalars['Float']['output'];
  type: EditType;
  updatedAt: Scalars['DateTime']['output'];
};

export type EditCreateManyWithoutSourceVideoInput = {
  connect?: InputMaybe<Array<EditWhereUniqueInput>>;
  create?: InputMaybe<Array<EditCreateWithoutSourceVideoInput>>;
};

export type EditCreateWithoutSourceVideoInput = {
  endTime: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['Float']['input'];
  type: EditType;
};

export enum EditOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EndTimeAsc = 'endTime_ASC',
  EndTimeDesc = 'endTime_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type EditScalarWhereInput = {
  AND?: InputMaybe<Array<EditScalarWhereInput>>;
  NOT?: InputMaybe<Array<EditScalarWhereInput>>;
  OR?: InputMaybe<Array<EditScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  endTime_gt?: InputMaybe<Scalars['Float']['input']>;
  endTime_gte?: InputMaybe<Scalars['Float']['input']>;
  endTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  endTime_lt?: InputMaybe<Scalars['Float']['input']>;
  endTime_lte?: InputMaybe<Scalars['Float']['input']>;
  endTime_not?: InputMaybe<Scalars['Float']['input']>;
  endTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  reason_contains?: InputMaybe<Scalars['String']['input']>;
  reason_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_gt?: InputMaybe<Scalars['String']['input']>;
  reason_gte?: InputMaybe<Scalars['String']['input']>;
  reason_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_lt?: InputMaybe<Scalars['String']['input']>;
  reason_lte?: InputMaybe<Scalars['String']['input']>;
  reason_not?: InputMaybe<Scalars['String']['input']>;
  reason_not_contains?: InputMaybe<Scalars['String']['input']>;
  reason_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  reason_starts_with?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  startTime_gt?: InputMaybe<Scalars['Float']['input']>;
  startTime_gte?: InputMaybe<Scalars['Float']['input']>;
  startTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTime_lt?: InputMaybe<Scalars['Float']['input']>;
  startTime_lte?: InputMaybe<Scalars['Float']['input']>;
  startTime_not?: InputMaybe<Scalars['Float']['input']>;
  startTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  type?: InputMaybe<EditType>;
  type_in?: InputMaybe<Array<EditType>>;
  type_not?: InputMaybe<EditType>;
  type_not_in?: InputMaybe<Array<EditType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export enum EditType {
  ClipOut = 'ClipOut',
  KeepAudio = 'KeepAudio'
}

export type EditUpdateManyDataInput = {
  endTime?: InputMaybe<Scalars['Float']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<EditType>;
};

export type EditUpdateManyWithWhereNestedInput = {
  data: EditUpdateManyDataInput;
  where: EditScalarWhereInput;
};

export type EditUpdateManyWithoutSourceVideoInput = {
  connect?: InputMaybe<Array<EditWhereUniqueInput>>;
  create?: InputMaybe<Array<EditCreateWithoutSourceVideoInput>>;
  delete?: InputMaybe<Array<EditWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EditScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EditWhereUniqueInput>>;
  set?: InputMaybe<Array<EditWhereUniqueInput>>;
  update?: InputMaybe<Array<EditUpdateWithWhereUniqueWithoutSourceVideoInput>>;
  updateMany?: InputMaybe<Array<EditUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<EditUpsertWithWhereUniqueWithoutSourceVideoInput>>;
};

export type EditUpdateWithWhereUniqueWithoutSourceVideoInput = {
  data: EditUpdateWithoutSourceVideoDataInput;
  where: EditWhereUniqueInput;
};

export type EditUpdateWithoutSourceVideoDataInput = {
  endTime?: InputMaybe<Scalars['Float']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<EditType>;
};

export type EditUpsertWithWhereUniqueWithoutSourceVideoInput = {
  create: EditCreateWithoutSourceVideoInput;
  update: EditUpdateWithoutSourceVideoDataInput;
  where: EditWhereUniqueInput;
};

export type EditWhereInput = {
  AND?: InputMaybe<Array<EditWhereInput>>;
  NOT?: InputMaybe<Array<EditWhereInput>>;
  OR?: InputMaybe<Array<EditWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  endTime_gt?: InputMaybe<Scalars['Float']['input']>;
  endTime_gte?: InputMaybe<Scalars['Float']['input']>;
  endTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  endTime_lt?: InputMaybe<Scalars['Float']['input']>;
  endTime_lte?: InputMaybe<Scalars['Float']['input']>;
  endTime_not?: InputMaybe<Scalars['Float']['input']>;
  endTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  reason_contains?: InputMaybe<Scalars['String']['input']>;
  reason_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_gt?: InputMaybe<Scalars['String']['input']>;
  reason_gte?: InputMaybe<Scalars['String']['input']>;
  reason_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_lt?: InputMaybe<Scalars['String']['input']>;
  reason_lte?: InputMaybe<Scalars['String']['input']>;
  reason_not?: InputMaybe<Scalars['String']['input']>;
  reason_not_contains?: InputMaybe<Scalars['String']['input']>;
  reason_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  reason_starts_with?: InputMaybe<Scalars['String']['input']>;
  sourceVideo?: InputMaybe<SourceVideoWhereInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  startTime_gt?: InputMaybe<Scalars['Float']['input']>;
  startTime_gte?: InputMaybe<Scalars['Float']['input']>;
  startTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTime_lt?: InputMaybe<Scalars['Float']['input']>;
  startTime_lte?: InputMaybe<Scalars['Float']['input']>;
  startTime_not?: InputMaybe<Scalars['Float']['input']>;
  startTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  type?: InputMaybe<EditType>;
  type_in?: InputMaybe<Array<EditType>>;
  type_not?: InputMaybe<EditType>;
  type_not_in?: InputMaybe<Array<EditType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type EditWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type EditedAudioGroup = {
  __typename?: 'EditedAudioGroup';
  createdAt: Scalars['DateTime']['output'];
  done?: Maybe<Scalars['Boolean']['output']>;
  editsHash: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lowVolumeAudioFile?: Maybe<File>;
  mutedAudioFile?: Maybe<File>;
  s3root: Scalars['String']['output'];
  sourceVideo: SourceVideo;
  updatedAt: Scalars['DateTime']['output'];
};

export type EditedAudioGroupCreateInput = {
  done?: InputMaybe<Scalars['Boolean']['input']>;
  editsHash: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lowVolumeAudioFile?: InputMaybe<FileCreateOneInput>;
  mutedAudioFile?: InputMaybe<FileCreateOneInput>;
  s3root: Scalars['String']['input'];
  sourceVideo: SourceVideoCreateOneWithoutEditedAudioGroupsInput;
};

export type EditedAudioGroupCreateManyWithoutSourceVideoInput = {
  connect?: InputMaybe<Array<EditedAudioGroupWhereUniqueInput>>;
  create?: InputMaybe<Array<EditedAudioGroupCreateWithoutSourceVideoInput>>;
};

export type EditedAudioGroupCreateOneInput = {
  connect?: InputMaybe<EditedAudioGroupWhereUniqueInput>;
  create?: InputMaybe<EditedAudioGroupCreateInput>;
};

export type EditedAudioGroupCreateWithoutSourceVideoInput = {
  done?: InputMaybe<Scalars['Boolean']['input']>;
  editsHash: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lowVolumeAudioFile?: InputMaybe<FileCreateOneInput>;
  mutedAudioFile?: InputMaybe<FileCreateOneInput>;
  s3root: Scalars['String']['input'];
};

export enum EditedAudioGroupOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DoneAsc = 'done_ASC',
  DoneDesc = 'done_DESC',
  EditsHashAsc = 'editsHash_ASC',
  EditsHashDesc = 'editsHash_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  S3rootAsc = 's3root_ASC',
  S3rootDesc = 's3root_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type EditedAudioGroupScalarWhereInput = {
  AND?: InputMaybe<Array<EditedAudioGroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<EditedAudioGroupScalarWhereInput>>;
  OR?: InputMaybe<Array<EditedAudioGroupScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  done_not?: InputMaybe<Scalars['Boolean']['input']>;
  editsHash?: InputMaybe<Scalars['String']['input']>;
  editsHash_contains?: InputMaybe<Scalars['String']['input']>;
  editsHash_ends_with?: InputMaybe<Scalars['String']['input']>;
  editsHash_gt?: InputMaybe<Scalars['String']['input']>;
  editsHash_gte?: InputMaybe<Scalars['String']['input']>;
  editsHash_in?: InputMaybe<Array<Scalars['String']['input']>>;
  editsHash_lt?: InputMaybe<Scalars['String']['input']>;
  editsHash_lte?: InputMaybe<Scalars['String']['input']>;
  editsHash_not?: InputMaybe<Scalars['String']['input']>;
  editsHash_not_contains?: InputMaybe<Scalars['String']['input']>;
  editsHash_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  editsHash_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  editsHash_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  editsHash_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  s3root_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_gt?: InputMaybe<Scalars['String']['input']>;
  s3root_gte?: InputMaybe<Scalars['String']['input']>;
  s3root_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_lt?: InputMaybe<Scalars['String']['input']>;
  s3root_lte?: InputMaybe<Scalars['String']['input']>;
  s3root_not?: InputMaybe<Scalars['String']['input']>;
  s3root_not_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  s3root_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type EditedAudioGroupUpdateDataInput = {
  done?: InputMaybe<Scalars['Boolean']['input']>;
  editsHash?: InputMaybe<Scalars['String']['input']>;
  lowVolumeAudioFile?: InputMaybe<FileUpdateOneInput>;
  mutedAudioFile?: InputMaybe<FileUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  sourceVideo?: InputMaybe<SourceVideoUpdateOneRequiredWithoutEditedAudioGroupsInput>;
};

export type EditedAudioGroupUpdateManyDataInput = {
  done?: InputMaybe<Scalars['Boolean']['input']>;
  editsHash?: InputMaybe<Scalars['String']['input']>;
  s3root?: InputMaybe<Scalars['String']['input']>;
};

export type EditedAudioGroupUpdateManyWithWhereNestedInput = {
  data: EditedAudioGroupUpdateManyDataInput;
  where: EditedAudioGroupScalarWhereInput;
};

export type EditedAudioGroupUpdateManyWithoutSourceVideoInput = {
  connect?: InputMaybe<Array<EditedAudioGroupWhereUniqueInput>>;
  create?: InputMaybe<Array<EditedAudioGroupCreateWithoutSourceVideoInput>>;
  delete?: InputMaybe<Array<EditedAudioGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EditedAudioGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EditedAudioGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<EditedAudioGroupWhereUniqueInput>>;
  update?: InputMaybe<Array<EditedAudioGroupUpdateWithWhereUniqueWithoutSourceVideoInput>>;
  updateMany?: InputMaybe<Array<EditedAudioGroupUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<EditedAudioGroupUpsertWithWhereUniqueWithoutSourceVideoInput>>;
};

export type EditedAudioGroupUpdateOneInput = {
  connect?: InputMaybe<EditedAudioGroupWhereUniqueInput>;
  create?: InputMaybe<EditedAudioGroupCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<EditedAudioGroupUpdateDataInput>;
  upsert?: InputMaybe<EditedAudioGroupUpsertNestedInput>;
};

export type EditedAudioGroupUpdateWithWhereUniqueWithoutSourceVideoInput = {
  data: EditedAudioGroupUpdateWithoutSourceVideoDataInput;
  where: EditedAudioGroupWhereUniqueInput;
};

export type EditedAudioGroupUpdateWithoutSourceVideoDataInput = {
  done?: InputMaybe<Scalars['Boolean']['input']>;
  editsHash?: InputMaybe<Scalars['String']['input']>;
  lowVolumeAudioFile?: InputMaybe<FileUpdateOneInput>;
  mutedAudioFile?: InputMaybe<FileUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
};

export type EditedAudioGroupUpsertNestedInput = {
  create: EditedAudioGroupCreateInput;
  update: EditedAudioGroupUpdateDataInput;
};

export type EditedAudioGroupUpsertWithWhereUniqueWithoutSourceVideoInput = {
  create: EditedAudioGroupCreateWithoutSourceVideoInput;
  update: EditedAudioGroupUpdateWithoutSourceVideoDataInput;
  where: EditedAudioGroupWhereUniqueInput;
};

export type EditedAudioGroupWhereInput = {
  AND?: InputMaybe<Array<EditedAudioGroupWhereInput>>;
  NOT?: InputMaybe<Array<EditedAudioGroupWhereInput>>;
  OR?: InputMaybe<Array<EditedAudioGroupWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  done_not?: InputMaybe<Scalars['Boolean']['input']>;
  editsHash?: InputMaybe<Scalars['String']['input']>;
  editsHash_contains?: InputMaybe<Scalars['String']['input']>;
  editsHash_ends_with?: InputMaybe<Scalars['String']['input']>;
  editsHash_gt?: InputMaybe<Scalars['String']['input']>;
  editsHash_gte?: InputMaybe<Scalars['String']['input']>;
  editsHash_in?: InputMaybe<Array<Scalars['String']['input']>>;
  editsHash_lt?: InputMaybe<Scalars['String']['input']>;
  editsHash_lte?: InputMaybe<Scalars['String']['input']>;
  editsHash_not?: InputMaybe<Scalars['String']['input']>;
  editsHash_not_contains?: InputMaybe<Scalars['String']['input']>;
  editsHash_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  editsHash_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  editsHash_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  editsHash_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  lowVolumeAudioFile?: InputMaybe<FileWhereInput>;
  mutedAudioFile?: InputMaybe<FileWhereInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  s3root_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_gt?: InputMaybe<Scalars['String']['input']>;
  s3root_gte?: InputMaybe<Scalars['String']['input']>;
  s3root_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_lt?: InputMaybe<Scalars['String']['input']>;
  s3root_lte?: InputMaybe<Scalars['String']['input']>;
  s3root_not?: InputMaybe<Scalars['String']['input']>;
  s3root_not_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  s3root_starts_with?: InputMaybe<Scalars['String']['input']>;
  sourceVideo?: InputMaybe<SourceVideoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type EditedAudioGroupWhereUniqueInput = {
  editsHash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type EffectUnit = {
  __typename?: 'EffectUnit';
  audioEffectEnum: AudioEffect;
  audioFile?: Maybe<File>;
  createdAt: Scalars['DateTime']['output'];
  failureMessage?: Maybe<Scalars['String']['output']>;
  generatingPriority: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  inputsHash: Scalars['String']['output'];
  processingDurationMs?: Maybe<Scalars['Float']['output']>;
  processingEndTime?: Maybe<Scalars['DateTime']['output']>;
  processingStartTime?: Maybe<Scalars['DateTime']['output']>;
  speechOutputs?: Maybe<Array<SpeechOutput>>;
  stateEnum: GeneratingState;
  stretchRate: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vocoderOutput: VocoderOutput;
};


export type EffectUnitSpeechOutputsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpeechOutputOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpeechOutputWhereInput>;
};

export type EffectUnitCreateManyWithoutVocoderOutputInput = {
  connect?: InputMaybe<Array<EffectUnitWhereUniqueInput>>;
  create?: InputMaybe<Array<EffectUnitCreateWithoutVocoderOutputInput>>;
};

export type EffectUnitCreateOneWithoutSpeechOutputsInput = {
  connect?: InputMaybe<EffectUnitWhereUniqueInput>;
  create?: InputMaybe<EffectUnitCreateWithoutSpeechOutputsInput>;
};

export type EffectUnitCreateWithoutSpeechOutputsInput = {
  audioEffectEnum: AudioEffect;
  audioFile?: InputMaybe<FileCreateOneInput>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  generatingPriority: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  inputsHash: Scalars['String']['input'];
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  stateEnum?: InputMaybe<GeneratingState>;
  stretchRate: Scalars['Float']['input'];
  vocoderOutput: VocoderOutputCreateOneWithoutEffectUnitsInput;
};

export type EffectUnitCreateWithoutVocoderOutputInput = {
  audioEffectEnum: AudioEffect;
  audioFile?: InputMaybe<FileCreateOneInput>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  generatingPriority: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  inputsHash: Scalars['String']['input'];
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputCreateManyWithoutEffectUnitInput>;
  stateEnum?: InputMaybe<GeneratingState>;
  stretchRate: Scalars['Float']['input'];
};

export enum EffectUnitOrderByInput {
  AudioEffectEnumAsc = 'audioEffectEnum_ASC',
  AudioEffectEnumDesc = 'audioEffectEnum_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FailureMessageAsc = 'failureMessage_ASC',
  FailureMessageDesc = 'failureMessage_DESC',
  GeneratingPriorityAsc = 'generatingPriority_ASC',
  GeneratingPriorityDesc = 'generatingPriority_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InputsHashAsc = 'inputsHash_ASC',
  InputsHashDesc = 'inputsHash_DESC',
  ProcessingDurationMsAsc = 'processingDurationMs_ASC',
  ProcessingDurationMsDesc = 'processingDurationMs_DESC',
  ProcessingEndTimeAsc = 'processingEndTime_ASC',
  ProcessingEndTimeDesc = 'processingEndTime_DESC',
  ProcessingStartTimeAsc = 'processingStartTime_ASC',
  ProcessingStartTimeDesc = 'processingStartTime_DESC',
  StateEnumAsc = 'stateEnum_ASC',
  StateEnumDesc = 'stateEnum_DESC',
  StretchRateAsc = 'stretchRate_ASC',
  StretchRateDesc = 'stretchRate_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type EffectUnitScalarWhereInput = {
  AND?: InputMaybe<Array<EffectUnitScalarWhereInput>>;
  NOT?: InputMaybe<Array<EffectUnitScalarWhereInput>>;
  OR?: InputMaybe<Array<EffectUnitScalarWhereInput>>;
  audioEffectEnum?: InputMaybe<AudioEffect>;
  audioEffectEnum_in?: InputMaybe<Array<AudioEffect>>;
  audioEffectEnum_not?: InputMaybe<AudioEffect>;
  audioEffectEnum_not_in?: InputMaybe<Array<AudioEffect>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureMessage_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_lt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_lte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_gt?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_gte?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  generatingPriority_lt?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_lte?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_not?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
  inputsHash_contains?: InputMaybe<Scalars['String']['input']>;
  inputsHash_ends_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_gt?: InputMaybe<Scalars['String']['input']>;
  inputsHash_gte?: InputMaybe<Scalars['String']['input']>;
  inputsHash_in?: InputMaybe<Array<Scalars['String']['input']>>;
  inputsHash_lt?: InputMaybe<Scalars['String']['input']>;
  inputsHash_lte?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_contains?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  inputsHash_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_starts_with?: InputMaybe<Scalars['String']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingDurationMs_lt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_lte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingEndTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  stateEnum?: InputMaybe<GeneratingState>;
  stateEnum_in?: InputMaybe<Array<GeneratingState>>;
  stateEnum_not?: InputMaybe<GeneratingState>;
  stateEnum_not_in?: InputMaybe<Array<GeneratingState>>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stretchRate_lt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_lte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type EffectUnitUpdateManyDataInput = {
  audioEffectEnum?: InputMaybe<AudioEffect>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  stateEnum?: InputMaybe<GeneratingState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
};

export type EffectUnitUpdateManyWithWhereNestedInput = {
  data: EffectUnitUpdateManyDataInput;
  where: EffectUnitScalarWhereInput;
};

export type EffectUnitUpdateManyWithoutVocoderOutputInput = {
  connect?: InputMaybe<Array<EffectUnitWhereUniqueInput>>;
  create?: InputMaybe<Array<EffectUnitCreateWithoutVocoderOutputInput>>;
  delete?: InputMaybe<Array<EffectUnitWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EffectUnitScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EffectUnitWhereUniqueInput>>;
  set?: InputMaybe<Array<EffectUnitWhereUniqueInput>>;
  update?: InputMaybe<Array<EffectUnitUpdateWithWhereUniqueWithoutVocoderOutputInput>>;
  updateMany?: InputMaybe<Array<EffectUnitUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<EffectUnitUpsertWithWhereUniqueWithoutVocoderOutputInput>>;
};

export type EffectUnitUpdateOneWithoutSpeechOutputsInput = {
  connect?: InputMaybe<EffectUnitWhereUniqueInput>;
  create?: InputMaybe<EffectUnitCreateWithoutSpeechOutputsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<EffectUnitUpdateWithoutSpeechOutputsDataInput>;
  upsert?: InputMaybe<EffectUnitUpsertWithoutSpeechOutputsInput>;
};

export type EffectUnitUpdateWithWhereUniqueWithoutVocoderOutputInput = {
  data: EffectUnitUpdateWithoutVocoderOutputDataInput;
  where: EffectUnitWhereUniqueInput;
};

export type EffectUnitUpdateWithoutSpeechOutputsDataInput = {
  audioEffectEnum?: InputMaybe<AudioEffect>;
  audioFile?: InputMaybe<FileUpdateOneInput>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  stateEnum?: InputMaybe<GeneratingState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  vocoderOutput?: InputMaybe<VocoderOutputUpdateOneRequiredWithoutEffectUnitsInput>;
};

export type EffectUnitUpdateWithoutVocoderOutputDataInput = {
  audioEffectEnum?: InputMaybe<AudioEffect>;
  audioFile?: InputMaybe<FileUpdateOneInput>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyWithoutEffectUnitInput>;
  stateEnum?: InputMaybe<GeneratingState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
};

export type EffectUnitUpsertWithWhereUniqueWithoutVocoderOutputInput = {
  create: EffectUnitCreateWithoutVocoderOutputInput;
  update: EffectUnitUpdateWithoutVocoderOutputDataInput;
  where: EffectUnitWhereUniqueInput;
};

export type EffectUnitUpsertWithoutSpeechOutputsInput = {
  create: EffectUnitCreateWithoutSpeechOutputsInput;
  update: EffectUnitUpdateWithoutSpeechOutputsDataInput;
};

export type EffectUnitWhereInput = {
  AND?: InputMaybe<Array<EffectUnitWhereInput>>;
  NOT?: InputMaybe<Array<EffectUnitWhereInput>>;
  OR?: InputMaybe<Array<EffectUnitWhereInput>>;
  audioEffectEnum?: InputMaybe<AudioEffect>;
  audioEffectEnum_in?: InputMaybe<Array<AudioEffect>>;
  audioEffectEnum_not?: InputMaybe<AudioEffect>;
  audioEffectEnum_not_in?: InputMaybe<Array<AudioEffect>>;
  audioFile?: InputMaybe<FileWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureMessage_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_lt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_lte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_gt?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_gte?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  generatingPriority_lt?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_lte?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_not?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
  inputsHash_contains?: InputMaybe<Scalars['String']['input']>;
  inputsHash_ends_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_gt?: InputMaybe<Scalars['String']['input']>;
  inputsHash_gte?: InputMaybe<Scalars['String']['input']>;
  inputsHash_in?: InputMaybe<Array<Scalars['String']['input']>>;
  inputsHash_lt?: InputMaybe<Scalars['String']['input']>;
  inputsHash_lte?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_contains?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  inputsHash_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_starts_with?: InputMaybe<Scalars['String']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingDurationMs_lt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_lte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingEndTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  speechOutputs_every?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_none?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_some?: InputMaybe<SpeechOutputWhereInput>;
  stateEnum?: InputMaybe<GeneratingState>;
  stateEnum_in?: InputMaybe<Array<GeneratingState>>;
  stateEnum_not?: InputMaybe<GeneratingState>;
  stateEnum_not_in?: InputMaybe<Array<GeneratingState>>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stretchRate_lt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_lte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  vocoderOutput?: InputMaybe<VocoderOutputWhereInput>;
};

export type EffectUnitWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
};

export type FeaturesData = {
  __typename?: 'FeaturesData';
  createdAt: Scalars['DateTime']['output'];
  failureMessage?: Maybe<Scalars['String']['output']>;
  featuresDuration?: Maybe<Scalars['Float']['output']>;
  featuresFile?: Maybe<File>;
  featuresModelName?: Maybe<Scalars['String']['output']>;
  featuresVersion: Scalars['String']['output'];
  generatingPriority: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  inputsHash: Scalars['String']['output'];
  languageCode: Scalars['String']['output'];
  mimicAudioFile?: Maybe<File>;
  phones: PhonesData;
  processingDurationMs?: Maybe<Scalars['Float']['output']>;
  processingEndTime?: Maybe<Scalars['DateTime']['output']>;
  processingStartTime?: Maybe<Scalars['DateTime']['output']>;
  s3root: Scalars['String']['output'];
  speakerCode: Scalars['String']['output'];
  stateEnum: GeneratingState;
  stretchRate: Scalars['Float']['output'];
  styleCode: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vocoderOutputs?: Maybe<Array<VocoderOutput>>;
  wordTimings?: Maybe<Scalars['Json']['output']>;
};


export type FeaturesDataVocoderOutputsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<VocoderOutputOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VocoderOutputWhereInput>;
};

export type FeaturesDataCreateOneWithoutVocoderOutputsInput = {
  connect?: InputMaybe<FeaturesDataWhereUniqueInput>;
  create?: InputMaybe<FeaturesDataCreateWithoutVocoderOutputsInput>;
};

export type FeaturesDataCreateWithoutVocoderOutputsInput = {
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  featuresDuration?: InputMaybe<Scalars['Float']['input']>;
  featuresFile?: InputMaybe<FileCreateOneInput>;
  featuresModelName?: InputMaybe<Scalars['String']['input']>;
  featuresVersion: Scalars['String']['input'];
  generatingPriority: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  inputsHash: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  mimicAudioFile?: InputMaybe<FileCreateOneInput>;
  phones: PhonesDataCreateOneWithoutFeaturesInput;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  s3root: Scalars['String']['input'];
  speakerCode: Scalars['String']['input'];
  stateEnum?: InputMaybe<GeneratingState>;
  stretchRate: Scalars['Float']['input'];
  styleCode: Scalars['String']['input'];
  wordTimings?: InputMaybe<Scalars['Json']['input']>;
};

export enum FeaturesDataOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FailureMessageAsc = 'failureMessage_ASC',
  FailureMessageDesc = 'failureMessage_DESC',
  FeaturesDurationAsc = 'featuresDuration_ASC',
  FeaturesDurationDesc = 'featuresDuration_DESC',
  FeaturesModelNameAsc = 'featuresModelName_ASC',
  FeaturesModelNameDesc = 'featuresModelName_DESC',
  FeaturesVersionAsc = 'featuresVersion_ASC',
  FeaturesVersionDesc = 'featuresVersion_DESC',
  GeneratingPriorityAsc = 'generatingPriority_ASC',
  GeneratingPriorityDesc = 'generatingPriority_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InputsHashAsc = 'inputsHash_ASC',
  InputsHashDesc = 'inputsHash_DESC',
  LanguageCodeAsc = 'languageCode_ASC',
  LanguageCodeDesc = 'languageCode_DESC',
  ProcessingDurationMsAsc = 'processingDurationMs_ASC',
  ProcessingDurationMsDesc = 'processingDurationMs_DESC',
  ProcessingEndTimeAsc = 'processingEndTime_ASC',
  ProcessingEndTimeDesc = 'processingEndTime_DESC',
  ProcessingStartTimeAsc = 'processingStartTime_ASC',
  ProcessingStartTimeDesc = 'processingStartTime_DESC',
  S3rootAsc = 's3root_ASC',
  S3rootDesc = 's3root_DESC',
  SpeakerCodeAsc = 'speakerCode_ASC',
  SpeakerCodeDesc = 'speakerCode_DESC',
  StateEnumAsc = 'stateEnum_ASC',
  StateEnumDesc = 'stateEnum_DESC',
  StretchRateAsc = 'stretchRate_ASC',
  StretchRateDesc = 'stretchRate_DESC',
  StyleCodeAsc = 'styleCode_ASC',
  StyleCodeDesc = 'styleCode_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WordTimingsAsc = 'wordTimings_ASC',
  WordTimingsDesc = 'wordTimings_DESC'
}

export type FeaturesDataUpdateOneRequiredWithoutVocoderOutputsInput = {
  connect?: InputMaybe<FeaturesDataWhereUniqueInput>;
  create?: InputMaybe<FeaturesDataCreateWithoutVocoderOutputsInput>;
  update?: InputMaybe<FeaturesDataUpdateWithoutVocoderOutputsDataInput>;
  upsert?: InputMaybe<FeaturesDataUpsertWithoutVocoderOutputsInput>;
};

export type FeaturesDataUpdateWithoutVocoderOutputsDataInput = {
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  featuresDuration?: InputMaybe<Scalars['Float']['input']>;
  featuresFile?: InputMaybe<FileUpdateOneInput>;
  featuresModelName?: InputMaybe<Scalars['String']['input']>;
  featuresVersion?: InputMaybe<Scalars['String']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  mimicAudioFile?: InputMaybe<FileUpdateOneInput>;
  phones?: InputMaybe<PhonesDataUpdateOneRequiredWithoutFeaturesInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  speakerCode?: InputMaybe<Scalars['String']['input']>;
  stateEnum?: InputMaybe<GeneratingState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  styleCode?: InputMaybe<Scalars['String']['input']>;
  wordTimings?: InputMaybe<Scalars['Json']['input']>;
};

export type FeaturesDataUpsertWithoutVocoderOutputsInput = {
  create: FeaturesDataCreateWithoutVocoderOutputsInput;
  update: FeaturesDataUpdateWithoutVocoderOutputsDataInput;
};

export type FeaturesDataWhereInput = {
  AND?: InputMaybe<Array<FeaturesDataWhereInput>>;
  NOT?: InputMaybe<Array<FeaturesDataWhereInput>>;
  OR?: InputMaybe<Array<FeaturesDataWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureMessage_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_lt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_lte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
  featuresDuration?: InputMaybe<Scalars['Float']['input']>;
  featuresDuration_gt?: InputMaybe<Scalars['Float']['input']>;
  featuresDuration_gte?: InputMaybe<Scalars['Float']['input']>;
  featuresDuration_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  featuresDuration_lt?: InputMaybe<Scalars['Float']['input']>;
  featuresDuration_lte?: InputMaybe<Scalars['Float']['input']>;
  featuresDuration_not?: InputMaybe<Scalars['Float']['input']>;
  featuresDuration_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  featuresFile?: InputMaybe<FileWhereInput>;
  featuresModelName?: InputMaybe<Scalars['String']['input']>;
  featuresModelName_contains?: InputMaybe<Scalars['String']['input']>;
  featuresModelName_ends_with?: InputMaybe<Scalars['String']['input']>;
  featuresModelName_gt?: InputMaybe<Scalars['String']['input']>;
  featuresModelName_gte?: InputMaybe<Scalars['String']['input']>;
  featuresModelName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  featuresModelName_lt?: InputMaybe<Scalars['String']['input']>;
  featuresModelName_lte?: InputMaybe<Scalars['String']['input']>;
  featuresModelName_not?: InputMaybe<Scalars['String']['input']>;
  featuresModelName_not_contains?: InputMaybe<Scalars['String']['input']>;
  featuresModelName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  featuresModelName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  featuresModelName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  featuresModelName_starts_with?: InputMaybe<Scalars['String']['input']>;
  featuresVersion?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_contains?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_gt?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_gte?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  featuresVersion_lt?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_lte?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_not?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  featuresVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_gt?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_gte?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  generatingPriority_lt?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_lte?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_not?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
  inputsHash_contains?: InputMaybe<Scalars['String']['input']>;
  inputsHash_ends_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_gt?: InputMaybe<Scalars['String']['input']>;
  inputsHash_gte?: InputMaybe<Scalars['String']['input']>;
  inputsHash_in?: InputMaybe<Array<Scalars['String']['input']>>;
  inputsHash_lt?: InputMaybe<Scalars['String']['input']>;
  inputsHash_lte?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_contains?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  inputsHash_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_starts_with?: InputMaybe<Scalars['String']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  languageCode_contains?: InputMaybe<Scalars['String']['input']>;
  languageCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  languageCode_gt?: InputMaybe<Scalars['String']['input']>;
  languageCode_gte?: InputMaybe<Scalars['String']['input']>;
  languageCode_in?: InputMaybe<Array<Scalars['String']['input']>>;
  languageCode_lt?: InputMaybe<Scalars['String']['input']>;
  languageCode_lte?: InputMaybe<Scalars['String']['input']>;
  languageCode_not?: InputMaybe<Scalars['String']['input']>;
  languageCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  languageCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  languageCode_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  languageCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  languageCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  mimicAudioFile?: InputMaybe<FileWhereInput>;
  phones?: InputMaybe<PhonesDataWhereInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingDurationMs_lt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_lte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingEndTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  s3root_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_gt?: InputMaybe<Scalars['String']['input']>;
  s3root_gte?: InputMaybe<Scalars['String']['input']>;
  s3root_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_lt?: InputMaybe<Scalars['String']['input']>;
  s3root_lte?: InputMaybe<Scalars['String']['input']>;
  s3root_not?: InputMaybe<Scalars['String']['input']>;
  s3root_not_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  s3root_starts_with?: InputMaybe<Scalars['String']['input']>;
  speakerCode?: InputMaybe<Scalars['String']['input']>;
  speakerCode_contains?: InputMaybe<Scalars['String']['input']>;
  speakerCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  speakerCode_gt?: InputMaybe<Scalars['String']['input']>;
  speakerCode_gte?: InputMaybe<Scalars['String']['input']>;
  speakerCode_in?: InputMaybe<Array<Scalars['String']['input']>>;
  speakerCode_lt?: InputMaybe<Scalars['String']['input']>;
  speakerCode_lte?: InputMaybe<Scalars['String']['input']>;
  speakerCode_not?: InputMaybe<Scalars['String']['input']>;
  speakerCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  speakerCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  speakerCode_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  speakerCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  speakerCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  stateEnum?: InputMaybe<GeneratingState>;
  stateEnum_in?: InputMaybe<Array<GeneratingState>>;
  stateEnum_not?: InputMaybe<GeneratingState>;
  stateEnum_not_in?: InputMaybe<Array<GeneratingState>>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stretchRate_lt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_lte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  styleCode?: InputMaybe<Scalars['String']['input']>;
  styleCode_contains?: InputMaybe<Scalars['String']['input']>;
  styleCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  styleCode_gt?: InputMaybe<Scalars['String']['input']>;
  styleCode_gte?: InputMaybe<Scalars['String']['input']>;
  styleCode_in?: InputMaybe<Array<Scalars['String']['input']>>;
  styleCode_lt?: InputMaybe<Scalars['String']['input']>;
  styleCode_lte?: InputMaybe<Scalars['String']['input']>;
  styleCode_not?: InputMaybe<Scalars['String']['input']>;
  styleCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  styleCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  styleCode_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  styleCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  styleCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  vocoderOutputs_every?: InputMaybe<VocoderOutputWhereInput>;
  vocoderOutputs_none?: InputMaybe<VocoderOutputWhereInput>;
  vocoderOutputs_some?: InputMaybe<VocoderOutputWhereInput>;
};

export type FeaturesDataWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
};

export type File = {
  __typename?: 'File';
  /** URL for the File */
  URL: Scalars['String']['output'];
  bucket?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  duration?: Maybe<Scalars['Float']['output']>;
  /** The extension of the file */
  extension: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  properties?: Maybe<Scalars['Json']['output']>;
  provider: ProviderType;
  region?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  videoProperties?: Maybe<VideoProperties>;
};

export type FileCreateInput = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  key: Scalars['String']['input'];
  properties?: InputMaybe<Scalars['Json']['input']>;
  provider?: InputMaybe<ProviderType>;
  region?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreateOneInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  create?: InputMaybe<FileCreateInput>;
};

export type FileUpdateDataInput = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['Json']['input']>;
  provider?: InputMaybe<ProviderType>;
  region?: InputMaybe<Scalars['String']['input']>;
};

export type FileUpdateOneInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  create?: InputMaybe<FileCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<FileUpdateDataInput>;
  upsert?: InputMaybe<FileUpsertNestedInput>;
};

export type FileUpdateOneRequiredInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  create?: InputMaybe<FileCreateInput>;
  update?: InputMaybe<FileUpdateDataInput>;
  upsert?: InputMaybe<FileUpsertNestedInput>;
};

export type FileUpsertNestedInput = {
  create: FileCreateInput;
  update: FileUpdateDataInput;
};

export type FileWhereInput = {
  AND?: InputMaybe<Array<FileWhereInput>>;
  NOT?: InputMaybe<Array<FileWhereInput>>;
  OR?: InputMaybe<Array<FileWhereInput>>;
  bucket?: InputMaybe<Scalars['String']['input']>;
  bucket_contains?: InputMaybe<Scalars['String']['input']>;
  bucket_ends_with?: InputMaybe<Scalars['String']['input']>;
  bucket_gt?: InputMaybe<Scalars['String']['input']>;
  bucket_gte?: InputMaybe<Scalars['String']['input']>;
  bucket_in?: InputMaybe<Array<Scalars['String']['input']>>;
  bucket_lt?: InputMaybe<Scalars['String']['input']>;
  bucket_lte?: InputMaybe<Scalars['String']['input']>;
  bucket_not?: InputMaybe<Scalars['String']['input']>;
  bucket_not_contains?: InputMaybe<Scalars['String']['input']>;
  bucket_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  bucket_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  bucket_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  bucket_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  duration_gt?: InputMaybe<Scalars['Float']['input']>;
  duration_gte?: InputMaybe<Scalars['Float']['input']>;
  duration_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  duration_lt?: InputMaybe<Scalars['Float']['input']>;
  duration_lte?: InputMaybe<Scalars['Float']['input']>;
  duration_not?: InputMaybe<Scalars['Float']['input']>;
  duration_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<ProviderType>;
  provider_in?: InputMaybe<Array<ProviderType>>;
  provider_not?: InputMaybe<ProviderType>;
  provider_not_in?: InputMaybe<Array<ProviderType>>;
  region?: InputMaybe<Scalars['String']['input']>;
  region_contains?: InputMaybe<Scalars['String']['input']>;
  region_ends_with?: InputMaybe<Scalars['String']['input']>;
  region_gt?: InputMaybe<Scalars['String']['input']>;
  region_gte?: InputMaybe<Scalars['String']['input']>;
  region_in?: InputMaybe<Array<Scalars['String']['input']>>;
  region_lt?: InputMaybe<Scalars['String']['input']>;
  region_lte?: InputMaybe<Scalars['String']['input']>;
  region_not?: InputMaybe<Scalars['String']['input']>;
  region_not_contains?: InputMaybe<Scalars['String']['input']>;
  region_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  region_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  region_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  region_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type FileWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum FrameProjectType {
  Customer = 'Customer',
  Editor = 'Editor'
}

export enum Frequency {
  Biweekly = 'Biweekly',
  Daily = 'Daily',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export enum GeneratingState {
  Done = 'Done',
  Failed = 'Failed',
  Generating = 'Generating',
  Initial = 'Initial'
}

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount: Scalars['Int']['output'];
  channel?: Maybe<TranslatedChannel>;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  stripeInvoiceItemId: Scalars['String']['output'];
  translatedVideo?: Maybe<TranslatedVideo>;
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceItemCreateOneWithoutTranslatedVideoInput = {
  connect?: InputMaybe<InvoiceItemWhereUniqueInput>;
  create?: InputMaybe<InvoiceItemCreateWithoutTranslatedVideoInput>;
};

export type InvoiceItemCreateWithoutTranslatedVideoInput = {
  amount: Scalars['Int']['input'];
  channel?: InputMaybe<TranslatedChannelCreateOneInput>;
  currency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  stripeInvoiceItemId: Scalars['String']['input'];
};

export type InvoiceItemUpdateOneWithoutTranslatedVideoInput = {
  connect?: InputMaybe<InvoiceItemWhereUniqueInput>;
  create?: InputMaybe<InvoiceItemCreateWithoutTranslatedVideoInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<InvoiceItemUpdateWithoutTranslatedVideoDataInput>;
  upsert?: InputMaybe<InvoiceItemUpsertWithoutTranslatedVideoInput>;
};

export type InvoiceItemUpdateWithoutTranslatedVideoDataInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  channel?: InputMaybe<TranslatedChannelUpdateOneInput>;
  currency?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceItemId?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceItemUpsertWithoutTranslatedVideoInput = {
  create: InvoiceItemCreateWithoutTranslatedVideoInput;
  update: InvoiceItemUpdateWithoutTranslatedVideoDataInput;
};

export type InvoiceItemWhereInput = {
  AND?: InputMaybe<Array<InvoiceItemWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceItemWhereInput>>;
  OR?: InputMaybe<Array<InvoiceItemWhereInput>>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  amount_gt?: InputMaybe<Scalars['Int']['input']>;
  amount_gte?: InputMaybe<Scalars['Int']['input']>;
  amount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  amount_lt?: InputMaybe<Scalars['Int']['input']>;
  amount_lte?: InputMaybe<Scalars['Int']['input']>;
  amount_not?: InputMaybe<Scalars['Int']['input']>;
  amount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  channel?: InputMaybe<TranslatedChannelWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  currency?: InputMaybe<Scalars['String']['input']>;
  currency_contains?: InputMaybe<Scalars['String']['input']>;
  currency_ends_with?: InputMaybe<Scalars['String']['input']>;
  currency_gt?: InputMaybe<Scalars['String']['input']>;
  currency_gte?: InputMaybe<Scalars['String']['input']>;
  currency_in?: InputMaybe<Array<Scalars['String']['input']>>;
  currency_lt?: InputMaybe<Scalars['String']['input']>;
  currency_lte?: InputMaybe<Scalars['String']['input']>;
  currency_not?: InputMaybe<Scalars['String']['input']>;
  currency_not_contains?: InputMaybe<Scalars['String']['input']>;
  currency_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  currency_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  currency_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  currency_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  stripeInvoiceItemId?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceItemId_contains?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceItemId_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceItemId_gt?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceItemId_gte?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceItemId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeInvoiceItemId_lt?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceItemId_lte?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceItemId_not?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceItemId_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceItemId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceItemId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeInvoiceItemId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeInvoiceItemId_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedVideo?: InputMaybe<TranslatedVideoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type InvoiceItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type LanguageStatus = {
  __typename?: 'LanguageStatus';
  PreviewVoice: Scalars['Int']['output'];
  TTS: Scalars['Int']['output'];
  TunedVoice: Scalars['Int']['output'];
};

export type MlOutput = {
  __typename?: 'MLOutput';
  id: Scalars['ID']['output'];
};

export type MlOutputCreateInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MlOutputCreateOneInput = {
  connect?: InputMaybe<MlOutputWhereUniqueInput>;
  create?: InputMaybe<MlOutputCreateInput>;
};

export type MlOutputUpdateOneInput = {
  connect?: InputMaybe<MlOutputWhereUniqueInput>;
  create?: InputMaybe<MlOutputCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MlOutputWhereInput = {
  AND?: InputMaybe<Array<MlOutputWhereInput>>;
  NOT?: InputMaybe<Array<MlOutputWhereInput>>;
  OR?: InputMaybe<Array<MlOutputWhereInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
};

export type MlOutputWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ManualTaskTime = {
  __typename?: 'ManualTaskTime';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  manualTimeSec: Scalars['Int']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  translatedVideo?: Maybe<TranslatedVideo>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ManualTaskTimeCreateInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  manualTimeSec: Scalars['Int']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  translatedVideo?: InputMaybe<TranslatedVideoCreateOneInput>;
};

export type ManualTaskTimeCreateManyInput = {
  connect?: InputMaybe<Array<ManualTaskTimeWhereUniqueInput>>;
  create?: InputMaybe<Array<ManualTaskTimeCreateInput>>;
};

export enum ManualTaskTimeOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ManualTimeSecAsc = 'manualTimeSec_ASC',
  ManualTimeSecDesc = 'manualTimeSec_DESC',
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ManualTaskTimeScalarWhereInput = {
  AND?: InputMaybe<Array<ManualTaskTimeScalarWhereInput>>;
  NOT?: InputMaybe<Array<ManualTaskTimeScalarWhereInput>>;
  OR?: InputMaybe<Array<ManualTaskTimeScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  manualTimeSec?: InputMaybe<Scalars['Int']['input']>;
  manualTimeSec_gt?: InputMaybe<Scalars['Int']['input']>;
  manualTimeSec_gte?: InputMaybe<Scalars['Int']['input']>;
  manualTimeSec_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  manualTimeSec_lt?: InputMaybe<Scalars['Int']['input']>;
  manualTimeSec_lte?: InputMaybe<Scalars['Int']['input']>;
  manualTimeSec_not?: InputMaybe<Scalars['Int']['input']>;
  manualTimeSec_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  reason?: InputMaybe<Scalars['String']['input']>;
  reason_contains?: InputMaybe<Scalars['String']['input']>;
  reason_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_gt?: InputMaybe<Scalars['String']['input']>;
  reason_gte?: InputMaybe<Scalars['String']['input']>;
  reason_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_lt?: InputMaybe<Scalars['String']['input']>;
  reason_lte?: InputMaybe<Scalars['String']['input']>;
  reason_not?: InputMaybe<Scalars['String']['input']>;
  reason_not_contains?: InputMaybe<Scalars['String']['input']>;
  reason_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  reason_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type ManualTaskTimeUpdateDataInput = {
  manualTimeSec?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  translatedVideo?: InputMaybe<TranslatedVideoUpdateOneInput>;
};

export type ManualTaskTimeUpdateManyDataInput = {
  manualTimeSec?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type ManualTaskTimeUpdateManyInput = {
  connect?: InputMaybe<Array<ManualTaskTimeWhereUniqueInput>>;
  create?: InputMaybe<Array<ManualTaskTimeCreateInput>>;
  delete?: InputMaybe<Array<ManualTaskTimeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ManualTaskTimeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ManualTaskTimeWhereUniqueInput>>;
  set?: InputMaybe<Array<ManualTaskTimeWhereUniqueInput>>;
  update?: InputMaybe<Array<ManualTaskTimeUpdateWithWhereUniqueNestedInput>>;
  updateMany?: InputMaybe<Array<ManualTaskTimeUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<ManualTaskTimeUpsertWithWhereUniqueNestedInput>>;
};

export type ManualTaskTimeUpdateManyWithWhereNestedInput = {
  data: ManualTaskTimeUpdateManyDataInput;
  where: ManualTaskTimeScalarWhereInput;
};

export type ManualTaskTimeUpdateWithWhereUniqueNestedInput = {
  data: ManualTaskTimeUpdateDataInput;
  where: ManualTaskTimeWhereUniqueInput;
};

export type ManualTaskTimeUpsertWithWhereUniqueNestedInput = {
  create: ManualTaskTimeCreateInput;
  update: ManualTaskTimeUpdateDataInput;
  where: ManualTaskTimeWhereUniqueInput;
};

export type ManualTaskTimeWhereInput = {
  AND?: InputMaybe<Array<ManualTaskTimeWhereInput>>;
  NOT?: InputMaybe<Array<ManualTaskTimeWhereInput>>;
  OR?: InputMaybe<Array<ManualTaskTimeWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  manualTimeSec?: InputMaybe<Scalars['Int']['input']>;
  manualTimeSec_gt?: InputMaybe<Scalars['Int']['input']>;
  manualTimeSec_gte?: InputMaybe<Scalars['Int']['input']>;
  manualTimeSec_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  manualTimeSec_lt?: InputMaybe<Scalars['Int']['input']>;
  manualTimeSec_lte?: InputMaybe<Scalars['Int']['input']>;
  manualTimeSec_not?: InputMaybe<Scalars['Int']['input']>;
  manualTimeSec_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  reason?: InputMaybe<Scalars['String']['input']>;
  reason_contains?: InputMaybe<Scalars['String']['input']>;
  reason_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_gt?: InputMaybe<Scalars['String']['input']>;
  reason_gte?: InputMaybe<Scalars['String']['input']>;
  reason_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_lt?: InputMaybe<Scalars['String']['input']>;
  reason_lte?: InputMaybe<Scalars['String']['input']>;
  reason_not?: InputMaybe<Scalars['String']['input']>;
  reason_not_contains?: InputMaybe<Scalars['String']['input']>;
  reason_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  reason_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedVideo?: InputMaybe<TranslatedVideoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type ManualTaskTimeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MimicAudioMetadata = {
  __typename?: 'MimicAudioMetadata';
  createdAt: Scalars['DateTime']['output'];
  generatedBy: User;
  id: Scalars['ID']['output'];
  mimicAudioFile: File;
  segment: Segment;
  updatedAt: Scalars['DateTime']['output'];
};

export type MimicAudioMetadataCreateManyWithoutSegmentInput = {
  connect?: InputMaybe<Array<MimicAudioMetadataWhereUniqueInput>>;
  create?: InputMaybe<Array<MimicAudioMetadataCreateWithoutSegmentInput>>;
};

export type MimicAudioMetadataCreateWithoutSegmentInput = {
  generatedBy: UserCreateOneInput;
  id?: InputMaybe<Scalars['ID']['input']>;
  mimicAudioFile: FileCreateOneInput;
};

export enum MimicAudioMetadataOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type MimicAudioMetadataScalarWhereInput = {
  AND?: InputMaybe<Array<MimicAudioMetadataScalarWhereInput>>;
  NOT?: InputMaybe<Array<MimicAudioMetadataScalarWhereInput>>;
  OR?: InputMaybe<Array<MimicAudioMetadataScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type MimicAudioMetadataUpdateManyWithoutSegmentInput = {
  connect?: InputMaybe<Array<MimicAudioMetadataWhereUniqueInput>>;
  create?: InputMaybe<Array<MimicAudioMetadataCreateWithoutSegmentInput>>;
  delete?: InputMaybe<Array<MimicAudioMetadataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MimicAudioMetadataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MimicAudioMetadataWhereUniqueInput>>;
  set?: InputMaybe<Array<MimicAudioMetadataWhereUniqueInput>>;
  update?: InputMaybe<Array<MimicAudioMetadataUpdateWithWhereUniqueWithoutSegmentInput>>;
  upsert?: InputMaybe<Array<MimicAudioMetadataUpsertWithWhereUniqueWithoutSegmentInput>>;
};

export type MimicAudioMetadataUpdateWithWhereUniqueWithoutSegmentInput = {
  data: MimicAudioMetadataUpdateWithoutSegmentDataInput;
  where: MimicAudioMetadataWhereUniqueInput;
};

export type MimicAudioMetadataUpdateWithoutSegmentDataInput = {
  generatedBy?: InputMaybe<UserUpdateOneRequiredInput>;
  mimicAudioFile?: InputMaybe<FileUpdateOneRequiredInput>;
};

export type MimicAudioMetadataUpsertWithWhereUniqueWithoutSegmentInput = {
  create: MimicAudioMetadataCreateWithoutSegmentInput;
  update: MimicAudioMetadataUpdateWithoutSegmentDataInput;
  where: MimicAudioMetadataWhereUniqueInput;
};

export type MimicAudioMetadataWhereInput = {
  AND?: InputMaybe<Array<MimicAudioMetadataWhereInput>>;
  NOT?: InputMaybe<Array<MimicAudioMetadataWhereInput>>;
  OR?: InputMaybe<Array<MimicAudioMetadataWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  generatedBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  mimicAudioFile?: InputMaybe<FileWhereInput>;
  segment?: InputMaybe<SegmentWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type MimicAudioMetadataWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum ModelType {
  OutputGroup = 'OutputGroup',
  OutputGroupIssue = 'OutputGroupIssue',
  SourceVideo = 'SourceVideo',
  TvStudioStage = 'TVStudioStage',
  TranslatedVideo = 'TranslatedVideo'
}

export type MultipleTranslationRes = {
  __typename?: 'MultipleTranslationRes';
  provider: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addChannelSpeaker: Speaker;
  addDefaultChannel: SourceChannel;
  addDefaultChannelWithManyTranslatedChannels: SourceChannel;
  addEdit: Edit;
  addGoogleVoicesByLanguage: Scalars['String']['output'];
  /** Generates presignedS3Policy for JSON transcript file */
  addJsonTranscriptToSourceVideo: Scalars['Json']['output'];
  addMicrosoftVoicesByLanguage: Scalars['String']['output'];
  /** Creates mimic audio file in the db and Generates presignedS3Policy for video post production. */
  addMimicAudioFile: Scalars['Json']['output'];
  addNewSegment: Segment;
  /** Generates presignedS3Policy to upload tracks */
  addPresignedToTrack: Scalars['Json']['output'];
  /** Generates presignedS3Policy for SRT file */
  addSrtToSourceVideo: Scalars['Json']['output'];
  /** Generates presignedS3Policy for SRT file */
  addTargetSrtTranscript: Scalars['Json']['output'];
  /** Adds track to source video */
  addTrackToSourceVideo: Scalars['Json']['output'];
  addTranslatedChannelsToSourceChannel: Scalars['String']['output'];
  addTranslationToOnScreenText?: Maybe<OnScreenTextSource>;
  /**
   *
   *       Create a Source Video object with a presignedS3Policy to
   *       use to manually upload the video. Does not create a Translated Video.
   */
  addUploadedVideoToSource: SourceVideo;
  addYouTubeChannel: SourceChannel;
  /**
   *
   *       Create a Translated Video from a YouTube video. If a Source Video doesn't
   *       exist for the YouTube video it will also be created.
   */
  addYouTubeVideo: SourceVideo;
  /**
   *
   *       Same as "addYouTubeVideo", but will create a new TranslatedVideo for each
   *       channel in "translations" input array.
   */
  addYouTubeVideoToSource: SourceVideo;
  applySegmentCorrection?: Maybe<Segment>;
  archiveCorrection: Scalars['String']['output'];
  /** Used to assign one Speech Output of many, generated from features such as Prosody Buffet or Multiple Renditions */
  assignSpeechOutputToSegment?: Maybe<Segment>;
  /** Assigns a userId to many translated videos */
  assignUserToManyTranslatedVideos: BatchPayload;
  calculateOutputGroupQualityScore: Scalars['Float']['output'];
  cancelChatterboxWorkflow: SpeechOutput;
  captureOnScreenText: OnScreenTextSource;
  clearYouTubePlaylistItemsCache: Scalars['String']['output'];
  /** collect Mimic Data for the most recent Output Group of the Translated Video */
  collectMimicData?: Maybe<Scalars['Json']['output']>;
  confirmManualTranscriptUploaded?: Maybe<SourceVideo>;
  /** Validates that the file has been uploaded at the expected location, and then uploads the video to the correct folder in Frame */
  confirmPostProductionFileUpload?: Maybe<OutputGroup>;
  /**
   *
   *         - Checks a file has been uploaded to the presignedS3Policy of a Source Video.
   *         - Creates TranslatedVideos for each object in the "translations" arg array.
   *         - Transitions the Source Video.
   *
   */
  confirmUploadedVideoAndTranslations?: Maybe<SourceVideo>;
  createApprovedInvoiceDate: ApprovedInvoiceDate;
  createCorrection: Correction;
  createCorrectionDraftForSegment: CorrectionDraft;
  createJWTToken: Scalars['String']['output'];
  createManualTaskTime: ManualTaskTime;
  createMultipleRenditionsForSegment?: Maybe<Segment>;
  createOutputGroupIssue: OutputGroupIssue;
  createPreUser: PreUser;
  /** Generates presignedS3Policy for a post production file */
  createPresignedPostForPostProdFile: Scalars['Json']['output'];
  createProsodyBuffetForSegment?: Maybe<Segment>;
  createSegment: Segment;
  createSegmentAnnotation: SegmentAnnotation;
  createSession: Session;
  createSpeechOutputFeedback: SpeechOutputFeedback;
  /** create source video and translated video from backup */
  createSvWithTvFromBackup: Scalars['String']['output'];
  /** Use to create a test TV Overlap Record */
  createTestTranslatedVideoSnapshot: TranslatedVideoSnapshot;
  /** create serialise a translated video and store on s3 */
  createTranslatedVideoBackup: Scalars['String']['output'];
  createUser: User;
  createVoice: Voice;
  deleteApprovedInvoiceDate?: Maybe<ApprovedInvoiceDate>;
  deleteEdit?: Maybe<Edit>;
  deleteOnScreenTextSource?: Maybe<OnScreenTextSource>;
  deleteOnScreenTextTranslation?: Maybe<OnScreenTextTranslation>;
  deleteOutputGroupIssue?: Maybe<OutputGroupIssue>;
  deletePreUser?: Maybe<PreUser>;
  deleteSegment?: Maybe<Segment>;
  deleteSegmentAnnotation?: Maybe<SegmentAnnotation>;
  deleteSession?: Maybe<Session>;
  /** Deletes all cloud files relating to a source video from cloud storage. This also includes all Outputs. */
  deleteSourceVideoAndOutputGroupFiles?: Maybe<SourceVideo>;
  deleteTrack?: Maybe<Track>;
  enableTranslatedVideoStudioStages?: Maybe<TranslatedVideo>;
  /** Generate a CSV on S3 containing all corrections usages that match the input criteria. If 'email' is provided, an email will be sent on completion. */
  generateCorrectionUsage: Scalars['String']['output'];
  /** Generate new SpeechOutput for the segment */
  generateSpeechOutputForSegment?: Maybe<Segment>;
  generateSplitAudio?: Maybe<SourceVideo>;
  /** Generates presignedS3Policy to upload glossary */
  getPresignedUrlForGlossary: Scalars['Json']['output'];
  /**
   * Reset a translated video to the state when first added.
   * WARNING: This will delete all existing segments and samples for that video.
   */
  hardResetTranslatedVideo?: Maybe<TranslatedVideo>;
  heartbeatSession?: Maybe<Session>;
  /** Create a new segment that is the result of merging the 2 input segments. The input segments will be marked as archived. */
  mergeSegments: Segment;
  migrateCorrection?: Maybe<Correction>;
  /** Migrate the Segments on a DMv1 TV to DMv3. Creates SpeechInputData objects on all the Segments */
  migrateTranslatedVideoToDatamodelV3?: Maybe<TranslatedVideo>;
  /** Generate new SpeechOutputs for all Segments on provided Translated Videos. */
  regenerateOutOfDateSegmentsForTVsById: Scalars['String']['output'];
  /** Generate SpeechOutput for all Segments on TVs that meet the input criteria. */
  regenerateOutOfDateSegmentsForTVsByState: Scalars['String']['output'];
  /** Remove mimic audio file from speechInputData (soft delete) */
  removeMimicAudioFile?: Maybe<Segment>;
  /** create serialise a translated video and store on s3 */
  restoreTranslatedVideoFromBackup: Scalars['String']['output'];
  /** Retranslate the source text for a segment */
  retranslateSegment?: Maybe<Segment>;
  retrySourceVideoState?: Maybe<SourceVideo>;
  retryTranslatedVideoState?: Maybe<TranslatedVideo>;
  scoreSegments: BatchPayload;
  /** Send a 'Ready for User email' */
  sendReadyForUserEmail: Scalars['String']['output'];
  /** Send a test generic toAddress */
  sendTestGenericEmail: Scalars['String']['output'];
  /** Send a test Output Group complete email */
  sendTestOutputCompleteEmail: Scalars['String']['output'];
  /** Send a test 'Ready for User email' */
  sendTestReadyForUserEmail: Scalars['String']['output'];
  setOutputGroupIssueStatus?: Maybe<OutputGroupIssue>;
  /** Set the video complexity 1 (low) - 3 (high) of a translated video (predicted by QA). */
  setVideoComplexityQA?: Maybe<TranslatedVideo>;
  /** Set the video complexity 1 (low) - 3 (high) of a translated video (predicted by Quality Controller). */
  setVideoComplexityQC?: Maybe<TranslatedVideo>;
  /** Set the video complexity rating of a translated video. */
  setVideoComplexityRating?: Maybe<TranslatedVideo>;
  /** Update the voiceConversion type for the Segment */
  setVoiceConversionType?: Maybe<Segment>;
  /**
   * "Split a segment into 2 new segments based on the Segment "source" time".
   *
   * If "splitTime" input is not provided, is within 5% of the "source" start or end time,
   * less than the start time, or greater than the end time, then the mid time-point
   * of the "source" will be used as the splitTime.
   *
   * Words (both "source lang" and "target lang") from the original Segment
   * are split by calculating a ratio of number of words based on the "splitTime"
   * applied to the "source" duration of the Segment.
   *
   * The input segment will be marked as archived.
   *
   */
  splitSegment: Array<Segment>;
  transitionAllSegmentStudioStageCompleteStates: BatchPayload;
  transitionSegmentStudioStageCompleteState?: Maybe<Segment>;
  transitionTranslatedVideo?: Maybe<TranslatedVideo>;
  transitionTranslatedVideoStudioStage?: Maybe<TranslatedVideo>;
  translateWithMultipleProviders: Array<MultipleTranslationRes>;
  updateAppropriateQualityScore?: Maybe<OutputGroup>;
  /** Updates the overlaps allowed value for many videos */
  updateAudibleOverlapsForManyTranslatedVideos: BatchPayload;
  updateCorrection?: Maybe<Correction>;
  /** Updates any of the due date fields for many videos */
  updateDueDateForManyTranslatedVideos: BatchPayload;
  updateEdit?: Maybe<Edit>;
  updateManySegments: BatchPayload;
  updateNote?: Maybe<Note>;
  updateOnScreenTextTranslation?: Maybe<OnScreenTextTranslation>;
  updateOutputGroup?: Maybe<OutputGroup>;
  updateOutputGroupIssue?: Maybe<OutputGroupIssue>;
  updateSegment?: Maybe<Segment>;
  updateSession?: Maybe<Session>;
  updateSessionVideoTime?: Maybe<SessionVideoTime>;
  updateSourceChannel?: Maybe<SourceChannel>;
  updateSourceVideo?: Maybe<SourceVideo>;
  updateSpeaker?: Maybe<Speaker>;
  /** Update SpeechInputs on many Segments */
  updateSpeechInputDataForManySegments: BatchPayload;
  /** Updates the statevValue of many translated videos. */
  updateStateValueForManyTranslatedVideos: BatchPayload;
  updateTrack?: Maybe<Track>;
  updateTranslatedChannel?: Maybe<TranslatedChannel>;
  updateTranslatedChannelMetadata?: Maybe<TranslatedChannel>;
  updateTranslatedVideo?: Maybe<TranslatedVideo>;
  updateTranslatedVideoChannelMetadata?: Maybe<TranslatedVideo>;
  updateTranslatedVideoMetadata?: Maybe<TranslatedVideo>;
  updateUser?: Maybe<User>;
  updateVoice?: Maybe<Voice>;
  upsertManualTaskTimes: Array<Session>;
  /** Check if mimic audio file was uploaded successfully. */
  wasMimicAudioFileUploadedSuccessfully: Scalars['Boolean']['output'];
};


export type MutationAddChannelSpeakerArgs = {
  speakerName?: InputMaybe<Scalars['String']['input']>;
  translatedChannelId: Scalars['ID']['input'];
};


export type MutationAddDefaultChannelArgs = {
  sourceLanguage: Scalars['String']['input'];
  targetLanguage: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationAddDefaultChannelWithManyTranslatedChannelsArgs = {
  sourceLanguage: Scalars['String']['input'];
  targetLanguages: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};


export type MutationAddEditArgs = {
  endTime: Scalars['Float']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  sourceVideoId: Scalars['ID']['input'];
  startTime: Scalars['Float']['input'];
  type: EditType;
};


export type MutationAddGoogleVoicesByLanguageArgs = {
  language: Scalars['String']['input'];
};


export type MutationAddJsonTranscriptToSourceVideoArgs = {
  sourceVideoId: Scalars['ID']['input'];
};


export type MutationAddMicrosoftVoicesByLanguageArgs = {
  language: Scalars['String']['input'];
};


export type MutationAddMimicAudioFileArgs = {
  segmentId: Scalars['ID']['input'];
  voiceConversionType: VoiceConversionTypeEnum;
};


export type MutationAddNewSegmentArgs = {
  endTimeMs?: InputMaybe<Scalars['Int']['input']>;
  sourceText?: InputMaybe<Scalars['String']['input']>;
  startTimeMs: Scalars['Int']['input'];
  translatedText?: InputMaybe<Scalars['String']['input']>;
  tvId: Scalars['ID']['input'];
};


export type MutationAddPresignedToTrackArgs = {
  fileExtension: Scalars['String']['input'];
  sourceVideoId: Scalars['ID']['input'];
};


export type MutationAddSrtToSourceVideoArgs = {
  sourceVideoId: Scalars['ID']['input'];
};


export type MutationAddTargetSrtTranscriptArgs = {
  sourceVideoId: Scalars['ID']['input'];
  translatedChannelId: Scalars['ID']['input'];
};


export type MutationAddTrackToSourceVideoArgs = {
  bucket: Scalars['String']['input'];
  fileStorageKey: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sourceVideoId: Scalars['ID']['input'];
  volumeAdj: Scalars['Float']['input'];
};


export type MutationAddTranslatedChannelsToSourceChannelArgs = {
  sourceChannelId: Scalars['ID']['input'];
  targetLanguages: Array<Scalars['String']['input']>;
};


export type MutationAddTranslationToOnScreenTextArgs = {
  onScreenTextId: Scalars['ID']['input'];
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedVideoId: Scalars['ID']['input'];
};


export type MutationAddUploadedVideoToSourceArgs = {
  sourceChannelId: Scalars['ID']['input'];
};


export type MutationAddYouTubeChannelArgs = {
  lookupType?: InputMaybe<YtChannelLookupType>;
  lookupValue: Scalars['String']['input'];
  sourceLanguage: Scalars['String']['input'];
  targetLanguage: Scalars['String']['input'];
  ytChannelTitle?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddYouTubeVideoArgs = {
  copyFromTvId?: InputMaybe<Scalars['ID']['input']>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  lookupType?: InputMaybe<YtVideoLookupType>;
  lookupValue: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedChannelId: Scalars['ID']['input'];
};


export type MutationAddYouTubeVideoToSourceArgs = {
  copyFromTvId?: InputMaybe<Scalars['ID']['input']>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  lookupType?: InputMaybe<YtVideoLookupType>;
  lookupValue?: InputMaybe<Scalars['String']['input']>;
  sourceChannelId?: InputMaybe<Scalars['ID']['input']>;
  sourceVideoId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  translations?: InputMaybe<Array<Translation>>;
};


export type MutationApplySegmentCorrectionArgs = {
  applicationLevel: CorrectionApplicationLevel;
  correctionDraftId: Scalars['ID']['input'];
  correctionId: Scalars['ID']['input'];
  segmentId: Scalars['ID']['input'];
};


export type MutationArchiveCorrectionArgs = {
  correctionId: Scalars['ID']['input'];
};


export type MutationAssignSpeechOutputToSegmentArgs = {
  segmentId: Scalars['ID']['input'];
  speechOutputId: Scalars['ID']['input'];
};


export type MutationAssignUserToManyTranslatedVideosArgs = {
  role: AssignableRoleForTranslatedVideoEnumType;
  translatedVideoIds: Array<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};


export type MutationCalculateOutputGroupQualityScoreArgs = {
  outputGroupId: Scalars['ID']['input'];
};


export type MutationCancelChatterboxWorkflowArgs = {
  speechOutputId: Scalars['ID']['input'];
};


export type MutationCaptureOnScreenTextArgs = {
  endTimeSec: Scalars['Float']['input'];
  onScreenTextType?: InputMaybe<OnScreenTextType>;
  sourceText: Scalars['String']['input'];
  sourceVideoId: Scalars['ID']['input'];
  startTimeSec: Scalars['Float']['input'];
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedVideoId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCollectMimicDataArgs = {
  translatedVideoId: Scalars['ID']['input'];
};


export type MutationConfirmManualTranscriptUploadedArgs = {
  sourceVideoId: Scalars['ID']['input'];
};


export type MutationConfirmPostProductionFileUploadArgs = {
  fileType: OutputType;
  initialFrameProjectType: FrameProjectType;
  outputGroupId: Scalars['ID']['input'];
  s3Key: Scalars['String']['input'];
};


export type MutationConfirmUploadedVideoAndTranslationsArgs = {
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  sourceVideoId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translations?: InputMaybe<Array<Translation>>;
};


export type MutationCreateApprovedInvoiceDateArgs = {
  data: ApprovedInvoiceDateCreateInput;
};


export type MutationCreateCorrectionArgs = {
  applicationLevel?: InputMaybe<CorrectionApplicationLevel>;
  applyToAllSpeakers?: InputMaybe<Scalars['Boolean']['input']>;
  channelId?: InputMaybe<Scalars['ID']['input']>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  originalText: Scalars['String']['input'];
  originalWord: Scalars['String']['input'];
  pronunciationLanguage?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  segmentId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateCorrectionDraftForSegmentArgs = {
  correctedWord: Scalars['String']['input'];
  correctedWordLanguageCode?: InputMaybe<Scalars['String']['input']>;
  correctionId: Scalars['ID']['input'];
  correctionType?: InputMaybe<CorrectionType>;
  markedupText: Scalars['String']['input'];
  segmentId: Scalars['ID']['input'];
};


export type MutationCreateJwtTokenArgs = {
  authId: Scalars['ID']['input'];
  expireIn?: InputMaybe<Scalars['Int']['input']>;
  organisationName?: InputMaybe<Scalars['String']['input']>;
  tvIds: Array<Scalars['ID']['input']>;
};


export type MutationCreateManualTaskTimeArgs = {
  data: ManualTaskTimeCreateInput;
};


export type MutationCreateMultipleRenditionsForSegmentArgs = {
  isTunedSpeechMode: Scalars['Boolean']['input'];
  segmentId: Scalars['ID']['input'];
  targetLanguageCode: Scalars['String']['input'];
  translatedChannelId: Scalars['ID']['input'];
  translatedVideoId: Scalars['ID']['input'];
};


export type MutationCreateOutputGroupIssueArgs = {
  category: Scalars['String']['input'];
  isMajor?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  outputGroupId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
  startTime: Scalars['Float']['input'];
};


export type MutationCreatePreUserArgs = {
  data: PreUserCreateInput;
};


export type MutationCreatePresignedPostForPostProdFileArgs = {
  fileType: OutputType;
  filename: Scalars['String']['input'];
  outputGroupId: Scalars['ID']['input'];
};


export type MutationCreateProsodyBuffetForSegmentArgs = {
  isTunedSpeechMode: Scalars['Boolean']['input'];
  prosodyTemplateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  segmentId: Scalars['ID']['input'];
  targetLanguageCode: Scalars['String']['input'];
  translatedChannelId: Scalars['ID']['input'];
  translatedVideoId: Scalars['ID']['input'];
};


export type MutationCreateSegmentArgs = {
  data: SegmentCreateInput;
};


export type MutationCreateSegmentAnnotationArgs = {
  details?: InputMaybe<Scalars['String']['input']>;
  segmentId: Scalars['ID']['input'];
  type: SegmentAnnotationType;
};


export type MutationCreateSessionArgs = {
  data: SessionCreateInput;
};


export type MutationCreateSpeechOutputFeedbackArgs = {
  data: SpeechOutputFeedbackCreateInput;
};


export type MutationCreateSvWithTvFromBackupArgs = {
  backupS3Bucket?: InputMaybe<Scalars['String']['input']>;
  backupS3Key: Scalars['String']['input'];
  copyMimicFiles?: InputMaybe<Scalars['Boolean']['input']>;
  targetTvState?: InputMaybe<TranslatedVideoState>;
  translatedChannelId: Scalars['String']['input'];
};


export type MutationCreateTestTranslatedVideoSnapshotArgs = {
  tvId: Scalars['ID']['input'];
};


export type MutationCreateTranslatedVideoBackupArgs = {
  translatedVideoId: Scalars['ID']['input'];
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateVoiceArgs = {
  data: VoiceCreateInput;
};


export type MutationDeleteApprovedInvoiceDateArgs = {
  where: ApprovedInvoiceDateWhereUniqueInput;
};


export type MutationDeleteEditArgs = {
  where: EditWhereUniqueInput;
};


export type MutationDeleteOnScreenTextSourceArgs = {
  where: OnScreenTextSourceWhereUniqueInput;
};


export type MutationDeleteOnScreenTextTranslationArgs = {
  where: OnScreenTextTranslationWhereUniqueInput;
};


export type MutationDeleteOutputGroupIssueArgs = {
  where: OutputGroupIssueWhereUniqueInput;
};


export type MutationDeletePreUserArgs = {
  where: PreUserWhereUniqueInput;
};


export type MutationDeleteSegmentArgs = {
  where: SegmentWhereUniqueInput;
};


export type MutationDeleteSegmentAnnotationArgs = {
  where: SegmentAnnotationWhereUniqueInput;
};


export type MutationDeleteSessionArgs = {
  where: SessionWhereUniqueInput;
};


export type MutationDeleteSourceVideoAndOutputGroupFilesArgs = {
  sourceVideoId: Scalars['ID']['input'];
};


export type MutationDeleteTrackArgs = {
  where: TrackWhereUniqueInput;
};


export type MutationEnableTranslatedVideoStudioStagesArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGenerateCorrectionUsageArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  translatedChannelId?: InputMaybe<Scalars['ID']['input']>;
  translatedVideoId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationGenerateSpeechOutputForSegmentArgs = {
  forceFeaturesRegen?: InputMaybe<Scalars['Boolean']['input']>;
  isTunedSpeechMode?: InputMaybe<Scalars['Boolean']['input']>;
  segmentId: Scalars['ID']['input'];
};


export type MutationGenerateSplitAudioArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGetPresignedUrlForGlossaryArgs = {
  translatedChannelId: Scalars['ID']['input'];
};


export type MutationHardResetTranslatedVideoArgs = {
  copyFromTvId?: InputMaybe<Scalars['ID']['input']>;
  translatedVideoId: Scalars['ID']['input'];
};


export type MutationHeartbeatSessionArgs = {
  page: SupportedPageEnumType;
  translatedChannelId?: InputMaybe<Scalars['ID']['input']>;
  translatedVideoId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationMergeSegmentsArgs = {
  segment1Id: Scalars['ID']['input'];
  segment2Id: Scalars['ID']['input'];
  translatedVideoId: Scalars['ID']['input'];
};


export type MutationMigrateCorrectionArgs = {
  correctionId: Scalars['ID']['input'];
  generateAudioForFinalDraft?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationMigrateTranslatedVideoToDatamodelV3Args = {
  tvId: Scalars['ID']['input'];
};


export type MutationRegenerateOutOfDateSegmentsForTVsByIdArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationRegenerateOutOfDateSegmentsForTVsByStateArgs = {
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  isTunedSpeechMode?: InputMaybe<Scalars['Boolean']['input']>;
  notificationEmail?: InputMaybe<Scalars['String']['input']>;
  onlyRegenerateOutOfDateSegments: Scalars['Boolean']['input'];
  stateValue_in: Array<TranslatedVideoState>;
};


export type MutationRemoveMimicAudioFileArgs = {
  segmentId: Scalars['ID']['input'];
};


export type MutationRestoreTranslatedVideoFromBackupArgs = {
  backupS3Bucket?: InputMaybe<Scalars['String']['input']>;
  backupS3Key: Scalars['String']['input'];
  targetTvState?: InputMaybe<TranslatedVideoState>;
  translatedVideoId: Scalars['ID']['input'];
};


export type MutationRetranslateSegmentArgs = {
  segmentId: Scalars['ID']['input'];
};


export type MutationRetrySourceVideoStateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRetryTranslatedVideoStateArgs = {
  copyFromTvId?: InputMaybe<Scalars['ID']['input']>;
  translatedVideoId: Scalars['ID']['input'];
};


export type MutationScoreSegmentsArgs = {
  scores?: InputMaybe<Array<SegmentTerScore>>;
};


export type MutationSendReadyForUserEmailArgs = {
  role: UserRole;
  tvId: Scalars['ID']['input'];
};


export type MutationSendTestGenericEmailArgs = {
  message: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  toAddress: Scalars['String']['input'];
};


export type MutationSendTestOutputCompleteEmailArgs = {
  ogId?: InputMaybe<Scalars['ID']['input']>;
  overrideEmail?: InputMaybe<Scalars['String']['input']>;
  tvId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSendTestReadyForUserEmailArgs = {
  overrideEmail?: InputMaybe<Scalars['String']['input']>;
  tvId: Scalars['ID']['input'];
};


export type MutationSetOutputGroupIssueStatusArgs = {
  id: Scalars['ID']['input'];
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  status: OutputGroupIssueStatus;
};


export type MutationSetVideoComplexityQaArgs = {
  translatedVideoId: Scalars['ID']['input'];
  videoComplexity: Scalars['Int']['input'];
};


export type MutationSetVideoComplexityQcArgs = {
  translatedVideoId: Scalars['ID']['input'];
  videoComplexity: Scalars['Int']['input'];
};


export type MutationSetVideoComplexityRatingArgs = {
  maxRating: Scalars['Int']['input'];
  minRating: Scalars['Int']['input'];
  reviewerRole: ReviewerRole;
  translatedVideoId: Scalars['ID']['input'];
  videoComplexityRating?: InputMaybe<Array<VideoComplexityRatingInput>>;
};


export type MutationSetVoiceConversionTypeArgs = {
  segmentId: Scalars['ID']['input'];
  voiceConversionType?: InputMaybe<VoiceConversionTypeEnum>;
};


export type MutationSplitSegmentArgs = {
  segmentId: Scalars['ID']['input'];
  splitTime?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationTransitionAllSegmentStudioStageCompleteStatesArgs = {
  isTunedSpeechMode?: Scalars['Boolean']['input'];
  tvId: Scalars['ID']['input'];
};


export type MutationTransitionSegmentStudioStageCompleteStateArgs = {
  id: Scalars['ID']['input'];
  isCurrStudioStageDone: Scalars['Boolean']['input'];
};


export type MutationTransitionTranslatedVideoArgs = {
  eventType: TranslatedVideoEventEnumType;
  id: Scalars['ID']['input'];
};


export type MutationTransitionTranslatedVideoStudioStageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationTranslateWithMultipleProvidersArgs = {
  segmentId: Scalars['ID']['input'];
};


export type MutationUpdateAppropriateQualityScoreArgs = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  outputGroupId: Scalars['ID']['input'];
};


export type MutationUpdateAudibleOverlapsForManyTranslatedVideosArgs = {
  areAudibleOverlapsAllowed: Scalars['Boolean']['input'];
  translatedVideoIds: Array<Scalars['ID']['input']>;
};


export type MutationUpdateCorrectionArgs = {
  data: CorrectionUpdateInput;
  where: CorrectionWhereUniqueInput;
};


export type MutationUpdateDueDateForManyTranslatedVideosArgs = {
  dueDate: Scalars['String']['input'];
  dueDateFieldToUpdate: DueDateFieldOnTranslatedVideoEnumType;
  translatedVideoIds: Array<Scalars['ID']['input']>;
};


export type MutationUpdateEditArgs = {
  endTime?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<EditType>;
};


export type MutationUpdateManySegmentsArgs = {
  data: SegmentUpdateManyMutationInput;
  where?: InputMaybe<SegmentWhereInput>;
};


export type MutationUpdateNoteArgs = {
  data: NoteUpdateInput;
  where: NoteWhereUniqueInput;
};


export type MutationUpdateOnScreenTextTranslationArgs = {
  onScreenTextTranslationId: Scalars['ID']['input'];
  translatedText?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOutputGroupArgs = {
  data: OutputGroupUpdateInput;
  where: OutputGroupWhereUniqueInput;
};


export type MutationUpdateOutputGroupIssueArgs = {
  data: OutputGroupIssueUpdateInput;
  where: OutputGroupIssueWhereUniqueInput;
};


export type MutationUpdateSegmentArgs = {
  data: SegmentUpdateInput;
  where: SegmentWhereUniqueInput;
};


export type MutationUpdateSessionArgs = {
  data: SessionUpdateInput;
  where: SessionWhereUniqueInput;
};


export type MutationUpdateSessionVideoTimeArgs = {
  data: SessionVideoTimeUpdateInput;
  where: SessionVideoTimeWhereUniqueInput;
};


export type MutationUpdateSourceChannelArgs = {
  data: SourceChannelUpdateInput;
  where: SourceChannelWhereUniqueInput;
};


export type MutationUpdateSourceVideoArgs = {
  data: SourceVideoUpdateInput;
  where: SourceVideoWhereUniqueInput;
};


export type MutationUpdateSpeakerArgs = {
  data: SpeakerUpdateInput;
  where: SpeakerWhereUniqueInput;
};


export type MutationUpdateSpeechInputDataForManySegmentsArgs = {
  segmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  speakerCode?: InputMaybe<Scalars['String']['input']>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  styleCode?: InputMaybe<Scalars['String']['input']>;
  tvId: Scalars['ID']['input'];
};


export type MutationUpdateStateValueForManyTranslatedVideosArgs = {
  stateValue: TranslatedVideoState;
  translatedVideoIds: Array<Scalars['ID']['input']>;
};


export type MutationUpdateTrackArgs = {
  data: TrackUpdateInput;
  where: TrackWhereUniqueInput;
};


export type MutationUpdateTranslatedChannelArgs = {
  data: TranslatedChannelUpdateInput;
  where: TranslatedChannelWhereUniqueInput;
};


export type MutationUpdateTranslatedChannelMetadataArgs = {
  data: Scalars['String']['input'];
  translatedChannelId: Scalars['ID']['input'];
};


export type MutationUpdateTranslatedVideoArgs = {
  data: TranslatedVideoUpdateInput;
  where: TranslatedVideoWhereUniqueInput;
};


export type MutationUpdateTranslatedVideoChannelMetadataArgs = {
  data: Scalars['String']['input'];
  translatedVideoId: Scalars['ID']['input'];
};


export type MutationUpdateTranslatedVideoMetadataArgs = {
  data: Scalars['String']['input'];
  translatedVideoId: Scalars['ID']['input'];
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateVoiceArgs = {
  age?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  speakerCode: Scalars['String']['input'];
  styles?: InputMaybe<Array<Scalars['String']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpsertManualTaskTimesArgs = {
  manualTaskTimes: Array<ManualTaskTimeCreateInput>;
  sessionId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationWasMimicAudioFileUploadedSuccessfullyArgs = {
  mimicAudioMetadataId: Scalars['ID']['input'];
};

export type Note = {
  __typename?: 'Note';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  details: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sourceVideo?: Maybe<SourceVideo>;
  translatedVideo?: Maybe<TranslatedVideo>;
  type: NoteType;
  updatedAt: Scalars['DateTime']['output'];
};

export type NoteCreateManyWithoutSourceVideoInput = {
  connect?: InputMaybe<Array<NoteWhereUniqueInput>>;
  create?: InputMaybe<Array<NoteCreateWithoutSourceVideoInput>>;
};

export type NoteCreateManyWithoutTranslatedVideoInput = {
  connect?: InputMaybe<Array<NoteWhereUniqueInput>>;
  create?: InputMaybe<Array<NoteCreateWithoutTranslatedVideoInput>>;
};

export type NoteCreateWithoutSourceVideoInput = {
  createdBy: UserCreateOneInput;
  details: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  translatedVideo?: InputMaybe<TranslatedVideoCreateOneWithoutNotesInput>;
  type: NoteType;
};

export type NoteCreateWithoutTranslatedVideoInput = {
  createdBy: UserCreateOneInput;
  details: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  sourceVideo?: InputMaybe<SourceVideoCreateOneWithoutNotesInput>;
  type: NoteType;
};

export enum NoteOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DetailsAsc = 'details_ASC',
  DetailsDesc = 'details_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type NoteScalarWhereInput = {
  AND?: InputMaybe<Array<NoteScalarWhereInput>>;
  NOT?: InputMaybe<Array<NoteScalarWhereInput>>;
  OR?: InputMaybe<Array<NoteScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  details?: InputMaybe<Scalars['String']['input']>;
  details_contains?: InputMaybe<Scalars['String']['input']>;
  details_ends_with?: InputMaybe<Scalars['String']['input']>;
  details_gt?: InputMaybe<Scalars['String']['input']>;
  details_gte?: InputMaybe<Scalars['String']['input']>;
  details_in?: InputMaybe<Array<Scalars['String']['input']>>;
  details_lt?: InputMaybe<Scalars['String']['input']>;
  details_lte?: InputMaybe<Scalars['String']['input']>;
  details_not?: InputMaybe<Scalars['String']['input']>;
  details_not_contains?: InputMaybe<Scalars['String']['input']>;
  details_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  details_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  details_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  details_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<NoteType>;
  type_in?: InputMaybe<Array<NoteType>>;
  type_not?: InputMaybe<NoteType>;
  type_not_in?: InputMaybe<Array<NoteType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export enum NoteType {
  ForEditor = 'ForEditor',
  ForQc = 'ForQC',
  Other = 'Other'
}

export type NoteUpdateInput = {
  createdBy?: InputMaybe<UserUpdateOneRequiredInput>;
  details?: InputMaybe<Scalars['String']['input']>;
  sourceVideo?: InputMaybe<SourceVideoUpdateOneWithoutNotesInput>;
  translatedVideo?: InputMaybe<TranslatedVideoUpdateOneWithoutNotesInput>;
  type?: InputMaybe<NoteType>;
};

export type NoteUpdateManyDataInput = {
  details?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<NoteType>;
};

export type NoteUpdateManyWithWhereNestedInput = {
  data: NoteUpdateManyDataInput;
  where: NoteScalarWhereInput;
};

export type NoteUpdateManyWithoutSourceVideoInput = {
  connect?: InputMaybe<Array<NoteWhereUniqueInput>>;
  create?: InputMaybe<Array<NoteCreateWithoutSourceVideoInput>>;
  delete?: InputMaybe<Array<NoteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NoteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NoteWhereUniqueInput>>;
  set?: InputMaybe<Array<NoteWhereUniqueInput>>;
  update?: InputMaybe<Array<NoteUpdateWithWhereUniqueWithoutSourceVideoInput>>;
  updateMany?: InputMaybe<Array<NoteUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<NoteUpsertWithWhereUniqueWithoutSourceVideoInput>>;
};

export type NoteUpdateManyWithoutTranslatedVideoInput = {
  connect?: InputMaybe<Array<NoteWhereUniqueInput>>;
  create?: InputMaybe<Array<NoteCreateWithoutTranslatedVideoInput>>;
  delete?: InputMaybe<Array<NoteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NoteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NoteWhereUniqueInput>>;
  set?: InputMaybe<Array<NoteWhereUniqueInput>>;
  update?: InputMaybe<Array<NoteUpdateWithWhereUniqueWithoutTranslatedVideoInput>>;
  updateMany?: InputMaybe<Array<NoteUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<NoteUpsertWithWhereUniqueWithoutTranslatedVideoInput>>;
};

export type NoteUpdateWithWhereUniqueWithoutSourceVideoInput = {
  data: NoteUpdateWithoutSourceVideoDataInput;
  where: NoteWhereUniqueInput;
};

export type NoteUpdateWithWhereUniqueWithoutTranslatedVideoInput = {
  data: NoteUpdateWithoutTranslatedVideoDataInput;
  where: NoteWhereUniqueInput;
};

export type NoteUpdateWithoutSourceVideoDataInput = {
  createdBy?: InputMaybe<UserUpdateOneRequiredInput>;
  details?: InputMaybe<Scalars['String']['input']>;
  translatedVideo?: InputMaybe<TranslatedVideoUpdateOneWithoutNotesInput>;
  type?: InputMaybe<NoteType>;
};

export type NoteUpdateWithoutTranslatedVideoDataInput = {
  createdBy?: InputMaybe<UserUpdateOneRequiredInput>;
  details?: InputMaybe<Scalars['String']['input']>;
  sourceVideo?: InputMaybe<SourceVideoUpdateOneWithoutNotesInput>;
  type?: InputMaybe<NoteType>;
};

export type NoteUpsertWithWhereUniqueWithoutSourceVideoInput = {
  create: NoteCreateWithoutSourceVideoInput;
  update: NoteUpdateWithoutSourceVideoDataInput;
  where: NoteWhereUniqueInput;
};

export type NoteUpsertWithWhereUniqueWithoutTranslatedVideoInput = {
  create: NoteCreateWithoutTranslatedVideoInput;
  update: NoteUpdateWithoutTranslatedVideoDataInput;
  where: NoteWhereUniqueInput;
};

export type NoteWhereInput = {
  AND?: InputMaybe<Array<NoteWhereInput>>;
  NOT?: InputMaybe<Array<NoteWhereInput>>;
  OR?: InputMaybe<Array<NoteWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  details?: InputMaybe<Scalars['String']['input']>;
  details_contains?: InputMaybe<Scalars['String']['input']>;
  details_ends_with?: InputMaybe<Scalars['String']['input']>;
  details_gt?: InputMaybe<Scalars['String']['input']>;
  details_gte?: InputMaybe<Scalars['String']['input']>;
  details_in?: InputMaybe<Array<Scalars['String']['input']>>;
  details_lt?: InputMaybe<Scalars['String']['input']>;
  details_lte?: InputMaybe<Scalars['String']['input']>;
  details_not?: InputMaybe<Scalars['String']['input']>;
  details_not_contains?: InputMaybe<Scalars['String']['input']>;
  details_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  details_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  details_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  details_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  sourceVideo?: InputMaybe<SourceVideoWhereInput>;
  translatedVideo?: InputMaybe<TranslatedVideoWhereInput>;
  type?: InputMaybe<NoteType>;
  type_in?: InputMaybe<Array<NoteType>>;
  type_not?: InputMaybe<NoteType>;
  type_not_in?: InputMaybe<Array<NoteType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NoteWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type OnScreenTextSource = {
  __typename?: 'OnScreenTextSource';
  endTimeSec: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  onScreenTextType: OnScreenTextType;
  sourceText: Scalars['String']['output'];
  sourceVideo: SourceVideo;
  startTimeSec: Scalars['Float']['output'];
  translations?: Maybe<Array<OnScreenTextTranslation>>;
};


export type OnScreenTextSourceTranslationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OnScreenTextTranslationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OnScreenTextTranslationWhereInput>;
};

export type OnScreenTextSourceCreateManyWithoutSourceVideoInput = {
  connect?: InputMaybe<Array<OnScreenTextSourceWhereUniqueInput>>;
  create?: InputMaybe<Array<OnScreenTextSourceCreateWithoutSourceVideoInput>>;
};

export type OnScreenTextSourceCreateWithoutSourceVideoInput = {
  endTimeSec: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  onScreenTextType: OnScreenTextType;
  sourceText: Scalars['String']['input'];
  startTimeSec: Scalars['Float']['input'];
  translations?: InputMaybe<OnScreenTextTranslationCreateManyInput>;
};

export enum OnScreenTextSourceOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EndTimeSecAsc = 'endTimeSec_ASC',
  EndTimeSecDesc = 'endTimeSec_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OnScreenTextTypeAsc = 'onScreenTextType_ASC',
  OnScreenTextTypeDesc = 'onScreenTextType_DESC',
  SourceTextAsc = 'sourceText_ASC',
  SourceTextDesc = 'sourceText_DESC',
  StartTimeSecAsc = 'startTimeSec_ASC',
  StartTimeSecDesc = 'startTimeSec_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type OnScreenTextSourceScalarWhereInput = {
  AND?: InputMaybe<Array<OnScreenTextSourceScalarWhereInput>>;
  NOT?: InputMaybe<Array<OnScreenTextSourceScalarWhereInput>>;
  OR?: InputMaybe<Array<OnScreenTextSourceScalarWhereInput>>;
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_gt?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_gte?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  endTimeSec_lt?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_lte?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_not?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  onScreenTextType?: InputMaybe<OnScreenTextType>;
  onScreenTextType_in?: InputMaybe<Array<OnScreenTextType>>;
  onScreenTextType_not?: InputMaybe<OnScreenTextType>;
  onScreenTextType_not_in?: InputMaybe<Array<OnScreenTextType>>;
  sourceText?: InputMaybe<Scalars['String']['input']>;
  sourceText_contains?: InputMaybe<Scalars['String']['input']>;
  sourceText_ends_with?: InputMaybe<Scalars['String']['input']>;
  sourceText_gt?: InputMaybe<Scalars['String']['input']>;
  sourceText_gte?: InputMaybe<Scalars['String']['input']>;
  sourceText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sourceText_lt?: InputMaybe<Scalars['String']['input']>;
  sourceText_lte?: InputMaybe<Scalars['String']['input']>;
  sourceText_not?: InputMaybe<Scalars['String']['input']>;
  sourceText_not_contains?: InputMaybe<Scalars['String']['input']>;
  sourceText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  sourceText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sourceText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  sourceText_starts_with?: InputMaybe<Scalars['String']['input']>;
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_gt?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_gte?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTimeSec_lt?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_lte?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_not?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type OnScreenTextSourceUpdateManyDataInput = {
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  onScreenTextType?: InputMaybe<OnScreenTextType>;
  sourceText?: InputMaybe<Scalars['String']['input']>;
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
};

export type OnScreenTextSourceUpdateManyWithWhereNestedInput = {
  data: OnScreenTextSourceUpdateManyDataInput;
  where: OnScreenTextSourceScalarWhereInput;
};

export type OnScreenTextSourceUpdateManyWithoutSourceVideoInput = {
  connect?: InputMaybe<Array<OnScreenTextSourceWhereUniqueInput>>;
  create?: InputMaybe<Array<OnScreenTextSourceCreateWithoutSourceVideoInput>>;
  delete?: InputMaybe<Array<OnScreenTextSourceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OnScreenTextSourceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OnScreenTextSourceWhereUniqueInput>>;
  set?: InputMaybe<Array<OnScreenTextSourceWhereUniqueInput>>;
  update?: InputMaybe<Array<OnScreenTextSourceUpdateWithWhereUniqueWithoutSourceVideoInput>>;
  updateMany?: InputMaybe<Array<OnScreenTextSourceUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<OnScreenTextSourceUpsertWithWhereUniqueWithoutSourceVideoInput>>;
};

export type OnScreenTextSourceUpdateWithWhereUniqueWithoutSourceVideoInput = {
  data: OnScreenTextSourceUpdateWithoutSourceVideoDataInput;
  where: OnScreenTextSourceWhereUniqueInput;
};

export type OnScreenTextSourceUpdateWithoutSourceVideoDataInput = {
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  onScreenTextType?: InputMaybe<OnScreenTextType>;
  sourceText?: InputMaybe<Scalars['String']['input']>;
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
  translations?: InputMaybe<OnScreenTextTranslationUpdateManyInput>;
};

export type OnScreenTextSourceUpsertWithWhereUniqueWithoutSourceVideoInput = {
  create: OnScreenTextSourceCreateWithoutSourceVideoInput;
  update: OnScreenTextSourceUpdateWithoutSourceVideoDataInput;
  where: OnScreenTextSourceWhereUniqueInput;
};

export type OnScreenTextSourceWhereInput = {
  AND?: InputMaybe<Array<OnScreenTextSourceWhereInput>>;
  NOT?: InputMaybe<Array<OnScreenTextSourceWhereInput>>;
  OR?: InputMaybe<Array<OnScreenTextSourceWhereInput>>;
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_gt?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_gte?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  endTimeSec_lt?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_lte?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_not?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  onScreenTextType?: InputMaybe<OnScreenTextType>;
  onScreenTextType_in?: InputMaybe<Array<OnScreenTextType>>;
  onScreenTextType_not?: InputMaybe<OnScreenTextType>;
  onScreenTextType_not_in?: InputMaybe<Array<OnScreenTextType>>;
  sourceText?: InputMaybe<Scalars['String']['input']>;
  sourceText_contains?: InputMaybe<Scalars['String']['input']>;
  sourceText_ends_with?: InputMaybe<Scalars['String']['input']>;
  sourceText_gt?: InputMaybe<Scalars['String']['input']>;
  sourceText_gte?: InputMaybe<Scalars['String']['input']>;
  sourceText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sourceText_lt?: InputMaybe<Scalars['String']['input']>;
  sourceText_lte?: InputMaybe<Scalars['String']['input']>;
  sourceText_not?: InputMaybe<Scalars['String']['input']>;
  sourceText_not_contains?: InputMaybe<Scalars['String']['input']>;
  sourceText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  sourceText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sourceText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  sourceText_starts_with?: InputMaybe<Scalars['String']['input']>;
  sourceVideo?: InputMaybe<SourceVideoWhereInput>;
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_gt?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_gte?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTimeSec_lt?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_lte?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_not?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  translations_every?: InputMaybe<OnScreenTextTranslationWhereInput>;
  translations_none?: InputMaybe<OnScreenTextTranslationWhereInput>;
  translations_some?: InputMaybe<OnScreenTextTranslationWhereInput>;
};

export type OnScreenTextSourceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type OnScreenTextTranslation = {
  __typename?: 'OnScreenTextTranslation';
  id: Scalars['ID']['output'];
  translatedText: Scalars['String']['output'];
  translatedVideo: TranslatedVideo;
};

export type OnScreenTextTranslationCreateInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  translatedText: Scalars['String']['input'];
  translatedVideo: TranslatedVideoCreateOneWithoutOnScreenTextsInput;
};

export type OnScreenTextTranslationCreateManyInput = {
  connect?: InputMaybe<Array<OnScreenTextTranslationWhereUniqueInput>>;
  create?: InputMaybe<Array<OnScreenTextTranslationCreateInput>>;
};

export type OnScreenTextTranslationCreateManyWithoutTranslatedVideoInput = {
  connect?: InputMaybe<Array<OnScreenTextTranslationWhereUniqueInput>>;
  create?: InputMaybe<Array<OnScreenTextTranslationCreateWithoutTranslatedVideoInput>>;
};

export type OnScreenTextTranslationCreateWithoutTranslatedVideoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  translatedText: Scalars['String']['input'];
};

export enum OnScreenTextTranslationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TranslatedTextAsc = 'translatedText_ASC',
  TranslatedTextDesc = 'translatedText_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type OnScreenTextTranslationScalarWhereInput = {
  AND?: InputMaybe<Array<OnScreenTextTranslationScalarWhereInput>>;
  NOT?: InputMaybe<Array<OnScreenTextTranslationScalarWhereInput>>;
  OR?: InputMaybe<Array<OnScreenTextTranslationScalarWhereInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedText_contains?: InputMaybe<Scalars['String']['input']>;
  translatedText_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedText_gt?: InputMaybe<Scalars['String']['input']>;
  translatedText_gte?: InputMaybe<Scalars['String']['input']>;
  translatedText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedText_lt?: InputMaybe<Scalars['String']['input']>;
  translatedText_lte?: InputMaybe<Scalars['String']['input']>;
  translatedText_not?: InputMaybe<Scalars['String']['input']>;
  translatedText_not_contains?: InputMaybe<Scalars['String']['input']>;
  translatedText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedText_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type OnScreenTextTranslationUpdateDataInput = {
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedVideo?: InputMaybe<TranslatedVideoUpdateOneRequiredWithoutOnScreenTextsInput>;
};

export type OnScreenTextTranslationUpdateManyDataInput = {
  translatedText?: InputMaybe<Scalars['String']['input']>;
};

export type OnScreenTextTranslationUpdateManyInput = {
  connect?: InputMaybe<Array<OnScreenTextTranslationWhereUniqueInput>>;
  create?: InputMaybe<Array<OnScreenTextTranslationCreateInput>>;
  delete?: InputMaybe<Array<OnScreenTextTranslationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OnScreenTextTranslationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OnScreenTextTranslationWhereUniqueInput>>;
  set?: InputMaybe<Array<OnScreenTextTranslationWhereUniqueInput>>;
  update?: InputMaybe<Array<OnScreenTextTranslationUpdateWithWhereUniqueNestedInput>>;
  updateMany?: InputMaybe<Array<OnScreenTextTranslationUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<OnScreenTextTranslationUpsertWithWhereUniqueNestedInput>>;
};

export type OnScreenTextTranslationUpdateManyWithWhereNestedInput = {
  data: OnScreenTextTranslationUpdateManyDataInput;
  where: OnScreenTextTranslationScalarWhereInput;
};

export type OnScreenTextTranslationUpdateManyWithoutTranslatedVideoInput = {
  connect?: InputMaybe<Array<OnScreenTextTranslationWhereUniqueInput>>;
  create?: InputMaybe<Array<OnScreenTextTranslationCreateWithoutTranslatedVideoInput>>;
  delete?: InputMaybe<Array<OnScreenTextTranslationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OnScreenTextTranslationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OnScreenTextTranslationWhereUniqueInput>>;
  set?: InputMaybe<Array<OnScreenTextTranslationWhereUniqueInput>>;
  update?: InputMaybe<Array<OnScreenTextTranslationUpdateWithWhereUniqueWithoutTranslatedVideoInput>>;
  updateMany?: InputMaybe<Array<OnScreenTextTranslationUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<OnScreenTextTranslationUpsertWithWhereUniqueWithoutTranslatedVideoInput>>;
};

export type OnScreenTextTranslationUpdateWithWhereUniqueNestedInput = {
  data: OnScreenTextTranslationUpdateDataInput;
  where: OnScreenTextTranslationWhereUniqueInput;
};

export type OnScreenTextTranslationUpdateWithWhereUniqueWithoutTranslatedVideoInput = {
  data: OnScreenTextTranslationUpdateWithoutTranslatedVideoDataInput;
  where: OnScreenTextTranslationWhereUniqueInput;
};

export type OnScreenTextTranslationUpdateWithoutTranslatedVideoDataInput = {
  translatedText?: InputMaybe<Scalars['String']['input']>;
};

export type OnScreenTextTranslationUpsertWithWhereUniqueNestedInput = {
  create: OnScreenTextTranslationCreateInput;
  update: OnScreenTextTranslationUpdateDataInput;
  where: OnScreenTextTranslationWhereUniqueInput;
};

export type OnScreenTextTranslationUpsertWithWhereUniqueWithoutTranslatedVideoInput = {
  create: OnScreenTextTranslationCreateWithoutTranslatedVideoInput;
  update: OnScreenTextTranslationUpdateWithoutTranslatedVideoDataInput;
  where: OnScreenTextTranslationWhereUniqueInput;
};

export type OnScreenTextTranslationWhereInput = {
  AND?: InputMaybe<Array<OnScreenTextTranslationWhereInput>>;
  NOT?: InputMaybe<Array<OnScreenTextTranslationWhereInput>>;
  OR?: InputMaybe<Array<OnScreenTextTranslationWhereInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedText_contains?: InputMaybe<Scalars['String']['input']>;
  translatedText_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedText_gt?: InputMaybe<Scalars['String']['input']>;
  translatedText_gte?: InputMaybe<Scalars['String']['input']>;
  translatedText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedText_lt?: InputMaybe<Scalars['String']['input']>;
  translatedText_lte?: InputMaybe<Scalars['String']['input']>;
  translatedText_not?: InputMaybe<Scalars['String']['input']>;
  translatedText_not_contains?: InputMaybe<Scalars['String']['input']>;
  translatedText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedText_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedVideo?: InputMaybe<TranslatedVideoWhereInput>;
};

export type OnScreenTextTranslationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum OnScreenTextType {
  Bugs = 'Bugs',
  Credits = 'Credits',
  LowThirds = 'LowThirds',
  Other = 'Other',
  Titles = 'Titles'
}

export type Output = {
  __typename?: 'Output';
  createdAt: Scalars['DateTime']['output'];
  file: File;
  frameReviewLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  initialFrameProjectType?: Maybe<FrameProjectType>;
  outputGroup: OutputGroup;
  type: OutputType;
  updatedAt: Scalars['DateTime']['output'];
};

export type OutputCreateManyWithoutOutputGroupInput = {
  connect?: InputMaybe<Array<OutputWhereUniqueInput>>;
  create?: InputMaybe<Array<OutputCreateWithoutOutputGroupInput>>;
};

export type OutputCreateWithoutOutputGroupInput = {
  file: FileCreateOneInput;
  frameReviewLink?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  initialFrameProjectType?: InputMaybe<FrameProjectType>;
  type: OutputType;
};

export type OutputGroup = {
  __typename?: 'OutputGroup';
  appropriateQualityScore?: Maybe<Scalars['Float']['output']>;
  appropriateQualityScoreNotes?: Maybe<Scalars['String']['output']>;
  appropriateQualityScoreQa?: Maybe<User>;
  audibleOverlaps: Scalars['Boolean']['output'];
  clipOutEditsSnapshot?: Maybe<Scalars['Json']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentFailure?: Maybe<OutputGroupFailure>;
  currentFailureState?: Maybe<OutputGroupState>;
  editedAudioGroup?: Maybe<EditedAudioGroup>;
  failures?: Maybe<Array<OutputGroupFailure>>;
  generatedBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  issues?: Maybe<Array<OutputGroupIssue>>;
  keepAudioEditsSnapshot?: Maybe<Scalars['Json']['output']>;
  mimicDataCollectionComplete?: Maybe<Scalars['Boolean']['output']>;
  muxPlaybackId?: Maybe<Scalars['String']['output']>;
  outputs?: Maybe<Array<Output>>;
  processingDurationMs?: Maybe<Scalars['Float']['output']>;
  processingEndTime?: Maybe<Scalars['DateTime']['output']>;
  processingStartTime?: Maybe<Scalars['DateTime']['output']>;
  qualityScore?: Maybe<Scalars['Float']['output']>;
  qualityScoreQa?: Maybe<User>;
  s3root: Scalars['String']['output'];
  samples?: Maybe<Array<Sample>>;
  speechOutputIdToInfo?: Maybe<Scalars['Json']['output']>;
  speechOutputs?: Maybe<Array<SpeechOutput>>;
  speechOutputsV2?: Maybe<Array<SpeechOutput>>;
  stateValue: OutputGroupState;
  translatedVideo: TranslatedVideo;
  updatedAt: Scalars['DateTime']['output'];
};


export type OutputGroupFailuresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OutputGroupFailureOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutputGroupFailureWhereInput>;
};


export type OutputGroupIssuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OutputGroupIssueOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutputGroupIssueWhereInput>;
};


export type OutputGroupOutputsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OutputOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutputWhereInput>;
};


export type OutputGroupSamplesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SampleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SampleWhereInput>;
};


export type OutputGroupSpeechOutputsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpeechOutputOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpeechOutputWhereInput>;
};


export type OutputGroupSpeechOutputsV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpeechOutputOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpeechOutputWhereInput>;
};

export type OutputGroupCreateManyWithoutSpeechOutputsV2Input = {
  connect?: InputMaybe<Array<OutputGroupWhereUniqueInput>>;
  create?: InputMaybe<Array<OutputGroupCreateWithoutSpeechOutputsV2Input>>;
};

export type OutputGroupCreateManyWithoutTranslatedVideoInput = {
  connect?: InputMaybe<Array<OutputGroupWhereUniqueInput>>;
  create?: InputMaybe<Array<OutputGroupCreateWithoutTranslatedVideoInput>>;
};

export type OutputGroupCreateOneWithoutCurrentFailureInput = {
  connect?: InputMaybe<OutputGroupWhereUniqueInput>;
  create?: InputMaybe<OutputGroupCreateWithoutCurrentFailureInput>;
};

export type OutputGroupCreateOneWithoutFailuresInput = {
  connect?: InputMaybe<OutputGroupWhereUniqueInput>;
  create?: InputMaybe<OutputGroupCreateWithoutFailuresInput>;
};

export type OutputGroupCreateOneWithoutSamplesInput = {
  connect?: InputMaybe<OutputGroupWhereUniqueInput>;
  create?: InputMaybe<OutputGroupCreateWithoutSamplesInput>;
};

export type OutputGroupCreateOneWithoutSpeechOutputsInput = {
  connect?: InputMaybe<OutputGroupWhereUniqueInput>;
  create?: InputMaybe<OutputGroupCreateWithoutSpeechOutputsInput>;
};

export type OutputGroupCreateWithoutCurrentFailureInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserCreateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupCreateOneInput>;
  failures?: InputMaybe<OutputGroupFailureCreateManyWithoutOutputGroupInput>;
  generatedBy?: InputMaybe<UserCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  issues?: InputMaybe<OutputGroupIssueCreateManyWithoutOutputGroupInput>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputCreateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  samples?: InputMaybe<SampleCreateManyWithoutOutputGroupInput>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputCreateManyWithoutOutputGroupInput>;
  speechOutputsV2?: InputMaybe<SpeechOutputCreateManyWithoutOutputGroupsInput>;
  stateValue?: InputMaybe<OutputGroupState>;
  translatedVideo: TranslatedVideoCreateOneWithoutOutputGroupsInput;
};

export type OutputGroupCreateWithoutFailuresInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserCreateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailure?: InputMaybe<OutputGroupFailureCreateOneWithoutCurrentFailureOutputGroupInput>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupCreateOneInput>;
  generatedBy?: InputMaybe<UserCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  issues?: InputMaybe<OutputGroupIssueCreateManyWithoutOutputGroupInput>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputCreateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  samples?: InputMaybe<SampleCreateManyWithoutOutputGroupInput>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputCreateManyWithoutOutputGroupInput>;
  speechOutputsV2?: InputMaybe<SpeechOutputCreateManyWithoutOutputGroupsInput>;
  stateValue?: InputMaybe<OutputGroupState>;
  translatedVideo: TranslatedVideoCreateOneWithoutOutputGroupsInput;
};

export type OutputGroupCreateWithoutIssuesInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserCreateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailure?: InputMaybe<OutputGroupFailureCreateOneWithoutCurrentFailureOutputGroupInput>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupCreateOneInput>;
  failures?: InputMaybe<OutputGroupFailureCreateManyWithoutOutputGroupInput>;
  generatedBy?: InputMaybe<UserCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputCreateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  samples?: InputMaybe<SampleCreateManyWithoutOutputGroupInput>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputCreateManyWithoutOutputGroupInput>;
  speechOutputsV2?: InputMaybe<SpeechOutputCreateManyWithoutOutputGroupsInput>;
  stateValue?: InputMaybe<OutputGroupState>;
  translatedVideo: TranslatedVideoCreateOneWithoutOutputGroupsInput;
};

export type OutputGroupCreateWithoutSamplesInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserCreateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailure?: InputMaybe<OutputGroupFailureCreateOneWithoutCurrentFailureOutputGroupInput>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupCreateOneInput>;
  failures?: InputMaybe<OutputGroupFailureCreateManyWithoutOutputGroupInput>;
  generatedBy?: InputMaybe<UserCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  issues?: InputMaybe<OutputGroupIssueCreateManyWithoutOutputGroupInput>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputCreateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputCreateManyWithoutOutputGroupInput>;
  speechOutputsV2?: InputMaybe<SpeechOutputCreateManyWithoutOutputGroupsInput>;
  stateValue?: InputMaybe<OutputGroupState>;
  translatedVideo: TranslatedVideoCreateOneWithoutOutputGroupsInput;
};

export type OutputGroupCreateWithoutSpeechOutputsInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserCreateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailure?: InputMaybe<OutputGroupFailureCreateOneWithoutCurrentFailureOutputGroupInput>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupCreateOneInput>;
  failures?: InputMaybe<OutputGroupFailureCreateManyWithoutOutputGroupInput>;
  generatedBy?: InputMaybe<UserCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  issues?: InputMaybe<OutputGroupIssueCreateManyWithoutOutputGroupInput>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputCreateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  samples?: InputMaybe<SampleCreateManyWithoutOutputGroupInput>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputsV2?: InputMaybe<SpeechOutputCreateManyWithoutOutputGroupsInput>;
  stateValue?: InputMaybe<OutputGroupState>;
  translatedVideo: TranslatedVideoCreateOneWithoutOutputGroupsInput;
};

export type OutputGroupCreateWithoutSpeechOutputsV2Input = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserCreateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailure?: InputMaybe<OutputGroupFailureCreateOneWithoutCurrentFailureOutputGroupInput>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupCreateOneInput>;
  failures?: InputMaybe<OutputGroupFailureCreateManyWithoutOutputGroupInput>;
  generatedBy?: InputMaybe<UserCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  issues?: InputMaybe<OutputGroupIssueCreateManyWithoutOutputGroupInput>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputCreateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  samples?: InputMaybe<SampleCreateManyWithoutOutputGroupInput>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputCreateManyWithoutOutputGroupInput>;
  stateValue?: InputMaybe<OutputGroupState>;
  translatedVideo: TranslatedVideoCreateOneWithoutOutputGroupsInput;
};

export type OutputGroupCreateWithoutTranslatedVideoInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserCreateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailure?: InputMaybe<OutputGroupFailureCreateOneWithoutCurrentFailureOutputGroupInput>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupCreateOneInput>;
  failures?: InputMaybe<OutputGroupFailureCreateManyWithoutOutputGroupInput>;
  generatedBy?: InputMaybe<UserCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  issues?: InputMaybe<OutputGroupIssueCreateManyWithoutOutputGroupInput>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputCreateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  samples?: InputMaybe<SampleCreateManyWithoutOutputGroupInput>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputCreateManyWithoutOutputGroupInput>;
  speechOutputsV2?: InputMaybe<SpeechOutputCreateManyWithoutOutputGroupsInput>;
  stateValue?: InputMaybe<OutputGroupState>;
};

export type OutputGroupFailure = {
  __typename?: 'OutputGroupFailure';
  createdAt: Scalars['DateTime']['output'];
  currentFailureOutputGroup?: Maybe<OutputGroup>;
  failureError?: Maybe<Scalars['String']['output']>;
  failureMessage?: Maybe<Scalars['String']['output']>;
  failureState?: Maybe<OutputGroupState>;
  failureStatusCode?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  outputGroup: OutputGroup;
  updatedAt: Scalars['DateTime']['output'];
};

export type OutputGroupFailureCreateManyWithoutOutputGroupInput = {
  connect?: InputMaybe<Array<OutputGroupFailureWhereUniqueInput>>;
  create?: InputMaybe<Array<OutputGroupFailureCreateWithoutOutputGroupInput>>;
};

export type OutputGroupFailureCreateOneWithoutCurrentFailureOutputGroupInput = {
  connect?: InputMaybe<OutputGroupFailureWhereUniqueInput>;
  create?: InputMaybe<OutputGroupFailureCreateWithoutCurrentFailureOutputGroupInput>;
};

export type OutputGroupFailureCreateWithoutCurrentFailureOutputGroupInput = {
  failureError?: InputMaybe<Scalars['String']['input']>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureState?: InputMaybe<OutputGroupState>;
  failureStatusCode?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  outputGroup: OutputGroupCreateOneWithoutFailuresInput;
};

export type OutputGroupFailureCreateWithoutOutputGroupInput = {
  currentFailureOutputGroup?: InputMaybe<OutputGroupCreateOneWithoutCurrentFailureInput>;
  failureError?: InputMaybe<Scalars['String']['input']>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureState?: InputMaybe<OutputGroupState>;
  failureStatusCode?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum OutputGroupFailureOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FailureErrorAsc = 'failureError_ASC',
  FailureErrorDesc = 'failureError_DESC',
  FailureMessageAsc = 'failureMessage_ASC',
  FailureMessageDesc = 'failureMessage_DESC',
  FailureStateAsc = 'failureState_ASC',
  FailureStateDesc = 'failureState_DESC',
  FailureStatusCodeAsc = 'failureStatusCode_ASC',
  FailureStatusCodeDesc = 'failureStatusCode_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type OutputGroupFailureScalarWhereInput = {
  AND?: InputMaybe<Array<OutputGroupFailureScalarWhereInput>>;
  NOT?: InputMaybe<Array<OutputGroupFailureScalarWhereInput>>;
  OR?: InputMaybe<Array<OutputGroupFailureScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  failureError?: InputMaybe<Scalars['String']['input']>;
  failureError_contains?: InputMaybe<Scalars['String']['input']>;
  failureError_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureError_gt?: InputMaybe<Scalars['String']['input']>;
  failureError_gte?: InputMaybe<Scalars['String']['input']>;
  failureError_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureError_lt?: InputMaybe<Scalars['String']['input']>;
  failureError_lte?: InputMaybe<Scalars['String']['input']>;
  failureError_not?: InputMaybe<Scalars['String']['input']>;
  failureError_not_contains?: InputMaybe<Scalars['String']['input']>;
  failureError_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureError_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureError_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureError_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureMessage_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_lt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_lte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureState?: InputMaybe<OutputGroupState>;
  failureState_in?: InputMaybe<Array<OutputGroupState>>;
  failureState_not?: InputMaybe<OutputGroupState>;
  failureState_not_in?: InputMaybe<Array<OutputGroupState>>;
  failureStatusCode?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_gt?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_gte?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  failureStatusCode_lt?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_lte?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_not?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type OutputGroupFailureUpdateManyDataInput = {
  failureError?: InputMaybe<Scalars['String']['input']>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureState?: InputMaybe<OutputGroupState>;
  failureStatusCode?: InputMaybe<Scalars['Int']['input']>;
};

export type OutputGroupFailureUpdateManyWithWhereNestedInput = {
  data: OutputGroupFailureUpdateManyDataInput;
  where: OutputGroupFailureScalarWhereInput;
};

export type OutputGroupFailureUpdateManyWithoutOutputGroupInput = {
  connect?: InputMaybe<Array<OutputGroupFailureWhereUniqueInput>>;
  create?: InputMaybe<Array<OutputGroupFailureCreateWithoutOutputGroupInput>>;
  delete?: InputMaybe<Array<OutputGroupFailureWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OutputGroupFailureScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OutputGroupFailureWhereUniqueInput>>;
  set?: InputMaybe<Array<OutputGroupFailureWhereUniqueInput>>;
  update?: InputMaybe<Array<OutputGroupFailureUpdateWithWhereUniqueWithoutOutputGroupInput>>;
  updateMany?: InputMaybe<Array<OutputGroupFailureUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<OutputGroupFailureUpsertWithWhereUniqueWithoutOutputGroupInput>>;
};

export type OutputGroupFailureUpdateOneWithoutCurrentFailureOutputGroupInput = {
  connect?: InputMaybe<OutputGroupFailureWhereUniqueInput>;
  create?: InputMaybe<OutputGroupFailureCreateWithoutCurrentFailureOutputGroupInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OutputGroupFailureUpdateWithoutCurrentFailureOutputGroupDataInput>;
  upsert?: InputMaybe<OutputGroupFailureUpsertWithoutCurrentFailureOutputGroupInput>;
};

export type OutputGroupFailureUpdateWithWhereUniqueWithoutOutputGroupInput = {
  data: OutputGroupFailureUpdateWithoutOutputGroupDataInput;
  where: OutputGroupFailureWhereUniqueInput;
};

export type OutputGroupFailureUpdateWithoutCurrentFailureOutputGroupDataInput = {
  failureError?: InputMaybe<Scalars['String']['input']>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureState?: InputMaybe<OutputGroupState>;
  failureStatusCode?: InputMaybe<Scalars['Int']['input']>;
  outputGroup?: InputMaybe<OutputGroupUpdateOneRequiredWithoutFailuresInput>;
};

export type OutputGroupFailureUpdateWithoutOutputGroupDataInput = {
  currentFailureOutputGroup?: InputMaybe<OutputGroupUpdateOneWithoutCurrentFailureInput>;
  failureError?: InputMaybe<Scalars['String']['input']>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureState?: InputMaybe<OutputGroupState>;
  failureStatusCode?: InputMaybe<Scalars['Int']['input']>;
};

export type OutputGroupFailureUpsertWithWhereUniqueWithoutOutputGroupInput = {
  create: OutputGroupFailureCreateWithoutOutputGroupInput;
  update: OutputGroupFailureUpdateWithoutOutputGroupDataInput;
  where: OutputGroupFailureWhereUniqueInput;
};

export type OutputGroupFailureUpsertWithoutCurrentFailureOutputGroupInput = {
  create: OutputGroupFailureCreateWithoutCurrentFailureOutputGroupInput;
  update: OutputGroupFailureUpdateWithoutCurrentFailureOutputGroupDataInput;
};

export type OutputGroupFailureWhereInput = {
  AND?: InputMaybe<Array<OutputGroupFailureWhereInput>>;
  NOT?: InputMaybe<Array<OutputGroupFailureWhereInput>>;
  OR?: InputMaybe<Array<OutputGroupFailureWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  currentFailureOutputGroup?: InputMaybe<OutputGroupWhereInput>;
  failureError?: InputMaybe<Scalars['String']['input']>;
  failureError_contains?: InputMaybe<Scalars['String']['input']>;
  failureError_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureError_gt?: InputMaybe<Scalars['String']['input']>;
  failureError_gte?: InputMaybe<Scalars['String']['input']>;
  failureError_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureError_lt?: InputMaybe<Scalars['String']['input']>;
  failureError_lte?: InputMaybe<Scalars['String']['input']>;
  failureError_not?: InputMaybe<Scalars['String']['input']>;
  failureError_not_contains?: InputMaybe<Scalars['String']['input']>;
  failureError_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureError_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureError_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureError_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureMessage_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_lt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_lte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureState?: InputMaybe<OutputGroupState>;
  failureState_in?: InputMaybe<Array<OutputGroupState>>;
  failureState_not?: InputMaybe<OutputGroupState>;
  failureState_not_in?: InputMaybe<Array<OutputGroupState>>;
  failureStatusCode?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_gt?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_gte?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  failureStatusCode_lt?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_lte?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_not?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  outputGroup?: InputMaybe<OutputGroupWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type OutputGroupFailureWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type OutputGroupIssue = {
  __typename?: 'OutputGroupIssue';
  category?: Maybe<Scalars['String']['output']>;
  collectionId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isMajor: Scalars['Boolean']['output'];
  isRecurring: Scalars['Boolean']['output'];
  isSuggestion: Scalars['Boolean']['output'];
  issueLocation?: Maybe<Scalars['Json']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  outputGroup: OutputGroup;
  reason?: Maybe<Scalars['String']['output']>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  resolvedBy?: Maybe<User>;
  segment?: Maybe<Segment>;
  severity?: Maybe<Scalars['String']['output']>;
  sourceStartTime?: Maybe<Scalars['Float']['output']>;
  speechOutput?: Maybe<SpeechOutput>;
  startTime: Scalars['Float']['output'];
  status: OutputGroupIssueStatus;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId: User;
  version: Scalars['Int']['output'];
};

export type OutputGroupIssueCreateManyWithoutOutputGroupInput = {
  connect?: InputMaybe<Array<OutputGroupIssueWhereUniqueInput>>;
  create?: InputMaybe<Array<OutputGroupIssueCreateWithoutOutputGroupInput>>;
};

export type OutputGroupIssueCreateWithoutOutputGroupInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  collectionId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isMajor?: InputMaybe<Scalars['Boolean']['input']>;
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  isSuggestion?: InputMaybe<Scalars['Boolean']['input']>;
  issueLocation?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  resolvedBy?: InputMaybe<UserCreateOneInput>;
  segment?: InputMaybe<SegmentCreateOneInput>;
  severity?: InputMaybe<Scalars['String']['input']>;
  sourceStartTime?: InputMaybe<Scalars['Float']['input']>;
  speechOutput?: InputMaybe<SpeechOutputCreateOneInput>;
  startTime: Scalars['Float']['input'];
  status?: InputMaybe<OutputGroupIssueStatus>;
  text?: InputMaybe<Scalars['String']['input']>;
  userId: UserCreateOneInput;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export enum OutputGroupIssueOrderByInput {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CollectionIdAsc = 'collectionId_ASC',
  CollectionIdDesc = 'collectionId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsMajorAsc = 'isMajor_ASC',
  IsMajorDesc = 'isMajor_DESC',
  IsRecurringAsc = 'isRecurring_ASC',
  IsRecurringDesc = 'isRecurring_DESC',
  IsSuggestionAsc = 'isSuggestion_ASC',
  IsSuggestionDesc = 'isSuggestion_DESC',
  IssueLocationAsc = 'issueLocation_ASC',
  IssueLocationDesc = 'issueLocation_DESC',
  NotesAsc = 'notes_ASC',
  NotesDesc = 'notes_DESC',
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC',
  RejectionReasonAsc = 'rejectionReason_ASC',
  RejectionReasonDesc = 'rejectionReason_DESC',
  SeverityAsc = 'severity_ASC',
  SeverityDesc = 'severity_DESC',
  SourceStartTimeAsc = 'sourceStartTime_ASC',
  SourceStartTimeDesc = 'sourceStartTime_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC'
}

export type OutputGroupIssueScalarWhereInput = {
  AND?: InputMaybe<Array<OutputGroupIssueScalarWhereInput>>;
  NOT?: InputMaybe<Array<OutputGroupIssueScalarWhereInput>>;
  OR?: InputMaybe<Array<OutputGroupIssueScalarWhereInput>>;
  category?: InputMaybe<Scalars['String']['input']>;
  category_contains?: InputMaybe<Scalars['String']['input']>;
  category_ends_with?: InputMaybe<Scalars['String']['input']>;
  category_gt?: InputMaybe<Scalars['String']['input']>;
  category_gte?: InputMaybe<Scalars['String']['input']>;
  category_in?: InputMaybe<Array<Scalars['String']['input']>>;
  category_lt?: InputMaybe<Scalars['String']['input']>;
  category_lte?: InputMaybe<Scalars['String']['input']>;
  category_not?: InputMaybe<Scalars['String']['input']>;
  category_not_contains?: InputMaybe<Scalars['String']['input']>;
  category_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  category_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  category_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  category_starts_with?: InputMaybe<Scalars['String']['input']>;
  collectionId?: InputMaybe<Scalars['String']['input']>;
  collectionId_contains?: InputMaybe<Scalars['String']['input']>;
  collectionId_ends_with?: InputMaybe<Scalars['String']['input']>;
  collectionId_gt?: InputMaybe<Scalars['String']['input']>;
  collectionId_gte?: InputMaybe<Scalars['String']['input']>;
  collectionId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  collectionId_lt?: InputMaybe<Scalars['String']['input']>;
  collectionId_lte?: InputMaybe<Scalars['String']['input']>;
  collectionId_not?: InputMaybe<Scalars['String']['input']>;
  collectionId_not_contains?: InputMaybe<Scalars['String']['input']>;
  collectionId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  collectionId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  collectionId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  collectionId_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isMajor?: InputMaybe<Scalars['Boolean']['input']>;
  isMajor_not?: InputMaybe<Scalars['Boolean']['input']>;
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  isRecurring_not?: InputMaybe<Scalars['Boolean']['input']>;
  isSuggestion?: InputMaybe<Scalars['Boolean']['input']>;
  isSuggestion_not?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  notes_contains?: InputMaybe<Scalars['String']['input']>;
  notes_ends_with?: InputMaybe<Scalars['String']['input']>;
  notes_gt?: InputMaybe<Scalars['String']['input']>;
  notes_gte?: InputMaybe<Scalars['String']['input']>;
  notes_in?: InputMaybe<Array<Scalars['String']['input']>>;
  notes_lt?: InputMaybe<Scalars['String']['input']>;
  notes_lte?: InputMaybe<Scalars['String']['input']>;
  notes_not?: InputMaybe<Scalars['String']['input']>;
  notes_not_contains?: InputMaybe<Scalars['String']['input']>;
  notes_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  notes_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  notes_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  notes_starts_with?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  reason_contains?: InputMaybe<Scalars['String']['input']>;
  reason_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_gt?: InputMaybe<Scalars['String']['input']>;
  reason_gte?: InputMaybe<Scalars['String']['input']>;
  reason_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_lt?: InputMaybe<Scalars['String']['input']>;
  reason_lte?: InputMaybe<Scalars['String']['input']>;
  reason_not?: InputMaybe<Scalars['String']['input']>;
  reason_not_contains?: InputMaybe<Scalars['String']['input']>;
  reason_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  reason_starts_with?: InputMaybe<Scalars['String']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_contains?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_ends_with?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_gt?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_gte?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_in?: InputMaybe<Array<Scalars['String']['input']>>;
  rejectionReason_lt?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_lte?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_not?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_not_contains?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  rejectionReason_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_starts_with?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<Scalars['String']['input']>;
  severity_contains?: InputMaybe<Scalars['String']['input']>;
  severity_ends_with?: InputMaybe<Scalars['String']['input']>;
  severity_gt?: InputMaybe<Scalars['String']['input']>;
  severity_gte?: InputMaybe<Scalars['String']['input']>;
  severity_in?: InputMaybe<Array<Scalars['String']['input']>>;
  severity_lt?: InputMaybe<Scalars['String']['input']>;
  severity_lte?: InputMaybe<Scalars['String']['input']>;
  severity_not?: InputMaybe<Scalars['String']['input']>;
  severity_not_contains?: InputMaybe<Scalars['String']['input']>;
  severity_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  severity_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  severity_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  severity_starts_with?: InputMaybe<Scalars['String']['input']>;
  sourceStartTime?: InputMaybe<Scalars['Float']['input']>;
  sourceStartTime_gt?: InputMaybe<Scalars['Float']['input']>;
  sourceStartTime_gte?: InputMaybe<Scalars['Float']['input']>;
  sourceStartTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  sourceStartTime_lt?: InputMaybe<Scalars['Float']['input']>;
  sourceStartTime_lte?: InputMaybe<Scalars['Float']['input']>;
  sourceStartTime_not?: InputMaybe<Scalars['Float']['input']>;
  sourceStartTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  startTime_gt?: InputMaybe<Scalars['Float']['input']>;
  startTime_gte?: InputMaybe<Scalars['Float']['input']>;
  startTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTime_lt?: InputMaybe<Scalars['Float']['input']>;
  startTime_lte?: InputMaybe<Scalars['Float']['input']>;
  startTime_not?: InputMaybe<Scalars['Float']['input']>;
  startTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  status?: InputMaybe<OutputGroupIssueStatus>;
  status_in?: InputMaybe<Array<OutputGroupIssueStatus>>;
  status_not?: InputMaybe<OutputGroupIssueStatus>;
  status_not_in?: InputMaybe<Array<OutputGroupIssueStatus>>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_not?: InputMaybe<Scalars['String']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  text_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_not?: InputMaybe<Scalars['Int']['input']>;
  version_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum OutputGroupIssueStatus {
  Pending = 'Pending',
  Rejected = 'Rejected',
  Resolved = 'Resolved'
}

export type OutputGroupIssueUpdateInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  collectionId?: InputMaybe<Scalars['String']['input']>;
  isMajor?: InputMaybe<Scalars['Boolean']['input']>;
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  isSuggestion?: InputMaybe<Scalars['Boolean']['input']>;
  issueLocation?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  outputGroup?: InputMaybe<OutputGroupUpdateOneRequiredWithoutIssuesInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  resolvedBy?: InputMaybe<UserUpdateOneInput>;
  segment?: InputMaybe<SegmentUpdateOneInput>;
  severity?: InputMaybe<Scalars['String']['input']>;
  sourceStartTime?: InputMaybe<Scalars['Float']['input']>;
  speechOutput?: InputMaybe<SpeechOutputUpdateOneInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<OutputGroupIssueStatus>;
  text?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<UserUpdateOneRequiredInput>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type OutputGroupIssueUpdateManyDataInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  collectionId?: InputMaybe<Scalars['String']['input']>;
  isMajor?: InputMaybe<Scalars['Boolean']['input']>;
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  isSuggestion?: InputMaybe<Scalars['Boolean']['input']>;
  issueLocation?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<Scalars['String']['input']>;
  sourceStartTime?: InputMaybe<Scalars['Float']['input']>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<OutputGroupIssueStatus>;
  text?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type OutputGroupIssueUpdateManyWithWhereNestedInput = {
  data: OutputGroupIssueUpdateManyDataInput;
  where: OutputGroupIssueScalarWhereInput;
};

export type OutputGroupIssueUpdateManyWithoutOutputGroupInput = {
  connect?: InputMaybe<Array<OutputGroupIssueWhereUniqueInput>>;
  create?: InputMaybe<Array<OutputGroupIssueCreateWithoutOutputGroupInput>>;
  delete?: InputMaybe<Array<OutputGroupIssueWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OutputGroupIssueScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OutputGroupIssueWhereUniqueInput>>;
  set?: InputMaybe<Array<OutputGroupIssueWhereUniqueInput>>;
  update?: InputMaybe<Array<OutputGroupIssueUpdateWithWhereUniqueWithoutOutputGroupInput>>;
  updateMany?: InputMaybe<Array<OutputGroupIssueUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<OutputGroupIssueUpsertWithWhereUniqueWithoutOutputGroupInput>>;
};

export type OutputGroupIssueUpdateWithWhereUniqueWithoutOutputGroupInput = {
  data: OutputGroupIssueUpdateWithoutOutputGroupDataInput;
  where: OutputGroupIssueWhereUniqueInput;
};

export type OutputGroupIssueUpdateWithoutOutputGroupDataInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  collectionId?: InputMaybe<Scalars['String']['input']>;
  isMajor?: InputMaybe<Scalars['Boolean']['input']>;
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  isSuggestion?: InputMaybe<Scalars['Boolean']['input']>;
  issueLocation?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  resolvedBy?: InputMaybe<UserUpdateOneInput>;
  segment?: InputMaybe<SegmentUpdateOneInput>;
  severity?: InputMaybe<Scalars['String']['input']>;
  sourceStartTime?: InputMaybe<Scalars['Float']['input']>;
  speechOutput?: InputMaybe<SpeechOutputUpdateOneInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<OutputGroupIssueStatus>;
  text?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<UserUpdateOneRequiredInput>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type OutputGroupIssueUpsertWithWhereUniqueWithoutOutputGroupInput = {
  create: OutputGroupIssueCreateWithoutOutputGroupInput;
  update: OutputGroupIssueUpdateWithoutOutputGroupDataInput;
  where: OutputGroupIssueWhereUniqueInput;
};

export type OutputGroupIssueWhereInput = {
  AND?: InputMaybe<Array<OutputGroupIssueWhereInput>>;
  NOT?: InputMaybe<Array<OutputGroupIssueWhereInput>>;
  OR?: InputMaybe<Array<OutputGroupIssueWhereInput>>;
  category?: InputMaybe<Scalars['String']['input']>;
  category_contains?: InputMaybe<Scalars['String']['input']>;
  category_ends_with?: InputMaybe<Scalars['String']['input']>;
  category_gt?: InputMaybe<Scalars['String']['input']>;
  category_gte?: InputMaybe<Scalars['String']['input']>;
  category_in?: InputMaybe<Array<Scalars['String']['input']>>;
  category_lt?: InputMaybe<Scalars['String']['input']>;
  category_lte?: InputMaybe<Scalars['String']['input']>;
  category_not?: InputMaybe<Scalars['String']['input']>;
  category_not_contains?: InputMaybe<Scalars['String']['input']>;
  category_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  category_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  category_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  category_starts_with?: InputMaybe<Scalars['String']['input']>;
  collectionId?: InputMaybe<Scalars['String']['input']>;
  collectionId_contains?: InputMaybe<Scalars['String']['input']>;
  collectionId_ends_with?: InputMaybe<Scalars['String']['input']>;
  collectionId_gt?: InputMaybe<Scalars['String']['input']>;
  collectionId_gte?: InputMaybe<Scalars['String']['input']>;
  collectionId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  collectionId_lt?: InputMaybe<Scalars['String']['input']>;
  collectionId_lte?: InputMaybe<Scalars['String']['input']>;
  collectionId_not?: InputMaybe<Scalars['String']['input']>;
  collectionId_not_contains?: InputMaybe<Scalars['String']['input']>;
  collectionId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  collectionId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  collectionId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  collectionId_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isMajor?: InputMaybe<Scalars['Boolean']['input']>;
  isMajor_not?: InputMaybe<Scalars['Boolean']['input']>;
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  isRecurring_not?: InputMaybe<Scalars['Boolean']['input']>;
  isSuggestion?: InputMaybe<Scalars['Boolean']['input']>;
  isSuggestion_not?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  notes_contains?: InputMaybe<Scalars['String']['input']>;
  notes_ends_with?: InputMaybe<Scalars['String']['input']>;
  notes_gt?: InputMaybe<Scalars['String']['input']>;
  notes_gte?: InputMaybe<Scalars['String']['input']>;
  notes_in?: InputMaybe<Array<Scalars['String']['input']>>;
  notes_lt?: InputMaybe<Scalars['String']['input']>;
  notes_lte?: InputMaybe<Scalars['String']['input']>;
  notes_not?: InputMaybe<Scalars['String']['input']>;
  notes_not_contains?: InputMaybe<Scalars['String']['input']>;
  notes_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  notes_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  notes_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  notes_starts_with?: InputMaybe<Scalars['String']['input']>;
  outputGroup?: InputMaybe<OutputGroupWhereInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
  reason_contains?: InputMaybe<Scalars['String']['input']>;
  reason_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_gt?: InputMaybe<Scalars['String']['input']>;
  reason_gte?: InputMaybe<Scalars['String']['input']>;
  reason_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_lt?: InputMaybe<Scalars['String']['input']>;
  reason_lte?: InputMaybe<Scalars['String']['input']>;
  reason_not?: InputMaybe<Scalars['String']['input']>;
  reason_not_contains?: InputMaybe<Scalars['String']['input']>;
  reason_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  reason_starts_with?: InputMaybe<Scalars['String']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_contains?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_ends_with?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_gt?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_gte?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_in?: InputMaybe<Array<Scalars['String']['input']>>;
  rejectionReason_lt?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_lte?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_not?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_not_contains?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  rejectionReason_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  rejectionReason_starts_with?: InputMaybe<Scalars['String']['input']>;
  resolvedBy?: InputMaybe<UserWhereInput>;
  segment?: InputMaybe<SegmentWhereInput>;
  severity?: InputMaybe<Scalars['String']['input']>;
  severity_contains?: InputMaybe<Scalars['String']['input']>;
  severity_ends_with?: InputMaybe<Scalars['String']['input']>;
  severity_gt?: InputMaybe<Scalars['String']['input']>;
  severity_gte?: InputMaybe<Scalars['String']['input']>;
  severity_in?: InputMaybe<Array<Scalars['String']['input']>>;
  severity_lt?: InputMaybe<Scalars['String']['input']>;
  severity_lte?: InputMaybe<Scalars['String']['input']>;
  severity_not?: InputMaybe<Scalars['String']['input']>;
  severity_not_contains?: InputMaybe<Scalars['String']['input']>;
  severity_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  severity_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  severity_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  severity_starts_with?: InputMaybe<Scalars['String']['input']>;
  sourceStartTime?: InputMaybe<Scalars['Float']['input']>;
  sourceStartTime_gt?: InputMaybe<Scalars['Float']['input']>;
  sourceStartTime_gte?: InputMaybe<Scalars['Float']['input']>;
  sourceStartTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  sourceStartTime_lt?: InputMaybe<Scalars['Float']['input']>;
  sourceStartTime_lte?: InputMaybe<Scalars['Float']['input']>;
  sourceStartTime_not?: InputMaybe<Scalars['Float']['input']>;
  sourceStartTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  speechOutput?: InputMaybe<SpeechOutputWhereInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  startTime_gt?: InputMaybe<Scalars['Float']['input']>;
  startTime_gte?: InputMaybe<Scalars['Float']['input']>;
  startTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTime_lt?: InputMaybe<Scalars['Float']['input']>;
  startTime_lte?: InputMaybe<Scalars['Float']['input']>;
  startTime_not?: InputMaybe<Scalars['Float']['input']>;
  startTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  status?: InputMaybe<OutputGroupIssueStatus>;
  status_in?: InputMaybe<Array<OutputGroupIssueStatus>>;
  status_not?: InputMaybe<OutputGroupIssueStatus>;
  status_not_in?: InputMaybe<Array<OutputGroupIssueStatus>>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_not?: InputMaybe<Scalars['String']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  text_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  userId?: InputMaybe<UserWhereInput>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_not?: InputMaybe<Scalars['Int']['input']>;
  version_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type OutputGroupIssueWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum OutputGroupOrderByInput {
  AppropriateQualityScoreNotesAsc = 'appropriateQualityScoreNotes_ASC',
  AppropriateQualityScoreNotesDesc = 'appropriateQualityScoreNotes_DESC',
  AppropriateQualityScoreAsc = 'appropriateQualityScore_ASC',
  AppropriateQualityScoreDesc = 'appropriateQualityScore_DESC',
  AudibleOverlapsAsc = 'audibleOverlaps_ASC',
  AudibleOverlapsDesc = 'audibleOverlaps_DESC',
  ClipOutEditsSnapshotAsc = 'clipOutEditsSnapshot_ASC',
  ClipOutEditsSnapshotDesc = 'clipOutEditsSnapshot_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CurrentFailureStateAsc = 'currentFailureState_ASC',
  CurrentFailureStateDesc = 'currentFailureState_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeepAudioEditsSnapshotAsc = 'keepAudioEditsSnapshot_ASC',
  KeepAudioEditsSnapshotDesc = 'keepAudioEditsSnapshot_DESC',
  MimicDataCollectionCompleteAsc = 'mimicDataCollectionComplete_ASC',
  MimicDataCollectionCompleteDesc = 'mimicDataCollectionComplete_DESC',
  MuxPlaybackIdAsc = 'muxPlaybackId_ASC',
  MuxPlaybackIdDesc = 'muxPlaybackId_DESC',
  ProcessingDurationMsAsc = 'processingDurationMs_ASC',
  ProcessingDurationMsDesc = 'processingDurationMs_DESC',
  ProcessingEndTimeAsc = 'processingEndTime_ASC',
  ProcessingEndTimeDesc = 'processingEndTime_DESC',
  ProcessingStartTimeAsc = 'processingStartTime_ASC',
  ProcessingStartTimeDesc = 'processingStartTime_DESC',
  QualityScoreAsc = 'qualityScore_ASC',
  QualityScoreDesc = 'qualityScore_DESC',
  S3rootAsc = 's3root_ASC',
  S3rootDesc = 's3root_DESC',
  SpeechOutputIdToInfoAsc = 'speechOutputIdToInfo_ASC',
  SpeechOutputIdToInfoDesc = 'speechOutputIdToInfo_DESC',
  StateValueAsc = 'stateValue_ASC',
  StateValueDesc = 'stateValue_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type OutputGroupScalarWhereInput = {
  AND?: InputMaybe<Array<OutputGroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<OutputGroupScalarWhereInput>>;
  OR?: InputMaybe<Array<OutputGroupScalarWhereInput>>;
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_contains?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_ends_with?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_gt?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_gte?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_in?: InputMaybe<Array<Scalars['String']['input']>>;
  appropriateQualityScoreNotes_lt?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_lte?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_not?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_not_contains?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  appropriateQualityScoreNotes_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_starts_with?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScore_gt?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScore_gte?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScore_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  appropriateQualityScore_lt?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScore_lte?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScore_not?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScore_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audibleOverlaps_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  currentFailureState_in?: InputMaybe<Array<OutputGroupState>>;
  currentFailureState_not?: InputMaybe<OutputGroupState>;
  currentFailureState_not_in?: InputMaybe<Array<OutputGroupState>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  mimicDataCollectionComplete_not?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_contains?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_ends_with?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_gt?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_gte?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  muxPlaybackId_lt?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_lte?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_not?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_not_contains?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  muxPlaybackId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_starts_with?: InputMaybe<Scalars['String']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingDurationMs_lt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_lte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingEndTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScore_gt?: InputMaybe<Scalars['Float']['input']>;
  qualityScore_gte?: InputMaybe<Scalars['Float']['input']>;
  qualityScore_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  qualityScore_lt?: InputMaybe<Scalars['Float']['input']>;
  qualityScore_lte?: InputMaybe<Scalars['Float']['input']>;
  qualityScore_not?: InputMaybe<Scalars['Float']['input']>;
  qualityScore_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  s3root_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_gt?: InputMaybe<Scalars['String']['input']>;
  s3root_gte?: InputMaybe<Scalars['String']['input']>;
  s3root_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_lt?: InputMaybe<Scalars['String']['input']>;
  s3root_lte?: InputMaybe<Scalars['String']['input']>;
  s3root_not?: InputMaybe<Scalars['String']['input']>;
  s3root_not_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  s3root_starts_with?: InputMaybe<Scalars['String']['input']>;
  stateValue?: InputMaybe<OutputGroupState>;
  stateValue_in?: InputMaybe<Array<OutputGroupState>>;
  stateValue_not?: InputMaybe<OutputGroupState>;
  stateValue_not_in?: InputMaybe<Array<OutputGroupState>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export enum OutputGroupState {
  Complete = 'Complete',
  ConcatenatingTunedSamples = 'ConcatenatingTunedSamples',
  ConcatenatingTunedSpeechOutputs = 'ConcatenatingTunedSpeechOutputs',
  Failed = 'Failed',
  GeneratingAudioOnlyWithTracks = 'GeneratingAudioOnlyWithTracks',
  GeneratingAudioRefinedDub = 'GeneratingAudioRefinedDub',
  GeneratingAudioRefinedVoiceOver = 'GeneratingAudioRefinedVoiceOver',
  GeneratingSourceAudioForMix = 'GeneratingSourceAudioForMix',
  GeneratingSourceAudioForReplace = 'GeneratingSourceAudioForReplace',
  GeneratingSubtitles = 'GeneratingSubtitles',
  GeneratingTunedSamples = 'GeneratingTunedSamples',
  GeneratingTunedSpeechOutputs = 'GeneratingTunedSpeechOutputs',
  GeneratingVideoAudioMix = 'GeneratingVideoAudioMix',
  GeneratingVideoAudioReplace = 'GeneratingVideoAudioReplace',
  Initial = 'Initial',
  MaybeSettingEditedAudioGroup = 'MaybeSettingEditedAudioGroup',
  UpdateAudioFrom16To24Bits = 'UpdateAudioFrom16To24Bits',
  UploadToMux = 'UploadToMux'
}

export type OutputGroupUpdateInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailure?: InputMaybe<OutputGroupFailureUpdateOneWithoutCurrentFailureOutputGroupInput>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupUpdateOneInput>;
  failures?: InputMaybe<OutputGroupFailureUpdateManyWithoutOutputGroupInput>;
  generatedBy?: InputMaybe<UserUpdateOneInput>;
  issues?: InputMaybe<OutputGroupIssueUpdateManyWithoutOutputGroupInput>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputUpdateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  samples?: InputMaybe<SampleUpdateManyWithoutOutputGroupInput>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyWithoutOutputGroupInput>;
  speechOutputsV2?: InputMaybe<SpeechOutputUpdateManyWithoutOutputGroupsInput>;
  stateValue?: InputMaybe<OutputGroupState>;
  translatedVideo?: InputMaybe<TranslatedVideoUpdateOneRequiredWithoutOutputGroupsInput>;
};

export type OutputGroupUpdateManyDataInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<OutputGroupState>;
};

export type OutputGroupUpdateManyWithWhereNestedInput = {
  data: OutputGroupUpdateManyDataInput;
  where: OutputGroupScalarWhereInput;
};

export type OutputGroupUpdateManyWithoutSpeechOutputsV2Input = {
  connect?: InputMaybe<Array<OutputGroupWhereUniqueInput>>;
  create?: InputMaybe<Array<OutputGroupCreateWithoutSpeechOutputsV2Input>>;
  delete?: InputMaybe<Array<OutputGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OutputGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OutputGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<OutputGroupWhereUniqueInput>>;
  update?: InputMaybe<Array<OutputGroupUpdateWithWhereUniqueWithoutSpeechOutputsV2Input>>;
  updateMany?: InputMaybe<Array<OutputGroupUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<OutputGroupUpsertWithWhereUniqueWithoutSpeechOutputsV2Input>>;
};

export type OutputGroupUpdateManyWithoutTranslatedVideoInput = {
  connect?: InputMaybe<Array<OutputGroupWhereUniqueInput>>;
  create?: InputMaybe<Array<OutputGroupCreateWithoutTranslatedVideoInput>>;
  delete?: InputMaybe<Array<OutputGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OutputGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OutputGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<OutputGroupWhereUniqueInput>>;
  update?: InputMaybe<Array<OutputGroupUpdateWithWhereUniqueWithoutTranslatedVideoInput>>;
  updateMany?: InputMaybe<Array<OutputGroupUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<OutputGroupUpsertWithWhereUniqueWithoutTranslatedVideoInput>>;
};

export type OutputGroupUpdateOneRequiredWithoutFailuresInput = {
  connect?: InputMaybe<OutputGroupWhereUniqueInput>;
  create?: InputMaybe<OutputGroupCreateWithoutFailuresInput>;
  update?: InputMaybe<OutputGroupUpdateWithoutFailuresDataInput>;
  upsert?: InputMaybe<OutputGroupUpsertWithoutFailuresInput>;
};

export type OutputGroupUpdateOneRequiredWithoutIssuesInput = {
  connect?: InputMaybe<OutputGroupWhereUniqueInput>;
  create?: InputMaybe<OutputGroupCreateWithoutIssuesInput>;
  update?: InputMaybe<OutputGroupUpdateWithoutIssuesDataInput>;
  upsert?: InputMaybe<OutputGroupUpsertWithoutIssuesInput>;
};

export type OutputGroupUpdateOneWithoutCurrentFailureInput = {
  connect?: InputMaybe<OutputGroupWhereUniqueInput>;
  create?: InputMaybe<OutputGroupCreateWithoutCurrentFailureInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OutputGroupUpdateWithoutCurrentFailureDataInput>;
  upsert?: InputMaybe<OutputGroupUpsertWithoutCurrentFailureInput>;
};

export type OutputGroupUpdateOneWithoutSamplesInput = {
  connect?: InputMaybe<OutputGroupWhereUniqueInput>;
  create?: InputMaybe<OutputGroupCreateWithoutSamplesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OutputGroupUpdateWithoutSamplesDataInput>;
  upsert?: InputMaybe<OutputGroupUpsertWithoutSamplesInput>;
};

export type OutputGroupUpdateOneWithoutSpeechOutputsInput = {
  connect?: InputMaybe<OutputGroupWhereUniqueInput>;
  create?: InputMaybe<OutputGroupCreateWithoutSpeechOutputsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OutputGroupUpdateWithoutSpeechOutputsDataInput>;
  upsert?: InputMaybe<OutputGroupUpsertWithoutSpeechOutputsInput>;
};

export type OutputGroupUpdateWithWhereUniqueWithoutSpeechOutputsV2Input = {
  data: OutputGroupUpdateWithoutSpeechOutputsV2DataInput;
  where: OutputGroupWhereUniqueInput;
};

export type OutputGroupUpdateWithWhereUniqueWithoutTranslatedVideoInput = {
  data: OutputGroupUpdateWithoutTranslatedVideoDataInput;
  where: OutputGroupWhereUniqueInput;
};

export type OutputGroupUpdateWithoutCurrentFailureDataInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupUpdateOneInput>;
  failures?: InputMaybe<OutputGroupFailureUpdateManyWithoutOutputGroupInput>;
  generatedBy?: InputMaybe<UserUpdateOneInput>;
  issues?: InputMaybe<OutputGroupIssueUpdateManyWithoutOutputGroupInput>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputUpdateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  samples?: InputMaybe<SampleUpdateManyWithoutOutputGroupInput>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyWithoutOutputGroupInput>;
  speechOutputsV2?: InputMaybe<SpeechOutputUpdateManyWithoutOutputGroupsInput>;
  stateValue?: InputMaybe<OutputGroupState>;
  translatedVideo?: InputMaybe<TranslatedVideoUpdateOneRequiredWithoutOutputGroupsInput>;
};

export type OutputGroupUpdateWithoutFailuresDataInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailure?: InputMaybe<OutputGroupFailureUpdateOneWithoutCurrentFailureOutputGroupInput>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupUpdateOneInput>;
  generatedBy?: InputMaybe<UserUpdateOneInput>;
  issues?: InputMaybe<OutputGroupIssueUpdateManyWithoutOutputGroupInput>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputUpdateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  samples?: InputMaybe<SampleUpdateManyWithoutOutputGroupInput>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyWithoutOutputGroupInput>;
  speechOutputsV2?: InputMaybe<SpeechOutputUpdateManyWithoutOutputGroupsInput>;
  stateValue?: InputMaybe<OutputGroupState>;
  translatedVideo?: InputMaybe<TranslatedVideoUpdateOneRequiredWithoutOutputGroupsInput>;
};

export type OutputGroupUpdateWithoutIssuesDataInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailure?: InputMaybe<OutputGroupFailureUpdateOneWithoutCurrentFailureOutputGroupInput>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupUpdateOneInput>;
  failures?: InputMaybe<OutputGroupFailureUpdateManyWithoutOutputGroupInput>;
  generatedBy?: InputMaybe<UserUpdateOneInput>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputUpdateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  samples?: InputMaybe<SampleUpdateManyWithoutOutputGroupInput>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyWithoutOutputGroupInput>;
  speechOutputsV2?: InputMaybe<SpeechOutputUpdateManyWithoutOutputGroupsInput>;
  stateValue?: InputMaybe<OutputGroupState>;
  translatedVideo?: InputMaybe<TranslatedVideoUpdateOneRequiredWithoutOutputGroupsInput>;
};

export type OutputGroupUpdateWithoutSamplesDataInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailure?: InputMaybe<OutputGroupFailureUpdateOneWithoutCurrentFailureOutputGroupInput>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupUpdateOneInput>;
  failures?: InputMaybe<OutputGroupFailureUpdateManyWithoutOutputGroupInput>;
  generatedBy?: InputMaybe<UserUpdateOneInput>;
  issues?: InputMaybe<OutputGroupIssueUpdateManyWithoutOutputGroupInput>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputUpdateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyWithoutOutputGroupInput>;
  speechOutputsV2?: InputMaybe<SpeechOutputUpdateManyWithoutOutputGroupsInput>;
  stateValue?: InputMaybe<OutputGroupState>;
  translatedVideo?: InputMaybe<TranslatedVideoUpdateOneRequiredWithoutOutputGroupsInput>;
};

export type OutputGroupUpdateWithoutSpeechOutputsDataInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailure?: InputMaybe<OutputGroupFailureUpdateOneWithoutCurrentFailureOutputGroupInput>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupUpdateOneInput>;
  failures?: InputMaybe<OutputGroupFailureUpdateManyWithoutOutputGroupInput>;
  generatedBy?: InputMaybe<UserUpdateOneInput>;
  issues?: InputMaybe<OutputGroupIssueUpdateManyWithoutOutputGroupInput>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputUpdateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  samples?: InputMaybe<SampleUpdateManyWithoutOutputGroupInput>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputsV2?: InputMaybe<SpeechOutputUpdateManyWithoutOutputGroupsInput>;
  stateValue?: InputMaybe<OutputGroupState>;
  translatedVideo?: InputMaybe<TranslatedVideoUpdateOneRequiredWithoutOutputGroupsInput>;
};

export type OutputGroupUpdateWithoutSpeechOutputsV2DataInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailure?: InputMaybe<OutputGroupFailureUpdateOneWithoutCurrentFailureOutputGroupInput>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupUpdateOneInput>;
  failures?: InputMaybe<OutputGroupFailureUpdateManyWithoutOutputGroupInput>;
  generatedBy?: InputMaybe<UserUpdateOneInput>;
  issues?: InputMaybe<OutputGroupIssueUpdateManyWithoutOutputGroupInput>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputUpdateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  samples?: InputMaybe<SampleUpdateManyWithoutOutputGroupInput>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyWithoutOutputGroupInput>;
  stateValue?: InputMaybe<OutputGroupState>;
  translatedVideo?: InputMaybe<TranslatedVideoUpdateOneRequiredWithoutOutputGroupsInput>;
};

export type OutputGroupUpdateWithoutTranslatedVideoDataInput = {
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  clipOutEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  currentFailure?: InputMaybe<OutputGroupFailureUpdateOneWithoutCurrentFailureOutputGroupInput>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupUpdateOneInput>;
  failures?: InputMaybe<OutputGroupFailureUpdateManyWithoutOutputGroupInput>;
  generatedBy?: InputMaybe<UserUpdateOneInput>;
  issues?: InputMaybe<OutputGroupIssueUpdateManyWithoutOutputGroupInput>;
  keepAudioEditsSnapshot?: InputMaybe<Scalars['Json']['input']>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  outputs?: InputMaybe<OutputUpdateManyWithoutOutputGroupInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  samples?: InputMaybe<SampleUpdateManyWithoutOutputGroupInput>;
  speechOutputIdToInfo?: InputMaybe<Scalars['Json']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyWithoutOutputGroupInput>;
  speechOutputsV2?: InputMaybe<SpeechOutputUpdateManyWithoutOutputGroupsInput>;
  stateValue?: InputMaybe<OutputGroupState>;
};

export type OutputGroupUpsertWithWhereUniqueWithoutSpeechOutputsV2Input = {
  create: OutputGroupCreateWithoutSpeechOutputsV2Input;
  update: OutputGroupUpdateWithoutSpeechOutputsV2DataInput;
  where: OutputGroupWhereUniqueInput;
};

export type OutputGroupUpsertWithWhereUniqueWithoutTranslatedVideoInput = {
  create: OutputGroupCreateWithoutTranslatedVideoInput;
  update: OutputGroupUpdateWithoutTranslatedVideoDataInput;
  where: OutputGroupWhereUniqueInput;
};

export type OutputGroupUpsertWithoutCurrentFailureInput = {
  create: OutputGroupCreateWithoutCurrentFailureInput;
  update: OutputGroupUpdateWithoutCurrentFailureDataInput;
};

export type OutputGroupUpsertWithoutFailuresInput = {
  create: OutputGroupCreateWithoutFailuresInput;
  update: OutputGroupUpdateWithoutFailuresDataInput;
};

export type OutputGroupUpsertWithoutIssuesInput = {
  create: OutputGroupCreateWithoutIssuesInput;
  update: OutputGroupUpdateWithoutIssuesDataInput;
};

export type OutputGroupUpsertWithoutSamplesInput = {
  create: OutputGroupCreateWithoutSamplesInput;
  update: OutputGroupUpdateWithoutSamplesDataInput;
};

export type OutputGroupUpsertWithoutSpeechOutputsInput = {
  create: OutputGroupCreateWithoutSpeechOutputsInput;
  update: OutputGroupUpdateWithoutSpeechOutputsDataInput;
};

export type OutputGroupWhereInput = {
  AND?: InputMaybe<Array<OutputGroupWhereInput>>;
  NOT?: InputMaybe<Array<OutputGroupWhereInput>>;
  OR?: InputMaybe<Array<OutputGroupWhereInput>>;
  appropriateQualityScore?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScoreNotes?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_contains?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_ends_with?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_gt?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_gte?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_in?: InputMaybe<Array<Scalars['String']['input']>>;
  appropriateQualityScoreNotes_lt?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_lte?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_not?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_not_contains?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  appropriateQualityScoreNotes_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreNotes_starts_with?: InputMaybe<Scalars['String']['input']>;
  appropriateQualityScoreQa?: InputMaybe<UserWhereInput>;
  appropriateQualityScore_gt?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScore_gte?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScore_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  appropriateQualityScore_lt?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScore_lte?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScore_not?: InputMaybe<Scalars['Float']['input']>;
  appropriateQualityScore_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audibleOverlaps_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  currentFailure?: InputMaybe<OutputGroupFailureWhereInput>;
  currentFailureState?: InputMaybe<OutputGroupState>;
  currentFailureState_in?: InputMaybe<Array<OutputGroupState>>;
  currentFailureState_not?: InputMaybe<OutputGroupState>;
  currentFailureState_not_in?: InputMaybe<Array<OutputGroupState>>;
  editedAudioGroup?: InputMaybe<EditedAudioGroupWhereInput>;
  failures_every?: InputMaybe<OutputGroupFailureWhereInput>;
  failures_none?: InputMaybe<OutputGroupFailureWhereInput>;
  failures_some?: InputMaybe<OutputGroupFailureWhereInput>;
  generatedBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  issues_every?: InputMaybe<OutputGroupIssueWhereInput>;
  issues_none?: InputMaybe<OutputGroupIssueWhereInput>;
  issues_some?: InputMaybe<OutputGroupIssueWhereInput>;
  mimicDataCollectionComplete?: InputMaybe<Scalars['Boolean']['input']>;
  mimicDataCollectionComplete_not?: InputMaybe<Scalars['Boolean']['input']>;
  muxPlaybackId?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_contains?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_ends_with?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_gt?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_gte?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  muxPlaybackId_lt?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_lte?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_not?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_not_contains?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  muxPlaybackId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  muxPlaybackId_starts_with?: InputMaybe<Scalars['String']['input']>;
  outputs_every?: InputMaybe<OutputWhereInput>;
  outputs_none?: InputMaybe<OutputWhereInput>;
  outputs_some?: InputMaybe<OutputWhereInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingDurationMs_lt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_lte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingEndTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  qualityScore?: InputMaybe<Scalars['Float']['input']>;
  qualityScoreQa?: InputMaybe<UserWhereInput>;
  qualityScore_gt?: InputMaybe<Scalars['Float']['input']>;
  qualityScore_gte?: InputMaybe<Scalars['Float']['input']>;
  qualityScore_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  qualityScore_lt?: InputMaybe<Scalars['Float']['input']>;
  qualityScore_lte?: InputMaybe<Scalars['Float']['input']>;
  qualityScore_not?: InputMaybe<Scalars['Float']['input']>;
  qualityScore_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  s3root_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_gt?: InputMaybe<Scalars['String']['input']>;
  s3root_gte?: InputMaybe<Scalars['String']['input']>;
  s3root_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_lt?: InputMaybe<Scalars['String']['input']>;
  s3root_lte?: InputMaybe<Scalars['String']['input']>;
  s3root_not?: InputMaybe<Scalars['String']['input']>;
  s3root_not_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  s3root_starts_with?: InputMaybe<Scalars['String']['input']>;
  samples_every?: InputMaybe<SampleWhereInput>;
  samples_none?: InputMaybe<SampleWhereInput>;
  samples_some?: InputMaybe<SampleWhereInput>;
  speechOutputsV2_every?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputsV2_none?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputsV2_some?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_every?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_none?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_some?: InputMaybe<SpeechOutputWhereInput>;
  stateValue?: InputMaybe<OutputGroupState>;
  stateValue_in?: InputMaybe<Array<OutputGroupState>>;
  stateValue_not?: InputMaybe<OutputGroupState>;
  stateValue_not_in?: InputMaybe<Array<OutputGroupState>>;
  translatedVideo?: InputMaybe<TranslatedVideoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type OutputGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum OutputOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FrameReviewLinkAsc = 'frameReviewLink_ASC',
  FrameReviewLinkDesc = 'frameReviewLink_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InitialFrameProjectTypeAsc = 'initialFrameProjectType_ASC',
  InitialFrameProjectTypeDesc = 'initialFrameProjectType_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type OutputScalarWhereInput = {
  AND?: InputMaybe<Array<OutputScalarWhereInput>>;
  NOT?: InputMaybe<Array<OutputScalarWhereInput>>;
  OR?: InputMaybe<Array<OutputScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  frameReviewLink?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_contains?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_ends_with?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_gt?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_gte?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_in?: InputMaybe<Array<Scalars['String']['input']>>;
  frameReviewLink_lt?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_lte?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_not?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  frameReviewLink_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  initialFrameProjectType?: InputMaybe<FrameProjectType>;
  initialFrameProjectType_in?: InputMaybe<Array<FrameProjectType>>;
  initialFrameProjectType_not?: InputMaybe<FrameProjectType>;
  initialFrameProjectType_not_in?: InputMaybe<Array<FrameProjectType>>;
  type?: InputMaybe<OutputType>;
  type_in?: InputMaybe<Array<OutputType>>;
  type_not?: InputMaybe<OutputType>;
  type_not_in?: InputMaybe<Array<OutputType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export enum OutputType {
  AudioMixOnly = 'AudioMixOnly',
  AudioOnly = 'AudioOnly',
  AudioOnlyOneSpeakerPerChannel = 'AudioOnlyOneSpeakerPerChannel',
  AudioOnlyOneSpeakerPerChannelRefined = 'AudioOnlyOneSpeakerPerChannelRefined',
  AudioOnlyWithTracks = 'AudioOnlyWithTracks',
  AudioPostProduction = 'AudioPostProduction',
  AudioRefinedDub = 'AudioRefinedDub',
  AudioRefinedVoiceOver = 'AudioRefinedVoiceOver',
  AudioReplaceWithEdits = 'AudioReplaceWithEdits',
  MimicOutputsData = 'MimicOutputsData',
  SourceSubtitles = 'SourceSubtitles',
  SrtPostProduction = 'SrtPostProduction',
  Subtitles = 'Subtitles',
  VideoAudioMix = 'VideoAudioMix',
  VideoAudioReplace = 'VideoAudioReplace',
  VideoDubWithTracks = 'VideoDubWithTracks',
  VideoPostProduction = 'VideoPostProduction'
}

export type OutputUpdateManyDataInput = {
  frameReviewLink?: InputMaybe<Scalars['String']['input']>;
  initialFrameProjectType?: InputMaybe<FrameProjectType>;
  type?: InputMaybe<OutputType>;
};

export type OutputUpdateManyWithWhereNestedInput = {
  data: OutputUpdateManyDataInput;
  where: OutputScalarWhereInput;
};

export type OutputUpdateManyWithoutOutputGroupInput = {
  connect?: InputMaybe<Array<OutputWhereUniqueInput>>;
  create?: InputMaybe<Array<OutputCreateWithoutOutputGroupInput>>;
  delete?: InputMaybe<Array<OutputWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OutputScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OutputWhereUniqueInput>>;
  set?: InputMaybe<Array<OutputWhereUniqueInput>>;
  update?: InputMaybe<Array<OutputUpdateWithWhereUniqueWithoutOutputGroupInput>>;
  updateMany?: InputMaybe<Array<OutputUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<OutputUpsertWithWhereUniqueWithoutOutputGroupInput>>;
};

export type OutputUpdateWithWhereUniqueWithoutOutputGroupInput = {
  data: OutputUpdateWithoutOutputGroupDataInput;
  where: OutputWhereUniqueInput;
};

export type OutputUpdateWithoutOutputGroupDataInput = {
  file?: InputMaybe<FileUpdateOneRequiredInput>;
  frameReviewLink?: InputMaybe<Scalars['String']['input']>;
  initialFrameProjectType?: InputMaybe<FrameProjectType>;
  type?: InputMaybe<OutputType>;
};

export type OutputUpsertWithWhereUniqueWithoutOutputGroupInput = {
  create: OutputCreateWithoutOutputGroupInput;
  update: OutputUpdateWithoutOutputGroupDataInput;
  where: OutputWhereUniqueInput;
};

export type OutputWhereInput = {
  AND?: InputMaybe<Array<OutputWhereInput>>;
  NOT?: InputMaybe<Array<OutputWhereInput>>;
  OR?: InputMaybe<Array<OutputWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  file?: InputMaybe<FileWhereInput>;
  frameReviewLink?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_contains?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_ends_with?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_gt?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_gte?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_in?: InputMaybe<Array<Scalars['String']['input']>>;
  frameReviewLink_lt?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_lte?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_not?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  frameReviewLink_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  frameReviewLink_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  initialFrameProjectType?: InputMaybe<FrameProjectType>;
  initialFrameProjectType_in?: InputMaybe<Array<FrameProjectType>>;
  initialFrameProjectType_not?: InputMaybe<FrameProjectType>;
  initialFrameProjectType_not_in?: InputMaybe<Array<FrameProjectType>>;
  outputGroup?: InputMaybe<OutputGroupWhereInput>;
  type?: InputMaybe<OutputType>;
  type_in?: InputMaybe<Array<OutputType>>;
  type_not?: InputMaybe<OutputType>;
  type_not_in?: InputMaybe<Array<OutputType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type OutputWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PhonesData = {
  __typename?: 'PhonesData';
  createdAt: Scalars['DateTime']['output'];
  features?: Maybe<Array<FeaturesData>>;
  id: Scalars['ID']['output'];
  inkwellResp: Scalars['Json']['output'];
  inkwellRespHashV3: Scalars['String']['output'];
  inkwellVersion: Scalars['String']['output'];
  languageCode: Scalars['String']['output'];
  parsedText: Scalars['String']['output'];
  processingDurationMs?: Maybe<Scalars['Float']['output']>;
  processingEndTime: Scalars['DateTime']['output'];
  processingStartTime: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type PhonesDataFeaturesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FeaturesDataOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeaturesDataWhereInput>;
};

export type PhonesDataCreateOneWithoutFeaturesInput = {
  connect?: InputMaybe<PhonesDataWhereUniqueInput>;
  create?: InputMaybe<PhonesDataCreateWithoutFeaturesInput>;
};

export type PhonesDataCreateWithoutFeaturesInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  inkwellResp: Scalars['Json']['input'];
  inkwellRespHashV3: Scalars['String']['input'];
  inkwellVersion: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  parsedText: Scalars['String']['input'];
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime: Scalars['DateTime']['input'];
  processingStartTime: Scalars['DateTime']['input'];
};

export type PhonesDataUpdateOneRequiredWithoutFeaturesInput = {
  connect?: InputMaybe<PhonesDataWhereUniqueInput>;
  create?: InputMaybe<PhonesDataCreateWithoutFeaturesInput>;
  update?: InputMaybe<PhonesDataUpdateWithoutFeaturesDataInput>;
  upsert?: InputMaybe<PhonesDataUpsertWithoutFeaturesInput>;
};

export type PhonesDataUpdateWithoutFeaturesDataInput = {
  inkwellResp?: InputMaybe<Scalars['Json']['input']>;
  inkwellRespHashV3?: InputMaybe<Scalars['String']['input']>;
  inkwellVersion?: InputMaybe<Scalars['String']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  parsedText?: InputMaybe<Scalars['String']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PhonesDataUpsertWithoutFeaturesInput = {
  create: PhonesDataCreateWithoutFeaturesInput;
  update: PhonesDataUpdateWithoutFeaturesDataInput;
};

export type PhonesDataWhereInput = {
  AND?: InputMaybe<Array<PhonesDataWhereInput>>;
  NOT?: InputMaybe<Array<PhonesDataWhereInput>>;
  OR?: InputMaybe<Array<PhonesDataWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  features_every?: InputMaybe<FeaturesDataWhereInput>;
  features_none?: InputMaybe<FeaturesDataWhereInput>;
  features_some?: InputMaybe<FeaturesDataWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  inkwellRespHashV3?: InputMaybe<Scalars['String']['input']>;
  inkwellRespHashV3_contains?: InputMaybe<Scalars['String']['input']>;
  inkwellRespHashV3_ends_with?: InputMaybe<Scalars['String']['input']>;
  inkwellRespHashV3_gt?: InputMaybe<Scalars['String']['input']>;
  inkwellRespHashV3_gte?: InputMaybe<Scalars['String']['input']>;
  inkwellRespHashV3_in?: InputMaybe<Array<Scalars['String']['input']>>;
  inkwellRespHashV3_lt?: InputMaybe<Scalars['String']['input']>;
  inkwellRespHashV3_lte?: InputMaybe<Scalars['String']['input']>;
  inkwellRespHashV3_not?: InputMaybe<Scalars['String']['input']>;
  inkwellRespHashV3_not_contains?: InputMaybe<Scalars['String']['input']>;
  inkwellRespHashV3_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  inkwellRespHashV3_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  inkwellRespHashV3_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  inkwellRespHashV3_starts_with?: InputMaybe<Scalars['String']['input']>;
  inkwellVersion?: InputMaybe<Scalars['String']['input']>;
  inkwellVersion_contains?: InputMaybe<Scalars['String']['input']>;
  inkwellVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  inkwellVersion_gt?: InputMaybe<Scalars['String']['input']>;
  inkwellVersion_gte?: InputMaybe<Scalars['String']['input']>;
  inkwellVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  inkwellVersion_lt?: InputMaybe<Scalars['String']['input']>;
  inkwellVersion_lte?: InputMaybe<Scalars['String']['input']>;
  inkwellVersion_not?: InputMaybe<Scalars['String']['input']>;
  inkwellVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  inkwellVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  inkwellVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  inkwellVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  inkwellVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  languageCode_contains?: InputMaybe<Scalars['String']['input']>;
  languageCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  languageCode_gt?: InputMaybe<Scalars['String']['input']>;
  languageCode_gte?: InputMaybe<Scalars['String']['input']>;
  languageCode_in?: InputMaybe<Array<Scalars['String']['input']>>;
  languageCode_lt?: InputMaybe<Scalars['String']['input']>;
  languageCode_lte?: InputMaybe<Scalars['String']['input']>;
  languageCode_not?: InputMaybe<Scalars['String']['input']>;
  languageCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  languageCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  languageCode_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  languageCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  languageCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  parsedText?: InputMaybe<Scalars['String']['input']>;
  parsedText_contains?: InputMaybe<Scalars['String']['input']>;
  parsedText_ends_with?: InputMaybe<Scalars['String']['input']>;
  parsedText_gt?: InputMaybe<Scalars['String']['input']>;
  parsedText_gte?: InputMaybe<Scalars['String']['input']>;
  parsedText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  parsedText_lt?: InputMaybe<Scalars['String']['input']>;
  parsedText_lte?: InputMaybe<Scalars['String']['input']>;
  parsedText_not?: InputMaybe<Scalars['String']['input']>;
  parsedText_not_contains?: InputMaybe<Scalars['String']['input']>;
  parsedText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  parsedText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  parsedText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  parsedText_starts_with?: InputMaybe<Scalars['String']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingDurationMs_lt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_lte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingEndTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type PhonesDataWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  inkwellRespHashV3?: InputMaybe<Scalars['String']['input']>;
};

export type PreUser = {
  __typename?: 'PreUser';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userRole: UserRole;
};

export type PreUserCreateInput = {
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  userRole: UserRole;
};

export enum PreUserOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UserRoleAsc = 'userRole_ASC',
  UserRoleDesc = 'userRole_DESC'
}

export type PreUserWhereInput = {
  AND?: InputMaybe<Array<PreUserWhereInput>>;
  NOT?: InputMaybe<Array<PreUserWhereInput>>;
  OR?: InputMaybe<Array<PreUserWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_contains?: InputMaybe<Scalars['String']['input']>;
  email_ends_with?: InputMaybe<Scalars['String']['input']>;
  email_gt?: InputMaybe<Scalars['String']['input']>;
  email_gte?: InputMaybe<Scalars['String']['input']>;
  email_in?: InputMaybe<Array<Scalars['String']['input']>>;
  email_lt?: InputMaybe<Scalars['String']['input']>;
  email_lte?: InputMaybe<Scalars['String']['input']>;
  email_not?: InputMaybe<Scalars['String']['input']>;
  email_not_contains?: InputMaybe<Scalars['String']['input']>;
  email_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  email_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  email_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  email_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  userRole?: InputMaybe<UserRole>;
  userRole_in?: InputMaybe<Array<UserRole>>;
  userRole_not?: InputMaybe<UserRole>;
  userRole_not_in?: InputMaybe<Array<UserRole>>;
};

export type PreUserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum PrepareSegmentsMethod {
  Tessellation = 'Tessellation',
  TessellationLlm = 'TessellationLLM'
}

export enum ProviderType {
  Gcp = 'GCP',
  S3 = 'S3'
}

export type Query = {
  __typename?: 'Query';
  approvedInvoiceDate?: Maybe<ApprovedInvoiceDate>;
  approvedInvoiceDates: Array<ApprovedInvoiceDate>;
  correction?: Maybe<Correction>;
  correctionDraft?: Maybe<CorrectionDraft>;
  correctionDrafts: Array<CorrectionDraft>;
  corrections: Array<Correction>;
  editedAudioGroup?: Maybe<EditedAudioGroup>;
  editedAudioGroups: Array<EditedAudioGroup>;
  findInSegments: Scalars['Json']['output'];
  getRefinedAudioPresets: Scalars['Json']['output'];
  myProfile: User;
  note?: Maybe<Note>;
  notes: Array<Note>;
  outputGroup?: Maybe<OutputGroup>;
  outputGroupIssue?: Maybe<OutputGroupIssue>;
  outputGroupIssueTimings: Scalars['Json']['output'];
  outputGroupIssues: Array<OutputGroupIssue>;
  outputGroups: Array<OutputGroup>;
  preUser?: Maybe<PreUser>;
  preUsers: Array<PreUser>;
  /** Presigned Url for an asset on Mux */
  presignedMuxUrl?: Maybe<Scalars['String']['output']>;
  regeneratedFeaturesData?: Maybe<RegeneratedFeaturesData>;
  regeneratedFeaturesDatas: Array<RegeneratedFeaturesData>;
  segment?: Maybe<Segment>;
  segmentSnapshot?: Maybe<SegmentSnapshot>;
  segmentSnapshots: Array<SegmentSnapshot>;
  segments: Array<Segment>;
  segmentsWithContextByTimeRange: Scalars['Json']['output'];
  session?: Maybe<Session>;
  sessions: Array<Session>;
  sourceChannel?: Maybe<SourceChannel>;
  sourceChannels: Array<SourceChannel>;
  sourceVideo?: Maybe<SourceVideo>;
  sourceVideos: Array<SourceVideo>;
  speechOutput?: Maybe<SpeechOutput>;
  speechOutputCollection?: Maybe<SpeechOutputCollection>;
  speechOutputCollections: Array<SpeechOutputCollection>;
  speechOutputs: Array<SpeechOutput>;
  stateTransitionEvent?: Maybe<StateTransitionEvent>;
  stateTransitionEvents: Array<StateTransitionEvent>;
  track?: Maybe<Track>;
  tracks: Array<Track>;
  translatedChannel?: Maybe<TranslatedChannel>;
  translatedChannels: Array<TranslatedChannel>;
  translatedVideo?: Maybe<TranslatedVideo>;
  translatedVideoSnapshot?: Maybe<TranslatedVideoSnapshot>;
  translatedVideoSnapshots: Array<TranslatedVideoSnapshot>;
  translatedVideos: Array<TranslatedVideo>;
  translationMemory: Scalars['Json']['output'];
  user?: Maybe<User>;
  users: Array<User>;
  voice?: Maybe<Voice>;
  voices: Array<Voice>;
  ytChannel: YtChannel;
  ytPlaylist?: Maybe<YtPlaylist>;
  ytPlaylistItems: YtPlaylistItems;
  ytVideo?: Maybe<YtVideo>;
};


export type QueryApprovedInvoiceDateArgs = {
  where: ApprovedInvoiceDateWhereUniqueInput;
};


export type QueryApprovedInvoiceDatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ApprovedInvoiceDateOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ApprovedInvoiceDateWhereInput>;
};


export type QueryCorrectionArgs = {
  where: CorrectionWhereUniqueInput;
};


export type QueryCorrectionDraftArgs = {
  where: CorrectionDraftWhereUniqueInput;
};


export type QueryCorrectionDraftsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CorrectionDraftOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CorrectionDraftWhereInput>;
};


export type QueryCorrectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CorrectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CorrectionWhereInput>;
};


export type QueryEditedAudioGroupArgs = {
  where: EditedAudioGroupWhereUniqueInput;
};


export type QueryEditedAudioGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EditedAudioGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EditedAudioGroupWhereInput>;
};


export type QueryFindInSegmentsArgs = {
  searchTerm: Scalars['String']['input'];
  translatedVideoId: Scalars['ID']['input'];
};


export type QueryNoteArgs = {
  where: NoteWhereUniqueInput;
};


export type QueryNotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<NoteOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoteWhereInput>;
};


export type QueryOutputGroupArgs = {
  where: OutputGroupWhereUniqueInput;
};


export type QueryOutputGroupIssueArgs = {
  where: OutputGroupIssueWhereUniqueInput;
};


export type QueryOutputGroupIssueTimingsArgs = {
  ogID: Scalars['ID']['input'];
};


export type QueryOutputGroupIssuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OutputGroupIssueOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutputGroupIssueWhereInput>;
};


export type QueryOutputGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OutputGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutputGroupWhereInput>;
};


export type QueryPreUserArgs = {
  where: PreUserWhereUniqueInput;
};


export type QueryPreUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PreUserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PreUserWhereInput>;
};


export type QueryPresignedMuxUrlArgs = {
  ogId: Scalars['ID']['input'];
};


export type QueryRegeneratedFeaturesDataArgs = {
  where: RegeneratedFeaturesDataWhereUniqueInput;
};


export type QueryRegeneratedFeaturesDatasArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RegeneratedFeaturesDataOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RegeneratedFeaturesDataWhereInput>;
};


export type QuerySegmentArgs = {
  where: SegmentWhereUniqueInput;
};


export type QuerySegmentSnapshotArgs = {
  where: SegmentSnapshotWhereUniqueInput;
};


export type QuerySegmentSnapshotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SegmentSnapshotOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SegmentSnapshotWhereInput>;
};


export type QuerySegmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SegmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SegmentWhereInput>;
};


export type QuerySegmentsWithContextByTimeRangeArgs = {
  category: Array<Scalars['String']['input']>;
  currentTime: Scalars['Float']['input'];
  outputGroupId: Scalars['String']['input'];
  timeRangeSecs: Scalars['Int']['input'];
  tvID: Scalars['String']['input'];
};


export type QuerySessionArgs = {
  where: SessionWhereUniqueInput;
};


export type QuerySessionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SessionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionWhereInput>;
};


export type QuerySourceChannelArgs = {
  where: SourceChannelWhereUniqueInput;
};


export type QuerySourceChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SourceChannelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SourceChannelWhereInput>;
};


export type QuerySourceVideoArgs = {
  where: SourceVideoWhereUniqueInput;
};


export type QuerySourceVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SourceVideoOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SourceVideoWhereInput>;
};


export type QuerySpeechOutputArgs = {
  where: SpeechOutputWhereUniqueInput;
};


export type QuerySpeechOutputCollectionArgs = {
  where: SpeechOutputCollectionWhereUniqueInput;
};


export type QuerySpeechOutputCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpeechOutputCollectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpeechOutputCollectionWhereInput>;
};


export type QuerySpeechOutputsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpeechOutputOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpeechOutputWhereInput>;
};


export type QueryStateTransitionEventArgs = {
  where: StateTransitionEventWhereUniqueInput;
};


export type QueryStateTransitionEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<StateTransitionEventOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StateTransitionEventWhereInput>;
};


export type QueryTrackArgs = {
  where: TrackWhereUniqueInput;
};


export type QueryTracksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TrackOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TrackWhereInput>;
};


export type QueryTranslatedChannelArgs = {
  where: TranslatedChannelWhereUniqueInput;
};


export type QueryTranslatedChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TranslatedChannelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TranslatedChannelWhereInput>;
};


export type QueryTranslatedVideoArgs = {
  where: TranslatedVideoWhereUniqueInput;
};


export type QueryTranslatedVideoSnapshotArgs = {
  where: TranslatedVideoSnapshotWhereUniqueInput;
};


export type QueryTranslatedVideoSnapshotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TranslatedVideoSnapshotOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TranslatedVideoSnapshotWhereInput>;
};


export type QueryTranslatedVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TranslatedVideoOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TranslatedVideoWhereInput>;
};


export type QueryTranslationMemoryArgs = {
  searchScope?: InputMaybe<Scalars['Int']['input']>;
  sourceLanguage?: InputMaybe<Scalars['String']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  term: Scalars['String']['input'];
  translatedChannelId: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryVoiceArgs = {
  where: VoiceWhereUniqueInput;
};


export type QueryVoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<VoiceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VoiceWhereInput>;
};


export type QueryYtChannelArgs = {
  lookupType?: InputMaybe<YtChannelLookupType>;
  lookupValue: Scalars['String']['input'];
};


export type QueryYtPlaylistArgs = {
  ytPlaylistId: Scalars['ID']['input'];
};


export type QueryYtPlaylistItemsArgs = {
  maxResults?: InputMaybe<Scalars['Int']['input']>;
  pageToken?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  whereYtPlaylistItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ytPlaylistId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryYtVideoArgs = {
  lookupType?: InputMaybe<YtVideoLookupType>;
  lookupValue: Scalars['String']['input'];
};

export type QueueStatus = {
  __typename?: 'QueueStatus';
  deDE: LanguageStatus;
  enGB: LanguageStatus;
  esLA: LanguageStatus;
  frFR: LanguageStatus;
  itIT: LanguageStatus;
  ptBR: LanguageStatus;
};

export type RegeneratedFeaturesData = {
  __typename?: 'RegeneratedFeaturesData';
  createdAt: Scalars['DateTime']['output'];
  featureDatas?: Maybe<Array<FeaturesData>>;
  id: Scalars['ID']['output'];
  inputsHash: Scalars['String']['output'];
  regenerationCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type RegeneratedFeaturesDataFeatureDatasArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FeaturesDataOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeaturesDataWhereInput>;
};

export enum RegeneratedFeaturesDataOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InputsHashAsc = 'inputsHash_ASC',
  InputsHashDesc = 'inputsHash_DESC',
  RegenerationCountAsc = 'regenerationCount_ASC',
  RegenerationCountDesc = 'regenerationCount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RegeneratedFeaturesDataWhereInput = {
  AND?: InputMaybe<Array<RegeneratedFeaturesDataWhereInput>>;
  NOT?: InputMaybe<Array<RegeneratedFeaturesDataWhereInput>>;
  OR?: InputMaybe<Array<RegeneratedFeaturesDataWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  featureDatas_every?: InputMaybe<FeaturesDataWhereInput>;
  featureDatas_none?: InputMaybe<FeaturesDataWhereInput>;
  featureDatas_some?: InputMaybe<FeaturesDataWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
  inputsHash_contains?: InputMaybe<Scalars['String']['input']>;
  inputsHash_ends_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_gt?: InputMaybe<Scalars['String']['input']>;
  inputsHash_gte?: InputMaybe<Scalars['String']['input']>;
  inputsHash_in?: InputMaybe<Array<Scalars['String']['input']>>;
  inputsHash_lt?: InputMaybe<Scalars['String']['input']>;
  inputsHash_lte?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_contains?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  inputsHash_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_starts_with?: InputMaybe<Scalars['String']['input']>;
  regenerationCount?: InputMaybe<Scalars['Int']['input']>;
  regenerationCount_gt?: InputMaybe<Scalars['Int']['input']>;
  regenerationCount_gte?: InputMaybe<Scalars['Int']['input']>;
  regenerationCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  regenerationCount_lt?: InputMaybe<Scalars['Int']['input']>;
  regenerationCount_lte?: InputMaybe<Scalars['Int']['input']>;
  regenerationCount_not?: InputMaybe<Scalars['Int']['input']>;
  regenerationCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type RegeneratedFeaturesDataWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
};

export enum ReviewerRole {
  Qa = 'QA',
  Qc = 'QC'
}

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID']['output'];
  name?: Maybe<UserRole>;
};

export type RoleCreateInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<UserRole>;
};

export type RoleCreateManyInput = {
  connect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  create?: InputMaybe<Array<RoleCreateInput>>;
};

export enum RoleOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RoleScalarWhereInput = {
  AND?: InputMaybe<Array<RoleScalarWhereInput>>;
  NOT?: InputMaybe<Array<RoleScalarWhereInput>>;
  OR?: InputMaybe<Array<RoleScalarWhereInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<UserRole>;
  name_in?: InputMaybe<Array<UserRole>>;
  name_not?: InputMaybe<UserRole>;
  name_not_in?: InputMaybe<Array<UserRole>>;
};

export type RoleUpdateDataInput = {
  name?: InputMaybe<UserRole>;
};

export type RoleUpdateManyDataInput = {
  name?: InputMaybe<UserRole>;
};

export type RoleUpdateManyInput = {
  connect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  create?: InputMaybe<Array<RoleCreateInput>>;
  delete?: InputMaybe<Array<RoleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  set?: InputMaybe<Array<RoleWhereUniqueInput>>;
  update?: InputMaybe<Array<RoleUpdateWithWhereUniqueNestedInput>>;
  updateMany?: InputMaybe<Array<RoleUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<RoleUpsertWithWhereUniqueNestedInput>>;
};

export type RoleUpdateManyWithWhereNestedInput = {
  data: RoleUpdateManyDataInput;
  where: RoleScalarWhereInput;
};

export type RoleUpdateWithWhereUniqueNestedInput = {
  data: RoleUpdateDataInput;
  where: RoleWhereUniqueInput;
};

export type RoleUpsertWithWhereUniqueNestedInput = {
  create: RoleCreateInput;
  update: RoleUpdateDataInput;
  where: RoleWhereUniqueInput;
};

export type RoleWhereInput = {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<UserRole>;
  name_in?: InputMaybe<Array<UserRole>>;
  name_not?: InputMaybe<UserRole>;
  name_not_in?: InputMaybe<Array<UserRole>>;
};

export type RoleWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<UserRole>;
};

export type Sample = {
  __typename?: 'Sample';
  audioDuration?: Maybe<Scalars['Float']['output']>;
  audioFile?: Maybe<File>;
  correctionApplied: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  featuresS3?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  modelName?: Maybe<Scalars['String']['output']>;
  modelVersion?: Maybe<Scalars['String']['output']>;
  outputGroup?: Maybe<OutputGroup>;
  papercupSpeakerId: Scalars['String']['output'];
  phonemizationVersion?: Maybe<Scalars['String']['output']>;
  phonemizedText: Array<Scalars['Int']['output']>;
  startTime?: Maybe<Scalars['Float']['output']>;
  state: Scalars['Json']['output'];
  stateValue: SampleState;
  stretchRate: Scalars['Float']['output'];
  style: Scalars['String']['output'];
  text: Scalars['String']['output'];
  trainingSetVersion?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  video?: Maybe<TranslatedVideo>;
  vocoderName?: Maybe<Scalars['String']['output']>;
  vocoderType: VocoderType;
  vocoderVersion?: Maybe<Scalars['String']['output']>;
};

export type SampleCreateInput = {
  audioDuration?: InputMaybe<Scalars['Float']['input']>;
  audioFile?: InputMaybe<FileCreateOneInput>;
  correctionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  outputGroup?: InputMaybe<OutputGroupCreateOneWithoutSamplesInput>;
  papercupSpeakerId: Scalars['String']['input'];
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SampleCreatephonemizedTextInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SampleState>;
  stretchRate: Scalars['Float']['input'];
  style?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  trainingSetVersion?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<TranslatedVideoCreateOneInput>;
  vocoderName?: InputMaybe<Scalars['String']['input']>;
  vocoderType: VocoderType;
  vocoderVersion?: InputMaybe<Scalars['String']['input']>;
};

export type SampleCreateManyInput = {
  connect?: InputMaybe<Array<SampleWhereUniqueInput>>;
  create?: InputMaybe<Array<SampleCreateInput>>;
};

export type SampleCreateManyWithoutOutputGroupInput = {
  connect?: InputMaybe<Array<SampleWhereUniqueInput>>;
  create?: InputMaybe<Array<SampleCreateWithoutOutputGroupInput>>;
};

export type SampleCreateOneInput = {
  connect?: InputMaybe<SampleWhereUniqueInput>;
  create?: InputMaybe<SampleCreateInput>;
};

export type SampleCreateWithoutOutputGroupInput = {
  audioDuration?: InputMaybe<Scalars['Float']['input']>;
  audioFile?: InputMaybe<FileCreateOneInput>;
  correctionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId: Scalars['String']['input'];
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SampleCreatephonemizedTextInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SampleState>;
  stretchRate: Scalars['Float']['input'];
  style?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  trainingSetVersion?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<TranslatedVideoCreateOneInput>;
  vocoderName?: InputMaybe<Scalars['String']['input']>;
  vocoderType: VocoderType;
  vocoderVersion?: InputMaybe<Scalars['String']['input']>;
};

export type SampleCreatephonemizedTextInput = {
  set?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum SampleOrderByInput {
  AudioDurationAsc = 'audioDuration_ASC',
  AudioDurationDesc = 'audioDuration_DESC',
  CorrectionAppliedAsc = 'correctionApplied_ASC',
  CorrectionAppliedDesc = 'correctionApplied_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FeaturesS3Asc = 'featuresS3_ASC',
  FeaturesS3Desc = 'featuresS3_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  ModelNameAsc = 'modelName_ASC',
  ModelNameDesc = 'modelName_DESC',
  ModelVersionAsc = 'modelVersion_ASC',
  ModelVersionDesc = 'modelVersion_DESC',
  PapercupSpeakerIdAsc = 'papercupSpeakerId_ASC',
  PapercupSpeakerIdDesc = 'papercupSpeakerId_DESC',
  PhonemizationVersionAsc = 'phonemizationVersion_ASC',
  PhonemizationVersionDesc = 'phonemizationVersion_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  StateValueAsc = 'stateValue_ASC',
  StateValueDesc = 'stateValue_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StretchRateAsc = 'stretchRate_ASC',
  StretchRateDesc = 'stretchRate_DESC',
  StyleAsc = 'style_ASC',
  StyleDesc = 'style_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TrainingSetVersionAsc = 'trainingSetVersion_ASC',
  TrainingSetVersionDesc = 'trainingSetVersion_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VocoderNameAsc = 'vocoderName_ASC',
  VocoderNameDesc = 'vocoderName_DESC',
  VocoderTypeAsc = 'vocoderType_ASC',
  VocoderTypeDesc = 'vocoderType_DESC',
  VocoderVersionAsc = 'vocoderVersion_ASC',
  VocoderVersionDesc = 'vocoderVersion_DESC'
}

export type SampleScalarWhereInput = {
  AND?: InputMaybe<Array<SampleScalarWhereInput>>;
  NOT?: InputMaybe<Array<SampleScalarWhereInput>>;
  OR?: InputMaybe<Array<SampleScalarWhereInput>>;
  audioDuration?: InputMaybe<Scalars['Float']['input']>;
  audioDuration_gt?: InputMaybe<Scalars['Float']['input']>;
  audioDuration_gte?: InputMaybe<Scalars['Float']['input']>;
  audioDuration_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  audioDuration_lt?: InputMaybe<Scalars['Float']['input']>;
  audioDuration_lte?: InputMaybe<Scalars['Float']['input']>;
  audioDuration_not?: InputMaybe<Scalars['Float']['input']>;
  audioDuration_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  correctionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  correctionApplied_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  featuresS3_contains?: InputMaybe<Scalars['String']['input']>;
  featuresS3_ends_with?: InputMaybe<Scalars['String']['input']>;
  featuresS3_gt?: InputMaybe<Scalars['String']['input']>;
  featuresS3_gte?: InputMaybe<Scalars['String']['input']>;
  featuresS3_in?: InputMaybe<Array<Scalars['String']['input']>>;
  featuresS3_lt?: InputMaybe<Scalars['String']['input']>;
  featuresS3_lte?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not_contains?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  featuresS3_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  featuresS3_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  language_contains?: InputMaybe<Scalars['String']['input']>;
  language_ends_with?: InputMaybe<Scalars['String']['input']>;
  language_gt?: InputMaybe<Scalars['String']['input']>;
  language_gte?: InputMaybe<Scalars['String']['input']>;
  language_in?: InputMaybe<Array<Scalars['String']['input']>>;
  language_lt?: InputMaybe<Scalars['String']['input']>;
  language_lte?: InputMaybe<Scalars['String']['input']>;
  language_not?: InputMaybe<Scalars['String']['input']>;
  language_not_contains?: InputMaybe<Scalars['String']['input']>;
  language_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  language_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  language_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  language_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelName_contains?: InputMaybe<Scalars['String']['input']>;
  modelName_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelName_gt?: InputMaybe<Scalars['String']['input']>;
  modelName_gte?: InputMaybe<Scalars['String']['input']>;
  modelName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelName_lt?: InputMaybe<Scalars['String']['input']>;
  modelName_lte?: InputMaybe<Scalars['String']['input']>;
  modelName_not?: InputMaybe<Scalars['String']['input']>;
  modelName_not_contains?: InputMaybe<Scalars['String']['input']>;
  modelName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelName_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  modelVersion_contains?: InputMaybe<Scalars['String']['input']>;
  modelVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion_gt?: InputMaybe<Scalars['String']['input']>;
  modelVersion_gte?: InputMaybe<Scalars['String']['input']>;
  modelVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelVersion_lt?: InputMaybe<Scalars['String']['input']>;
  modelVersion_lte?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_contains?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_ends_with?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_gt?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_gte?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  papercupSpeakerId_lt?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_lte?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not_contains?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  papercupSpeakerId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_starts_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_contains?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_gt?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_gte?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phonemizationVersion_lt?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_lte?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phonemizationVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  startTime_gt?: InputMaybe<Scalars['Float']['input']>;
  startTime_gte?: InputMaybe<Scalars['Float']['input']>;
  startTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTime_lt?: InputMaybe<Scalars['Float']['input']>;
  startTime_lte?: InputMaybe<Scalars['Float']['input']>;
  startTime_not?: InputMaybe<Scalars['Float']['input']>;
  startTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stateValue?: InputMaybe<SampleState>;
  stateValue_in?: InputMaybe<Array<SampleState>>;
  stateValue_not?: InputMaybe<SampleState>;
  stateValue_not_in?: InputMaybe<Array<SampleState>>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stretchRate_lt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_lte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  style?: InputMaybe<Scalars['String']['input']>;
  style_contains?: InputMaybe<Scalars['String']['input']>;
  style_ends_with?: InputMaybe<Scalars['String']['input']>;
  style_gt?: InputMaybe<Scalars['String']['input']>;
  style_gte?: InputMaybe<Scalars['String']['input']>;
  style_in?: InputMaybe<Array<Scalars['String']['input']>>;
  style_lt?: InputMaybe<Scalars['String']['input']>;
  style_lte?: InputMaybe<Scalars['String']['input']>;
  style_not?: InputMaybe<Scalars['String']['input']>;
  style_not_contains?: InputMaybe<Scalars['String']['input']>;
  style_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  style_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  style_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  style_starts_with?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_not?: InputMaybe<Scalars['String']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  text_starts_with?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_contains?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_gt?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_gte?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  trainingSetVersion_lt?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_lte?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_not?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  trainingSetVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  vocoderName?: InputMaybe<Scalars['String']['input']>;
  vocoderName_contains?: InputMaybe<Scalars['String']['input']>;
  vocoderName_ends_with?: InputMaybe<Scalars['String']['input']>;
  vocoderName_gt?: InputMaybe<Scalars['String']['input']>;
  vocoderName_gte?: InputMaybe<Scalars['String']['input']>;
  vocoderName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vocoderName_lt?: InputMaybe<Scalars['String']['input']>;
  vocoderName_lte?: InputMaybe<Scalars['String']['input']>;
  vocoderName_not?: InputMaybe<Scalars['String']['input']>;
  vocoderName_not_contains?: InputMaybe<Scalars['String']['input']>;
  vocoderName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  vocoderName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vocoderName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  vocoderName_starts_with?: InputMaybe<Scalars['String']['input']>;
  vocoderType?: InputMaybe<VocoderType>;
  vocoderType_in?: InputMaybe<Array<VocoderType>>;
  vocoderType_not?: InputMaybe<VocoderType>;
  vocoderType_not_in?: InputMaybe<Array<VocoderType>>;
  vocoderVersion?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_contains?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_gt?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_gte?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vocoderVersion_lt?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_lte?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_not?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vocoderVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export enum SampleState {
  Complete = 'Complete',
  Generating = 'Generating',
  Initial = 'Initial'
}

export type SampleUpdateDataInput = {
  audioDuration?: InputMaybe<Scalars['Float']['input']>;
  audioFile?: InputMaybe<FileUpdateOneInput>;
  correctionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  outputGroup?: InputMaybe<OutputGroupUpdateOneWithoutSamplesInput>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SampleUpdatephonemizedTextInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SampleState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<TranslatedVideoUpdateOneInput>;
  vocoderName?: InputMaybe<Scalars['String']['input']>;
  vocoderType?: InputMaybe<VocoderType>;
  vocoderVersion?: InputMaybe<Scalars['String']['input']>;
};

export type SampleUpdateManyDataInput = {
  audioDuration?: InputMaybe<Scalars['Float']['input']>;
  correctionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SampleUpdatephonemizedTextInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SampleState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion?: InputMaybe<Scalars['String']['input']>;
  vocoderName?: InputMaybe<Scalars['String']['input']>;
  vocoderType?: InputMaybe<VocoderType>;
  vocoderVersion?: InputMaybe<Scalars['String']['input']>;
};

export type SampleUpdateManyInput = {
  connect?: InputMaybe<Array<SampleWhereUniqueInput>>;
  create?: InputMaybe<Array<SampleCreateInput>>;
  delete?: InputMaybe<Array<SampleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SampleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SampleWhereUniqueInput>>;
  set?: InputMaybe<Array<SampleWhereUniqueInput>>;
  update?: InputMaybe<Array<SampleUpdateWithWhereUniqueNestedInput>>;
  updateMany?: InputMaybe<Array<SampleUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SampleUpsertWithWhereUniqueNestedInput>>;
};

export type SampleUpdateManyWithWhereNestedInput = {
  data: SampleUpdateManyDataInput;
  where: SampleScalarWhereInput;
};

export type SampleUpdateManyWithoutOutputGroupInput = {
  connect?: InputMaybe<Array<SampleWhereUniqueInput>>;
  create?: InputMaybe<Array<SampleCreateWithoutOutputGroupInput>>;
  delete?: InputMaybe<Array<SampleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SampleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SampleWhereUniqueInput>>;
  set?: InputMaybe<Array<SampleWhereUniqueInput>>;
  update?: InputMaybe<Array<SampleUpdateWithWhereUniqueWithoutOutputGroupInput>>;
  updateMany?: InputMaybe<Array<SampleUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SampleUpsertWithWhereUniqueWithoutOutputGroupInput>>;
};

export type SampleUpdateOneInput = {
  connect?: InputMaybe<SampleWhereUniqueInput>;
  create?: InputMaybe<SampleCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SampleUpdateDataInput>;
  upsert?: InputMaybe<SampleUpsertNestedInput>;
};

export type SampleUpdateWithWhereUniqueNestedInput = {
  data: SampleUpdateDataInput;
  where: SampleWhereUniqueInput;
};

export type SampleUpdateWithWhereUniqueWithoutOutputGroupInput = {
  data: SampleUpdateWithoutOutputGroupDataInput;
  where: SampleWhereUniqueInput;
};

export type SampleUpdateWithoutOutputGroupDataInput = {
  audioDuration?: InputMaybe<Scalars['Float']['input']>;
  audioFile?: InputMaybe<FileUpdateOneInput>;
  correctionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SampleUpdatephonemizedTextInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SampleState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<TranslatedVideoUpdateOneInput>;
  vocoderName?: InputMaybe<Scalars['String']['input']>;
  vocoderType?: InputMaybe<VocoderType>;
  vocoderVersion?: InputMaybe<Scalars['String']['input']>;
};

export type SampleUpdatephonemizedTextInput = {
  set?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SampleUpsertNestedInput = {
  create: SampleCreateInput;
  update: SampleUpdateDataInput;
};

export type SampleUpsertWithWhereUniqueNestedInput = {
  create: SampleCreateInput;
  update: SampleUpdateDataInput;
  where: SampleWhereUniqueInput;
};

export type SampleUpsertWithWhereUniqueWithoutOutputGroupInput = {
  create: SampleCreateWithoutOutputGroupInput;
  update: SampleUpdateWithoutOutputGroupDataInput;
  where: SampleWhereUniqueInput;
};

export type SampleWhereInput = {
  AND?: InputMaybe<Array<SampleWhereInput>>;
  NOT?: InputMaybe<Array<SampleWhereInput>>;
  OR?: InputMaybe<Array<SampleWhereInput>>;
  audioDuration?: InputMaybe<Scalars['Float']['input']>;
  audioDuration_gt?: InputMaybe<Scalars['Float']['input']>;
  audioDuration_gte?: InputMaybe<Scalars['Float']['input']>;
  audioDuration_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  audioDuration_lt?: InputMaybe<Scalars['Float']['input']>;
  audioDuration_lte?: InputMaybe<Scalars['Float']['input']>;
  audioDuration_not?: InputMaybe<Scalars['Float']['input']>;
  audioDuration_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  audioFile?: InputMaybe<FileWhereInput>;
  correctionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  correctionApplied_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  featuresS3_contains?: InputMaybe<Scalars['String']['input']>;
  featuresS3_ends_with?: InputMaybe<Scalars['String']['input']>;
  featuresS3_gt?: InputMaybe<Scalars['String']['input']>;
  featuresS3_gte?: InputMaybe<Scalars['String']['input']>;
  featuresS3_in?: InputMaybe<Array<Scalars['String']['input']>>;
  featuresS3_lt?: InputMaybe<Scalars['String']['input']>;
  featuresS3_lte?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not_contains?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  featuresS3_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  featuresS3_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  language_contains?: InputMaybe<Scalars['String']['input']>;
  language_ends_with?: InputMaybe<Scalars['String']['input']>;
  language_gt?: InputMaybe<Scalars['String']['input']>;
  language_gte?: InputMaybe<Scalars['String']['input']>;
  language_in?: InputMaybe<Array<Scalars['String']['input']>>;
  language_lt?: InputMaybe<Scalars['String']['input']>;
  language_lte?: InputMaybe<Scalars['String']['input']>;
  language_not?: InputMaybe<Scalars['String']['input']>;
  language_not_contains?: InputMaybe<Scalars['String']['input']>;
  language_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  language_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  language_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  language_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelName_contains?: InputMaybe<Scalars['String']['input']>;
  modelName_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelName_gt?: InputMaybe<Scalars['String']['input']>;
  modelName_gte?: InputMaybe<Scalars['String']['input']>;
  modelName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelName_lt?: InputMaybe<Scalars['String']['input']>;
  modelName_lte?: InputMaybe<Scalars['String']['input']>;
  modelName_not?: InputMaybe<Scalars['String']['input']>;
  modelName_not_contains?: InputMaybe<Scalars['String']['input']>;
  modelName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelName_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  modelVersion_contains?: InputMaybe<Scalars['String']['input']>;
  modelVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion_gt?: InputMaybe<Scalars['String']['input']>;
  modelVersion_gte?: InputMaybe<Scalars['String']['input']>;
  modelVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelVersion_lt?: InputMaybe<Scalars['String']['input']>;
  modelVersion_lte?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  outputGroup?: InputMaybe<OutputGroupWhereInput>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_contains?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_ends_with?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_gt?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_gte?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  papercupSpeakerId_lt?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_lte?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not_contains?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  papercupSpeakerId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_starts_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_contains?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_gt?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_gte?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phonemizationVersion_lt?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_lte?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phonemizationVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  startTime_gt?: InputMaybe<Scalars['Float']['input']>;
  startTime_gte?: InputMaybe<Scalars['Float']['input']>;
  startTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTime_lt?: InputMaybe<Scalars['Float']['input']>;
  startTime_lte?: InputMaybe<Scalars['Float']['input']>;
  startTime_not?: InputMaybe<Scalars['Float']['input']>;
  startTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stateValue?: InputMaybe<SampleState>;
  stateValue_in?: InputMaybe<Array<SampleState>>;
  stateValue_not?: InputMaybe<SampleState>;
  stateValue_not_in?: InputMaybe<Array<SampleState>>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stretchRate_lt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_lte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  style?: InputMaybe<Scalars['String']['input']>;
  style_contains?: InputMaybe<Scalars['String']['input']>;
  style_ends_with?: InputMaybe<Scalars['String']['input']>;
  style_gt?: InputMaybe<Scalars['String']['input']>;
  style_gte?: InputMaybe<Scalars['String']['input']>;
  style_in?: InputMaybe<Array<Scalars['String']['input']>>;
  style_lt?: InputMaybe<Scalars['String']['input']>;
  style_lte?: InputMaybe<Scalars['String']['input']>;
  style_not?: InputMaybe<Scalars['String']['input']>;
  style_not_contains?: InputMaybe<Scalars['String']['input']>;
  style_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  style_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  style_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  style_starts_with?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_not?: InputMaybe<Scalars['String']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  text_starts_with?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_contains?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_gt?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_gte?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  trainingSetVersion_lt?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_lte?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_not?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  trainingSetVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  trainingSetVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  video?: InputMaybe<TranslatedVideoWhereInput>;
  vocoderName?: InputMaybe<Scalars['String']['input']>;
  vocoderName_contains?: InputMaybe<Scalars['String']['input']>;
  vocoderName_ends_with?: InputMaybe<Scalars['String']['input']>;
  vocoderName_gt?: InputMaybe<Scalars['String']['input']>;
  vocoderName_gte?: InputMaybe<Scalars['String']['input']>;
  vocoderName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vocoderName_lt?: InputMaybe<Scalars['String']['input']>;
  vocoderName_lte?: InputMaybe<Scalars['String']['input']>;
  vocoderName_not?: InputMaybe<Scalars['String']['input']>;
  vocoderName_not_contains?: InputMaybe<Scalars['String']['input']>;
  vocoderName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  vocoderName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vocoderName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  vocoderName_starts_with?: InputMaybe<Scalars['String']['input']>;
  vocoderType?: InputMaybe<VocoderType>;
  vocoderType_in?: InputMaybe<Array<VocoderType>>;
  vocoderType_not?: InputMaybe<VocoderType>;
  vocoderType_not_in?: InputMaybe<Array<VocoderType>>;
  vocoderVersion?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_contains?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_gt?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_gte?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vocoderVersion_lt?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_lte?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_not?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vocoderVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  vocoderVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type SampleWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Segment = {
  __typename?: 'Segment';
  annotations?: Maybe<Array<SegmentAnnotation>>;
  autoTranslation?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentMLOutput?: Maybe<MlOutput>;
  done: Scalars['Boolean']['output'];
  endTime: Scalars['Float']['output'];
  featuresS3?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  isCurrStudioStageDone: Scalars['Boolean']['output'];
  /** Does the SpeechInputData for the Segment match current SpeechOutput */
  isSegmentUpToDate: Scalars['Boolean']['output'];
  isTranslationDone: Scalars['Boolean']['output'];
  latestProsodyBuffet?: Maybe<SpeechOutputCollection>;
  latestSpeechOutput?: Maybe<SpeechOutput>;
  latestTunedMultipleRenditions?: Maybe<SpeechOutputCollection>;
  latestTunedSpeechOutput?: Maybe<SpeechOutput>;
  mimicAudioMetadatas?: Maybe<Array<MimicAudioMetadata>>;
  modelName?: Maybe<Scalars['String']['output']>;
  modelVersion?: Maybe<Scalars['String']['output']>;
  origin: SegmentOrigin;
  papercupSpeakerId?: Maybe<Scalars['String']['output']>;
  phonemizationVersion?: Maybe<Scalars['String']['output']>;
  phonemizedText: Array<Scalars['Int']['output']>;
  previewSample?: Maybe<Sample>;
  requiresVoiceActor?: Maybe<Scalars['Boolean']['output']>;
  sourceLanguage: Scalars['String']['output'];
  sourceWords: Array<Scalars['Json']['output']>;
  speechInputData?: Maybe<SpeechInputData>;
  speechOutputCollections?: Maybe<Array<SpeechOutputCollection>>;
  speechOutputs?: Maybe<Array<SpeechOutput>>;
  startTime: Scalars['Float']['output'];
  state?: Maybe<Scalars['Json']['output']>;
  stateValue?: Maybe<SegmentState>;
  stretchRate?: Maybe<Scalars['Float']['output']>;
  studioStageCompleteState: SegmentStudioStageCompleteState;
  style?: Maybe<Scalars['String']['output']>;
  targetLanguage?: Maybe<Scalars['String']['output']>;
  terScore?: Maybe<Scalars['Float']['output']>;
  text: Scalars['String']['output'];
  translatedText?: Maybe<Scalars['String']['output']>;
  translatedTextParsed?: Maybe<Scalars['String']['output']>;
  translationStartTime: Scalars['Float']['output'];
  tunedAudioDone: Scalars['Boolean']['output'];
  tunedSample?: Maybe<Sample>;
  updatedAt: Scalars['DateTime']['output'];
  video: TranslatedVideo;
};


export type SegmentAnnotationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SegmentAnnotationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SegmentAnnotationWhereInput>;
};


export type SegmentIsCurrStudioStageDoneArgs = {
  isTunedSpeechMode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type SegmentIsSegmentUpToDateArgs = {
  isTunedSpeechMode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type SegmentLatestSpeechOutputArgs = {
  isTunedSpeechMode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type SegmentMimicAudioMetadatasArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MimicAudioMetadataOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MimicAudioMetadataWhereInput>;
};


export type SegmentSpeechOutputCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpeechOutputCollectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpeechOutputCollectionWhereInput>;
};


export type SegmentSpeechOutputsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpeechOutputOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpeechOutputWhereInput>;
};

export type SegmentAnnotation = {
  __typename?: 'SegmentAnnotation';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  details?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  segment: Segment;
  type: SegmentAnnotationType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SegmentAnnotationCreateManyWithoutSegmentInput = {
  connect?: InputMaybe<Array<SegmentAnnotationWhereUniqueInput>>;
  create?: InputMaybe<Array<SegmentAnnotationCreateWithoutSegmentInput>>;
};

export type SegmentAnnotationCreateWithoutSegmentInput = {
  createdBy: UserCreateOneInput;
  details?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  type: SegmentAnnotationType;
};

export enum SegmentAnnotationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DetailsAsc = 'details_ASC',
  DetailsDesc = 'details_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type SegmentAnnotationScalarWhereInput = {
  AND?: InputMaybe<Array<SegmentAnnotationScalarWhereInput>>;
  NOT?: InputMaybe<Array<SegmentAnnotationScalarWhereInput>>;
  OR?: InputMaybe<Array<SegmentAnnotationScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  details?: InputMaybe<Scalars['String']['input']>;
  details_contains?: InputMaybe<Scalars['String']['input']>;
  details_ends_with?: InputMaybe<Scalars['String']['input']>;
  details_gt?: InputMaybe<Scalars['String']['input']>;
  details_gte?: InputMaybe<Scalars['String']['input']>;
  details_in?: InputMaybe<Array<Scalars['String']['input']>>;
  details_lt?: InputMaybe<Scalars['String']['input']>;
  details_lte?: InputMaybe<Scalars['String']['input']>;
  details_not?: InputMaybe<Scalars['String']['input']>;
  details_not_contains?: InputMaybe<Scalars['String']['input']>;
  details_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  details_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  details_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  details_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<SegmentAnnotationType>;
  type_in?: InputMaybe<Array<SegmentAnnotationType>>;
  type_not?: InputMaybe<SegmentAnnotationType>;
  type_not_in?: InputMaybe<Array<SegmentAnnotationType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export enum SegmentAnnotationType {
  InsufficientExpressivity = 'InsufficientExpressivity',
  NoteForVa = 'NoteForVA'
}

export type SegmentAnnotationUpdateManyDataInput = {
  details?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<SegmentAnnotationType>;
};

export type SegmentAnnotationUpdateManyWithWhereNestedInput = {
  data: SegmentAnnotationUpdateManyDataInput;
  where: SegmentAnnotationScalarWhereInput;
};

export type SegmentAnnotationUpdateManyWithoutSegmentInput = {
  connect?: InputMaybe<Array<SegmentAnnotationWhereUniqueInput>>;
  create?: InputMaybe<Array<SegmentAnnotationCreateWithoutSegmentInput>>;
  delete?: InputMaybe<Array<SegmentAnnotationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SegmentAnnotationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SegmentAnnotationWhereUniqueInput>>;
  set?: InputMaybe<Array<SegmentAnnotationWhereUniqueInput>>;
  update?: InputMaybe<Array<SegmentAnnotationUpdateWithWhereUniqueWithoutSegmentInput>>;
  updateMany?: InputMaybe<Array<SegmentAnnotationUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SegmentAnnotationUpsertWithWhereUniqueWithoutSegmentInput>>;
};

export type SegmentAnnotationUpdateWithWhereUniqueWithoutSegmentInput = {
  data: SegmentAnnotationUpdateWithoutSegmentDataInput;
  where: SegmentAnnotationWhereUniqueInput;
};

export type SegmentAnnotationUpdateWithoutSegmentDataInput = {
  createdBy?: InputMaybe<UserUpdateOneRequiredInput>;
  details?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<SegmentAnnotationType>;
};

export type SegmentAnnotationUpsertWithWhereUniqueWithoutSegmentInput = {
  create: SegmentAnnotationCreateWithoutSegmentInput;
  update: SegmentAnnotationUpdateWithoutSegmentDataInput;
  where: SegmentAnnotationWhereUniqueInput;
};

export type SegmentAnnotationWhereInput = {
  AND?: InputMaybe<Array<SegmentAnnotationWhereInput>>;
  NOT?: InputMaybe<Array<SegmentAnnotationWhereInput>>;
  OR?: InputMaybe<Array<SegmentAnnotationWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  details?: InputMaybe<Scalars['String']['input']>;
  details_contains?: InputMaybe<Scalars['String']['input']>;
  details_ends_with?: InputMaybe<Scalars['String']['input']>;
  details_gt?: InputMaybe<Scalars['String']['input']>;
  details_gte?: InputMaybe<Scalars['String']['input']>;
  details_in?: InputMaybe<Array<Scalars['String']['input']>>;
  details_lt?: InputMaybe<Scalars['String']['input']>;
  details_lte?: InputMaybe<Scalars['String']['input']>;
  details_not?: InputMaybe<Scalars['String']['input']>;
  details_not_contains?: InputMaybe<Scalars['String']['input']>;
  details_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  details_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  details_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  details_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  segment?: InputMaybe<SegmentWhereInput>;
  type?: InputMaybe<SegmentAnnotationType>;
  type_in?: InputMaybe<Array<SegmentAnnotationType>>;
  type_not?: InputMaybe<SegmentAnnotationType>;
  type_not_in?: InputMaybe<Array<SegmentAnnotationType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type SegmentAnnotationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SegmentCreateInput = {
  annotations?: InputMaybe<SegmentAnnotationCreateManyWithoutSegmentInput>;
  autoTranslation?: InputMaybe<Scalars['String']['input']>;
  currentMLOutput?: InputMaybe<MlOutputCreateOneInput>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  endTime: Scalars['Float']['input'];
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone?: InputMaybe<Scalars['Boolean']['input']>;
  latestProsodyBuffet?: InputMaybe<SpeechOutputCollectionCreateOneInput>;
  latestSpeechOutput?: InputMaybe<SpeechOutputCreateOneInput>;
  latestTunedMultipleRenditions?: InputMaybe<SpeechOutputCollectionCreateOneInput>;
  latestTunedSpeechOutput?: InputMaybe<SpeechOutputCreateOneInput>;
  mimicAudioMetadatas?: InputMaybe<MimicAudioMetadataCreateManyWithoutSegmentInput>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<SegmentOrigin>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SegmentCreatephonemizedTextInput>;
  previewSample?: InputMaybe<SampleCreateOneInput>;
  requiresVoiceActor?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLanguage: Scalars['String']['input'];
  sourceWords?: InputMaybe<SegmentCreatesourceWordsInput>;
  speechInputData?: InputMaybe<SpeechInputDataCreateOneInput>;
  speechOutputCollections?: InputMaybe<SpeechOutputCollectionCreateManyWithoutSegmentInput>;
  speechOutputs?: InputMaybe<SpeechOutputCreateManyWithoutSegmentInput>;
  startTime: Scalars['Float']['input'];
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SegmentState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  studioStageCompleteState?: InputMaybe<SegmentStudioStageCompleteState>;
  style?: InputMaybe<Scalars['String']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  terScore?: InputMaybe<Scalars['Float']['input']>;
  text: Scalars['String']['input'];
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed?: InputMaybe<Scalars['String']['input']>;
  translationStartTime: Scalars['Float']['input'];
  tunedAudioDone?: InputMaybe<Scalars['Boolean']['input']>;
  tunedSample?: InputMaybe<SampleCreateOneInput>;
  video: TranslatedVideoCreateOneWithoutSegmentsInput;
};

export type SegmentCreateManyWithoutVideoInput = {
  connect?: InputMaybe<Array<SegmentWhereUniqueInput>>;
  create?: InputMaybe<Array<SegmentCreateWithoutVideoInput>>;
};

export type SegmentCreateOneInput = {
  connect?: InputMaybe<SegmentWhereUniqueInput>;
  create?: InputMaybe<SegmentCreateInput>;
};

export type SegmentCreateOneWithoutSpeechOutputCollectionsInput = {
  connect?: InputMaybe<SegmentWhereUniqueInput>;
  create?: InputMaybe<SegmentCreateWithoutSpeechOutputCollectionsInput>;
};

export type SegmentCreateOneWithoutSpeechOutputsInput = {
  connect?: InputMaybe<SegmentWhereUniqueInput>;
  create?: InputMaybe<SegmentCreateWithoutSpeechOutputsInput>;
};

export type SegmentCreateWithoutSpeechOutputCollectionsInput = {
  annotations?: InputMaybe<SegmentAnnotationCreateManyWithoutSegmentInput>;
  autoTranslation?: InputMaybe<Scalars['String']['input']>;
  currentMLOutput?: InputMaybe<MlOutputCreateOneInput>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  endTime: Scalars['Float']['input'];
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone?: InputMaybe<Scalars['Boolean']['input']>;
  latestProsodyBuffet?: InputMaybe<SpeechOutputCollectionCreateOneInput>;
  latestSpeechOutput?: InputMaybe<SpeechOutputCreateOneInput>;
  latestTunedMultipleRenditions?: InputMaybe<SpeechOutputCollectionCreateOneInput>;
  latestTunedSpeechOutput?: InputMaybe<SpeechOutputCreateOneInput>;
  mimicAudioMetadatas?: InputMaybe<MimicAudioMetadataCreateManyWithoutSegmentInput>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<SegmentOrigin>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SegmentCreatephonemizedTextInput>;
  previewSample?: InputMaybe<SampleCreateOneInput>;
  requiresVoiceActor?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLanguage: Scalars['String']['input'];
  sourceWords?: InputMaybe<SegmentCreatesourceWordsInput>;
  speechInputData?: InputMaybe<SpeechInputDataCreateOneInput>;
  speechOutputs?: InputMaybe<SpeechOutputCreateManyWithoutSegmentInput>;
  startTime: Scalars['Float']['input'];
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SegmentState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  studioStageCompleteState?: InputMaybe<SegmentStudioStageCompleteState>;
  style?: InputMaybe<Scalars['String']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  terScore?: InputMaybe<Scalars['Float']['input']>;
  text: Scalars['String']['input'];
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed?: InputMaybe<Scalars['String']['input']>;
  translationStartTime: Scalars['Float']['input'];
  tunedAudioDone?: InputMaybe<Scalars['Boolean']['input']>;
  tunedSample?: InputMaybe<SampleCreateOneInput>;
  video: TranslatedVideoCreateOneWithoutSegmentsInput;
};

export type SegmentCreateWithoutSpeechOutputsInput = {
  annotations?: InputMaybe<SegmentAnnotationCreateManyWithoutSegmentInput>;
  autoTranslation?: InputMaybe<Scalars['String']['input']>;
  currentMLOutput?: InputMaybe<MlOutputCreateOneInput>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  endTime: Scalars['Float']['input'];
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone?: InputMaybe<Scalars['Boolean']['input']>;
  latestProsodyBuffet?: InputMaybe<SpeechOutputCollectionCreateOneInput>;
  latestSpeechOutput?: InputMaybe<SpeechOutputCreateOneInput>;
  latestTunedMultipleRenditions?: InputMaybe<SpeechOutputCollectionCreateOneInput>;
  latestTunedSpeechOutput?: InputMaybe<SpeechOutputCreateOneInput>;
  mimicAudioMetadatas?: InputMaybe<MimicAudioMetadataCreateManyWithoutSegmentInput>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<SegmentOrigin>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SegmentCreatephonemizedTextInput>;
  previewSample?: InputMaybe<SampleCreateOneInput>;
  requiresVoiceActor?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLanguage: Scalars['String']['input'];
  sourceWords?: InputMaybe<SegmentCreatesourceWordsInput>;
  speechInputData?: InputMaybe<SpeechInputDataCreateOneInput>;
  speechOutputCollections?: InputMaybe<SpeechOutputCollectionCreateManyWithoutSegmentInput>;
  startTime: Scalars['Float']['input'];
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SegmentState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  studioStageCompleteState?: InputMaybe<SegmentStudioStageCompleteState>;
  style?: InputMaybe<Scalars['String']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  terScore?: InputMaybe<Scalars['Float']['input']>;
  text: Scalars['String']['input'];
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed?: InputMaybe<Scalars['String']['input']>;
  translationStartTime: Scalars['Float']['input'];
  tunedAudioDone?: InputMaybe<Scalars['Boolean']['input']>;
  tunedSample?: InputMaybe<SampleCreateOneInput>;
  video: TranslatedVideoCreateOneWithoutSegmentsInput;
};

export type SegmentCreateWithoutVideoInput = {
  annotations?: InputMaybe<SegmentAnnotationCreateManyWithoutSegmentInput>;
  autoTranslation?: InputMaybe<Scalars['String']['input']>;
  currentMLOutput?: InputMaybe<MlOutputCreateOneInput>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  endTime: Scalars['Float']['input'];
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone?: InputMaybe<Scalars['Boolean']['input']>;
  latestProsodyBuffet?: InputMaybe<SpeechOutputCollectionCreateOneInput>;
  latestSpeechOutput?: InputMaybe<SpeechOutputCreateOneInput>;
  latestTunedMultipleRenditions?: InputMaybe<SpeechOutputCollectionCreateOneInput>;
  latestTunedSpeechOutput?: InputMaybe<SpeechOutputCreateOneInput>;
  mimicAudioMetadatas?: InputMaybe<MimicAudioMetadataCreateManyWithoutSegmentInput>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<SegmentOrigin>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SegmentCreatephonemizedTextInput>;
  previewSample?: InputMaybe<SampleCreateOneInput>;
  requiresVoiceActor?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLanguage: Scalars['String']['input'];
  sourceWords?: InputMaybe<SegmentCreatesourceWordsInput>;
  speechInputData?: InputMaybe<SpeechInputDataCreateOneInput>;
  speechOutputCollections?: InputMaybe<SpeechOutputCollectionCreateManyWithoutSegmentInput>;
  speechOutputs?: InputMaybe<SpeechOutputCreateManyWithoutSegmentInput>;
  startTime: Scalars['Float']['input'];
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SegmentState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  studioStageCompleteState?: InputMaybe<SegmentStudioStageCompleteState>;
  style?: InputMaybe<Scalars['String']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  terScore?: InputMaybe<Scalars['Float']['input']>;
  text: Scalars['String']['input'];
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed?: InputMaybe<Scalars['String']['input']>;
  translationStartTime: Scalars['Float']['input'];
  tunedAudioDone?: InputMaybe<Scalars['Boolean']['input']>;
  tunedSample?: InputMaybe<SampleCreateOneInput>;
};

export type SegmentCreatephonemizedTextInput = {
  set?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SegmentCreatesourceWordsInput = {
  set?: InputMaybe<Array<Scalars['Json']['input']>>;
};

export enum SegmentOrderByInput {
  AutoTranslationAsc = 'autoTranslation_ASC',
  AutoTranslationDesc = 'autoTranslation_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DoneAsc = 'done_ASC',
  DoneDesc = 'done_DESC',
  EndTimeAsc = 'endTime_ASC',
  EndTimeDesc = 'endTime_DESC',
  FeaturesS3Asc = 'featuresS3_ASC',
  FeaturesS3Desc = 'featuresS3_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsArchivedAsc = 'isArchived_ASC',
  IsArchivedDesc = 'isArchived_DESC',
  IsTranslationDoneAsc = 'isTranslationDone_ASC',
  IsTranslationDoneDesc = 'isTranslationDone_DESC',
  ModelNameAsc = 'modelName_ASC',
  ModelNameDesc = 'modelName_DESC',
  ModelVersionAsc = 'modelVersion_ASC',
  ModelVersionDesc = 'modelVersion_DESC',
  OriginAsc = 'origin_ASC',
  OriginDesc = 'origin_DESC',
  PapercupSpeakerIdAsc = 'papercupSpeakerId_ASC',
  PapercupSpeakerIdDesc = 'papercupSpeakerId_DESC',
  PhonemizationVersionAsc = 'phonemizationVersion_ASC',
  PhonemizationVersionDesc = 'phonemizationVersion_DESC',
  RequiresVoiceActorAsc = 'requiresVoiceActor_ASC',
  RequiresVoiceActorDesc = 'requiresVoiceActor_DESC',
  SourceLanguageAsc = 'sourceLanguage_ASC',
  SourceLanguageDesc = 'sourceLanguage_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  StateValueAsc = 'stateValue_ASC',
  StateValueDesc = 'stateValue_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StretchRateAsc = 'stretchRate_ASC',
  StretchRateDesc = 'stretchRate_DESC',
  StudioStageCompleteStateAsc = 'studioStageCompleteState_ASC',
  StudioStageCompleteStateDesc = 'studioStageCompleteState_DESC',
  StyleAsc = 'style_ASC',
  StyleDesc = 'style_DESC',
  TargetLanguageAsc = 'targetLanguage_ASC',
  TargetLanguageDesc = 'targetLanguage_DESC',
  TerScoreAsc = 'terScore_ASC',
  TerScoreDesc = 'terScore_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TranslatedTextParsedAsc = 'translatedTextParsed_ASC',
  TranslatedTextParsedDesc = 'translatedTextParsed_DESC',
  TranslatedTextAsc = 'translatedText_ASC',
  TranslatedTextDesc = 'translatedText_DESC',
  TranslationStartTimeAsc = 'translationStartTime_ASC',
  TranslationStartTimeDesc = 'translationStartTime_DESC',
  TunedAudioDoneAsc = 'tunedAudioDone_ASC',
  TunedAudioDoneDesc = 'tunedAudioDone_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export enum SegmentOrigin {
  MergeArchive = 'MergeArchive',
  MergeSink = 'MergeSink',
  Original = 'Original',
  SplitBranch = 'SplitBranch',
  SplitSource = 'SplitSource'
}

export type SegmentScalarWhereInput = {
  AND?: InputMaybe<Array<SegmentScalarWhereInput>>;
  NOT?: InputMaybe<Array<SegmentScalarWhereInput>>;
  OR?: InputMaybe<Array<SegmentScalarWhereInput>>;
  autoTranslation?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_contains?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_ends_with?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_gt?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_gte?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_in?: InputMaybe<Array<Scalars['String']['input']>>;
  autoTranslation_lt?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_lte?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_not?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_not_contains?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  autoTranslation_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  done_not?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  endTime_gt?: InputMaybe<Scalars['Float']['input']>;
  endTime_gte?: InputMaybe<Scalars['Float']['input']>;
  endTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  endTime_lt?: InputMaybe<Scalars['Float']['input']>;
  endTime_lte?: InputMaybe<Scalars['Float']['input']>;
  endTime_not?: InputMaybe<Scalars['Float']['input']>;
  endTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  featuresS3_contains?: InputMaybe<Scalars['String']['input']>;
  featuresS3_ends_with?: InputMaybe<Scalars['String']['input']>;
  featuresS3_gt?: InputMaybe<Scalars['String']['input']>;
  featuresS3_gte?: InputMaybe<Scalars['String']['input']>;
  featuresS3_in?: InputMaybe<Array<Scalars['String']['input']>>;
  featuresS3_lt?: InputMaybe<Scalars['String']['input']>;
  featuresS3_lte?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not_contains?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  featuresS3_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  featuresS3_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived_not?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone_not?: InputMaybe<Scalars['Boolean']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelName_contains?: InputMaybe<Scalars['String']['input']>;
  modelName_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelName_gt?: InputMaybe<Scalars['String']['input']>;
  modelName_gte?: InputMaybe<Scalars['String']['input']>;
  modelName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelName_lt?: InputMaybe<Scalars['String']['input']>;
  modelName_lte?: InputMaybe<Scalars['String']['input']>;
  modelName_not?: InputMaybe<Scalars['String']['input']>;
  modelName_not_contains?: InputMaybe<Scalars['String']['input']>;
  modelName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelName_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  modelVersion_contains?: InputMaybe<Scalars['String']['input']>;
  modelVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion_gt?: InputMaybe<Scalars['String']['input']>;
  modelVersion_gte?: InputMaybe<Scalars['String']['input']>;
  modelVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelVersion_lt?: InputMaybe<Scalars['String']['input']>;
  modelVersion_lte?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<SegmentOrigin>;
  origin_in?: InputMaybe<Array<SegmentOrigin>>;
  origin_not?: InputMaybe<SegmentOrigin>;
  origin_not_in?: InputMaybe<Array<SegmentOrigin>>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_contains?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_ends_with?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_gt?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_gte?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  papercupSpeakerId_lt?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_lte?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not_contains?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  papercupSpeakerId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_starts_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_contains?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_gt?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_gte?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phonemizationVersion_lt?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_lte?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phonemizationVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  requiresVoiceActor?: InputMaybe<Scalars['Boolean']['input']>;
  requiresVoiceActor_not?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLanguage?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_contains?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_ends_with?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_gt?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_gte?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sourceLanguage_lt?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_lte?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_not?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_not_contains?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sourceLanguage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_starts_with?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  startTime_gt?: InputMaybe<Scalars['Float']['input']>;
  startTime_gte?: InputMaybe<Scalars['Float']['input']>;
  startTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTime_lt?: InputMaybe<Scalars['Float']['input']>;
  startTime_lte?: InputMaybe<Scalars['Float']['input']>;
  startTime_not?: InputMaybe<Scalars['Float']['input']>;
  startTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stateValue?: InputMaybe<SegmentState>;
  stateValue_in?: InputMaybe<Array<SegmentState>>;
  stateValue_not?: InputMaybe<SegmentState>;
  stateValue_not_in?: InputMaybe<Array<SegmentState>>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stretchRate_lt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_lte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  studioStageCompleteState?: InputMaybe<SegmentStudioStageCompleteState>;
  studioStageCompleteState_in?: InputMaybe<Array<SegmentStudioStageCompleteState>>;
  studioStageCompleteState_not?: InputMaybe<SegmentStudioStageCompleteState>;
  studioStageCompleteState_not_in?: InputMaybe<Array<SegmentStudioStageCompleteState>>;
  style?: InputMaybe<Scalars['String']['input']>;
  style_contains?: InputMaybe<Scalars['String']['input']>;
  style_ends_with?: InputMaybe<Scalars['String']['input']>;
  style_gt?: InputMaybe<Scalars['String']['input']>;
  style_gte?: InputMaybe<Scalars['String']['input']>;
  style_in?: InputMaybe<Array<Scalars['String']['input']>>;
  style_lt?: InputMaybe<Scalars['String']['input']>;
  style_lte?: InputMaybe<Scalars['String']['input']>;
  style_not?: InputMaybe<Scalars['String']['input']>;
  style_not_contains?: InputMaybe<Scalars['String']['input']>;
  style_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  style_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  style_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  style_starts_with?: InputMaybe<Scalars['String']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_contains?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_ends_with?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_gt?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_gte?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  targetLanguage_lt?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_lte?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_not?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_not_contains?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  targetLanguage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_starts_with?: InputMaybe<Scalars['String']['input']>;
  terScore?: InputMaybe<Scalars['Float']['input']>;
  terScore_gt?: InputMaybe<Scalars['Float']['input']>;
  terScore_gte?: InputMaybe<Scalars['Float']['input']>;
  terScore_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  terScore_lt?: InputMaybe<Scalars['Float']['input']>;
  terScore_lte?: InputMaybe<Scalars['Float']['input']>;
  terScore_not?: InputMaybe<Scalars['Float']['input']>;
  terScore_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_not?: InputMaybe<Scalars['String']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  text_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_contains?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_gt?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_gte?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedTextParsed_lt?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_lte?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_not?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_not_contains?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedTextParsed_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedText_contains?: InputMaybe<Scalars['String']['input']>;
  translatedText_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedText_gt?: InputMaybe<Scalars['String']['input']>;
  translatedText_gte?: InputMaybe<Scalars['String']['input']>;
  translatedText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedText_lt?: InputMaybe<Scalars['String']['input']>;
  translatedText_lte?: InputMaybe<Scalars['String']['input']>;
  translatedText_not?: InputMaybe<Scalars['String']['input']>;
  translatedText_not_contains?: InputMaybe<Scalars['String']['input']>;
  translatedText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedText_starts_with?: InputMaybe<Scalars['String']['input']>;
  translationStartTime?: InputMaybe<Scalars['Float']['input']>;
  translationStartTime_gt?: InputMaybe<Scalars['Float']['input']>;
  translationStartTime_gte?: InputMaybe<Scalars['Float']['input']>;
  translationStartTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  translationStartTime_lt?: InputMaybe<Scalars['Float']['input']>;
  translationStartTime_lte?: InputMaybe<Scalars['Float']['input']>;
  translationStartTime_not?: InputMaybe<Scalars['Float']['input']>;
  translationStartTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  tunedAudioDone?: InputMaybe<Scalars['Boolean']['input']>;
  tunedAudioDone_not?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type SegmentSnapshot = {
  __typename?: 'SegmentSnapshot';
  createdAt: Scalars['DateTime']['output'];
  durationSec: Scalars['Float']['output'];
  endTimeSec: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isFirstSegment: Scalars['Boolean']['output'];
  isLastSegment: Scalars['Boolean']['output'];
  nextSegmentSnapshot?: Maybe<SegmentSnapshot>;
  segment: Segment;
  speechOutput: SpeechOutput;
  startTimeSec: Scalars['Float']['output'];
  stretchRate: Scalars['Float']['output'];
  timeToNextSec?: Maybe<Scalars['Float']['output']>;
  timelinePosition: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  videoSnapshot: TranslatedVideoSnapshot;
};

export type SegmentSnapshotCreateManyWithoutVideoSnapshotInput = {
  connect?: InputMaybe<Array<SegmentSnapshotWhereUniqueInput>>;
  create?: InputMaybe<Array<SegmentSnapshotCreateWithoutVideoSnapshotInput>>;
};

export type SegmentSnapshotCreateOneWithoutNextSegmentSnapshotInput = {
  connect?: InputMaybe<SegmentSnapshotWhereUniqueInput>;
  create?: InputMaybe<SegmentSnapshotCreateWithoutNextSegmentSnapshotInput>;
};

export type SegmentSnapshotCreateWithoutNextSegmentSnapshotInput = {
  durationSec: Scalars['Float']['input'];
  endTimeSec: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isFirstSegment: Scalars['Boolean']['input'];
  isLastSegment: Scalars['Boolean']['input'];
  segment: SegmentCreateOneInput;
  speechOutput: SpeechOutputCreateOneInput;
  startTimeSec: Scalars['Float']['input'];
  stretchRate: Scalars['Float']['input'];
  timeToNextSec?: InputMaybe<Scalars['Float']['input']>;
  timelinePosition: Scalars['Int']['input'];
  videoSnapshot: TranslatedVideoSnapshotCreateOneWithoutSegmentSnapshotsInput;
};

export type SegmentSnapshotCreateWithoutVideoSnapshotInput = {
  durationSec: Scalars['Float']['input'];
  endTimeSec: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isFirstSegment: Scalars['Boolean']['input'];
  isLastSegment: Scalars['Boolean']['input'];
  nextSegmentSnapshot?: InputMaybe<SegmentSnapshotCreateOneWithoutNextSegmentSnapshotInput>;
  segment: SegmentCreateOneInput;
  speechOutput: SpeechOutputCreateOneInput;
  startTimeSec: Scalars['Float']['input'];
  stretchRate: Scalars['Float']['input'];
  timeToNextSec?: InputMaybe<Scalars['Float']['input']>;
  timelinePosition: Scalars['Int']['input'];
};

export enum SegmentSnapshotOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DurationSecAsc = 'durationSec_ASC',
  DurationSecDesc = 'durationSec_DESC',
  EndTimeSecAsc = 'endTimeSec_ASC',
  EndTimeSecDesc = 'endTimeSec_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsFirstSegmentAsc = 'isFirstSegment_ASC',
  IsFirstSegmentDesc = 'isFirstSegment_DESC',
  IsLastSegmentAsc = 'isLastSegment_ASC',
  IsLastSegmentDesc = 'isLastSegment_DESC',
  StartTimeSecAsc = 'startTimeSec_ASC',
  StartTimeSecDesc = 'startTimeSec_DESC',
  StretchRateAsc = 'stretchRate_ASC',
  StretchRateDesc = 'stretchRate_DESC',
  TimeToNextSecAsc = 'timeToNextSec_ASC',
  TimeToNextSecDesc = 'timeToNextSec_DESC',
  TimelinePositionAsc = 'timelinePosition_ASC',
  TimelinePositionDesc = 'timelinePosition_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type SegmentSnapshotScalarWhereInput = {
  AND?: InputMaybe<Array<SegmentSnapshotScalarWhereInput>>;
  NOT?: InputMaybe<Array<SegmentSnapshotScalarWhereInput>>;
  OR?: InputMaybe<Array<SegmentSnapshotScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  durationSec?: InputMaybe<Scalars['Float']['input']>;
  durationSec_gt?: InputMaybe<Scalars['Float']['input']>;
  durationSec_gte?: InputMaybe<Scalars['Float']['input']>;
  durationSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  durationSec_lt?: InputMaybe<Scalars['Float']['input']>;
  durationSec_lte?: InputMaybe<Scalars['Float']['input']>;
  durationSec_not?: InputMaybe<Scalars['Float']['input']>;
  durationSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_gt?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_gte?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  endTimeSec_lt?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_lte?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_not?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isFirstSegment?: InputMaybe<Scalars['Boolean']['input']>;
  isFirstSegment_not?: InputMaybe<Scalars['Boolean']['input']>;
  isLastSegment?: InputMaybe<Scalars['Boolean']['input']>;
  isLastSegment_not?: InputMaybe<Scalars['Boolean']['input']>;
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_gt?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_gte?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTimeSec_lt?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_lte?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_not?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stretchRate_lt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_lte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  timeToNextSec?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec_gt?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec_gte?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  timeToNextSec_lt?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec_lte?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec_not?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  timelinePosition?: InputMaybe<Scalars['Int']['input']>;
  timelinePosition_gt?: InputMaybe<Scalars['Int']['input']>;
  timelinePosition_gte?: InputMaybe<Scalars['Int']['input']>;
  timelinePosition_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  timelinePosition_lt?: InputMaybe<Scalars['Int']['input']>;
  timelinePosition_lte?: InputMaybe<Scalars['Int']['input']>;
  timelinePosition_not?: InputMaybe<Scalars['Int']['input']>;
  timelinePosition_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type SegmentSnapshotUpdateManyDataInput = {
  durationSec?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  isFirstSegment?: InputMaybe<Scalars['Boolean']['input']>;
  isLastSegment?: InputMaybe<Scalars['Boolean']['input']>;
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec?: InputMaybe<Scalars['Float']['input']>;
  timelinePosition?: InputMaybe<Scalars['Int']['input']>;
};

export type SegmentSnapshotUpdateManyWithWhereNestedInput = {
  data: SegmentSnapshotUpdateManyDataInput;
  where: SegmentSnapshotScalarWhereInput;
};

export type SegmentSnapshotUpdateManyWithoutVideoSnapshotInput = {
  connect?: InputMaybe<Array<SegmentSnapshotWhereUniqueInput>>;
  create?: InputMaybe<Array<SegmentSnapshotCreateWithoutVideoSnapshotInput>>;
  delete?: InputMaybe<Array<SegmentSnapshotWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SegmentSnapshotScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SegmentSnapshotWhereUniqueInput>>;
  set?: InputMaybe<Array<SegmentSnapshotWhereUniqueInput>>;
  update?: InputMaybe<Array<SegmentSnapshotUpdateWithWhereUniqueWithoutVideoSnapshotInput>>;
  updateMany?: InputMaybe<Array<SegmentSnapshotUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SegmentSnapshotUpsertWithWhereUniqueWithoutVideoSnapshotInput>>;
};

export type SegmentSnapshotUpdateOneWithoutNextSegmentSnapshotInput = {
  connect?: InputMaybe<SegmentSnapshotWhereUniqueInput>;
  create?: InputMaybe<SegmentSnapshotCreateWithoutNextSegmentSnapshotInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SegmentSnapshotUpdateWithoutNextSegmentSnapshotDataInput>;
  upsert?: InputMaybe<SegmentSnapshotUpsertWithoutNextSegmentSnapshotInput>;
};

export type SegmentSnapshotUpdateWithWhereUniqueWithoutVideoSnapshotInput = {
  data: SegmentSnapshotUpdateWithoutVideoSnapshotDataInput;
  where: SegmentSnapshotWhereUniqueInput;
};

export type SegmentSnapshotUpdateWithoutNextSegmentSnapshotDataInput = {
  durationSec?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  isFirstSegment?: InputMaybe<Scalars['Boolean']['input']>;
  isLastSegment?: InputMaybe<Scalars['Boolean']['input']>;
  segment?: InputMaybe<SegmentUpdateOneRequiredInput>;
  speechOutput?: InputMaybe<SpeechOutputUpdateOneRequiredInput>;
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec?: InputMaybe<Scalars['Float']['input']>;
  timelinePosition?: InputMaybe<Scalars['Int']['input']>;
  videoSnapshot?: InputMaybe<TranslatedVideoSnapshotUpdateOneRequiredWithoutSegmentSnapshotsInput>;
};

export type SegmentSnapshotUpdateWithoutVideoSnapshotDataInput = {
  durationSec?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  isFirstSegment?: InputMaybe<Scalars['Boolean']['input']>;
  isLastSegment?: InputMaybe<Scalars['Boolean']['input']>;
  nextSegmentSnapshot?: InputMaybe<SegmentSnapshotUpdateOneWithoutNextSegmentSnapshotInput>;
  segment?: InputMaybe<SegmentUpdateOneRequiredInput>;
  speechOutput?: InputMaybe<SpeechOutputUpdateOneRequiredInput>;
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec?: InputMaybe<Scalars['Float']['input']>;
  timelinePosition?: InputMaybe<Scalars['Int']['input']>;
};

export type SegmentSnapshotUpsertWithWhereUniqueWithoutVideoSnapshotInput = {
  create: SegmentSnapshotCreateWithoutVideoSnapshotInput;
  update: SegmentSnapshotUpdateWithoutVideoSnapshotDataInput;
  where: SegmentSnapshotWhereUniqueInput;
};

export type SegmentSnapshotUpsertWithoutNextSegmentSnapshotInput = {
  create: SegmentSnapshotCreateWithoutNextSegmentSnapshotInput;
  update: SegmentSnapshotUpdateWithoutNextSegmentSnapshotDataInput;
};

export type SegmentSnapshotWhereInput = {
  AND?: InputMaybe<Array<SegmentSnapshotWhereInput>>;
  NOT?: InputMaybe<Array<SegmentSnapshotWhereInput>>;
  OR?: InputMaybe<Array<SegmentSnapshotWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  durationSec?: InputMaybe<Scalars['Float']['input']>;
  durationSec_gt?: InputMaybe<Scalars['Float']['input']>;
  durationSec_gte?: InputMaybe<Scalars['Float']['input']>;
  durationSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  durationSec_lt?: InputMaybe<Scalars['Float']['input']>;
  durationSec_lte?: InputMaybe<Scalars['Float']['input']>;
  durationSec_not?: InputMaybe<Scalars['Float']['input']>;
  durationSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_gt?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_gte?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  endTimeSec_lt?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_lte?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_not?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isFirstSegment?: InputMaybe<Scalars['Boolean']['input']>;
  isFirstSegment_not?: InputMaybe<Scalars['Boolean']['input']>;
  isLastSegment?: InputMaybe<Scalars['Boolean']['input']>;
  isLastSegment_not?: InputMaybe<Scalars['Boolean']['input']>;
  nextSegmentSnapshot?: InputMaybe<SegmentSnapshotWhereInput>;
  segment?: InputMaybe<SegmentWhereInput>;
  speechOutput?: InputMaybe<SpeechOutputWhereInput>;
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_gt?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_gte?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTimeSec_lt?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_lte?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_not?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stretchRate_lt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_lte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  timeToNextSec?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec_gt?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec_gte?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  timeToNextSec_lt?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec_lte?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec_not?: InputMaybe<Scalars['Float']['input']>;
  timeToNextSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  timelinePosition?: InputMaybe<Scalars['Int']['input']>;
  timelinePosition_gt?: InputMaybe<Scalars['Int']['input']>;
  timelinePosition_gte?: InputMaybe<Scalars['Int']['input']>;
  timelinePosition_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  timelinePosition_lt?: InputMaybe<Scalars['Int']['input']>;
  timelinePosition_lte?: InputMaybe<Scalars['Int']['input']>;
  timelinePosition_not?: InputMaybe<Scalars['Int']['input']>;
  timelinePosition_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  videoSnapshot?: InputMaybe<TranslatedVideoSnapshotWhereInput>;
};

export type SegmentSnapshotWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum SegmentState {
  Complete = 'Complete',
  GeneratingFeatures = 'GeneratingFeatures',
  GeneratingPhones = 'GeneratingPhones',
  Initial = 'Initial',
  Translating = 'Translating'
}

export enum SegmentStudioStageCompleteState {
  PreviewSpeechComplete = 'PreviewSpeechComplete',
  TranscriptionComplete = 'TranscriptionComplete',
  TranslationComplete = 'TranslationComplete',
  TunedSpeechComplete = 'TunedSpeechComplete',
  Unknown = 'Unknown'
}

export type SegmentTerScore = {
  /** id of the segment scored */
  segmentId?: InputMaybe<Scalars['ID']['input']>;
  /** ter score - must be between 0 and 1 */
  terScore: Scalars['Float']['input'];
};

export type SegmentUpdateDataInput = {
  annotations?: InputMaybe<SegmentAnnotationUpdateManyWithoutSegmentInput>;
  autoTranslation?: InputMaybe<Scalars['String']['input']>;
  currentMLOutput?: InputMaybe<MlOutputUpdateOneInput>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone?: InputMaybe<Scalars['Boolean']['input']>;
  latestProsodyBuffet?: InputMaybe<SpeechOutputCollectionUpdateOneInput>;
  latestSpeechOutput?: InputMaybe<SpeechOutputUpdateOneInput>;
  latestTunedMultipleRenditions?: InputMaybe<SpeechOutputCollectionUpdateOneInput>;
  latestTunedSpeechOutput?: InputMaybe<SpeechOutputUpdateOneInput>;
  mimicAudioMetadatas?: InputMaybe<MimicAudioMetadataUpdateManyWithoutSegmentInput>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<SegmentOrigin>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SegmentUpdatephonemizedTextInput>;
  previewSample?: InputMaybe<SampleUpdateOneInput>;
  requiresVoiceActor?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLanguage?: InputMaybe<Scalars['String']['input']>;
  sourceWords?: InputMaybe<SegmentUpdatesourceWordsInput>;
  speechInputData?: InputMaybe<SpeechInputDataUpdateOneInput>;
  speechOutputCollections?: InputMaybe<SpeechOutputCollectionUpdateManyWithoutSegmentInput>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyWithoutSegmentInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SegmentState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  studioStageCompleteState?: InputMaybe<SegmentStudioStageCompleteState>;
  style?: InputMaybe<Scalars['String']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  terScore?: InputMaybe<Scalars['Float']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed?: InputMaybe<Scalars['String']['input']>;
  translationStartTime?: InputMaybe<Scalars['Float']['input']>;
  tunedAudioDone?: InputMaybe<Scalars['Boolean']['input']>;
  tunedSample?: InputMaybe<SampleUpdateOneInput>;
  video?: InputMaybe<TranslatedVideoUpdateOneRequiredWithoutSegmentsInput>;
};

export type SegmentUpdateInput = {
  annotations?: InputMaybe<SegmentAnnotationUpdateManyWithoutSegmentInput>;
  autoTranslation?: InputMaybe<Scalars['String']['input']>;
  currentMLOutput?: InputMaybe<MlOutputUpdateOneInput>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone?: InputMaybe<Scalars['Boolean']['input']>;
  latestProsodyBuffet?: InputMaybe<SpeechOutputCollectionUpdateOneInput>;
  latestSpeechOutput?: InputMaybe<SpeechOutputUpdateOneInput>;
  latestTunedMultipleRenditions?: InputMaybe<SpeechOutputCollectionUpdateOneInput>;
  latestTunedSpeechOutput?: InputMaybe<SpeechOutputUpdateOneInput>;
  mimicAudioMetadatas?: InputMaybe<MimicAudioMetadataUpdateManyWithoutSegmentInput>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<SegmentOrigin>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SegmentUpdatephonemizedTextInput>;
  previewSample?: InputMaybe<SampleUpdateOneInput>;
  requiresVoiceActor?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLanguage?: InputMaybe<Scalars['String']['input']>;
  sourceWords?: InputMaybe<SegmentUpdatesourceWordsInput>;
  speechInputData?: InputMaybe<SpeechInputDataUpdateOneInput>;
  speechOutputCollections?: InputMaybe<SpeechOutputCollectionUpdateManyWithoutSegmentInput>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyWithoutSegmentInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SegmentState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  studioStageCompleteState?: InputMaybe<SegmentStudioStageCompleteState>;
  style?: InputMaybe<Scalars['String']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  terScore?: InputMaybe<Scalars['Float']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed?: InputMaybe<Scalars['String']['input']>;
  translationStartTime?: InputMaybe<Scalars['Float']['input']>;
  tunedAudioDone?: InputMaybe<Scalars['Boolean']['input']>;
  tunedSample?: InputMaybe<SampleUpdateOneInput>;
  video?: InputMaybe<TranslatedVideoUpdateOneRequiredWithoutSegmentsInput>;
};

export type SegmentUpdateManyDataInput = {
  autoTranslation?: InputMaybe<Scalars['String']['input']>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone?: InputMaybe<Scalars['Boolean']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<SegmentOrigin>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SegmentUpdatephonemizedTextInput>;
  requiresVoiceActor?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLanguage?: InputMaybe<Scalars['String']['input']>;
  sourceWords?: InputMaybe<SegmentUpdatesourceWordsInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SegmentState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  studioStageCompleteState?: InputMaybe<SegmentStudioStageCompleteState>;
  style?: InputMaybe<Scalars['String']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  terScore?: InputMaybe<Scalars['Float']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed?: InputMaybe<Scalars['String']['input']>;
  translationStartTime?: InputMaybe<Scalars['Float']['input']>;
  tunedAudioDone?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SegmentUpdateManyMutationInput = {
  autoTranslation?: InputMaybe<Scalars['String']['input']>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone?: InputMaybe<Scalars['Boolean']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<SegmentOrigin>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SegmentUpdatephonemizedTextInput>;
  requiresVoiceActor?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLanguage?: InputMaybe<Scalars['String']['input']>;
  sourceWords?: InputMaybe<SegmentUpdatesourceWordsInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SegmentState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  studioStageCompleteState?: InputMaybe<SegmentStudioStageCompleteState>;
  style?: InputMaybe<Scalars['String']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  terScore?: InputMaybe<Scalars['Float']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed?: InputMaybe<Scalars['String']['input']>;
  translationStartTime?: InputMaybe<Scalars['Float']['input']>;
  tunedAudioDone?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SegmentUpdateManyWithWhereNestedInput = {
  data: SegmentUpdateManyDataInput;
  where: SegmentScalarWhereInput;
};

export type SegmentUpdateManyWithoutVideoInput = {
  connect?: InputMaybe<Array<SegmentWhereUniqueInput>>;
  create?: InputMaybe<Array<SegmentCreateWithoutVideoInput>>;
  delete?: InputMaybe<Array<SegmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SegmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SegmentWhereUniqueInput>>;
  set?: InputMaybe<Array<SegmentWhereUniqueInput>>;
  update?: InputMaybe<Array<SegmentUpdateWithWhereUniqueWithoutVideoInput>>;
  updateMany?: InputMaybe<Array<SegmentUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SegmentUpsertWithWhereUniqueWithoutVideoInput>>;
};

export type SegmentUpdateOneInput = {
  connect?: InputMaybe<SegmentWhereUniqueInput>;
  create?: InputMaybe<SegmentCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SegmentUpdateDataInput>;
  upsert?: InputMaybe<SegmentUpsertNestedInput>;
};

export type SegmentUpdateOneRequiredInput = {
  connect?: InputMaybe<SegmentWhereUniqueInput>;
  create?: InputMaybe<SegmentCreateInput>;
  update?: InputMaybe<SegmentUpdateDataInput>;
  upsert?: InputMaybe<SegmentUpsertNestedInput>;
};

export type SegmentUpdateOneRequiredWithoutSpeechOutputCollectionsInput = {
  connect?: InputMaybe<SegmentWhereUniqueInput>;
  create?: InputMaybe<SegmentCreateWithoutSpeechOutputCollectionsInput>;
  update?: InputMaybe<SegmentUpdateWithoutSpeechOutputCollectionsDataInput>;
  upsert?: InputMaybe<SegmentUpsertWithoutSpeechOutputCollectionsInput>;
};

export type SegmentUpdateOneWithoutSpeechOutputsInput = {
  connect?: InputMaybe<SegmentWhereUniqueInput>;
  create?: InputMaybe<SegmentCreateWithoutSpeechOutputsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SegmentUpdateWithoutSpeechOutputsDataInput>;
  upsert?: InputMaybe<SegmentUpsertWithoutSpeechOutputsInput>;
};

export type SegmentUpdateWithWhereUniqueWithoutVideoInput = {
  data: SegmentUpdateWithoutVideoDataInput;
  where: SegmentWhereUniqueInput;
};

export type SegmentUpdateWithoutSpeechOutputCollectionsDataInput = {
  annotations?: InputMaybe<SegmentAnnotationUpdateManyWithoutSegmentInput>;
  autoTranslation?: InputMaybe<Scalars['String']['input']>;
  currentMLOutput?: InputMaybe<MlOutputUpdateOneInput>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone?: InputMaybe<Scalars['Boolean']['input']>;
  latestProsodyBuffet?: InputMaybe<SpeechOutputCollectionUpdateOneInput>;
  latestSpeechOutput?: InputMaybe<SpeechOutputUpdateOneInput>;
  latestTunedMultipleRenditions?: InputMaybe<SpeechOutputCollectionUpdateOneInput>;
  latestTunedSpeechOutput?: InputMaybe<SpeechOutputUpdateOneInput>;
  mimicAudioMetadatas?: InputMaybe<MimicAudioMetadataUpdateManyWithoutSegmentInput>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<SegmentOrigin>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SegmentUpdatephonemizedTextInput>;
  previewSample?: InputMaybe<SampleUpdateOneInput>;
  requiresVoiceActor?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLanguage?: InputMaybe<Scalars['String']['input']>;
  sourceWords?: InputMaybe<SegmentUpdatesourceWordsInput>;
  speechInputData?: InputMaybe<SpeechInputDataUpdateOneInput>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyWithoutSegmentInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SegmentState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  studioStageCompleteState?: InputMaybe<SegmentStudioStageCompleteState>;
  style?: InputMaybe<Scalars['String']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  terScore?: InputMaybe<Scalars['Float']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed?: InputMaybe<Scalars['String']['input']>;
  translationStartTime?: InputMaybe<Scalars['Float']['input']>;
  tunedAudioDone?: InputMaybe<Scalars['Boolean']['input']>;
  tunedSample?: InputMaybe<SampleUpdateOneInput>;
  video?: InputMaybe<TranslatedVideoUpdateOneRequiredWithoutSegmentsInput>;
};

export type SegmentUpdateWithoutSpeechOutputsDataInput = {
  annotations?: InputMaybe<SegmentAnnotationUpdateManyWithoutSegmentInput>;
  autoTranslation?: InputMaybe<Scalars['String']['input']>;
  currentMLOutput?: InputMaybe<MlOutputUpdateOneInput>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone?: InputMaybe<Scalars['Boolean']['input']>;
  latestProsodyBuffet?: InputMaybe<SpeechOutputCollectionUpdateOneInput>;
  latestSpeechOutput?: InputMaybe<SpeechOutputUpdateOneInput>;
  latestTunedMultipleRenditions?: InputMaybe<SpeechOutputCollectionUpdateOneInput>;
  latestTunedSpeechOutput?: InputMaybe<SpeechOutputUpdateOneInput>;
  mimicAudioMetadatas?: InputMaybe<MimicAudioMetadataUpdateManyWithoutSegmentInput>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<SegmentOrigin>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SegmentUpdatephonemizedTextInput>;
  previewSample?: InputMaybe<SampleUpdateOneInput>;
  requiresVoiceActor?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLanguage?: InputMaybe<Scalars['String']['input']>;
  sourceWords?: InputMaybe<SegmentUpdatesourceWordsInput>;
  speechInputData?: InputMaybe<SpeechInputDataUpdateOneInput>;
  speechOutputCollections?: InputMaybe<SpeechOutputCollectionUpdateManyWithoutSegmentInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SegmentState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  studioStageCompleteState?: InputMaybe<SegmentStudioStageCompleteState>;
  style?: InputMaybe<Scalars['String']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  terScore?: InputMaybe<Scalars['Float']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed?: InputMaybe<Scalars['String']['input']>;
  translationStartTime?: InputMaybe<Scalars['Float']['input']>;
  tunedAudioDone?: InputMaybe<Scalars['Boolean']['input']>;
  tunedSample?: InputMaybe<SampleUpdateOneInput>;
  video?: InputMaybe<TranslatedVideoUpdateOneRequiredWithoutSegmentsInput>;
};

export type SegmentUpdateWithoutVideoDataInput = {
  annotations?: InputMaybe<SegmentAnnotationUpdateManyWithoutSegmentInput>;
  autoTranslation?: InputMaybe<Scalars['String']['input']>;
  currentMLOutput?: InputMaybe<MlOutputUpdateOneInput>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone?: InputMaybe<Scalars['Boolean']['input']>;
  latestProsodyBuffet?: InputMaybe<SpeechOutputCollectionUpdateOneInput>;
  latestSpeechOutput?: InputMaybe<SpeechOutputUpdateOneInput>;
  latestTunedMultipleRenditions?: InputMaybe<SpeechOutputCollectionUpdateOneInput>;
  latestTunedSpeechOutput?: InputMaybe<SpeechOutputUpdateOneInput>;
  mimicAudioMetadatas?: InputMaybe<MimicAudioMetadataUpdateManyWithoutSegmentInput>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<SegmentOrigin>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<SegmentUpdatephonemizedTextInput>;
  previewSample?: InputMaybe<SampleUpdateOneInput>;
  requiresVoiceActor?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLanguage?: InputMaybe<Scalars['String']['input']>;
  sourceWords?: InputMaybe<SegmentUpdatesourceWordsInput>;
  speechInputData?: InputMaybe<SpeechInputDataUpdateOneInput>;
  speechOutputCollections?: InputMaybe<SpeechOutputCollectionUpdateManyWithoutSegmentInput>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyWithoutSegmentInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SegmentState>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  studioStageCompleteState?: InputMaybe<SegmentStudioStageCompleteState>;
  style?: InputMaybe<Scalars['String']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  terScore?: InputMaybe<Scalars['Float']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed?: InputMaybe<Scalars['String']['input']>;
  translationStartTime?: InputMaybe<Scalars['Float']['input']>;
  tunedAudioDone?: InputMaybe<Scalars['Boolean']['input']>;
  tunedSample?: InputMaybe<SampleUpdateOneInput>;
};

export type SegmentUpdatephonemizedTextInput = {
  set?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SegmentUpdatesourceWordsInput = {
  set?: InputMaybe<Array<Scalars['Json']['input']>>;
};

export type SegmentUpsertNestedInput = {
  create: SegmentCreateInput;
  update: SegmentUpdateDataInput;
};

export type SegmentUpsertWithWhereUniqueWithoutVideoInput = {
  create: SegmentCreateWithoutVideoInput;
  update: SegmentUpdateWithoutVideoDataInput;
  where: SegmentWhereUniqueInput;
};

export type SegmentUpsertWithoutSpeechOutputCollectionsInput = {
  create: SegmentCreateWithoutSpeechOutputCollectionsInput;
  update: SegmentUpdateWithoutSpeechOutputCollectionsDataInput;
};

export type SegmentUpsertWithoutSpeechOutputsInput = {
  create: SegmentCreateWithoutSpeechOutputsInput;
  update: SegmentUpdateWithoutSpeechOutputsDataInput;
};

export type SegmentWhereInput = {
  AND?: InputMaybe<Array<SegmentWhereInput>>;
  NOT?: InputMaybe<Array<SegmentWhereInput>>;
  OR?: InputMaybe<Array<SegmentWhereInput>>;
  annotations_every?: InputMaybe<SegmentAnnotationWhereInput>;
  annotations_none?: InputMaybe<SegmentAnnotationWhereInput>;
  annotations_some?: InputMaybe<SegmentAnnotationWhereInput>;
  autoTranslation?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_contains?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_ends_with?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_gt?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_gte?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_in?: InputMaybe<Array<Scalars['String']['input']>>;
  autoTranslation_lt?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_lte?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_not?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_not_contains?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  autoTranslation_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  autoTranslation_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  currentMLOutput?: InputMaybe<MlOutputWhereInput>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  done_not?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  endTime_gt?: InputMaybe<Scalars['Float']['input']>;
  endTime_gte?: InputMaybe<Scalars['Float']['input']>;
  endTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  endTime_lt?: InputMaybe<Scalars['Float']['input']>;
  endTime_lte?: InputMaybe<Scalars['Float']['input']>;
  endTime_not?: InputMaybe<Scalars['Float']['input']>;
  endTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  featuresS3?: InputMaybe<Scalars['String']['input']>;
  featuresS3_contains?: InputMaybe<Scalars['String']['input']>;
  featuresS3_ends_with?: InputMaybe<Scalars['String']['input']>;
  featuresS3_gt?: InputMaybe<Scalars['String']['input']>;
  featuresS3_gte?: InputMaybe<Scalars['String']['input']>;
  featuresS3_in?: InputMaybe<Array<Scalars['String']['input']>>;
  featuresS3_lt?: InputMaybe<Scalars['String']['input']>;
  featuresS3_lte?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not_contains?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  featuresS3_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  featuresS3_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  featuresS3_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived_not?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone?: InputMaybe<Scalars['Boolean']['input']>;
  isTranslationDone_not?: InputMaybe<Scalars['Boolean']['input']>;
  latestProsodyBuffet?: InputMaybe<SpeechOutputCollectionWhereInput>;
  latestSpeechOutput?: InputMaybe<SpeechOutputWhereInput>;
  latestTunedMultipleRenditions?: InputMaybe<SpeechOutputCollectionWhereInput>;
  latestTunedSpeechOutput?: InputMaybe<SpeechOutputWhereInput>;
  mimicAudioMetadatas_every?: InputMaybe<MimicAudioMetadataWhereInput>;
  mimicAudioMetadatas_none?: InputMaybe<MimicAudioMetadataWhereInput>;
  mimicAudioMetadatas_some?: InputMaybe<MimicAudioMetadataWhereInput>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelName_contains?: InputMaybe<Scalars['String']['input']>;
  modelName_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelName_gt?: InputMaybe<Scalars['String']['input']>;
  modelName_gte?: InputMaybe<Scalars['String']['input']>;
  modelName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelName_lt?: InputMaybe<Scalars['String']['input']>;
  modelName_lte?: InputMaybe<Scalars['String']['input']>;
  modelName_not?: InputMaybe<Scalars['String']['input']>;
  modelName_not_contains?: InputMaybe<Scalars['String']['input']>;
  modelName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelName_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
  modelVersion_contains?: InputMaybe<Scalars['String']['input']>;
  modelVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion_gt?: InputMaybe<Scalars['String']['input']>;
  modelVersion_gte?: InputMaybe<Scalars['String']['input']>;
  modelVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelVersion_lt?: InputMaybe<Scalars['String']['input']>;
  modelVersion_lte?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<SegmentOrigin>;
  origin_in?: InputMaybe<Array<SegmentOrigin>>;
  origin_not?: InputMaybe<SegmentOrigin>;
  origin_not_in?: InputMaybe<Array<SegmentOrigin>>;
  papercupSpeakerId?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_contains?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_ends_with?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_gt?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_gte?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  papercupSpeakerId_lt?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_lte?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not_contains?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  papercupSpeakerId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  papercupSpeakerId_starts_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_contains?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_gt?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_gte?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phonemizationVersion_lt?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_lte?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phonemizationVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  previewSample?: InputMaybe<SampleWhereInput>;
  requiresVoiceActor?: InputMaybe<Scalars['Boolean']['input']>;
  requiresVoiceActor_not?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLanguage?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_contains?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_ends_with?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_gt?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_gte?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sourceLanguage_lt?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_lte?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_not?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_not_contains?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sourceLanguage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  sourceLanguage_starts_with?: InputMaybe<Scalars['String']['input']>;
  speechInputData?: InputMaybe<SpeechInputDataWhereInput>;
  speechOutputCollections_every?: InputMaybe<SpeechOutputCollectionWhereInput>;
  speechOutputCollections_none?: InputMaybe<SpeechOutputCollectionWhereInput>;
  speechOutputCollections_some?: InputMaybe<SpeechOutputCollectionWhereInput>;
  speechOutputs_every?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_none?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_some?: InputMaybe<SpeechOutputWhereInput>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  startTime_gt?: InputMaybe<Scalars['Float']['input']>;
  startTime_gte?: InputMaybe<Scalars['Float']['input']>;
  startTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTime_lt?: InputMaybe<Scalars['Float']['input']>;
  startTime_lte?: InputMaybe<Scalars['Float']['input']>;
  startTime_not?: InputMaybe<Scalars['Float']['input']>;
  startTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stateValue?: InputMaybe<SegmentState>;
  stateValue_in?: InputMaybe<Array<SegmentState>>;
  stateValue_not?: InputMaybe<SegmentState>;
  stateValue_not_in?: InputMaybe<Array<SegmentState>>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stretchRate_lt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_lte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  studioStageCompleteState?: InputMaybe<SegmentStudioStageCompleteState>;
  studioStageCompleteState_in?: InputMaybe<Array<SegmentStudioStageCompleteState>>;
  studioStageCompleteState_not?: InputMaybe<SegmentStudioStageCompleteState>;
  studioStageCompleteState_not_in?: InputMaybe<Array<SegmentStudioStageCompleteState>>;
  style?: InputMaybe<Scalars['String']['input']>;
  style_contains?: InputMaybe<Scalars['String']['input']>;
  style_ends_with?: InputMaybe<Scalars['String']['input']>;
  style_gt?: InputMaybe<Scalars['String']['input']>;
  style_gte?: InputMaybe<Scalars['String']['input']>;
  style_in?: InputMaybe<Array<Scalars['String']['input']>>;
  style_lt?: InputMaybe<Scalars['String']['input']>;
  style_lte?: InputMaybe<Scalars['String']['input']>;
  style_not?: InputMaybe<Scalars['String']['input']>;
  style_not_contains?: InputMaybe<Scalars['String']['input']>;
  style_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  style_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  style_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  style_starts_with?: InputMaybe<Scalars['String']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_contains?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_ends_with?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_gt?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_gte?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  targetLanguage_lt?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_lte?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_not?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_not_contains?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  targetLanguage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  targetLanguage_starts_with?: InputMaybe<Scalars['String']['input']>;
  terScore?: InputMaybe<Scalars['Float']['input']>;
  terScore_gt?: InputMaybe<Scalars['Float']['input']>;
  terScore_gte?: InputMaybe<Scalars['Float']['input']>;
  terScore_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  terScore_lt?: InputMaybe<Scalars['Float']['input']>;
  terScore_lte?: InputMaybe<Scalars['Float']['input']>;
  terScore_not?: InputMaybe<Scalars['Float']['input']>;
  terScore_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_not?: InputMaybe<Scalars['String']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  text_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedText?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_contains?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_gt?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_gte?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedTextParsed_lt?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_lte?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_not?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_not_contains?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedTextParsed_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedTextParsed_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedText_contains?: InputMaybe<Scalars['String']['input']>;
  translatedText_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedText_gt?: InputMaybe<Scalars['String']['input']>;
  translatedText_gte?: InputMaybe<Scalars['String']['input']>;
  translatedText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedText_lt?: InputMaybe<Scalars['String']['input']>;
  translatedText_lte?: InputMaybe<Scalars['String']['input']>;
  translatedText_not?: InputMaybe<Scalars['String']['input']>;
  translatedText_not_contains?: InputMaybe<Scalars['String']['input']>;
  translatedText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedText_starts_with?: InputMaybe<Scalars['String']['input']>;
  translationStartTime?: InputMaybe<Scalars['Float']['input']>;
  translationStartTime_gt?: InputMaybe<Scalars['Float']['input']>;
  translationStartTime_gte?: InputMaybe<Scalars['Float']['input']>;
  translationStartTime_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  translationStartTime_lt?: InputMaybe<Scalars['Float']['input']>;
  translationStartTime_lte?: InputMaybe<Scalars['Float']['input']>;
  translationStartTime_not?: InputMaybe<Scalars['Float']['input']>;
  translationStartTime_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  tunedAudioDone?: InputMaybe<Scalars['Boolean']['input']>;
  tunedAudioDone_not?: InputMaybe<Scalars['Boolean']['input']>;
  tunedSample?: InputMaybe<SampleWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  video?: InputMaybe<TranslatedVideoWhereInput>;
};

export type SegmentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Session = {
  __typename?: 'Session';
  activityDate?: Maybe<Scalars['DateTime']['output']>;
  channelSessions?: Maybe<Array<SessionChannelTime>>;
  createdAt: Scalars['DateTime']['output'];
  dashboardTime: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  manualTaskTimes?: Maybe<Array<ManualTaskTime>>;
  totalTime: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: User;
  videoSessions?: Maybe<Array<SessionVideoTime>>;
};


export type SessionChannelSessionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SessionChannelTimeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionChannelTimeWhereInput>;
};


export type SessionManualTaskTimesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ManualTaskTimeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManualTaskTimeWhereInput>;
};


export type SessionVideoSessionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SessionVideoTimeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionVideoTimeWhereInput>;
};

export type SessionChannelTime = {
  __typename?: 'SessionChannelTime';
  channelDetailsTime: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  translatedChannelId?: Maybe<TranslatedChannel>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SessionChannelTimeCreateInput = {
  channelDetailsTime?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  translatedChannelId?: InputMaybe<TranslatedChannelCreateOneInput>;
};

export type SessionChannelTimeCreateManyInput = {
  connect?: InputMaybe<Array<SessionChannelTimeWhereUniqueInput>>;
  create?: InputMaybe<Array<SessionChannelTimeCreateInput>>;
};

export enum SessionChannelTimeOrderByInput {
  ChannelDetailsTimeAsc = 'channelDetailsTime_ASC',
  ChannelDetailsTimeDesc = 'channelDetailsTime_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type SessionChannelTimeScalarWhereInput = {
  AND?: InputMaybe<Array<SessionChannelTimeScalarWhereInput>>;
  NOT?: InputMaybe<Array<SessionChannelTimeScalarWhereInput>>;
  OR?: InputMaybe<Array<SessionChannelTimeScalarWhereInput>>;
  channelDetailsTime?: InputMaybe<Scalars['Int']['input']>;
  channelDetailsTime_gt?: InputMaybe<Scalars['Int']['input']>;
  channelDetailsTime_gte?: InputMaybe<Scalars['Int']['input']>;
  channelDetailsTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  channelDetailsTime_lt?: InputMaybe<Scalars['Int']['input']>;
  channelDetailsTime_lte?: InputMaybe<Scalars['Int']['input']>;
  channelDetailsTime_not?: InputMaybe<Scalars['Int']['input']>;
  channelDetailsTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type SessionChannelTimeUpdateDataInput = {
  channelDetailsTime?: InputMaybe<Scalars['Int']['input']>;
  translatedChannelId?: InputMaybe<TranslatedChannelUpdateOneInput>;
};

export type SessionChannelTimeUpdateManyDataInput = {
  channelDetailsTime?: InputMaybe<Scalars['Int']['input']>;
};

export type SessionChannelTimeUpdateManyInput = {
  connect?: InputMaybe<Array<SessionChannelTimeWhereUniqueInput>>;
  create?: InputMaybe<Array<SessionChannelTimeCreateInput>>;
  delete?: InputMaybe<Array<SessionChannelTimeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionChannelTimeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionChannelTimeWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionChannelTimeWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionChannelTimeUpdateWithWhereUniqueNestedInput>>;
  updateMany?: InputMaybe<Array<SessionChannelTimeUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SessionChannelTimeUpsertWithWhereUniqueNestedInput>>;
};

export type SessionChannelTimeUpdateManyWithWhereNestedInput = {
  data: SessionChannelTimeUpdateManyDataInput;
  where: SessionChannelTimeScalarWhereInput;
};

export type SessionChannelTimeUpdateWithWhereUniqueNestedInput = {
  data: SessionChannelTimeUpdateDataInput;
  where: SessionChannelTimeWhereUniqueInput;
};

export type SessionChannelTimeUpsertWithWhereUniqueNestedInput = {
  create: SessionChannelTimeCreateInput;
  update: SessionChannelTimeUpdateDataInput;
  where: SessionChannelTimeWhereUniqueInput;
};

export type SessionChannelTimeWhereInput = {
  AND?: InputMaybe<Array<SessionChannelTimeWhereInput>>;
  NOT?: InputMaybe<Array<SessionChannelTimeWhereInput>>;
  OR?: InputMaybe<Array<SessionChannelTimeWhereInput>>;
  channelDetailsTime?: InputMaybe<Scalars['Int']['input']>;
  channelDetailsTime_gt?: InputMaybe<Scalars['Int']['input']>;
  channelDetailsTime_gte?: InputMaybe<Scalars['Int']['input']>;
  channelDetailsTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  channelDetailsTime_lt?: InputMaybe<Scalars['Int']['input']>;
  channelDetailsTime_lte?: InputMaybe<Scalars['Int']['input']>;
  channelDetailsTime_not?: InputMaybe<Scalars['Int']['input']>;
  channelDetailsTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  translatedChannelId?: InputMaybe<TranslatedChannelWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type SessionChannelTimeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SessionCreateInput = {
  activityDate?: InputMaybe<Scalars['DateTime']['input']>;
  channelSessions?: InputMaybe<SessionChannelTimeCreateManyInput>;
  dashboardTime?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  manualTaskTimes?: InputMaybe<ManualTaskTimeCreateManyInput>;
  totalTime?: InputMaybe<Scalars['Int']['input']>;
  userId: UserCreateOneInput;
  videoSessions?: InputMaybe<SessionVideoTimeCreateManyInput>;
};

export type SessionCreateManyInput = {
  connect?: InputMaybe<Array<SessionWhereUniqueInput>>;
  create?: InputMaybe<Array<SessionCreateInput>>;
};

export enum SessionOrderByInput {
  ActivityDateAsc = 'activityDate_ASC',
  ActivityDateDesc = 'activityDate_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DashboardTimeAsc = 'dashboardTime_ASC',
  DashboardTimeDesc = 'dashboardTime_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TotalTimeAsc = 'totalTime_ASC',
  TotalTimeDesc = 'totalTime_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type SessionUpdateInput = {
  activityDate?: InputMaybe<Scalars['DateTime']['input']>;
  channelSessions?: InputMaybe<SessionChannelTimeUpdateManyInput>;
  dashboardTime?: InputMaybe<Scalars['Int']['input']>;
  manualTaskTimes?: InputMaybe<ManualTaskTimeUpdateManyInput>;
  totalTime?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<UserUpdateOneRequiredInput>;
  videoSessions?: InputMaybe<SessionVideoTimeUpdateManyInput>;
};

export type SessionVideoTime = {
  __typename?: 'SessionVideoTime';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  manualTaskTimes?: Maybe<Array<ManualTaskTime>>;
  outputReview: Scalars['Int']['output'];
  speechTime: Scalars['Int']['output'];
  studioTime: Scalars['Int']['output'];
  studioTunedModeTime: Scalars['Int']['output'];
  transcriptionTime: Scalars['Int']['output'];
  translatedVideoId?: Maybe<TranslatedVideo>;
  translationTime: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  videoDetailsTime: Scalars['Int']['output'];
};


export type SessionVideoTimeManualTaskTimesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ManualTaskTimeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManualTaskTimeWhereInput>;
};

export type SessionVideoTimeCreateInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  manualTaskTimes?: InputMaybe<ManualTaskTimeCreateManyInput>;
  outputReview?: InputMaybe<Scalars['Int']['input']>;
  speechTime?: InputMaybe<Scalars['Int']['input']>;
  studioTime?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime?: InputMaybe<Scalars['Int']['input']>;
  translatedVideoId?: InputMaybe<TranslatedVideoCreateOneInput>;
  translationTime?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime?: InputMaybe<Scalars['Int']['input']>;
};

export type SessionVideoTimeCreateManyInput = {
  connect?: InputMaybe<Array<SessionVideoTimeWhereUniqueInput>>;
  create?: InputMaybe<Array<SessionVideoTimeCreateInput>>;
};

export enum SessionVideoTimeOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OutputReviewAsc = 'outputReview_ASC',
  OutputReviewDesc = 'outputReview_DESC',
  SpeechTimeAsc = 'speechTime_ASC',
  SpeechTimeDesc = 'speechTime_DESC',
  StudioTimeAsc = 'studioTime_ASC',
  StudioTimeDesc = 'studioTime_DESC',
  StudioTunedModeTimeAsc = 'studioTunedModeTime_ASC',
  StudioTunedModeTimeDesc = 'studioTunedModeTime_DESC',
  TranscriptionTimeAsc = 'transcriptionTime_ASC',
  TranscriptionTimeDesc = 'transcriptionTime_DESC',
  TranslationTimeAsc = 'translationTime_ASC',
  TranslationTimeDesc = 'translationTime_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoDetailsTimeAsc = 'videoDetailsTime_ASC',
  VideoDetailsTimeDesc = 'videoDetailsTime_DESC'
}

export type SessionVideoTimeScalarWhereInput = {
  AND?: InputMaybe<Array<SessionVideoTimeScalarWhereInput>>;
  NOT?: InputMaybe<Array<SessionVideoTimeScalarWhereInput>>;
  OR?: InputMaybe<Array<SessionVideoTimeScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  outputReview?: InputMaybe<Scalars['Int']['input']>;
  outputReview_gt?: InputMaybe<Scalars['Int']['input']>;
  outputReview_gte?: InputMaybe<Scalars['Int']['input']>;
  outputReview_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  outputReview_lt?: InputMaybe<Scalars['Int']['input']>;
  outputReview_lte?: InputMaybe<Scalars['Int']['input']>;
  outputReview_not?: InputMaybe<Scalars['Int']['input']>;
  outputReview_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  speechTime?: InputMaybe<Scalars['Int']['input']>;
  speechTime_gt?: InputMaybe<Scalars['Int']['input']>;
  speechTime_gte?: InputMaybe<Scalars['Int']['input']>;
  speechTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  speechTime_lt?: InputMaybe<Scalars['Int']['input']>;
  speechTime_lte?: InputMaybe<Scalars['Int']['input']>;
  speechTime_not?: InputMaybe<Scalars['Int']['input']>;
  speechTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  studioTime?: InputMaybe<Scalars['Int']['input']>;
  studioTime_gt?: InputMaybe<Scalars['Int']['input']>;
  studioTime_gte?: InputMaybe<Scalars['Int']['input']>;
  studioTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  studioTime_lt?: InputMaybe<Scalars['Int']['input']>;
  studioTime_lte?: InputMaybe<Scalars['Int']['input']>;
  studioTime_not?: InputMaybe<Scalars['Int']['input']>;
  studioTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  studioTunedModeTime?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime_gt?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime_gte?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  studioTunedModeTime_lt?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime_lte?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime_not?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  transcriptionTime?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime_gt?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime_gte?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  transcriptionTime_lt?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime_lte?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime_not?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  translationTime?: InputMaybe<Scalars['Int']['input']>;
  translationTime_gt?: InputMaybe<Scalars['Int']['input']>;
  translationTime_gte?: InputMaybe<Scalars['Int']['input']>;
  translationTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  translationTime_lt?: InputMaybe<Scalars['Int']['input']>;
  translationTime_lte?: InputMaybe<Scalars['Int']['input']>;
  translationTime_not?: InputMaybe<Scalars['Int']['input']>;
  translationTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  videoDetailsTime?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime_gt?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime_gte?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  videoDetailsTime_lt?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime_lte?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime_not?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SessionVideoTimeUpdateDataInput = {
  manualTaskTimes?: InputMaybe<ManualTaskTimeUpdateManyInput>;
  outputReview?: InputMaybe<Scalars['Int']['input']>;
  speechTime?: InputMaybe<Scalars['Int']['input']>;
  studioTime?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime?: InputMaybe<Scalars['Int']['input']>;
  translatedVideoId?: InputMaybe<TranslatedVideoUpdateOneInput>;
  translationTime?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime?: InputMaybe<Scalars['Int']['input']>;
};

export type SessionVideoTimeUpdateInput = {
  manualTaskTimes?: InputMaybe<ManualTaskTimeUpdateManyInput>;
  outputReview?: InputMaybe<Scalars['Int']['input']>;
  speechTime?: InputMaybe<Scalars['Int']['input']>;
  studioTime?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime?: InputMaybe<Scalars['Int']['input']>;
  translatedVideoId?: InputMaybe<TranslatedVideoUpdateOneInput>;
  translationTime?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime?: InputMaybe<Scalars['Int']['input']>;
};

export type SessionVideoTimeUpdateManyDataInput = {
  outputReview?: InputMaybe<Scalars['Int']['input']>;
  speechTime?: InputMaybe<Scalars['Int']['input']>;
  studioTime?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime?: InputMaybe<Scalars['Int']['input']>;
  translationTime?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime?: InputMaybe<Scalars['Int']['input']>;
};

export type SessionVideoTimeUpdateManyInput = {
  connect?: InputMaybe<Array<SessionVideoTimeWhereUniqueInput>>;
  create?: InputMaybe<Array<SessionVideoTimeCreateInput>>;
  delete?: InputMaybe<Array<SessionVideoTimeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionVideoTimeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionVideoTimeWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionVideoTimeWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionVideoTimeUpdateWithWhereUniqueNestedInput>>;
  updateMany?: InputMaybe<Array<SessionVideoTimeUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SessionVideoTimeUpsertWithWhereUniqueNestedInput>>;
};

export type SessionVideoTimeUpdateManyWithWhereNestedInput = {
  data: SessionVideoTimeUpdateManyDataInput;
  where: SessionVideoTimeScalarWhereInput;
};

export type SessionVideoTimeUpdateWithWhereUniqueNestedInput = {
  data: SessionVideoTimeUpdateDataInput;
  where: SessionVideoTimeWhereUniqueInput;
};

export type SessionVideoTimeUpsertWithWhereUniqueNestedInput = {
  create: SessionVideoTimeCreateInput;
  update: SessionVideoTimeUpdateDataInput;
  where: SessionVideoTimeWhereUniqueInput;
};

export type SessionVideoTimeWhereInput = {
  AND?: InputMaybe<Array<SessionVideoTimeWhereInput>>;
  NOT?: InputMaybe<Array<SessionVideoTimeWhereInput>>;
  OR?: InputMaybe<Array<SessionVideoTimeWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  manualTaskTimes_every?: InputMaybe<ManualTaskTimeWhereInput>;
  manualTaskTimes_none?: InputMaybe<ManualTaskTimeWhereInput>;
  manualTaskTimes_some?: InputMaybe<ManualTaskTimeWhereInput>;
  outputReview?: InputMaybe<Scalars['Int']['input']>;
  outputReview_gt?: InputMaybe<Scalars['Int']['input']>;
  outputReview_gte?: InputMaybe<Scalars['Int']['input']>;
  outputReview_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  outputReview_lt?: InputMaybe<Scalars['Int']['input']>;
  outputReview_lte?: InputMaybe<Scalars['Int']['input']>;
  outputReview_not?: InputMaybe<Scalars['Int']['input']>;
  outputReview_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  speechTime?: InputMaybe<Scalars['Int']['input']>;
  speechTime_gt?: InputMaybe<Scalars['Int']['input']>;
  speechTime_gte?: InputMaybe<Scalars['Int']['input']>;
  speechTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  speechTime_lt?: InputMaybe<Scalars['Int']['input']>;
  speechTime_lte?: InputMaybe<Scalars['Int']['input']>;
  speechTime_not?: InputMaybe<Scalars['Int']['input']>;
  speechTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  studioTime?: InputMaybe<Scalars['Int']['input']>;
  studioTime_gt?: InputMaybe<Scalars['Int']['input']>;
  studioTime_gte?: InputMaybe<Scalars['Int']['input']>;
  studioTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  studioTime_lt?: InputMaybe<Scalars['Int']['input']>;
  studioTime_lte?: InputMaybe<Scalars['Int']['input']>;
  studioTime_not?: InputMaybe<Scalars['Int']['input']>;
  studioTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  studioTunedModeTime?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime_gt?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime_gte?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  studioTunedModeTime_lt?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime_lte?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime_not?: InputMaybe<Scalars['Int']['input']>;
  studioTunedModeTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  transcriptionTime?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime_gt?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime_gte?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  transcriptionTime_lt?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime_lte?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime_not?: InputMaybe<Scalars['Int']['input']>;
  transcriptionTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  translatedVideoId?: InputMaybe<TranslatedVideoWhereInput>;
  translationTime?: InputMaybe<Scalars['Int']['input']>;
  translationTime_gt?: InputMaybe<Scalars['Int']['input']>;
  translationTime_gte?: InputMaybe<Scalars['Int']['input']>;
  translationTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  translationTime_lt?: InputMaybe<Scalars['Int']['input']>;
  translationTime_lte?: InputMaybe<Scalars['Int']['input']>;
  translationTime_not?: InputMaybe<Scalars['Int']['input']>;
  translationTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  videoDetailsTime?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime_gt?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime_gte?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  videoDetailsTime_lt?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime_lte?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime_not?: InputMaybe<Scalars['Int']['input']>;
  videoDetailsTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SessionVideoTimeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SessionWhereInput = {
  AND?: InputMaybe<Array<SessionWhereInput>>;
  NOT?: InputMaybe<Array<SessionWhereInput>>;
  OR?: InputMaybe<Array<SessionWhereInput>>;
  activityDate?: InputMaybe<Scalars['DateTime']['input']>;
  activityDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  activityDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  activityDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  activityDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  activityDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  activityDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  activityDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  channelSessions_every?: InputMaybe<SessionChannelTimeWhereInput>;
  channelSessions_none?: InputMaybe<SessionChannelTimeWhereInput>;
  channelSessions_some?: InputMaybe<SessionChannelTimeWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  dashboardTime?: InputMaybe<Scalars['Int']['input']>;
  dashboardTime_gt?: InputMaybe<Scalars['Int']['input']>;
  dashboardTime_gte?: InputMaybe<Scalars['Int']['input']>;
  dashboardTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  dashboardTime_lt?: InputMaybe<Scalars['Int']['input']>;
  dashboardTime_lte?: InputMaybe<Scalars['Int']['input']>;
  dashboardTime_not?: InputMaybe<Scalars['Int']['input']>;
  dashboardTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  manualTaskTimes_every?: InputMaybe<ManualTaskTimeWhereInput>;
  manualTaskTimes_none?: InputMaybe<ManualTaskTimeWhereInput>;
  manualTaskTimes_some?: InputMaybe<ManualTaskTimeWhereInput>;
  totalTime?: InputMaybe<Scalars['Int']['input']>;
  totalTime_gt?: InputMaybe<Scalars['Int']['input']>;
  totalTime_gte?: InputMaybe<Scalars['Int']['input']>;
  totalTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalTime_lt?: InputMaybe<Scalars['Int']['input']>;
  totalTime_lte?: InputMaybe<Scalars['Int']['input']>;
  totalTime_not?: InputMaybe<Scalars['Int']['input']>;
  totalTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  userId?: InputMaybe<UserWhereInput>;
  videoSessions_every?: InputMaybe<SessionVideoTimeWhereInput>;
  videoSessions_none?: InputMaybe<SessionVideoTimeWhereInput>;
  videoSessions_some?: InputMaybe<SessionVideoTimeWhereInput>;
};

export type SessionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SourceChannel = {
  __typename?: 'SourceChannel';
  activeStatus: SourceChannelActiveStatus;
  channelCreator?: Maybe<Creator>;
  channelOwner?: Maybe<User>;
  channelType?: Maybe<ChannelType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isTest: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  sourceVideos?: Maybe<Array<SourceVideo>>;
  splitAudioProvider: SplitAudioProvider;
  splitAudioProviderConfig?: Maybe<Scalars['Json']['output']>;
  title: Scalars['String']['output'];
  transcriptionType: TranscriptionProvider;
  translatedChannels?: Maybe<Array<TranslatedChannel>>;
  typicalVideoDuration?: Maybe<TypicalVideoDuration>;
  updatedAt: Scalars['DateTime']['output'];
  /** Channel Information from YouTube data API */
  ytChannel?: Maybe<YtChannel>;
  ytChannelId?: Maybe<Scalars['String']['output']>;
};


export type SourceChannelSourceVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SourceVideoOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SourceVideoWhereInput>;
};


export type SourceChannelTranslatedChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TranslatedChannelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TranslatedChannelWhereInput>;
};

export enum SourceChannelActiveStatus {
  Active = 'Active',
  ToBeDeleted = 'ToBeDeleted'
}

export type SourceChannelCreateOneWithoutSourceVideosInput = {
  connect?: InputMaybe<SourceChannelWhereUniqueInput>;
  create?: InputMaybe<SourceChannelCreateWithoutSourceVideosInput>;
};

export type SourceChannelCreateOneWithoutTranslatedChannelsInput = {
  connect?: InputMaybe<SourceChannelWhereUniqueInput>;
  create?: InputMaybe<SourceChannelCreateWithoutTranslatedChannelsInput>;
};

export type SourceChannelCreateWithoutSourceVideosInput = {
  activeStatus?: InputMaybe<SourceChannelActiveStatus>;
  channelCreator?: InputMaybe<CreatorCreateOneInput>;
  channelOwner?: InputMaybe<UserCreateOneInput>;
  channelType?: InputMaybe<ChannelType>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  title: Scalars['String']['input'];
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedChannels?: InputMaybe<TranslatedChannelCreateManyWithoutSourceChannelInput>;
  typicalVideoDuration?: InputMaybe<TypicalVideoDuration>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceChannelCreateWithoutTranslatedChannelsInput = {
  activeStatus?: InputMaybe<SourceChannelActiveStatus>;
  channelCreator?: InputMaybe<CreatorCreateOneInput>;
  channelOwner?: InputMaybe<UserCreateOneInput>;
  channelType?: InputMaybe<ChannelType>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  sourceVideos?: InputMaybe<SourceVideoCreateManyWithoutSourceChannelInput>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  title: Scalars['String']['input'];
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  typicalVideoDuration?: InputMaybe<TypicalVideoDuration>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export enum SourceChannelOrderByInput {
  ActiveStatusAsc = 'activeStatus_ASC',
  ActiveStatusDesc = 'activeStatus_DESC',
  ChannelTypeAsc = 'channelType_ASC',
  ChannelTypeDesc = 'channelType_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsTestAsc = 'isTest_ASC',
  IsTestDesc = 'isTest_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  SplitAudioProviderConfigAsc = 'splitAudioProviderConfig_ASC',
  SplitAudioProviderConfigDesc = 'splitAudioProviderConfig_DESC',
  SplitAudioProviderAsc = 'splitAudioProvider_ASC',
  SplitAudioProviderDesc = 'splitAudioProvider_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TranscriptionTypeAsc = 'transcriptionType_ASC',
  TranscriptionTypeDesc = 'transcriptionType_DESC',
  TypicalVideoDurationAsc = 'typicalVideoDuration_ASC',
  TypicalVideoDurationDesc = 'typicalVideoDuration_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  YtChannelIdAsc = 'ytChannelId_ASC',
  YtChannelIdDesc = 'ytChannelId_DESC'
}

export type SourceChannelUpdateInput = {
  activeStatus?: InputMaybe<SourceChannelActiveStatus>;
  channelCreator?: InputMaybe<CreatorUpdateOneInput>;
  channelOwner?: InputMaybe<UserUpdateOneInput>;
  channelType?: InputMaybe<ChannelType>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  sourceVideos?: InputMaybe<SourceVideoUpdateManyWithoutSourceChannelInput>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedChannels?: InputMaybe<TranslatedChannelUpdateManyWithoutSourceChannelInput>;
  typicalVideoDuration?: InputMaybe<TypicalVideoDuration>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceChannelUpdateOneRequiredWithoutSourceVideosInput = {
  connect?: InputMaybe<SourceChannelWhereUniqueInput>;
  create?: InputMaybe<SourceChannelCreateWithoutSourceVideosInput>;
  update?: InputMaybe<SourceChannelUpdateWithoutSourceVideosDataInput>;
  upsert?: InputMaybe<SourceChannelUpsertWithoutSourceVideosInput>;
};

export type SourceChannelUpdateOneRequiredWithoutTranslatedChannelsInput = {
  connect?: InputMaybe<SourceChannelWhereUniqueInput>;
  create?: InputMaybe<SourceChannelCreateWithoutTranslatedChannelsInput>;
  update?: InputMaybe<SourceChannelUpdateWithoutTranslatedChannelsDataInput>;
  upsert?: InputMaybe<SourceChannelUpsertWithoutTranslatedChannelsInput>;
};

export type SourceChannelUpdateWithoutSourceVideosDataInput = {
  activeStatus?: InputMaybe<SourceChannelActiveStatus>;
  channelCreator?: InputMaybe<CreatorUpdateOneInput>;
  channelOwner?: InputMaybe<UserUpdateOneInput>;
  channelType?: InputMaybe<ChannelType>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedChannels?: InputMaybe<TranslatedChannelUpdateManyWithoutSourceChannelInput>;
  typicalVideoDuration?: InputMaybe<TypicalVideoDuration>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceChannelUpdateWithoutTranslatedChannelsDataInput = {
  activeStatus?: InputMaybe<SourceChannelActiveStatus>;
  channelCreator?: InputMaybe<CreatorUpdateOneInput>;
  channelOwner?: InputMaybe<UserUpdateOneInput>;
  channelType?: InputMaybe<ChannelType>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  sourceVideos?: InputMaybe<SourceVideoUpdateManyWithoutSourceChannelInput>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  typicalVideoDuration?: InputMaybe<TypicalVideoDuration>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceChannelUpsertWithoutSourceVideosInput = {
  create: SourceChannelCreateWithoutSourceVideosInput;
  update: SourceChannelUpdateWithoutSourceVideosDataInput;
};

export type SourceChannelUpsertWithoutTranslatedChannelsInput = {
  create: SourceChannelCreateWithoutTranslatedChannelsInput;
  update: SourceChannelUpdateWithoutTranslatedChannelsDataInput;
};

export type SourceChannelWhereInput = {
  AND?: InputMaybe<Array<SourceChannelWhereInput>>;
  NOT?: InputMaybe<Array<SourceChannelWhereInput>>;
  OR?: InputMaybe<Array<SourceChannelWhereInput>>;
  activeStatus?: InputMaybe<SourceChannelActiveStatus>;
  activeStatus_in?: InputMaybe<Array<SourceChannelActiveStatus>>;
  activeStatus_not?: InputMaybe<SourceChannelActiveStatus>;
  activeStatus_not_in?: InputMaybe<Array<SourceChannelActiveStatus>>;
  channelCreator?: InputMaybe<CreatorWhereInput>;
  channelOwner?: InputMaybe<UserWhereInput>;
  channelType?: InputMaybe<ChannelType>;
  channelType_in?: InputMaybe<Array<ChannelType>>;
  channelType_not?: InputMaybe<ChannelType>;
  channelType_not_in?: InputMaybe<Array<ChannelType>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  isTest_not?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  language_contains?: InputMaybe<Scalars['String']['input']>;
  language_ends_with?: InputMaybe<Scalars['String']['input']>;
  language_gt?: InputMaybe<Scalars['String']['input']>;
  language_gte?: InputMaybe<Scalars['String']['input']>;
  language_in?: InputMaybe<Array<Scalars['String']['input']>>;
  language_lt?: InputMaybe<Scalars['String']['input']>;
  language_lte?: InputMaybe<Scalars['String']['input']>;
  language_not?: InputMaybe<Scalars['String']['input']>;
  language_not_contains?: InputMaybe<Scalars['String']['input']>;
  language_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  language_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  language_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  language_starts_with?: InputMaybe<Scalars['String']['input']>;
  sourceVideos_every?: InputMaybe<SourceVideoWhereInput>;
  sourceVideos_none?: InputMaybe<SourceVideoWhereInput>;
  sourceVideos_some?: InputMaybe<SourceVideoWhereInput>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProvider_in?: InputMaybe<Array<SplitAudioProvider>>;
  splitAudioProvider_not?: InputMaybe<SplitAudioProvider>;
  splitAudioProvider_not_in?: InputMaybe<Array<SplitAudioProvider>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_gt?: InputMaybe<Scalars['String']['input']>;
  title_gte?: InputMaybe<Scalars['String']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_lt?: InputMaybe<Scalars['String']['input']>;
  title_lte?: InputMaybe<Scalars['String']['input']>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  transcriptionType_in?: InputMaybe<Array<TranscriptionProvider>>;
  transcriptionType_not?: InputMaybe<TranscriptionProvider>;
  transcriptionType_not_in?: InputMaybe<Array<TranscriptionProvider>>;
  translatedChannels_every?: InputMaybe<TranslatedChannelWhereInput>;
  translatedChannels_none?: InputMaybe<TranslatedChannelWhereInput>;
  translatedChannels_some?: InputMaybe<TranslatedChannelWhereInput>;
  typicalVideoDuration?: InputMaybe<TypicalVideoDuration>;
  typicalVideoDuration_in?: InputMaybe<Array<TypicalVideoDuration>>;
  typicalVideoDuration_not?: InputMaybe<TypicalVideoDuration>;
  typicalVideoDuration_not_in?: InputMaybe<Array<TypicalVideoDuration>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_contains?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_ends_with?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_gt?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_gte?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ytChannelId_lt?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_lte?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_not?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_not_contains?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ytChannelId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type SourceChannelWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideo = {
  __typename?: 'SourceVideo';
  activeStatus: SourceVideoActiveStatus;
  asrConfig?: Maybe<Scalars['Json']['output']>;
  asrSegmentsFile?: Maybe<File>;
  audioAccompanimentFile?: Maybe<File>;
  audioVocalsFile?: Maybe<File>;
  audioWaveformJSONFile?: Maybe<File>;
  createdAt: Scalars['DateTime']['output'];
  currentFailure?: Maybe<SourceVideoFailure>;
  currentFailureState?: Maybe<SourceVideoState>;
  duration?: Maybe<Scalars['Float']['output']>;
  durationFormatted?: Maybe<Scalars['String']['output']>;
  editedAudioGroups?: Maybe<Array<EditedAudioGroup>>;
  edits?: Maybe<Array<Edit>>;
  extractedAudioGCP?: Maybe<File>;
  extractedAudioS3?: Maybe<File>;
  failures?: Maybe<Array<SourceVideoFailure>>;
  highResVideoFile?: Maybe<File>;
  id: Scalars['ID']['output'];
  notes?: Maybe<Array<Note>>;
  onScreenTexts?: Maybe<Array<OnScreenTextSource>>;
  presignedS3Policy?: Maybe<Scalars['Json']['output']>;
  rawAsrFile?: Maybe<File>;
  s3root: Scalars['String']['output'];
  sourceChannel: SourceChannel;
  splitAudioProvider: SplitAudioProvider;
  splitAudioProviderConfig?: Maybe<Scalars['Json']['output']>;
  stateValue: SourceVideoState;
  thumbnailFile?: Maybe<File>;
  title?: Maybe<Scalars['String']['output']>;
  tracks?: Maybe<Array<Track>>;
  transcriptionType: TranscriptionProvider;
  translatedVideos?: Maybe<Array<TranslatedVideo>>;
  updatedAt: Scalars['DateTime']['output'];
  uploader?: Maybe<User>;
  videoFile?: Maybe<File>;
  videoSource: SourceVideoSource;
  /** Video Information from YouTube data API */
  ytVideo?: Maybe<YtVideo>;
  ytVideoId?: Maybe<Scalars['String']['output']>;
};


export type SourceVideoEditedAudioGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EditedAudioGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EditedAudioGroupWhereInput>;
};


export type SourceVideoEditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EditOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EditWhereInput>;
};


export type SourceVideoFailuresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SourceVideoFailureOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SourceVideoFailureWhereInput>;
};


export type SourceVideoNotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<NoteOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoteWhereInput>;
};


export type SourceVideoOnScreenTextsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OnScreenTextSourceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OnScreenTextSourceWhereInput>;
};


export type SourceVideoTracksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TrackOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TrackWhereInput>;
};


export type SourceVideoTranslatedVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TranslatedVideoOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TranslatedVideoWhereInput>;
};

export enum SourceVideoActiveStatus {
  Active = 'Active',
  CloudFileDeletionComplete = 'CloudFileDeletionComplete',
  CloudFileDeletionInProgress = 'CloudFileDeletionInProgress',
  ToBeDeleted = 'ToBeDeleted'
}

export type SourceVideoCreateManyWithoutSourceChannelInput = {
  connect?: InputMaybe<Array<SourceVideoWhereUniqueInput>>;
  create?: InputMaybe<Array<SourceVideoCreateWithoutSourceChannelInput>>;
};

export type SourceVideoCreateOneWithoutCurrentFailureInput = {
  connect?: InputMaybe<SourceVideoWhereUniqueInput>;
  create?: InputMaybe<SourceVideoCreateWithoutCurrentFailureInput>;
};

export type SourceVideoCreateOneWithoutEditedAudioGroupsInput = {
  connect?: InputMaybe<SourceVideoWhereUniqueInput>;
  create?: InputMaybe<SourceVideoCreateWithoutEditedAudioGroupsInput>;
};

export type SourceVideoCreateOneWithoutFailuresInput = {
  connect?: InputMaybe<SourceVideoWhereUniqueInput>;
  create?: InputMaybe<SourceVideoCreateWithoutFailuresInput>;
};

export type SourceVideoCreateOneWithoutNotesInput = {
  connect?: InputMaybe<SourceVideoWhereUniqueInput>;
  create?: InputMaybe<SourceVideoCreateWithoutNotesInput>;
};

export type SourceVideoCreateOneWithoutTranslatedVideosInput = {
  connect?: InputMaybe<SourceVideoWhereUniqueInput>;
  create?: InputMaybe<SourceVideoCreateWithoutTranslatedVideosInput>;
};

export type SourceVideoCreateWithoutCurrentFailureInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileCreateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileCreateOneInput>;
  audioVocalsFile?: InputMaybe<FileCreateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileCreateOneInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  editedAudioGroups?: InputMaybe<EditedAudioGroupCreateManyWithoutSourceVideoInput>;
  edits?: InputMaybe<EditCreateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileCreateOneInput>;
  extractedAudioS3?: InputMaybe<FileCreateOneInput>;
  failures?: InputMaybe<SourceVideoFailureCreateManyWithoutSourceVideoInput>;
  highResVideoFile?: InputMaybe<FileCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  notes?: InputMaybe<NoteCreateManyWithoutSourceVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceCreateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileCreateOneInput>;
  s3root: Scalars['String']['input'];
  sourceChannel: SourceChannelCreateOneWithoutSourceVideosInput;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  tracks?: InputMaybe<TrackCreateManyWithoutSourceVideoInput>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedVideos?: InputMaybe<TranslatedVideoCreateManyWithoutSourceVideoInput>;
  uploader?: InputMaybe<UserCreateOneInput>;
  videoFile?: InputMaybe<FileCreateOneInput>;
  videoSource: SourceVideoSource;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoCreateWithoutEditedAudioGroupsInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileCreateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileCreateOneInput>;
  audioVocalsFile?: InputMaybe<FileCreateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileCreateOneInput>;
  currentFailure?: InputMaybe<SourceVideoFailureCreateOneWithoutCurrentFailureSourceVideoInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  edits?: InputMaybe<EditCreateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileCreateOneInput>;
  extractedAudioS3?: InputMaybe<FileCreateOneInput>;
  failures?: InputMaybe<SourceVideoFailureCreateManyWithoutSourceVideoInput>;
  highResVideoFile?: InputMaybe<FileCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  notes?: InputMaybe<NoteCreateManyWithoutSourceVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceCreateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileCreateOneInput>;
  s3root: Scalars['String']['input'];
  sourceChannel: SourceChannelCreateOneWithoutSourceVideosInput;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  tracks?: InputMaybe<TrackCreateManyWithoutSourceVideoInput>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedVideos?: InputMaybe<TranslatedVideoCreateManyWithoutSourceVideoInput>;
  uploader?: InputMaybe<UserCreateOneInput>;
  videoFile?: InputMaybe<FileCreateOneInput>;
  videoSource: SourceVideoSource;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoCreateWithoutFailuresInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileCreateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileCreateOneInput>;
  audioVocalsFile?: InputMaybe<FileCreateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileCreateOneInput>;
  currentFailure?: InputMaybe<SourceVideoFailureCreateOneWithoutCurrentFailureSourceVideoInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  editedAudioGroups?: InputMaybe<EditedAudioGroupCreateManyWithoutSourceVideoInput>;
  edits?: InputMaybe<EditCreateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileCreateOneInput>;
  extractedAudioS3?: InputMaybe<FileCreateOneInput>;
  highResVideoFile?: InputMaybe<FileCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  notes?: InputMaybe<NoteCreateManyWithoutSourceVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceCreateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileCreateOneInput>;
  s3root: Scalars['String']['input'];
  sourceChannel: SourceChannelCreateOneWithoutSourceVideosInput;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  tracks?: InputMaybe<TrackCreateManyWithoutSourceVideoInput>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedVideos?: InputMaybe<TranslatedVideoCreateManyWithoutSourceVideoInput>;
  uploader?: InputMaybe<UserCreateOneInput>;
  videoFile?: InputMaybe<FileCreateOneInput>;
  videoSource: SourceVideoSource;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoCreateWithoutNotesInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileCreateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileCreateOneInput>;
  audioVocalsFile?: InputMaybe<FileCreateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileCreateOneInput>;
  currentFailure?: InputMaybe<SourceVideoFailureCreateOneWithoutCurrentFailureSourceVideoInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  editedAudioGroups?: InputMaybe<EditedAudioGroupCreateManyWithoutSourceVideoInput>;
  edits?: InputMaybe<EditCreateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileCreateOneInput>;
  extractedAudioS3?: InputMaybe<FileCreateOneInput>;
  failures?: InputMaybe<SourceVideoFailureCreateManyWithoutSourceVideoInput>;
  highResVideoFile?: InputMaybe<FileCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceCreateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileCreateOneInput>;
  s3root: Scalars['String']['input'];
  sourceChannel: SourceChannelCreateOneWithoutSourceVideosInput;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  tracks?: InputMaybe<TrackCreateManyWithoutSourceVideoInput>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedVideos?: InputMaybe<TranslatedVideoCreateManyWithoutSourceVideoInput>;
  uploader?: InputMaybe<UserCreateOneInput>;
  videoFile?: InputMaybe<FileCreateOneInput>;
  videoSource: SourceVideoSource;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoCreateWithoutSourceChannelInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileCreateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileCreateOneInput>;
  audioVocalsFile?: InputMaybe<FileCreateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileCreateOneInput>;
  currentFailure?: InputMaybe<SourceVideoFailureCreateOneWithoutCurrentFailureSourceVideoInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  editedAudioGroups?: InputMaybe<EditedAudioGroupCreateManyWithoutSourceVideoInput>;
  edits?: InputMaybe<EditCreateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileCreateOneInput>;
  extractedAudioS3?: InputMaybe<FileCreateOneInput>;
  failures?: InputMaybe<SourceVideoFailureCreateManyWithoutSourceVideoInput>;
  highResVideoFile?: InputMaybe<FileCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  notes?: InputMaybe<NoteCreateManyWithoutSourceVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceCreateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileCreateOneInput>;
  s3root: Scalars['String']['input'];
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  tracks?: InputMaybe<TrackCreateManyWithoutSourceVideoInput>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedVideos?: InputMaybe<TranslatedVideoCreateManyWithoutSourceVideoInput>;
  uploader?: InputMaybe<UserCreateOneInput>;
  videoFile?: InputMaybe<FileCreateOneInput>;
  videoSource: SourceVideoSource;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoCreateWithoutTracksInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileCreateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileCreateOneInput>;
  audioVocalsFile?: InputMaybe<FileCreateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileCreateOneInput>;
  currentFailure?: InputMaybe<SourceVideoFailureCreateOneWithoutCurrentFailureSourceVideoInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  editedAudioGroups?: InputMaybe<EditedAudioGroupCreateManyWithoutSourceVideoInput>;
  edits?: InputMaybe<EditCreateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileCreateOneInput>;
  extractedAudioS3?: InputMaybe<FileCreateOneInput>;
  failures?: InputMaybe<SourceVideoFailureCreateManyWithoutSourceVideoInput>;
  highResVideoFile?: InputMaybe<FileCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  notes?: InputMaybe<NoteCreateManyWithoutSourceVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceCreateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileCreateOneInput>;
  s3root: Scalars['String']['input'];
  sourceChannel: SourceChannelCreateOneWithoutSourceVideosInput;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedVideos?: InputMaybe<TranslatedVideoCreateManyWithoutSourceVideoInput>;
  uploader?: InputMaybe<UserCreateOneInput>;
  videoFile?: InputMaybe<FileCreateOneInput>;
  videoSource: SourceVideoSource;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoCreateWithoutTranslatedVideosInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileCreateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileCreateOneInput>;
  audioVocalsFile?: InputMaybe<FileCreateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileCreateOneInput>;
  currentFailure?: InputMaybe<SourceVideoFailureCreateOneWithoutCurrentFailureSourceVideoInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  editedAudioGroups?: InputMaybe<EditedAudioGroupCreateManyWithoutSourceVideoInput>;
  edits?: InputMaybe<EditCreateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileCreateOneInput>;
  extractedAudioS3?: InputMaybe<FileCreateOneInput>;
  failures?: InputMaybe<SourceVideoFailureCreateManyWithoutSourceVideoInput>;
  highResVideoFile?: InputMaybe<FileCreateOneInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  notes?: InputMaybe<NoteCreateManyWithoutSourceVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceCreateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileCreateOneInput>;
  s3root: Scalars['String']['input'];
  sourceChannel: SourceChannelCreateOneWithoutSourceVideosInput;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  tracks?: InputMaybe<TrackCreateManyWithoutSourceVideoInput>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  uploader?: InputMaybe<UserCreateOneInput>;
  videoFile?: InputMaybe<FileCreateOneInput>;
  videoSource: SourceVideoSource;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoFailure = {
  __typename?: 'SourceVideoFailure';
  createdAt: Scalars['DateTime']['output'];
  currentFailureSourceVideo?: Maybe<SourceVideo>;
  failureError?: Maybe<Scalars['String']['output']>;
  failureMessage?: Maybe<Scalars['String']['output']>;
  failureState?: Maybe<SourceVideoState>;
  failureStatusCode?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  sourceVideo: SourceVideo;
  updatedAt: Scalars['DateTime']['output'];
};

export type SourceVideoFailureCreateManyWithoutSourceVideoInput = {
  connect?: InputMaybe<Array<SourceVideoFailureWhereUniqueInput>>;
  create?: InputMaybe<Array<SourceVideoFailureCreateWithoutSourceVideoInput>>;
};

export type SourceVideoFailureCreateOneWithoutCurrentFailureSourceVideoInput = {
  connect?: InputMaybe<SourceVideoFailureWhereUniqueInput>;
  create?: InputMaybe<SourceVideoFailureCreateWithoutCurrentFailureSourceVideoInput>;
};

export type SourceVideoFailureCreateWithoutCurrentFailureSourceVideoInput = {
  failureError?: InputMaybe<Scalars['String']['input']>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureState?: InputMaybe<SourceVideoState>;
  failureStatusCode?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  sourceVideo: SourceVideoCreateOneWithoutFailuresInput;
};

export type SourceVideoFailureCreateWithoutSourceVideoInput = {
  currentFailureSourceVideo?: InputMaybe<SourceVideoCreateOneWithoutCurrentFailureInput>;
  failureError?: InputMaybe<Scalars['String']['input']>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureState?: InputMaybe<SourceVideoState>;
  failureStatusCode?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum SourceVideoFailureOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FailureErrorAsc = 'failureError_ASC',
  FailureErrorDesc = 'failureError_DESC',
  FailureMessageAsc = 'failureMessage_ASC',
  FailureMessageDesc = 'failureMessage_DESC',
  FailureStateAsc = 'failureState_ASC',
  FailureStateDesc = 'failureState_DESC',
  FailureStatusCodeAsc = 'failureStatusCode_ASC',
  FailureStatusCodeDesc = 'failureStatusCode_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type SourceVideoFailureScalarWhereInput = {
  AND?: InputMaybe<Array<SourceVideoFailureScalarWhereInput>>;
  NOT?: InputMaybe<Array<SourceVideoFailureScalarWhereInput>>;
  OR?: InputMaybe<Array<SourceVideoFailureScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  failureError?: InputMaybe<Scalars['String']['input']>;
  failureError_contains?: InputMaybe<Scalars['String']['input']>;
  failureError_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureError_gt?: InputMaybe<Scalars['String']['input']>;
  failureError_gte?: InputMaybe<Scalars['String']['input']>;
  failureError_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureError_lt?: InputMaybe<Scalars['String']['input']>;
  failureError_lte?: InputMaybe<Scalars['String']['input']>;
  failureError_not?: InputMaybe<Scalars['String']['input']>;
  failureError_not_contains?: InputMaybe<Scalars['String']['input']>;
  failureError_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureError_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureError_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureError_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureMessage_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_lt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_lte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureState?: InputMaybe<SourceVideoState>;
  failureState_in?: InputMaybe<Array<SourceVideoState>>;
  failureState_not?: InputMaybe<SourceVideoState>;
  failureState_not_in?: InputMaybe<Array<SourceVideoState>>;
  failureStatusCode?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_gt?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_gte?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  failureStatusCode_lt?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_lte?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_not?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type SourceVideoFailureUpdateManyDataInput = {
  failureError?: InputMaybe<Scalars['String']['input']>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureState?: InputMaybe<SourceVideoState>;
  failureStatusCode?: InputMaybe<Scalars['Int']['input']>;
};

export type SourceVideoFailureUpdateManyWithWhereNestedInput = {
  data: SourceVideoFailureUpdateManyDataInput;
  where: SourceVideoFailureScalarWhereInput;
};

export type SourceVideoFailureUpdateManyWithoutSourceVideoInput = {
  connect?: InputMaybe<Array<SourceVideoFailureWhereUniqueInput>>;
  create?: InputMaybe<Array<SourceVideoFailureCreateWithoutSourceVideoInput>>;
  delete?: InputMaybe<Array<SourceVideoFailureWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SourceVideoFailureScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SourceVideoFailureWhereUniqueInput>>;
  set?: InputMaybe<Array<SourceVideoFailureWhereUniqueInput>>;
  update?: InputMaybe<Array<SourceVideoFailureUpdateWithWhereUniqueWithoutSourceVideoInput>>;
  updateMany?: InputMaybe<Array<SourceVideoFailureUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SourceVideoFailureUpsertWithWhereUniqueWithoutSourceVideoInput>>;
};

export type SourceVideoFailureUpdateOneWithoutCurrentFailureSourceVideoInput = {
  connect?: InputMaybe<SourceVideoFailureWhereUniqueInput>;
  create?: InputMaybe<SourceVideoFailureCreateWithoutCurrentFailureSourceVideoInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SourceVideoFailureUpdateWithoutCurrentFailureSourceVideoDataInput>;
  upsert?: InputMaybe<SourceVideoFailureUpsertWithoutCurrentFailureSourceVideoInput>;
};

export type SourceVideoFailureUpdateWithWhereUniqueWithoutSourceVideoInput = {
  data: SourceVideoFailureUpdateWithoutSourceVideoDataInput;
  where: SourceVideoFailureWhereUniqueInput;
};

export type SourceVideoFailureUpdateWithoutCurrentFailureSourceVideoDataInput = {
  failureError?: InputMaybe<Scalars['String']['input']>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureState?: InputMaybe<SourceVideoState>;
  failureStatusCode?: InputMaybe<Scalars['Int']['input']>;
  sourceVideo?: InputMaybe<SourceVideoUpdateOneRequiredWithoutFailuresInput>;
};

export type SourceVideoFailureUpdateWithoutSourceVideoDataInput = {
  currentFailureSourceVideo?: InputMaybe<SourceVideoUpdateOneWithoutCurrentFailureInput>;
  failureError?: InputMaybe<Scalars['String']['input']>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureState?: InputMaybe<SourceVideoState>;
  failureStatusCode?: InputMaybe<Scalars['Int']['input']>;
};

export type SourceVideoFailureUpsertWithWhereUniqueWithoutSourceVideoInput = {
  create: SourceVideoFailureCreateWithoutSourceVideoInput;
  update: SourceVideoFailureUpdateWithoutSourceVideoDataInput;
  where: SourceVideoFailureWhereUniqueInput;
};

export type SourceVideoFailureUpsertWithoutCurrentFailureSourceVideoInput = {
  create: SourceVideoFailureCreateWithoutCurrentFailureSourceVideoInput;
  update: SourceVideoFailureUpdateWithoutCurrentFailureSourceVideoDataInput;
};

export type SourceVideoFailureWhereInput = {
  AND?: InputMaybe<Array<SourceVideoFailureWhereInput>>;
  NOT?: InputMaybe<Array<SourceVideoFailureWhereInput>>;
  OR?: InputMaybe<Array<SourceVideoFailureWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  currentFailureSourceVideo?: InputMaybe<SourceVideoWhereInput>;
  failureError?: InputMaybe<Scalars['String']['input']>;
  failureError_contains?: InputMaybe<Scalars['String']['input']>;
  failureError_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureError_gt?: InputMaybe<Scalars['String']['input']>;
  failureError_gte?: InputMaybe<Scalars['String']['input']>;
  failureError_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureError_lt?: InputMaybe<Scalars['String']['input']>;
  failureError_lte?: InputMaybe<Scalars['String']['input']>;
  failureError_not?: InputMaybe<Scalars['String']['input']>;
  failureError_not_contains?: InputMaybe<Scalars['String']['input']>;
  failureError_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureError_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureError_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureError_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureMessage_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_lt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_lte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureState?: InputMaybe<SourceVideoState>;
  failureState_in?: InputMaybe<Array<SourceVideoState>>;
  failureState_not?: InputMaybe<SourceVideoState>;
  failureState_not_in?: InputMaybe<Array<SourceVideoState>>;
  failureStatusCode?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_gt?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_gte?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  failureStatusCode_lt?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_lte?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_not?: InputMaybe<Scalars['Int']['input']>;
  failureStatusCode_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  sourceVideo?: InputMaybe<SourceVideoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type SourceVideoFailureWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum SourceVideoOrderByInput {
  ActiveStatusAsc = 'activeStatus_ASC',
  ActiveStatusDesc = 'activeStatus_DESC',
  AsrConfigAsc = 'asrConfig_ASC',
  AsrConfigDesc = 'asrConfig_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CurrentFailureStateAsc = 'currentFailureState_ASC',
  CurrentFailureStateDesc = 'currentFailureState_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PresignedS3PolicyAsc = 'presignedS3Policy_ASC',
  PresignedS3PolicyDesc = 'presignedS3Policy_DESC',
  S3rootAsc = 's3root_ASC',
  S3rootDesc = 's3root_DESC',
  SplitAudioProviderConfigAsc = 'splitAudioProviderConfig_ASC',
  SplitAudioProviderConfigDesc = 'splitAudioProviderConfig_DESC',
  SplitAudioProviderAsc = 'splitAudioProvider_ASC',
  SplitAudioProviderDesc = 'splitAudioProvider_DESC',
  StateValueAsc = 'stateValue_ASC',
  StateValueDesc = 'stateValue_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TranscriptionTypeAsc = 'transcriptionType_ASC',
  TranscriptionTypeDesc = 'transcriptionType_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoSourceAsc = 'videoSource_ASC',
  VideoSourceDesc = 'videoSource_DESC',
  YtVideoIdAsc = 'ytVideoId_ASC',
  YtVideoIdDesc = 'ytVideoId_DESC'
}

export type SourceVideoScalarWhereInput = {
  AND?: InputMaybe<Array<SourceVideoScalarWhereInput>>;
  NOT?: InputMaybe<Array<SourceVideoScalarWhereInput>>;
  OR?: InputMaybe<Array<SourceVideoScalarWhereInput>>;
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  activeStatus_in?: InputMaybe<Array<SourceVideoActiveStatus>>;
  activeStatus_not?: InputMaybe<SourceVideoActiveStatus>;
  activeStatus_not_in?: InputMaybe<Array<SourceVideoActiveStatus>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  currentFailureState_in?: InputMaybe<Array<SourceVideoState>>;
  currentFailureState_not?: InputMaybe<SourceVideoState>;
  currentFailureState_not_in?: InputMaybe<Array<SourceVideoState>>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  duration_gt?: InputMaybe<Scalars['Float']['input']>;
  duration_gte?: InputMaybe<Scalars['Float']['input']>;
  duration_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  duration_lt?: InputMaybe<Scalars['Float']['input']>;
  duration_lte?: InputMaybe<Scalars['Float']['input']>;
  duration_not?: InputMaybe<Scalars['Float']['input']>;
  duration_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  s3root_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_gt?: InputMaybe<Scalars['String']['input']>;
  s3root_gte?: InputMaybe<Scalars['String']['input']>;
  s3root_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_lt?: InputMaybe<Scalars['String']['input']>;
  s3root_lte?: InputMaybe<Scalars['String']['input']>;
  s3root_not?: InputMaybe<Scalars['String']['input']>;
  s3root_not_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  s3root_starts_with?: InputMaybe<Scalars['String']['input']>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProvider_in?: InputMaybe<Array<SplitAudioProvider>>;
  splitAudioProvider_not?: InputMaybe<SplitAudioProvider>;
  splitAudioProvider_not_in?: InputMaybe<Array<SplitAudioProvider>>;
  stateValue?: InputMaybe<SourceVideoState>;
  stateValue_in?: InputMaybe<Array<SourceVideoState>>;
  stateValue_not?: InputMaybe<SourceVideoState>;
  stateValue_not_in?: InputMaybe<Array<SourceVideoState>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_gt?: InputMaybe<Scalars['String']['input']>;
  title_gte?: InputMaybe<Scalars['String']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_lt?: InputMaybe<Scalars['String']['input']>;
  title_lte?: InputMaybe<Scalars['String']['input']>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  transcriptionType_in?: InputMaybe<Array<TranscriptionProvider>>;
  transcriptionType_not?: InputMaybe<TranscriptionProvider>;
  transcriptionType_not_in?: InputMaybe<Array<TranscriptionProvider>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  videoSource?: InputMaybe<SourceVideoSource>;
  videoSource_in?: InputMaybe<Array<SourceVideoSource>>;
  videoSource_not?: InputMaybe<SourceVideoSource>;
  videoSource_not_in?: InputMaybe<Array<SourceVideoSource>>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_contains?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_ends_with?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_gt?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_gte?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ytVideoId_lt?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_lte?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not_contains?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ytVideoId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export enum SourceVideoSource {
  ManualUpload = 'ManualUpload',
  YouTube = 'YouTube'
}

export enum SourceVideoState {
  AsRing = 'ASRing',
  AwaitingManualTranscript = 'AwaitingManualTranscript',
  AwaitingManualUpload = 'AwaitingManualUpload',
  Complete = 'Complete',
  DownloadingHighResYtVideo = 'DownloadingHighResYtVideo',
  DownloadingLowResYtVideo = 'DownloadingLowResYtVideo',
  DownsamplingVideo = 'DownsamplingVideo',
  ExtractingAudio = 'ExtractingAudio',
  Failed = 'Failed',
  GeneratingAudioVocalsAndAccompaniment = 'GeneratingAudioVocalsAndAccompaniment',
  GeneratingWaveform = 'GeneratingWaveform',
  Initial = 'Initial'
}

export type SourceVideoUpdateInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileUpdateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileUpdateOneInput>;
  audioVocalsFile?: InputMaybe<FileUpdateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileUpdateOneInput>;
  currentFailure?: InputMaybe<SourceVideoFailureUpdateOneWithoutCurrentFailureSourceVideoInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  editedAudioGroups?: InputMaybe<EditedAudioGroupUpdateManyWithoutSourceVideoInput>;
  edits?: InputMaybe<EditUpdateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileUpdateOneInput>;
  extractedAudioS3?: InputMaybe<FileUpdateOneInput>;
  failures?: InputMaybe<SourceVideoFailureUpdateManyWithoutSourceVideoInput>;
  highResVideoFile?: InputMaybe<FileUpdateOneInput>;
  notes?: InputMaybe<NoteUpdateManyWithoutSourceVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceUpdateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  sourceChannel?: InputMaybe<SourceChannelUpdateOneRequiredWithoutSourceVideosInput>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  tracks?: InputMaybe<TrackUpdateManyWithoutSourceVideoInput>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedVideos?: InputMaybe<TranslatedVideoUpdateManyWithoutSourceVideoInput>;
  uploader?: InputMaybe<UserUpdateOneInput>;
  videoFile?: InputMaybe<FileUpdateOneInput>;
  videoSource?: InputMaybe<SourceVideoSource>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoUpdateManyDataInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  title?: InputMaybe<Scalars['String']['input']>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  videoSource?: InputMaybe<SourceVideoSource>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoUpdateManyWithWhereNestedInput = {
  data: SourceVideoUpdateManyDataInput;
  where: SourceVideoScalarWhereInput;
};

export type SourceVideoUpdateManyWithoutSourceChannelInput = {
  connect?: InputMaybe<Array<SourceVideoWhereUniqueInput>>;
  create?: InputMaybe<Array<SourceVideoCreateWithoutSourceChannelInput>>;
  delete?: InputMaybe<Array<SourceVideoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SourceVideoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SourceVideoWhereUniqueInput>>;
  set?: InputMaybe<Array<SourceVideoWhereUniqueInput>>;
  update?: InputMaybe<Array<SourceVideoUpdateWithWhereUniqueWithoutSourceChannelInput>>;
  updateMany?: InputMaybe<Array<SourceVideoUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SourceVideoUpsertWithWhereUniqueWithoutSourceChannelInput>>;
};

export type SourceVideoUpdateOneRequiredWithoutEditedAudioGroupsInput = {
  connect?: InputMaybe<SourceVideoWhereUniqueInput>;
  create?: InputMaybe<SourceVideoCreateWithoutEditedAudioGroupsInput>;
  update?: InputMaybe<SourceVideoUpdateWithoutEditedAudioGroupsDataInput>;
  upsert?: InputMaybe<SourceVideoUpsertWithoutEditedAudioGroupsInput>;
};

export type SourceVideoUpdateOneRequiredWithoutFailuresInput = {
  connect?: InputMaybe<SourceVideoWhereUniqueInput>;
  create?: InputMaybe<SourceVideoCreateWithoutFailuresInput>;
  update?: InputMaybe<SourceVideoUpdateWithoutFailuresDataInput>;
  upsert?: InputMaybe<SourceVideoUpsertWithoutFailuresInput>;
};

export type SourceVideoUpdateOneRequiredWithoutTracksInput = {
  connect?: InputMaybe<SourceVideoWhereUniqueInput>;
  create?: InputMaybe<SourceVideoCreateWithoutTracksInput>;
  update?: InputMaybe<SourceVideoUpdateWithoutTracksDataInput>;
  upsert?: InputMaybe<SourceVideoUpsertWithoutTracksInput>;
};

export type SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput = {
  connect?: InputMaybe<SourceVideoWhereUniqueInput>;
  create?: InputMaybe<SourceVideoCreateWithoutTranslatedVideosInput>;
  update?: InputMaybe<SourceVideoUpdateWithoutTranslatedVideosDataInput>;
  upsert?: InputMaybe<SourceVideoUpsertWithoutTranslatedVideosInput>;
};

export type SourceVideoUpdateOneWithoutCurrentFailureInput = {
  connect?: InputMaybe<SourceVideoWhereUniqueInput>;
  create?: InputMaybe<SourceVideoCreateWithoutCurrentFailureInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SourceVideoUpdateWithoutCurrentFailureDataInput>;
  upsert?: InputMaybe<SourceVideoUpsertWithoutCurrentFailureInput>;
};

export type SourceVideoUpdateOneWithoutNotesInput = {
  connect?: InputMaybe<SourceVideoWhereUniqueInput>;
  create?: InputMaybe<SourceVideoCreateWithoutNotesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SourceVideoUpdateWithoutNotesDataInput>;
  upsert?: InputMaybe<SourceVideoUpsertWithoutNotesInput>;
};

export type SourceVideoUpdateWithWhereUniqueWithoutSourceChannelInput = {
  data: SourceVideoUpdateWithoutSourceChannelDataInput;
  where: SourceVideoWhereUniqueInput;
};

export type SourceVideoUpdateWithoutCurrentFailureDataInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileUpdateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileUpdateOneInput>;
  audioVocalsFile?: InputMaybe<FileUpdateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileUpdateOneInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  editedAudioGroups?: InputMaybe<EditedAudioGroupUpdateManyWithoutSourceVideoInput>;
  edits?: InputMaybe<EditUpdateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileUpdateOneInput>;
  extractedAudioS3?: InputMaybe<FileUpdateOneInput>;
  failures?: InputMaybe<SourceVideoFailureUpdateManyWithoutSourceVideoInput>;
  highResVideoFile?: InputMaybe<FileUpdateOneInput>;
  notes?: InputMaybe<NoteUpdateManyWithoutSourceVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceUpdateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  sourceChannel?: InputMaybe<SourceChannelUpdateOneRequiredWithoutSourceVideosInput>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  tracks?: InputMaybe<TrackUpdateManyWithoutSourceVideoInput>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedVideos?: InputMaybe<TranslatedVideoUpdateManyWithoutSourceVideoInput>;
  uploader?: InputMaybe<UserUpdateOneInput>;
  videoFile?: InputMaybe<FileUpdateOneInput>;
  videoSource?: InputMaybe<SourceVideoSource>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoUpdateWithoutEditedAudioGroupsDataInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileUpdateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileUpdateOneInput>;
  audioVocalsFile?: InputMaybe<FileUpdateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileUpdateOneInput>;
  currentFailure?: InputMaybe<SourceVideoFailureUpdateOneWithoutCurrentFailureSourceVideoInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  edits?: InputMaybe<EditUpdateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileUpdateOneInput>;
  extractedAudioS3?: InputMaybe<FileUpdateOneInput>;
  failures?: InputMaybe<SourceVideoFailureUpdateManyWithoutSourceVideoInput>;
  highResVideoFile?: InputMaybe<FileUpdateOneInput>;
  notes?: InputMaybe<NoteUpdateManyWithoutSourceVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceUpdateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  sourceChannel?: InputMaybe<SourceChannelUpdateOneRequiredWithoutSourceVideosInput>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  tracks?: InputMaybe<TrackUpdateManyWithoutSourceVideoInput>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedVideos?: InputMaybe<TranslatedVideoUpdateManyWithoutSourceVideoInput>;
  uploader?: InputMaybe<UserUpdateOneInput>;
  videoFile?: InputMaybe<FileUpdateOneInput>;
  videoSource?: InputMaybe<SourceVideoSource>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoUpdateWithoutFailuresDataInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileUpdateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileUpdateOneInput>;
  audioVocalsFile?: InputMaybe<FileUpdateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileUpdateOneInput>;
  currentFailure?: InputMaybe<SourceVideoFailureUpdateOneWithoutCurrentFailureSourceVideoInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  editedAudioGroups?: InputMaybe<EditedAudioGroupUpdateManyWithoutSourceVideoInput>;
  edits?: InputMaybe<EditUpdateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileUpdateOneInput>;
  extractedAudioS3?: InputMaybe<FileUpdateOneInput>;
  highResVideoFile?: InputMaybe<FileUpdateOneInput>;
  notes?: InputMaybe<NoteUpdateManyWithoutSourceVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceUpdateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  sourceChannel?: InputMaybe<SourceChannelUpdateOneRequiredWithoutSourceVideosInput>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  tracks?: InputMaybe<TrackUpdateManyWithoutSourceVideoInput>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedVideos?: InputMaybe<TranslatedVideoUpdateManyWithoutSourceVideoInput>;
  uploader?: InputMaybe<UserUpdateOneInput>;
  videoFile?: InputMaybe<FileUpdateOneInput>;
  videoSource?: InputMaybe<SourceVideoSource>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoUpdateWithoutNotesDataInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileUpdateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileUpdateOneInput>;
  audioVocalsFile?: InputMaybe<FileUpdateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileUpdateOneInput>;
  currentFailure?: InputMaybe<SourceVideoFailureUpdateOneWithoutCurrentFailureSourceVideoInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  editedAudioGroups?: InputMaybe<EditedAudioGroupUpdateManyWithoutSourceVideoInput>;
  edits?: InputMaybe<EditUpdateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileUpdateOneInput>;
  extractedAudioS3?: InputMaybe<FileUpdateOneInput>;
  failures?: InputMaybe<SourceVideoFailureUpdateManyWithoutSourceVideoInput>;
  highResVideoFile?: InputMaybe<FileUpdateOneInput>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceUpdateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  sourceChannel?: InputMaybe<SourceChannelUpdateOneRequiredWithoutSourceVideosInput>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  tracks?: InputMaybe<TrackUpdateManyWithoutSourceVideoInput>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedVideos?: InputMaybe<TranslatedVideoUpdateManyWithoutSourceVideoInput>;
  uploader?: InputMaybe<UserUpdateOneInput>;
  videoFile?: InputMaybe<FileUpdateOneInput>;
  videoSource?: InputMaybe<SourceVideoSource>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoUpdateWithoutSourceChannelDataInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileUpdateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileUpdateOneInput>;
  audioVocalsFile?: InputMaybe<FileUpdateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileUpdateOneInput>;
  currentFailure?: InputMaybe<SourceVideoFailureUpdateOneWithoutCurrentFailureSourceVideoInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  editedAudioGroups?: InputMaybe<EditedAudioGroupUpdateManyWithoutSourceVideoInput>;
  edits?: InputMaybe<EditUpdateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileUpdateOneInput>;
  extractedAudioS3?: InputMaybe<FileUpdateOneInput>;
  failures?: InputMaybe<SourceVideoFailureUpdateManyWithoutSourceVideoInput>;
  highResVideoFile?: InputMaybe<FileUpdateOneInput>;
  notes?: InputMaybe<NoteUpdateManyWithoutSourceVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceUpdateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  tracks?: InputMaybe<TrackUpdateManyWithoutSourceVideoInput>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedVideos?: InputMaybe<TranslatedVideoUpdateManyWithoutSourceVideoInput>;
  uploader?: InputMaybe<UserUpdateOneInput>;
  videoFile?: InputMaybe<FileUpdateOneInput>;
  videoSource?: InputMaybe<SourceVideoSource>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoUpdateWithoutTracksDataInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileUpdateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileUpdateOneInput>;
  audioVocalsFile?: InputMaybe<FileUpdateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileUpdateOneInput>;
  currentFailure?: InputMaybe<SourceVideoFailureUpdateOneWithoutCurrentFailureSourceVideoInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  editedAudioGroups?: InputMaybe<EditedAudioGroupUpdateManyWithoutSourceVideoInput>;
  edits?: InputMaybe<EditUpdateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileUpdateOneInput>;
  extractedAudioS3?: InputMaybe<FileUpdateOneInput>;
  failures?: InputMaybe<SourceVideoFailureUpdateManyWithoutSourceVideoInput>;
  highResVideoFile?: InputMaybe<FileUpdateOneInput>;
  notes?: InputMaybe<NoteUpdateManyWithoutSourceVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceUpdateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  sourceChannel?: InputMaybe<SourceChannelUpdateOneRequiredWithoutSourceVideosInput>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  translatedVideos?: InputMaybe<TranslatedVideoUpdateManyWithoutSourceVideoInput>;
  uploader?: InputMaybe<UserUpdateOneInput>;
  videoFile?: InputMaybe<FileUpdateOneInput>;
  videoSource?: InputMaybe<SourceVideoSource>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoUpdateWithoutTranslatedVideosDataInput = {
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  asrConfig?: InputMaybe<Scalars['Json']['input']>;
  asrSegmentsFile?: InputMaybe<FileUpdateOneInput>;
  audioAccompanimentFile?: InputMaybe<FileUpdateOneInput>;
  audioVocalsFile?: InputMaybe<FileUpdateOneInput>;
  audioWaveformJSONFile?: InputMaybe<FileUpdateOneInput>;
  currentFailure?: InputMaybe<SourceVideoFailureUpdateOneWithoutCurrentFailureSourceVideoInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  editedAudioGroups?: InputMaybe<EditedAudioGroupUpdateManyWithoutSourceVideoInput>;
  edits?: InputMaybe<EditUpdateManyWithoutSourceVideoInput>;
  extractedAudioGCP?: InputMaybe<FileUpdateOneInput>;
  extractedAudioS3?: InputMaybe<FileUpdateOneInput>;
  failures?: InputMaybe<SourceVideoFailureUpdateManyWithoutSourceVideoInput>;
  highResVideoFile?: InputMaybe<FileUpdateOneInput>;
  notes?: InputMaybe<NoteUpdateManyWithoutSourceVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextSourceUpdateManyWithoutSourceVideoInput>;
  presignedS3Policy?: InputMaybe<Scalars['Json']['input']>;
  rawAsrFile?: InputMaybe<FileUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  sourceChannel?: InputMaybe<SourceChannelUpdateOneRequiredWithoutSourceVideosInput>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProviderConfig?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<SourceVideoState>;
  thumbnailFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  tracks?: InputMaybe<TrackUpdateManyWithoutSourceVideoInput>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  uploader?: InputMaybe<UserUpdateOneInput>;
  videoFile?: InputMaybe<FileUpdateOneInput>;
  videoSource?: InputMaybe<SourceVideoSource>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoUpsertWithWhereUniqueWithoutSourceChannelInput = {
  create: SourceVideoCreateWithoutSourceChannelInput;
  update: SourceVideoUpdateWithoutSourceChannelDataInput;
  where: SourceVideoWhereUniqueInput;
};

export type SourceVideoUpsertWithoutCurrentFailureInput = {
  create: SourceVideoCreateWithoutCurrentFailureInput;
  update: SourceVideoUpdateWithoutCurrentFailureDataInput;
};

export type SourceVideoUpsertWithoutEditedAudioGroupsInput = {
  create: SourceVideoCreateWithoutEditedAudioGroupsInput;
  update: SourceVideoUpdateWithoutEditedAudioGroupsDataInput;
};

export type SourceVideoUpsertWithoutFailuresInput = {
  create: SourceVideoCreateWithoutFailuresInput;
  update: SourceVideoUpdateWithoutFailuresDataInput;
};

export type SourceVideoUpsertWithoutNotesInput = {
  create: SourceVideoCreateWithoutNotesInput;
  update: SourceVideoUpdateWithoutNotesDataInput;
};

export type SourceVideoUpsertWithoutTracksInput = {
  create: SourceVideoCreateWithoutTracksInput;
  update: SourceVideoUpdateWithoutTracksDataInput;
};

export type SourceVideoUpsertWithoutTranslatedVideosInput = {
  create: SourceVideoCreateWithoutTranslatedVideosInput;
  update: SourceVideoUpdateWithoutTranslatedVideosDataInput;
};

export type SourceVideoWhereInput = {
  AND?: InputMaybe<Array<SourceVideoWhereInput>>;
  NOT?: InputMaybe<Array<SourceVideoWhereInput>>;
  OR?: InputMaybe<Array<SourceVideoWhereInput>>;
  activeStatus?: InputMaybe<SourceVideoActiveStatus>;
  activeStatus_in?: InputMaybe<Array<SourceVideoActiveStatus>>;
  activeStatus_not?: InputMaybe<SourceVideoActiveStatus>;
  activeStatus_not_in?: InputMaybe<Array<SourceVideoActiveStatus>>;
  asrSegmentsFile?: InputMaybe<FileWhereInput>;
  audioAccompanimentFile?: InputMaybe<FileWhereInput>;
  audioVocalsFile?: InputMaybe<FileWhereInput>;
  audioWaveformJSONFile?: InputMaybe<FileWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  currentFailure?: InputMaybe<SourceVideoFailureWhereInput>;
  currentFailureState?: InputMaybe<SourceVideoState>;
  currentFailureState_in?: InputMaybe<Array<SourceVideoState>>;
  currentFailureState_not?: InputMaybe<SourceVideoState>;
  currentFailureState_not_in?: InputMaybe<Array<SourceVideoState>>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  duration_gt?: InputMaybe<Scalars['Float']['input']>;
  duration_gte?: InputMaybe<Scalars['Float']['input']>;
  duration_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  duration_lt?: InputMaybe<Scalars['Float']['input']>;
  duration_lte?: InputMaybe<Scalars['Float']['input']>;
  duration_not?: InputMaybe<Scalars['Float']['input']>;
  duration_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  editedAudioGroups_every?: InputMaybe<EditedAudioGroupWhereInput>;
  editedAudioGroups_none?: InputMaybe<EditedAudioGroupWhereInput>;
  editedAudioGroups_some?: InputMaybe<EditedAudioGroupWhereInput>;
  edits_every?: InputMaybe<EditWhereInput>;
  edits_none?: InputMaybe<EditWhereInput>;
  edits_some?: InputMaybe<EditWhereInput>;
  extractedAudioGCP?: InputMaybe<FileWhereInput>;
  extractedAudioS3?: InputMaybe<FileWhereInput>;
  failures_every?: InputMaybe<SourceVideoFailureWhereInput>;
  failures_none?: InputMaybe<SourceVideoFailureWhereInput>;
  failures_some?: InputMaybe<SourceVideoFailureWhereInput>;
  highResVideoFile?: InputMaybe<FileWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  notes_every?: InputMaybe<NoteWhereInput>;
  notes_none?: InputMaybe<NoteWhereInput>;
  notes_some?: InputMaybe<NoteWhereInput>;
  onScreenTexts_every?: InputMaybe<OnScreenTextSourceWhereInput>;
  onScreenTexts_none?: InputMaybe<OnScreenTextSourceWhereInput>;
  onScreenTexts_some?: InputMaybe<OnScreenTextSourceWhereInput>;
  rawAsrFile?: InputMaybe<FileWhereInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  s3root_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_gt?: InputMaybe<Scalars['String']['input']>;
  s3root_gte?: InputMaybe<Scalars['String']['input']>;
  s3root_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_lt?: InputMaybe<Scalars['String']['input']>;
  s3root_lte?: InputMaybe<Scalars['String']['input']>;
  s3root_not?: InputMaybe<Scalars['String']['input']>;
  s3root_not_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  s3root_starts_with?: InputMaybe<Scalars['String']['input']>;
  sourceChannel?: InputMaybe<SourceChannelWhereInput>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProvider_in?: InputMaybe<Array<SplitAudioProvider>>;
  splitAudioProvider_not?: InputMaybe<SplitAudioProvider>;
  splitAudioProvider_not_in?: InputMaybe<Array<SplitAudioProvider>>;
  stateValue?: InputMaybe<SourceVideoState>;
  stateValue_in?: InputMaybe<Array<SourceVideoState>>;
  stateValue_not?: InputMaybe<SourceVideoState>;
  stateValue_not_in?: InputMaybe<Array<SourceVideoState>>;
  thumbnailFile?: InputMaybe<FileWhereInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_gt?: InputMaybe<Scalars['String']['input']>;
  title_gte?: InputMaybe<Scalars['String']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_lt?: InputMaybe<Scalars['String']['input']>;
  title_lte?: InputMaybe<Scalars['String']['input']>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  tracks_every?: InputMaybe<TrackWhereInput>;
  tracks_none?: InputMaybe<TrackWhereInput>;
  tracks_some?: InputMaybe<TrackWhereInput>;
  transcriptionType?: InputMaybe<TranscriptionProvider>;
  transcriptionType_in?: InputMaybe<Array<TranscriptionProvider>>;
  transcriptionType_not?: InputMaybe<TranscriptionProvider>;
  transcriptionType_not_in?: InputMaybe<Array<TranscriptionProvider>>;
  translatedVideos_every?: InputMaybe<TranslatedVideoWhereInput>;
  translatedVideos_none?: InputMaybe<TranslatedVideoWhereInput>;
  translatedVideos_some?: InputMaybe<TranslatedVideoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  uploader?: InputMaybe<UserWhereInput>;
  videoFile?: InputMaybe<FileWhereInput>;
  videoSource?: InputMaybe<SourceVideoSource>;
  videoSource_in?: InputMaybe<Array<SourceVideoSource>>;
  videoSource_not?: InputMaybe<SourceVideoSource>;
  videoSource_not_in?: InputMaybe<Array<SourceVideoSource>>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_contains?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_ends_with?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_gt?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_gte?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ytVideoId_lt?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_lte?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not_contains?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ytVideoId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type SourceVideoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type Speaker = {
  __typename?: 'Speaker';
  aliases?: Maybe<Array<Alias>>;
  channel?: Maybe<TranslatedChannel>;
  createdAt: Scalars['DateTime']['output'];
  defaultStretchRate: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isMainCharacter: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  style: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  voice: Voice;
  voiceActor?: Maybe<User>;
};


export type SpeakerAliasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AliasOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AliasWhereInput>;
};

export type SpeakerCreateInput = {
  aliases?: InputMaybe<AliasCreateManyWithoutSpeakerInput>;
  channel?: InputMaybe<TranslatedChannelCreateOneWithoutSpeakersInput>;
  defaultStretchRate?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isMainCharacter?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  voice: VoiceCreateOneInput;
  voiceActor?: InputMaybe<UserCreateOneInput>;
};

export type SpeakerCreateManyInput = {
  connect?: InputMaybe<Array<SpeakerWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeakerCreateInput>>;
};

export type SpeakerCreateManyWithoutChannelInput = {
  connect?: InputMaybe<Array<SpeakerWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeakerCreateWithoutChannelInput>>;
};

export type SpeakerCreateOneInput = {
  connect?: InputMaybe<SpeakerWhereUniqueInput>;
  create?: InputMaybe<SpeakerCreateInput>;
};

export type SpeakerCreateWithoutChannelInput = {
  aliases?: InputMaybe<AliasCreateManyWithoutSpeakerInput>;
  defaultStretchRate?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isMainCharacter?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  voice: VoiceCreateOneInput;
  voiceActor?: InputMaybe<UserCreateOneInput>;
};

export enum SpeakerOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DefaultStretchRateAsc = 'defaultStretchRate_ASC',
  DefaultStretchRateDesc = 'defaultStretchRate_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsMainCharacterAsc = 'isMainCharacter_ASC',
  IsMainCharacterDesc = 'isMainCharacter_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  StyleAsc = 'style_ASC',
  StyleDesc = 'style_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type SpeakerScalarWhereInput = {
  AND?: InputMaybe<Array<SpeakerScalarWhereInput>>;
  NOT?: InputMaybe<Array<SpeakerScalarWhereInput>>;
  OR?: InputMaybe<Array<SpeakerScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  defaultStretchRate?: InputMaybe<Scalars['Float']['input']>;
  defaultStretchRate_gt?: InputMaybe<Scalars['Float']['input']>;
  defaultStretchRate_gte?: InputMaybe<Scalars['Float']['input']>;
  defaultStretchRate_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  defaultStretchRate_lt?: InputMaybe<Scalars['Float']['input']>;
  defaultStretchRate_lte?: InputMaybe<Scalars['Float']['input']>;
  defaultStretchRate_not?: InputMaybe<Scalars['Float']['input']>;
  defaultStretchRate_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isMainCharacter?: InputMaybe<Scalars['Boolean']['input']>;
  isMainCharacter_not?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  style_contains?: InputMaybe<Scalars['String']['input']>;
  style_ends_with?: InputMaybe<Scalars['String']['input']>;
  style_gt?: InputMaybe<Scalars['String']['input']>;
  style_gte?: InputMaybe<Scalars['String']['input']>;
  style_in?: InputMaybe<Array<Scalars['String']['input']>>;
  style_lt?: InputMaybe<Scalars['String']['input']>;
  style_lte?: InputMaybe<Scalars['String']['input']>;
  style_not?: InputMaybe<Scalars['String']['input']>;
  style_not_contains?: InputMaybe<Scalars['String']['input']>;
  style_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  style_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  style_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  style_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type SpeakerUpdateDataInput = {
  aliases?: InputMaybe<AliasUpdateManyWithoutSpeakerInput>;
  channel?: InputMaybe<TranslatedChannelUpdateOneWithoutSpeakersInput>;
  defaultStretchRate?: InputMaybe<Scalars['Float']['input']>;
  isMainCharacter?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  voice?: InputMaybe<VoiceUpdateOneRequiredInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
};

export type SpeakerUpdateInput = {
  aliases?: InputMaybe<AliasUpdateManyWithoutSpeakerInput>;
  channel?: InputMaybe<TranslatedChannelUpdateOneWithoutSpeakersInput>;
  defaultStretchRate?: InputMaybe<Scalars['Float']['input']>;
  isMainCharacter?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  voice?: InputMaybe<VoiceUpdateOneRequiredInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
};

export type SpeakerUpdateManyDataInput = {
  defaultStretchRate?: InputMaybe<Scalars['Float']['input']>;
  isMainCharacter?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
};

export type SpeakerUpdateManyInput = {
  connect?: InputMaybe<Array<SpeakerWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeakerCreateInput>>;
  delete?: InputMaybe<Array<SpeakerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpeakerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpeakerWhereUniqueInput>>;
  set?: InputMaybe<Array<SpeakerWhereUniqueInput>>;
  update?: InputMaybe<Array<SpeakerUpdateWithWhereUniqueNestedInput>>;
  updateMany?: InputMaybe<Array<SpeakerUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SpeakerUpsertWithWhereUniqueNestedInput>>;
};

export type SpeakerUpdateManyWithWhereNestedInput = {
  data: SpeakerUpdateManyDataInput;
  where: SpeakerScalarWhereInput;
};

export type SpeakerUpdateManyWithoutChannelInput = {
  connect?: InputMaybe<Array<SpeakerWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeakerCreateWithoutChannelInput>>;
  delete?: InputMaybe<Array<SpeakerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpeakerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpeakerWhereUniqueInput>>;
  set?: InputMaybe<Array<SpeakerWhereUniqueInput>>;
  update?: InputMaybe<Array<SpeakerUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: InputMaybe<Array<SpeakerUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SpeakerUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type SpeakerUpdateOneInput = {
  connect?: InputMaybe<SpeakerWhereUniqueInput>;
  create?: InputMaybe<SpeakerCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SpeakerUpdateDataInput>;
  upsert?: InputMaybe<SpeakerUpsertNestedInput>;
};

export enum SpeakerUpdateRetroactivity {
  FullyRetroactive = 'FullyRetroactive',
  NotRetroactive = 'NotRetroactive'
}

export type SpeakerUpdateWithWhereUniqueNestedInput = {
  data: SpeakerUpdateDataInput;
  where: SpeakerWhereUniqueInput;
};

export type SpeakerUpdateWithWhereUniqueWithoutChannelInput = {
  data: SpeakerUpdateWithoutChannelDataInput;
  where: SpeakerWhereUniqueInput;
};

export type SpeakerUpdateWithoutChannelDataInput = {
  aliases?: InputMaybe<AliasUpdateManyWithoutSpeakerInput>;
  defaultStretchRate?: InputMaybe<Scalars['Float']['input']>;
  isMainCharacter?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  voice?: InputMaybe<VoiceUpdateOneRequiredInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
};

export type SpeakerUpsertNestedInput = {
  create: SpeakerCreateInput;
  update: SpeakerUpdateDataInput;
};

export type SpeakerUpsertWithWhereUniqueNestedInput = {
  create: SpeakerCreateInput;
  update: SpeakerUpdateDataInput;
  where: SpeakerWhereUniqueInput;
};

export type SpeakerUpsertWithWhereUniqueWithoutChannelInput = {
  create: SpeakerCreateWithoutChannelInput;
  update: SpeakerUpdateWithoutChannelDataInput;
  where: SpeakerWhereUniqueInput;
};

export type SpeakerWhereInput = {
  AND?: InputMaybe<Array<SpeakerWhereInput>>;
  NOT?: InputMaybe<Array<SpeakerWhereInput>>;
  OR?: InputMaybe<Array<SpeakerWhereInput>>;
  aliases_every?: InputMaybe<AliasWhereInput>;
  aliases_none?: InputMaybe<AliasWhereInput>;
  aliases_some?: InputMaybe<AliasWhereInput>;
  channel?: InputMaybe<TranslatedChannelWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  defaultStretchRate?: InputMaybe<Scalars['Float']['input']>;
  defaultStretchRate_gt?: InputMaybe<Scalars['Float']['input']>;
  defaultStretchRate_gte?: InputMaybe<Scalars['Float']['input']>;
  defaultStretchRate_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  defaultStretchRate_lt?: InputMaybe<Scalars['Float']['input']>;
  defaultStretchRate_lte?: InputMaybe<Scalars['Float']['input']>;
  defaultStretchRate_not?: InputMaybe<Scalars['Float']['input']>;
  defaultStretchRate_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isMainCharacter?: InputMaybe<Scalars['Boolean']['input']>;
  isMainCharacter_not?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  style_contains?: InputMaybe<Scalars['String']['input']>;
  style_ends_with?: InputMaybe<Scalars['String']['input']>;
  style_gt?: InputMaybe<Scalars['String']['input']>;
  style_gte?: InputMaybe<Scalars['String']['input']>;
  style_in?: InputMaybe<Array<Scalars['String']['input']>>;
  style_lt?: InputMaybe<Scalars['String']['input']>;
  style_lte?: InputMaybe<Scalars['String']['input']>;
  style_not?: InputMaybe<Scalars['String']['input']>;
  style_not_contains?: InputMaybe<Scalars['String']['input']>;
  style_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  style_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  style_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  style_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  voice?: InputMaybe<VoiceWhereInput>;
  voiceActor?: InputMaybe<UserWhereInput>;
};

export type SpeakerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SpeechInputData = {
  __typename?: 'SpeechInputData';
  /** Corrected Text with all the flags removed */
  correctedTextWithNoMarkup: Scalars['String']['output'];
  correctionIdsToFinalDraftId: Scalars['Json']['output'];
  correctionsHash: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  featuresVersion?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  languageCode: Scalars['String']['output'];
  markedupText: Scalars['String']['output'];
  mimicAudioFile?: Maybe<File>;
  mlConfigName?: Maybe<Scalars['String']['output']>;
  prosodyTemplateId?: Maybe<Scalars['String']['output']>;
  speakerCode: Scalars['String']['output'];
  speakerOverride?: Maybe<Speaker>;
  stretchRate: Scalars['Float']['output'];
  styleCode: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vocoderType: VocoderType;
  voiceConversionType?: Maybe<VoiceConversionTypeEnum>;
};

export type SpeechInputDataCreateInput = {
  correctionIdsToFinalDraftId: Scalars['Json']['input'];
  correctionsHash: Scalars['String']['input'];
  featuresVersion?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  languageCode: Scalars['String']['input'];
  markedupText: Scalars['String']['input'];
  mimicAudioFile?: InputMaybe<FileCreateOneInput>;
  mlConfigName?: InputMaybe<Scalars['String']['input']>;
  prosodyTemplateId?: InputMaybe<Scalars['String']['input']>;
  speakerCode: Scalars['String']['input'];
  speakerOverride?: InputMaybe<SpeakerCreateOneInput>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  styleCode: Scalars['String']['input'];
  vocoderType: VocoderType;
  voiceConversionType?: InputMaybe<VoiceConversionTypeEnum>;
};

export type SpeechInputDataCreateOneInput = {
  connect?: InputMaybe<SpeechInputDataWhereUniqueInput>;
  create?: InputMaybe<SpeechInputDataCreateInput>;
};

export type SpeechInputDataUpdateDataInput = {
  correctionIdsToFinalDraftId?: InputMaybe<Scalars['Json']['input']>;
  correctionsHash?: InputMaybe<Scalars['String']['input']>;
  featuresVersion?: InputMaybe<Scalars['String']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  markedupText?: InputMaybe<Scalars['String']['input']>;
  mimicAudioFile?: InputMaybe<FileUpdateOneInput>;
  mlConfigName?: InputMaybe<Scalars['String']['input']>;
  prosodyTemplateId?: InputMaybe<Scalars['String']['input']>;
  speakerCode?: InputMaybe<Scalars['String']['input']>;
  speakerOverride?: InputMaybe<SpeakerUpdateOneInput>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  styleCode?: InputMaybe<Scalars['String']['input']>;
  vocoderType?: InputMaybe<VocoderType>;
  voiceConversionType?: InputMaybe<VoiceConversionTypeEnum>;
};

export type SpeechInputDataUpdateOneInput = {
  connect?: InputMaybe<SpeechInputDataWhereUniqueInput>;
  create?: InputMaybe<SpeechInputDataCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SpeechInputDataUpdateDataInput>;
  upsert?: InputMaybe<SpeechInputDataUpsertNestedInput>;
};

export type SpeechInputDataUpdateOneRequiredInput = {
  connect?: InputMaybe<SpeechInputDataWhereUniqueInput>;
  create?: InputMaybe<SpeechInputDataCreateInput>;
  update?: InputMaybe<SpeechInputDataUpdateDataInput>;
  upsert?: InputMaybe<SpeechInputDataUpsertNestedInput>;
};

export type SpeechInputDataUpsertNestedInput = {
  create: SpeechInputDataCreateInput;
  update: SpeechInputDataUpdateDataInput;
};

export type SpeechInputDataWhereInput = {
  AND?: InputMaybe<Array<SpeechInputDataWhereInput>>;
  NOT?: InputMaybe<Array<SpeechInputDataWhereInput>>;
  OR?: InputMaybe<Array<SpeechInputDataWhereInput>>;
  correctionsHash?: InputMaybe<Scalars['String']['input']>;
  correctionsHash_contains?: InputMaybe<Scalars['String']['input']>;
  correctionsHash_ends_with?: InputMaybe<Scalars['String']['input']>;
  correctionsHash_gt?: InputMaybe<Scalars['String']['input']>;
  correctionsHash_gte?: InputMaybe<Scalars['String']['input']>;
  correctionsHash_in?: InputMaybe<Array<Scalars['String']['input']>>;
  correctionsHash_lt?: InputMaybe<Scalars['String']['input']>;
  correctionsHash_lte?: InputMaybe<Scalars['String']['input']>;
  correctionsHash_not?: InputMaybe<Scalars['String']['input']>;
  correctionsHash_not_contains?: InputMaybe<Scalars['String']['input']>;
  correctionsHash_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  correctionsHash_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  correctionsHash_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  correctionsHash_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  featuresVersion?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_contains?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_gt?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_gte?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  featuresVersion_lt?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_lte?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_not?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  featuresVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  featuresVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  languageCode_contains?: InputMaybe<Scalars['String']['input']>;
  languageCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  languageCode_gt?: InputMaybe<Scalars['String']['input']>;
  languageCode_gte?: InputMaybe<Scalars['String']['input']>;
  languageCode_in?: InputMaybe<Array<Scalars['String']['input']>>;
  languageCode_lt?: InputMaybe<Scalars['String']['input']>;
  languageCode_lte?: InputMaybe<Scalars['String']['input']>;
  languageCode_not?: InputMaybe<Scalars['String']['input']>;
  languageCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  languageCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  languageCode_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  languageCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  languageCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  markedupText?: InputMaybe<Scalars['String']['input']>;
  markedupText_contains?: InputMaybe<Scalars['String']['input']>;
  markedupText_ends_with?: InputMaybe<Scalars['String']['input']>;
  markedupText_gt?: InputMaybe<Scalars['String']['input']>;
  markedupText_gte?: InputMaybe<Scalars['String']['input']>;
  markedupText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  markedupText_lt?: InputMaybe<Scalars['String']['input']>;
  markedupText_lte?: InputMaybe<Scalars['String']['input']>;
  markedupText_not?: InputMaybe<Scalars['String']['input']>;
  markedupText_not_contains?: InputMaybe<Scalars['String']['input']>;
  markedupText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  markedupText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  markedupText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  markedupText_starts_with?: InputMaybe<Scalars['String']['input']>;
  mimicAudioFile?: InputMaybe<FileWhereInput>;
  mlConfigName?: InputMaybe<Scalars['String']['input']>;
  mlConfigName_contains?: InputMaybe<Scalars['String']['input']>;
  mlConfigName_ends_with?: InputMaybe<Scalars['String']['input']>;
  mlConfigName_gt?: InputMaybe<Scalars['String']['input']>;
  mlConfigName_gte?: InputMaybe<Scalars['String']['input']>;
  mlConfigName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  mlConfigName_lt?: InputMaybe<Scalars['String']['input']>;
  mlConfigName_lte?: InputMaybe<Scalars['String']['input']>;
  mlConfigName_not?: InputMaybe<Scalars['String']['input']>;
  mlConfigName_not_contains?: InputMaybe<Scalars['String']['input']>;
  mlConfigName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  mlConfigName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  mlConfigName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  mlConfigName_starts_with?: InputMaybe<Scalars['String']['input']>;
  prosodyTemplateId?: InputMaybe<Scalars['String']['input']>;
  prosodyTemplateId_contains?: InputMaybe<Scalars['String']['input']>;
  prosodyTemplateId_ends_with?: InputMaybe<Scalars['String']['input']>;
  prosodyTemplateId_gt?: InputMaybe<Scalars['String']['input']>;
  prosodyTemplateId_gte?: InputMaybe<Scalars['String']['input']>;
  prosodyTemplateId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  prosodyTemplateId_lt?: InputMaybe<Scalars['String']['input']>;
  prosodyTemplateId_lte?: InputMaybe<Scalars['String']['input']>;
  prosodyTemplateId_not?: InputMaybe<Scalars['String']['input']>;
  prosodyTemplateId_not_contains?: InputMaybe<Scalars['String']['input']>;
  prosodyTemplateId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  prosodyTemplateId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  prosodyTemplateId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  prosodyTemplateId_starts_with?: InputMaybe<Scalars['String']['input']>;
  speakerCode?: InputMaybe<Scalars['String']['input']>;
  speakerCode_contains?: InputMaybe<Scalars['String']['input']>;
  speakerCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  speakerCode_gt?: InputMaybe<Scalars['String']['input']>;
  speakerCode_gte?: InputMaybe<Scalars['String']['input']>;
  speakerCode_in?: InputMaybe<Array<Scalars['String']['input']>>;
  speakerCode_lt?: InputMaybe<Scalars['String']['input']>;
  speakerCode_lte?: InputMaybe<Scalars['String']['input']>;
  speakerCode_not?: InputMaybe<Scalars['String']['input']>;
  speakerCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  speakerCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  speakerCode_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  speakerCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  speakerCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  speakerOverride?: InputMaybe<SpeakerWhereInput>;
  stretchRate?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_gte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stretchRate_lt?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_lte?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not?: InputMaybe<Scalars['Float']['input']>;
  stretchRate_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  styleCode?: InputMaybe<Scalars['String']['input']>;
  styleCode_contains?: InputMaybe<Scalars['String']['input']>;
  styleCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  styleCode_gt?: InputMaybe<Scalars['String']['input']>;
  styleCode_gte?: InputMaybe<Scalars['String']['input']>;
  styleCode_in?: InputMaybe<Array<Scalars['String']['input']>>;
  styleCode_lt?: InputMaybe<Scalars['String']['input']>;
  styleCode_lte?: InputMaybe<Scalars['String']['input']>;
  styleCode_not?: InputMaybe<Scalars['String']['input']>;
  styleCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  styleCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  styleCode_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  styleCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  styleCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  vocoderType?: InputMaybe<VocoderType>;
  vocoderType_in?: InputMaybe<Array<VocoderType>>;
  vocoderType_not?: InputMaybe<VocoderType>;
  vocoderType_not_in?: InputMaybe<Array<VocoderType>>;
  voiceConversionType?: InputMaybe<VoiceConversionTypeEnum>;
  voiceConversionType_in?: InputMaybe<Array<VoiceConversionTypeEnum>>;
  voiceConversionType_not?: InputMaybe<VoiceConversionTypeEnum>;
  voiceConversionType_not_in?: InputMaybe<Array<VoiceConversionTypeEnum>>;
};

export type SpeechInputDataWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SpeechOutput = {
  __typename?: 'SpeechOutput';
  audioFile?: Maybe<File>;
  audioFileSourceEnum?: Maybe<AudioFileSource>;
  correctionDraft?: Maybe<CorrectionDraft>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  effectUnit?: Maybe<EffectUnit>;
  forModelTypeEnum?: Maybe<SpeechOutputForModelTypeEnum>;
  id: Scalars['ID']['output'];
  isAutoGenerated: Scalars['Boolean']['output'];
  isBulkUpdate: Scalars['Boolean']['output'];
  isCorrectionApplied: Scalars['Boolean']['output'];
  isReady: Scalars['Boolean']['output'];
  outputGroup?: Maybe<OutputGroup>;
  outputGroups?: Maybe<Array<OutputGroup>>;
  processingDurationMs?: Maybe<Scalars['Float']['output']>;
  processingEndTime?: Maybe<Scalars['DateTime']['output']>;
  processingStartTime?: Maybe<Scalars['DateTime']['output']>;
  segment?: Maybe<Segment>;
  selectedAt?: Maybe<Scalars['DateTime']['output']>;
  speechInputData: SpeechInputData;
  speechOutputFeedbacks?: Maybe<Array<SpeechOutputFeedback>>;
  /** Current Status of the generation of the SpeechOutput */
  status: SpeechOutputStatus;
  thirdPartyTtsData?: Maybe<ThirdPartyTtsData>;
  transcriptionFile?: Maybe<File>;
  updatedAt: Scalars['DateTime']['output'];
  vocoderOutput?: Maybe<VocoderOutput>;
  voice?: Maybe<Voice>;
  wasOutputFoundInCache: Scalars['Boolean']['output'];
};


export type SpeechOutputOutputGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OutputGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutputGroupWhereInput>;
};


export type SpeechOutputSpeechOutputFeedbacksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpeechOutputFeedbackOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpeechOutputFeedbackWhereInput>;
};

export type SpeechOutputCollection = {
  __typename?: 'SpeechOutputCollection';
  collectionType: SpeechOutputCollectionType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  segment: Segment;
  speechOutputs?: Maybe<Array<SpeechOutput>>;
  updatedAt: Scalars['DateTime']['output'];
};


export type SpeechOutputCollectionSpeechOutputsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpeechOutputOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpeechOutputWhereInput>;
};

export type SpeechOutputCollectionCreateInput = {
  collectionType: SpeechOutputCollectionType;
  id?: InputMaybe<Scalars['ID']['input']>;
  segment: SegmentCreateOneWithoutSpeechOutputCollectionsInput;
  speechOutputs?: InputMaybe<SpeechOutputCreateManyInput>;
};

export type SpeechOutputCollectionCreateManyWithoutSegmentInput = {
  connect?: InputMaybe<Array<SpeechOutputCollectionWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCollectionCreateWithoutSegmentInput>>;
};

export type SpeechOutputCollectionCreateOneInput = {
  connect?: InputMaybe<SpeechOutputCollectionWhereUniqueInput>;
  create?: InputMaybe<SpeechOutputCollectionCreateInput>;
};

export type SpeechOutputCollectionCreateWithoutSegmentInput = {
  collectionType: SpeechOutputCollectionType;
  id?: InputMaybe<Scalars['ID']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputCreateManyInput>;
};

export enum SpeechOutputCollectionOrderByInput {
  CollectionTypeAsc = 'collectionType_ASC',
  CollectionTypeDesc = 'collectionType_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type SpeechOutputCollectionScalarWhereInput = {
  AND?: InputMaybe<Array<SpeechOutputCollectionScalarWhereInput>>;
  NOT?: InputMaybe<Array<SpeechOutputCollectionScalarWhereInput>>;
  OR?: InputMaybe<Array<SpeechOutputCollectionScalarWhereInput>>;
  collectionType?: InputMaybe<SpeechOutputCollectionType>;
  collectionType_in?: InputMaybe<Array<SpeechOutputCollectionType>>;
  collectionType_not?: InputMaybe<SpeechOutputCollectionType>;
  collectionType_not_in?: InputMaybe<Array<SpeechOutputCollectionType>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export enum SpeechOutputCollectionType {
  MultipleRenditions = 'MultipleRenditions',
  ProsodyBuffet = 'ProsodyBuffet'
}

export type SpeechOutputCollectionUpdateDataInput = {
  collectionType?: InputMaybe<SpeechOutputCollectionType>;
  segment?: InputMaybe<SegmentUpdateOneRequiredWithoutSpeechOutputCollectionsInput>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyInput>;
};

export type SpeechOutputCollectionUpdateManyDataInput = {
  collectionType?: InputMaybe<SpeechOutputCollectionType>;
};

export type SpeechOutputCollectionUpdateManyWithWhereNestedInput = {
  data: SpeechOutputCollectionUpdateManyDataInput;
  where: SpeechOutputCollectionScalarWhereInput;
};

export type SpeechOutputCollectionUpdateManyWithoutSegmentInput = {
  connect?: InputMaybe<Array<SpeechOutputCollectionWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCollectionCreateWithoutSegmentInput>>;
  delete?: InputMaybe<Array<SpeechOutputCollectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpeechOutputCollectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpeechOutputCollectionWhereUniqueInput>>;
  set?: InputMaybe<Array<SpeechOutputCollectionWhereUniqueInput>>;
  update?: InputMaybe<Array<SpeechOutputCollectionUpdateWithWhereUniqueWithoutSegmentInput>>;
  updateMany?: InputMaybe<Array<SpeechOutputCollectionUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SpeechOutputCollectionUpsertWithWhereUniqueWithoutSegmentInput>>;
};

export type SpeechOutputCollectionUpdateOneInput = {
  connect?: InputMaybe<SpeechOutputCollectionWhereUniqueInput>;
  create?: InputMaybe<SpeechOutputCollectionCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SpeechOutputCollectionUpdateDataInput>;
  upsert?: InputMaybe<SpeechOutputCollectionUpsertNestedInput>;
};

export type SpeechOutputCollectionUpdateWithWhereUniqueWithoutSegmentInput = {
  data: SpeechOutputCollectionUpdateWithoutSegmentDataInput;
  where: SpeechOutputCollectionWhereUniqueInput;
};

export type SpeechOutputCollectionUpdateWithoutSegmentDataInput = {
  collectionType?: InputMaybe<SpeechOutputCollectionType>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyInput>;
};

export type SpeechOutputCollectionUpsertNestedInput = {
  create: SpeechOutputCollectionCreateInput;
  update: SpeechOutputCollectionUpdateDataInput;
};

export type SpeechOutputCollectionUpsertWithWhereUniqueWithoutSegmentInput = {
  create: SpeechOutputCollectionCreateWithoutSegmentInput;
  update: SpeechOutputCollectionUpdateWithoutSegmentDataInput;
  where: SpeechOutputCollectionWhereUniqueInput;
};

export type SpeechOutputCollectionWhereInput = {
  AND?: InputMaybe<Array<SpeechOutputCollectionWhereInput>>;
  NOT?: InputMaybe<Array<SpeechOutputCollectionWhereInput>>;
  OR?: InputMaybe<Array<SpeechOutputCollectionWhereInput>>;
  collectionType?: InputMaybe<SpeechOutputCollectionType>;
  collectionType_in?: InputMaybe<Array<SpeechOutputCollectionType>>;
  collectionType_not?: InputMaybe<SpeechOutputCollectionType>;
  collectionType_not_in?: InputMaybe<Array<SpeechOutputCollectionType>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  segment?: InputMaybe<SegmentWhereInput>;
  speechOutputs_every?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_none?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_some?: InputMaybe<SpeechOutputWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type SpeechOutputCollectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SpeechOutputCreateInput = {
  audioFile?: InputMaybe<FileCreateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftCreateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserCreateOneInput>;
  effectUnit?: InputMaybe<EffectUnitCreateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAutoGenerated: Scalars['Boolean']['input'];
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied: Scalars['Boolean']['input'];
  outputGroup?: InputMaybe<OutputGroupCreateOneWithoutSpeechOutputsInput>;
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentCreateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData: SpeechInputDataCreateOneInput;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackCreateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataCreateOneInput>;
  transcriptionFile?: InputMaybe<FileCreateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputCreateOneWithoutSpeechOutputsInput>;
  voice?: InputMaybe<VoiceCreateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputCreateManyInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateInput>>;
};

export type SpeechOutputCreateManyWithoutEffectUnitInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateWithoutEffectUnitInput>>;
};

export type SpeechOutputCreateManyWithoutOutputGroupInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateWithoutOutputGroupInput>>;
};

export type SpeechOutputCreateManyWithoutOutputGroupsInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateWithoutOutputGroupsInput>>;
};

export type SpeechOutputCreateManyWithoutSegmentInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateWithoutSegmentInput>>;
};

export type SpeechOutputCreateManyWithoutSpeechOutputFeedbacksInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateWithoutSpeechOutputFeedbacksInput>>;
};

export type SpeechOutputCreateManyWithoutVocoderOutputInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateWithoutVocoderOutputInput>>;
};

export type SpeechOutputCreateManyWithoutVoiceInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateWithoutVoiceInput>>;
};

export type SpeechOutputCreateOneInput = {
  connect?: InputMaybe<SpeechOutputWhereUniqueInput>;
  create?: InputMaybe<SpeechOutputCreateInput>;
};

export type SpeechOutputCreateOneWithoutCorrectionDraftInput = {
  connect?: InputMaybe<SpeechOutputWhereUniqueInput>;
  create?: InputMaybe<SpeechOutputCreateWithoutCorrectionDraftInput>;
};

export type SpeechOutputCreateWithoutCorrectionDraftInput = {
  audioFile?: InputMaybe<FileCreateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  createdBy?: InputMaybe<UserCreateOneInput>;
  effectUnit?: InputMaybe<EffectUnitCreateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAutoGenerated: Scalars['Boolean']['input'];
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied: Scalars['Boolean']['input'];
  outputGroup?: InputMaybe<OutputGroupCreateOneWithoutSpeechOutputsInput>;
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentCreateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData: SpeechInputDataCreateOneInput;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackCreateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataCreateOneInput>;
  transcriptionFile?: InputMaybe<FileCreateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputCreateOneWithoutSpeechOutputsInput>;
  voice?: InputMaybe<VoiceCreateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputCreateWithoutEffectUnitInput = {
  audioFile?: InputMaybe<FileCreateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftCreateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserCreateOneInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAutoGenerated: Scalars['Boolean']['input'];
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied: Scalars['Boolean']['input'];
  outputGroup?: InputMaybe<OutputGroupCreateOneWithoutSpeechOutputsInput>;
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentCreateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData: SpeechInputDataCreateOneInput;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackCreateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataCreateOneInput>;
  transcriptionFile?: InputMaybe<FileCreateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputCreateOneWithoutSpeechOutputsInput>;
  voice?: InputMaybe<VoiceCreateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputCreateWithoutOutputGroupInput = {
  audioFile?: InputMaybe<FileCreateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftCreateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserCreateOneInput>;
  effectUnit?: InputMaybe<EffectUnitCreateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAutoGenerated: Scalars['Boolean']['input'];
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied: Scalars['Boolean']['input'];
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentCreateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData: SpeechInputDataCreateOneInput;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackCreateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataCreateOneInput>;
  transcriptionFile?: InputMaybe<FileCreateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputCreateOneWithoutSpeechOutputsInput>;
  voice?: InputMaybe<VoiceCreateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputCreateWithoutOutputGroupsInput = {
  audioFile?: InputMaybe<FileCreateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftCreateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserCreateOneInput>;
  effectUnit?: InputMaybe<EffectUnitCreateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAutoGenerated: Scalars['Boolean']['input'];
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied: Scalars['Boolean']['input'];
  outputGroup?: InputMaybe<OutputGroupCreateOneWithoutSpeechOutputsInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentCreateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData: SpeechInputDataCreateOneInput;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackCreateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataCreateOneInput>;
  transcriptionFile?: InputMaybe<FileCreateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputCreateOneWithoutSpeechOutputsInput>;
  voice?: InputMaybe<VoiceCreateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputCreateWithoutSegmentInput = {
  audioFile?: InputMaybe<FileCreateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftCreateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserCreateOneInput>;
  effectUnit?: InputMaybe<EffectUnitCreateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAutoGenerated: Scalars['Boolean']['input'];
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied: Scalars['Boolean']['input'];
  outputGroup?: InputMaybe<OutputGroupCreateOneWithoutSpeechOutputsInput>;
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData: SpeechInputDataCreateOneInput;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackCreateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataCreateOneInput>;
  transcriptionFile?: InputMaybe<FileCreateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputCreateOneWithoutSpeechOutputsInput>;
  voice?: InputMaybe<VoiceCreateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputCreateWithoutSpeechOutputFeedbacksInput = {
  audioFile?: InputMaybe<FileCreateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftCreateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserCreateOneInput>;
  effectUnit?: InputMaybe<EffectUnitCreateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAutoGenerated: Scalars['Boolean']['input'];
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied: Scalars['Boolean']['input'];
  outputGroup?: InputMaybe<OutputGroupCreateOneWithoutSpeechOutputsInput>;
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentCreateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData: SpeechInputDataCreateOneInput;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataCreateOneInput>;
  transcriptionFile?: InputMaybe<FileCreateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputCreateOneWithoutSpeechOutputsInput>;
  voice?: InputMaybe<VoiceCreateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputCreateWithoutVocoderOutputInput = {
  audioFile?: InputMaybe<FileCreateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftCreateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserCreateOneInput>;
  effectUnit?: InputMaybe<EffectUnitCreateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAutoGenerated: Scalars['Boolean']['input'];
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied: Scalars['Boolean']['input'];
  outputGroup?: InputMaybe<OutputGroupCreateOneWithoutSpeechOutputsInput>;
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentCreateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData: SpeechInputDataCreateOneInput;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackCreateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataCreateOneInput>;
  transcriptionFile?: InputMaybe<FileCreateOneInput>;
  voice?: InputMaybe<VoiceCreateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputCreateWithoutVoiceInput = {
  audioFile?: InputMaybe<FileCreateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftCreateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserCreateOneInput>;
  effectUnit?: InputMaybe<EffectUnitCreateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAutoGenerated: Scalars['Boolean']['input'];
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied: Scalars['Boolean']['input'];
  outputGroup?: InputMaybe<OutputGroupCreateOneWithoutSpeechOutputsInput>;
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentCreateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData: SpeechInputDataCreateOneInput;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackCreateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataCreateOneInput>;
  transcriptionFile?: InputMaybe<FileCreateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputCreateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputFeedback = {
  __typename?: 'SpeechOutputFeedback';
  controlFeature?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  endTimeSec?: Maybe<Scalars['Float']['output']>;
  feedbackDetails?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  issue: Scalars['String']['output'];
  speechOutputs?: Maybe<Array<SpeechOutput>>;
  startTimeSec?: Maybe<Scalars['Float']['output']>;
  type: SpeechOutputFeedbackType;
  updatedAt: Scalars['DateTime']['output'];
};


export type SpeechOutputFeedbackSpeechOutputsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpeechOutputOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpeechOutputWhereInput>;
};

export type SpeechOutputFeedbackCreateInput = {
  controlFeature?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<UserCreateOneInput>;
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  feedbackDetails?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  issue: Scalars['String']['input'];
  speechOutputs?: InputMaybe<SpeechOutputCreateManyWithoutSpeechOutputFeedbacksInput>;
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
  type: SpeechOutputFeedbackType;
};

export type SpeechOutputFeedbackCreateManyWithoutSpeechOutputsInput = {
  connect?: InputMaybe<Array<SpeechOutputFeedbackWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputFeedbackCreateWithoutSpeechOutputsInput>>;
};

export type SpeechOutputFeedbackCreateWithoutSpeechOutputsInput = {
  controlFeature?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<UserCreateOneInput>;
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  feedbackDetails?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  issue: Scalars['String']['input'];
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
  type: SpeechOutputFeedbackType;
};

export enum SpeechOutputFeedbackOrderByInput {
  ControlFeatureAsc = 'controlFeature_ASC',
  ControlFeatureDesc = 'controlFeature_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EndTimeSecAsc = 'endTimeSec_ASC',
  EndTimeSecDesc = 'endTimeSec_DESC',
  FeedbackDetailsAsc = 'feedbackDetails_ASC',
  FeedbackDetailsDesc = 'feedbackDetails_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IssueAsc = 'issue_ASC',
  IssueDesc = 'issue_DESC',
  StartTimeSecAsc = 'startTimeSec_ASC',
  StartTimeSecDesc = 'startTimeSec_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type SpeechOutputFeedbackScalarWhereInput = {
  AND?: InputMaybe<Array<SpeechOutputFeedbackScalarWhereInput>>;
  NOT?: InputMaybe<Array<SpeechOutputFeedbackScalarWhereInput>>;
  OR?: InputMaybe<Array<SpeechOutputFeedbackScalarWhereInput>>;
  controlFeature?: InputMaybe<Scalars['String']['input']>;
  controlFeature_contains?: InputMaybe<Scalars['String']['input']>;
  controlFeature_ends_with?: InputMaybe<Scalars['String']['input']>;
  controlFeature_gt?: InputMaybe<Scalars['String']['input']>;
  controlFeature_gte?: InputMaybe<Scalars['String']['input']>;
  controlFeature_in?: InputMaybe<Array<Scalars['String']['input']>>;
  controlFeature_lt?: InputMaybe<Scalars['String']['input']>;
  controlFeature_lte?: InputMaybe<Scalars['String']['input']>;
  controlFeature_not?: InputMaybe<Scalars['String']['input']>;
  controlFeature_not_contains?: InputMaybe<Scalars['String']['input']>;
  controlFeature_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  controlFeature_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  controlFeature_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  controlFeature_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_gt?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_gte?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  endTimeSec_lt?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_lte?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_not?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  feedbackDetails?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_contains?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_ends_with?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_gt?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_gte?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_in?: InputMaybe<Array<Scalars['String']['input']>>;
  feedbackDetails_lt?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_lte?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_not?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_not_contains?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  feedbackDetails_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  issue?: InputMaybe<Scalars['String']['input']>;
  issue_contains?: InputMaybe<Scalars['String']['input']>;
  issue_ends_with?: InputMaybe<Scalars['String']['input']>;
  issue_gt?: InputMaybe<Scalars['String']['input']>;
  issue_gte?: InputMaybe<Scalars['String']['input']>;
  issue_in?: InputMaybe<Array<Scalars['String']['input']>>;
  issue_lt?: InputMaybe<Scalars['String']['input']>;
  issue_lte?: InputMaybe<Scalars['String']['input']>;
  issue_not?: InputMaybe<Scalars['String']['input']>;
  issue_not_contains?: InputMaybe<Scalars['String']['input']>;
  issue_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  issue_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  issue_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  issue_starts_with?: InputMaybe<Scalars['String']['input']>;
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_gt?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_gte?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTimeSec_lt?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_lte?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_not?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  type?: InputMaybe<SpeechOutputFeedbackType>;
  type_in?: InputMaybe<Array<SpeechOutputFeedbackType>>;
  type_not?: InputMaybe<SpeechOutputFeedbackType>;
  type_not_in?: InputMaybe<Array<SpeechOutputFeedbackType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export enum SpeechOutputFeedbackType {
  Artifact = 'Artifact',
  Expressivity = 'Expressivity',
  Other = 'Other',
  Pronunciation = 'Pronunciation'
}

export type SpeechOutputFeedbackUpdateManyDataInput = {
  controlFeature?: InputMaybe<Scalars['String']['input']>;
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  feedbackDetails?: InputMaybe<Scalars['String']['input']>;
  issue?: InputMaybe<Scalars['String']['input']>;
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<SpeechOutputFeedbackType>;
};

export type SpeechOutputFeedbackUpdateManyWithWhereNestedInput = {
  data: SpeechOutputFeedbackUpdateManyDataInput;
  where: SpeechOutputFeedbackScalarWhereInput;
};

export type SpeechOutputFeedbackUpdateManyWithoutSpeechOutputsInput = {
  connect?: InputMaybe<Array<SpeechOutputFeedbackWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputFeedbackCreateWithoutSpeechOutputsInput>>;
  delete?: InputMaybe<Array<SpeechOutputFeedbackWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpeechOutputFeedbackScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpeechOutputFeedbackWhereUniqueInput>>;
  set?: InputMaybe<Array<SpeechOutputFeedbackWhereUniqueInput>>;
  update?: InputMaybe<Array<SpeechOutputFeedbackUpdateWithWhereUniqueWithoutSpeechOutputsInput>>;
  updateMany?: InputMaybe<Array<SpeechOutputFeedbackUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SpeechOutputFeedbackUpsertWithWhereUniqueWithoutSpeechOutputsInput>>;
};

export type SpeechOutputFeedbackUpdateWithWhereUniqueWithoutSpeechOutputsInput = {
  data: SpeechOutputFeedbackUpdateWithoutSpeechOutputsDataInput;
  where: SpeechOutputFeedbackWhereUniqueInput;
};

export type SpeechOutputFeedbackUpdateWithoutSpeechOutputsDataInput = {
  controlFeature?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<UserUpdateOneInput>;
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  feedbackDetails?: InputMaybe<Scalars['String']['input']>;
  issue?: InputMaybe<Scalars['String']['input']>;
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<SpeechOutputFeedbackType>;
};

export type SpeechOutputFeedbackUpsertWithWhereUniqueWithoutSpeechOutputsInput = {
  create: SpeechOutputFeedbackCreateWithoutSpeechOutputsInput;
  update: SpeechOutputFeedbackUpdateWithoutSpeechOutputsDataInput;
  where: SpeechOutputFeedbackWhereUniqueInput;
};

export type SpeechOutputFeedbackWhereInput = {
  AND?: InputMaybe<Array<SpeechOutputFeedbackWhereInput>>;
  NOT?: InputMaybe<Array<SpeechOutputFeedbackWhereInput>>;
  OR?: InputMaybe<Array<SpeechOutputFeedbackWhereInput>>;
  controlFeature?: InputMaybe<Scalars['String']['input']>;
  controlFeature_contains?: InputMaybe<Scalars['String']['input']>;
  controlFeature_ends_with?: InputMaybe<Scalars['String']['input']>;
  controlFeature_gt?: InputMaybe<Scalars['String']['input']>;
  controlFeature_gte?: InputMaybe<Scalars['String']['input']>;
  controlFeature_in?: InputMaybe<Array<Scalars['String']['input']>>;
  controlFeature_lt?: InputMaybe<Scalars['String']['input']>;
  controlFeature_lte?: InputMaybe<Scalars['String']['input']>;
  controlFeature_not?: InputMaybe<Scalars['String']['input']>;
  controlFeature_not_contains?: InputMaybe<Scalars['String']['input']>;
  controlFeature_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  controlFeature_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  controlFeature_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  controlFeature_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  endTimeSec?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_gt?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_gte?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  endTimeSec_lt?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_lte?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_not?: InputMaybe<Scalars['Float']['input']>;
  endTimeSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  feedbackDetails?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_contains?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_ends_with?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_gt?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_gte?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_in?: InputMaybe<Array<Scalars['String']['input']>>;
  feedbackDetails_lt?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_lte?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_not?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_not_contains?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  feedbackDetails_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  feedbackDetails_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  issue?: InputMaybe<Scalars['String']['input']>;
  issue_contains?: InputMaybe<Scalars['String']['input']>;
  issue_ends_with?: InputMaybe<Scalars['String']['input']>;
  issue_gt?: InputMaybe<Scalars['String']['input']>;
  issue_gte?: InputMaybe<Scalars['String']['input']>;
  issue_in?: InputMaybe<Array<Scalars['String']['input']>>;
  issue_lt?: InputMaybe<Scalars['String']['input']>;
  issue_lte?: InputMaybe<Scalars['String']['input']>;
  issue_not?: InputMaybe<Scalars['String']['input']>;
  issue_not_contains?: InputMaybe<Scalars['String']['input']>;
  issue_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  issue_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  issue_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  issue_starts_with?: InputMaybe<Scalars['String']['input']>;
  speechOutputs_every?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_none?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_some?: InputMaybe<SpeechOutputWhereInput>;
  startTimeSec?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_gt?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_gte?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  startTimeSec_lt?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_lte?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_not?: InputMaybe<Scalars['Float']['input']>;
  startTimeSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  type?: InputMaybe<SpeechOutputFeedbackType>;
  type_in?: InputMaybe<Array<SpeechOutputFeedbackType>>;
  type_not?: InputMaybe<SpeechOutputFeedbackType>;
  type_not_in?: InputMaybe<Array<SpeechOutputFeedbackType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type SpeechOutputFeedbackWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum SpeechOutputForModelTypeEnum {
  CorrectionDraft = 'CorrectionDraft',
  OutputGroup = 'OutputGroup',
  ProsodyBuffet = 'ProsodyBuffet',
  Segment = 'Segment',
  SpeechOutputCollection = 'SpeechOutputCollection',
  Voice = 'Voice'
}

export enum SpeechOutputOrderByInput {
  AudioFileSourceEnumAsc = 'audioFileSourceEnum_ASC',
  AudioFileSourceEnumDesc = 'audioFileSourceEnum_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  ForModelTypeEnumAsc = 'forModelTypeEnum_ASC',
  ForModelTypeEnumDesc = 'forModelTypeEnum_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsAutoGeneratedAsc = 'isAutoGenerated_ASC',
  IsAutoGeneratedDesc = 'isAutoGenerated_DESC',
  IsBulkUpdateAsc = 'isBulkUpdate_ASC',
  IsBulkUpdateDesc = 'isBulkUpdate_DESC',
  IsCorrectionAppliedAsc = 'isCorrectionApplied_ASC',
  IsCorrectionAppliedDesc = 'isCorrectionApplied_DESC',
  ProcessingDurationMsAsc = 'processingDurationMs_ASC',
  ProcessingDurationMsDesc = 'processingDurationMs_DESC',
  ProcessingEndTimeAsc = 'processingEndTime_ASC',
  ProcessingEndTimeDesc = 'processingEndTime_DESC',
  ProcessingStartTimeAsc = 'processingStartTime_ASC',
  ProcessingStartTimeDesc = 'processingStartTime_DESC',
  SelectedAtAsc = 'selectedAt_ASC',
  SelectedAtDesc = 'selectedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WasOutputFoundInCacheAsc = 'wasOutputFoundInCache_ASC',
  WasOutputFoundInCacheDesc = 'wasOutputFoundInCache_DESC'
}

export type SpeechOutputScalarWhereInput = {
  AND?: InputMaybe<Array<SpeechOutputScalarWhereInput>>;
  NOT?: InputMaybe<Array<SpeechOutputScalarWhereInput>>;
  OR?: InputMaybe<Array<SpeechOutputScalarWhereInput>>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  audioFileSourceEnum_in?: InputMaybe<Array<AudioFileSource>>;
  audioFileSourceEnum_not?: InputMaybe<AudioFileSource>;
  audioFileSourceEnum_not_in?: InputMaybe<Array<AudioFileSource>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  forModelTypeEnum_in?: InputMaybe<Array<SpeechOutputForModelTypeEnum>>;
  forModelTypeEnum_not?: InputMaybe<SpeechOutputForModelTypeEnum>;
  forModelTypeEnum_not_in?: InputMaybe<Array<SpeechOutputForModelTypeEnum>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoGenerated_not?: InputMaybe<Scalars['Boolean']['input']>;
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isBulkUpdate_not?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied_not?: InputMaybe<Scalars['Boolean']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingDurationMs_lt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_lte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingEndTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  selectedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
  wasOutputFoundInCache_not?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Inferred status of the SpeechOutput */
export type SpeechOutputStatus = {
  __typename?: 'SpeechOutputStatus';
  failureMessage?: Maybe<Scalars['String']['output']>;
  generatingPriority?: Maybe<Scalars['GeneratingPriority']['output']>;
  /** Additional status info. E.g. more granular detail of the workflow status */
  info?: Maybe<Scalars['String']['output']>;
  modelId: Scalars['String']['output'];
  modelType: Scalars['String']['output'];
  stateEnum: GeneratingState;
};

export type SpeechOutputUpdateDataInput = {
  audioFile?: InputMaybe<FileUpdateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftUpdateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserUpdateOneInput>;
  effectUnit?: InputMaybe<EffectUnitUpdateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  outputGroup?: InputMaybe<OutputGroupUpdateOneWithoutSpeechOutputsInput>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentUpdateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData?: InputMaybe<SpeechInputDataUpdateOneRequiredInput>;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackUpdateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataUpdateOneInput>;
  transcriptionFile?: InputMaybe<FileUpdateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputUpdateOneWithoutSpeechOutputsInput>;
  voice?: InputMaybe<VoiceUpdateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputUpdateManyDataInput = {
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputUpdateManyInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateInput>>;
  delete?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpeechOutputScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  set?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  update?: InputMaybe<Array<SpeechOutputUpdateWithWhereUniqueNestedInput>>;
  updateMany?: InputMaybe<Array<SpeechOutputUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SpeechOutputUpsertWithWhereUniqueNestedInput>>;
};

export type SpeechOutputUpdateManyWithWhereNestedInput = {
  data: SpeechOutputUpdateManyDataInput;
  where: SpeechOutputScalarWhereInput;
};

export type SpeechOutputUpdateManyWithoutEffectUnitInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateWithoutEffectUnitInput>>;
  delete?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpeechOutputScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  set?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  update?: InputMaybe<Array<SpeechOutputUpdateWithWhereUniqueWithoutEffectUnitInput>>;
  updateMany?: InputMaybe<Array<SpeechOutputUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SpeechOutputUpsertWithWhereUniqueWithoutEffectUnitInput>>;
};

export type SpeechOutputUpdateManyWithoutOutputGroupInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateWithoutOutputGroupInput>>;
  delete?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpeechOutputScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  set?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  update?: InputMaybe<Array<SpeechOutputUpdateWithWhereUniqueWithoutOutputGroupInput>>;
  updateMany?: InputMaybe<Array<SpeechOutputUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SpeechOutputUpsertWithWhereUniqueWithoutOutputGroupInput>>;
};

export type SpeechOutputUpdateManyWithoutOutputGroupsInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateWithoutOutputGroupsInput>>;
  delete?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpeechOutputScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  set?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  update?: InputMaybe<Array<SpeechOutputUpdateWithWhereUniqueWithoutOutputGroupsInput>>;
  updateMany?: InputMaybe<Array<SpeechOutputUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SpeechOutputUpsertWithWhereUniqueWithoutOutputGroupsInput>>;
};

export type SpeechOutputUpdateManyWithoutSegmentInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateWithoutSegmentInput>>;
  delete?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpeechOutputScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  set?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  update?: InputMaybe<Array<SpeechOutputUpdateWithWhereUniqueWithoutSegmentInput>>;
  updateMany?: InputMaybe<Array<SpeechOutputUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SpeechOutputUpsertWithWhereUniqueWithoutSegmentInput>>;
};

export type SpeechOutputUpdateManyWithoutVocoderOutputInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateWithoutVocoderOutputInput>>;
  delete?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpeechOutputScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  set?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  update?: InputMaybe<Array<SpeechOutputUpdateWithWhereUniqueWithoutVocoderOutputInput>>;
  updateMany?: InputMaybe<Array<SpeechOutputUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SpeechOutputUpsertWithWhereUniqueWithoutVocoderOutputInput>>;
};

export type SpeechOutputUpdateManyWithoutVoiceInput = {
  connect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  create?: InputMaybe<Array<SpeechOutputCreateWithoutVoiceInput>>;
  delete?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpeechOutputScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  set?: InputMaybe<Array<SpeechOutputWhereUniqueInput>>;
  update?: InputMaybe<Array<SpeechOutputUpdateWithWhereUniqueWithoutVoiceInput>>;
  updateMany?: InputMaybe<Array<SpeechOutputUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<SpeechOutputUpsertWithWhereUniqueWithoutVoiceInput>>;
};

export type SpeechOutputUpdateOneInput = {
  connect?: InputMaybe<SpeechOutputWhereUniqueInput>;
  create?: InputMaybe<SpeechOutputCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SpeechOutputUpdateDataInput>;
  upsert?: InputMaybe<SpeechOutputUpsertNestedInput>;
};

export type SpeechOutputUpdateOneRequiredInput = {
  connect?: InputMaybe<SpeechOutputWhereUniqueInput>;
  create?: InputMaybe<SpeechOutputCreateInput>;
  update?: InputMaybe<SpeechOutputUpdateDataInput>;
  upsert?: InputMaybe<SpeechOutputUpsertNestedInput>;
};

export type SpeechOutputUpdateOneWithoutCorrectionDraftInput = {
  connect?: InputMaybe<SpeechOutputWhereUniqueInput>;
  create?: InputMaybe<SpeechOutputCreateWithoutCorrectionDraftInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SpeechOutputUpdateWithoutCorrectionDraftDataInput>;
  upsert?: InputMaybe<SpeechOutputUpsertWithoutCorrectionDraftInput>;
};

export type SpeechOutputUpdateWithWhereUniqueNestedInput = {
  data: SpeechOutputUpdateDataInput;
  where: SpeechOutputWhereUniqueInput;
};

export type SpeechOutputUpdateWithWhereUniqueWithoutEffectUnitInput = {
  data: SpeechOutputUpdateWithoutEffectUnitDataInput;
  where: SpeechOutputWhereUniqueInput;
};

export type SpeechOutputUpdateWithWhereUniqueWithoutOutputGroupInput = {
  data: SpeechOutputUpdateWithoutOutputGroupDataInput;
  where: SpeechOutputWhereUniqueInput;
};

export type SpeechOutputUpdateWithWhereUniqueWithoutOutputGroupsInput = {
  data: SpeechOutputUpdateWithoutOutputGroupsDataInput;
  where: SpeechOutputWhereUniqueInput;
};

export type SpeechOutputUpdateWithWhereUniqueWithoutSegmentInput = {
  data: SpeechOutputUpdateWithoutSegmentDataInput;
  where: SpeechOutputWhereUniqueInput;
};

export type SpeechOutputUpdateWithWhereUniqueWithoutVocoderOutputInput = {
  data: SpeechOutputUpdateWithoutVocoderOutputDataInput;
  where: SpeechOutputWhereUniqueInput;
};

export type SpeechOutputUpdateWithWhereUniqueWithoutVoiceInput = {
  data: SpeechOutputUpdateWithoutVoiceDataInput;
  where: SpeechOutputWhereUniqueInput;
};

export type SpeechOutputUpdateWithoutCorrectionDraftDataInput = {
  audioFile?: InputMaybe<FileUpdateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  createdBy?: InputMaybe<UserUpdateOneInput>;
  effectUnit?: InputMaybe<EffectUnitUpdateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  outputGroup?: InputMaybe<OutputGroupUpdateOneWithoutSpeechOutputsInput>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentUpdateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData?: InputMaybe<SpeechInputDataUpdateOneRequiredInput>;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackUpdateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataUpdateOneInput>;
  transcriptionFile?: InputMaybe<FileUpdateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputUpdateOneWithoutSpeechOutputsInput>;
  voice?: InputMaybe<VoiceUpdateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputUpdateWithoutEffectUnitDataInput = {
  audioFile?: InputMaybe<FileUpdateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftUpdateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserUpdateOneInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  outputGroup?: InputMaybe<OutputGroupUpdateOneWithoutSpeechOutputsInput>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentUpdateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData?: InputMaybe<SpeechInputDataUpdateOneRequiredInput>;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackUpdateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataUpdateOneInput>;
  transcriptionFile?: InputMaybe<FileUpdateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputUpdateOneWithoutSpeechOutputsInput>;
  voice?: InputMaybe<VoiceUpdateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputUpdateWithoutOutputGroupDataInput = {
  audioFile?: InputMaybe<FileUpdateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftUpdateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserUpdateOneInput>;
  effectUnit?: InputMaybe<EffectUnitUpdateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentUpdateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData?: InputMaybe<SpeechInputDataUpdateOneRequiredInput>;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackUpdateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataUpdateOneInput>;
  transcriptionFile?: InputMaybe<FileUpdateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputUpdateOneWithoutSpeechOutputsInput>;
  voice?: InputMaybe<VoiceUpdateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputUpdateWithoutOutputGroupsDataInput = {
  audioFile?: InputMaybe<FileUpdateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftUpdateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserUpdateOneInput>;
  effectUnit?: InputMaybe<EffectUnitUpdateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  outputGroup?: InputMaybe<OutputGroupUpdateOneWithoutSpeechOutputsInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentUpdateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData?: InputMaybe<SpeechInputDataUpdateOneRequiredInput>;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackUpdateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataUpdateOneInput>;
  transcriptionFile?: InputMaybe<FileUpdateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputUpdateOneWithoutSpeechOutputsInput>;
  voice?: InputMaybe<VoiceUpdateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputUpdateWithoutSegmentDataInput = {
  audioFile?: InputMaybe<FileUpdateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftUpdateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserUpdateOneInput>;
  effectUnit?: InputMaybe<EffectUnitUpdateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  outputGroup?: InputMaybe<OutputGroupUpdateOneWithoutSpeechOutputsInput>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData?: InputMaybe<SpeechInputDataUpdateOneRequiredInput>;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackUpdateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataUpdateOneInput>;
  transcriptionFile?: InputMaybe<FileUpdateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputUpdateOneWithoutSpeechOutputsInput>;
  voice?: InputMaybe<VoiceUpdateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputUpdateWithoutVocoderOutputDataInput = {
  audioFile?: InputMaybe<FileUpdateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftUpdateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserUpdateOneInput>;
  effectUnit?: InputMaybe<EffectUnitUpdateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  outputGroup?: InputMaybe<OutputGroupUpdateOneWithoutSpeechOutputsInput>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentUpdateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData?: InputMaybe<SpeechInputDataUpdateOneRequiredInput>;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackUpdateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataUpdateOneInput>;
  transcriptionFile?: InputMaybe<FileUpdateOneInput>;
  voice?: InputMaybe<VoiceUpdateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputUpdateWithoutVoiceDataInput = {
  audioFile?: InputMaybe<FileUpdateOneInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  correctionDraft?: InputMaybe<CorrectionDraftUpdateOneWithoutSpeechOutputInput>;
  createdBy?: InputMaybe<UserUpdateOneInput>;
  effectUnit?: InputMaybe<EffectUnitUpdateOneWithoutSpeechOutputsInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  outputGroup?: InputMaybe<OutputGroupUpdateOneWithoutSpeechOutputsInput>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutSpeechOutputsV2Input>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  segment?: InputMaybe<SegmentUpdateOneWithoutSpeechOutputsInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speechInputData?: InputMaybe<SpeechInputDataUpdateOneRequiredInput>;
  speechOutputFeedbacks?: InputMaybe<SpeechOutputFeedbackUpdateManyWithoutSpeechOutputsInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataUpdateOneInput>;
  transcriptionFile?: InputMaybe<FileUpdateOneInput>;
  vocoderOutput?: InputMaybe<VocoderOutputUpdateOneWithoutSpeechOutputsInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputUpsertNestedInput = {
  create: SpeechOutputCreateInput;
  update: SpeechOutputUpdateDataInput;
};

export type SpeechOutputUpsertWithWhereUniqueNestedInput = {
  create: SpeechOutputCreateInput;
  update: SpeechOutputUpdateDataInput;
  where: SpeechOutputWhereUniqueInput;
};

export type SpeechOutputUpsertWithWhereUniqueWithoutEffectUnitInput = {
  create: SpeechOutputCreateWithoutEffectUnitInput;
  update: SpeechOutputUpdateWithoutEffectUnitDataInput;
  where: SpeechOutputWhereUniqueInput;
};

export type SpeechOutputUpsertWithWhereUniqueWithoutOutputGroupInput = {
  create: SpeechOutputCreateWithoutOutputGroupInput;
  update: SpeechOutputUpdateWithoutOutputGroupDataInput;
  where: SpeechOutputWhereUniqueInput;
};

export type SpeechOutputUpsertWithWhereUniqueWithoutOutputGroupsInput = {
  create: SpeechOutputCreateWithoutOutputGroupsInput;
  update: SpeechOutputUpdateWithoutOutputGroupsDataInput;
  where: SpeechOutputWhereUniqueInput;
};

export type SpeechOutputUpsertWithWhereUniqueWithoutSegmentInput = {
  create: SpeechOutputCreateWithoutSegmentInput;
  update: SpeechOutputUpdateWithoutSegmentDataInput;
  where: SpeechOutputWhereUniqueInput;
};

export type SpeechOutputUpsertWithWhereUniqueWithoutVocoderOutputInput = {
  create: SpeechOutputCreateWithoutVocoderOutputInput;
  update: SpeechOutputUpdateWithoutVocoderOutputDataInput;
  where: SpeechOutputWhereUniqueInput;
};

export type SpeechOutputUpsertWithWhereUniqueWithoutVoiceInput = {
  create: SpeechOutputCreateWithoutVoiceInput;
  update: SpeechOutputUpdateWithoutVoiceDataInput;
  where: SpeechOutputWhereUniqueInput;
};

export type SpeechOutputUpsertWithoutCorrectionDraftInput = {
  create: SpeechOutputCreateWithoutCorrectionDraftInput;
  update: SpeechOutputUpdateWithoutCorrectionDraftDataInput;
};

export type SpeechOutputWhereInput = {
  AND?: InputMaybe<Array<SpeechOutputWhereInput>>;
  NOT?: InputMaybe<Array<SpeechOutputWhereInput>>;
  OR?: InputMaybe<Array<SpeechOutputWhereInput>>;
  audioFile?: InputMaybe<FileWhereInput>;
  audioFileSourceEnum?: InputMaybe<AudioFileSource>;
  audioFileSourceEnum_in?: InputMaybe<Array<AudioFileSource>>;
  audioFileSourceEnum_not?: InputMaybe<AudioFileSource>;
  audioFileSourceEnum_not_in?: InputMaybe<Array<AudioFileSource>>;
  correctionDraft?: InputMaybe<CorrectionDraftWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  effectUnit?: InputMaybe<EffectUnitWhereInput>;
  forModelTypeEnum?: InputMaybe<SpeechOutputForModelTypeEnum>;
  forModelTypeEnum_in?: InputMaybe<Array<SpeechOutputForModelTypeEnum>>;
  forModelTypeEnum_not?: InputMaybe<SpeechOutputForModelTypeEnum>;
  forModelTypeEnum_not_in?: InputMaybe<Array<SpeechOutputForModelTypeEnum>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isAutoGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoGenerated_not?: InputMaybe<Scalars['Boolean']['input']>;
  isBulkUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  isBulkUpdate_not?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectionApplied_not?: InputMaybe<Scalars['Boolean']['input']>;
  outputGroup?: InputMaybe<OutputGroupWhereInput>;
  outputGroups_every?: InputMaybe<OutputGroupWhereInput>;
  outputGroups_none?: InputMaybe<OutputGroupWhereInput>;
  outputGroups_some?: InputMaybe<OutputGroupWhereInput>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingDurationMs_lt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_lte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingEndTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  segment?: InputMaybe<SegmentWhereInput>;
  selectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  selectedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  selectedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  speechInputData?: InputMaybe<SpeechInputDataWhereInput>;
  speechOutputFeedbacks_every?: InputMaybe<SpeechOutputFeedbackWhereInput>;
  speechOutputFeedbacks_none?: InputMaybe<SpeechOutputFeedbackWhereInput>;
  speechOutputFeedbacks_some?: InputMaybe<SpeechOutputFeedbackWhereInput>;
  thirdPartyTtsData?: InputMaybe<ThirdPartyTtsDataWhereInput>;
  transcriptionFile?: InputMaybe<FileWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  vocoderOutput?: InputMaybe<VocoderOutputWhereInput>;
  voice?: InputMaybe<VoiceWhereInput>;
  wasOutputFoundInCache?: InputMaybe<Scalars['Boolean']['input']>;
  wasOutputFoundInCache_not?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeechOutputWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum SplitAudioProvider {
  AudioShake = 'AudioShake',
  NoSplitAudio = 'NoSplitAudio'
}

export type StateTransitionEvent = {
  __typename?: 'StateTransitionEvent';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  event: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  modelId: Scalars['String']['output'];
  modelType: ModelType;
  newState: Scalars['String']['output'];
  previousState: Scalars['String']['output'];
  previousStateDurationMs: Scalars['Float']['output'];
  previousStateDurationSecStr?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum StateTransitionEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EventAsc = 'event_ASC',
  EventDesc = 'event_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ModelIdAsc = 'modelId_ASC',
  ModelIdDesc = 'modelId_DESC',
  ModelTypeAsc = 'modelType_ASC',
  ModelTypeDesc = 'modelType_DESC',
  NewStateAsc = 'newState_ASC',
  NewStateDesc = 'newState_DESC',
  PreviousStateDurationMsAsc = 'previousStateDurationMs_ASC',
  PreviousStateDurationMsDesc = 'previousStateDurationMs_DESC',
  PreviousStateAsc = 'previousState_ASC',
  PreviousStateDesc = 'previousState_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type StateTransitionEventWhereInput = {
  AND?: InputMaybe<Array<StateTransitionEventWhereInput>>;
  NOT?: InputMaybe<Array<StateTransitionEventWhereInput>>;
  OR?: InputMaybe<Array<StateTransitionEventWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdBy?: InputMaybe<UserWhereInput>;
  event?: InputMaybe<Scalars['String']['input']>;
  event_contains?: InputMaybe<Scalars['String']['input']>;
  event_ends_with?: InputMaybe<Scalars['String']['input']>;
  event_gt?: InputMaybe<Scalars['String']['input']>;
  event_gte?: InputMaybe<Scalars['String']['input']>;
  event_in?: InputMaybe<Array<Scalars['String']['input']>>;
  event_lt?: InputMaybe<Scalars['String']['input']>;
  event_lte?: InputMaybe<Scalars['String']['input']>;
  event_not?: InputMaybe<Scalars['String']['input']>;
  event_not_contains?: InputMaybe<Scalars['String']['input']>;
  event_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  event_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  event_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  event_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  modelId_contains?: InputMaybe<Scalars['String']['input']>;
  modelId_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelId_gt?: InputMaybe<Scalars['String']['input']>;
  modelId_gte?: InputMaybe<Scalars['String']['input']>;
  modelId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelId_lt?: InputMaybe<Scalars['String']['input']>;
  modelId_lte?: InputMaybe<Scalars['String']['input']>;
  modelId_not?: InputMaybe<Scalars['String']['input']>;
  modelId_not_contains?: InputMaybe<Scalars['String']['input']>;
  modelId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelId_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelType?: InputMaybe<ModelType>;
  modelType_in?: InputMaybe<Array<ModelType>>;
  modelType_not?: InputMaybe<ModelType>;
  modelType_not_in?: InputMaybe<Array<ModelType>>;
  newState?: InputMaybe<Scalars['String']['input']>;
  newState_contains?: InputMaybe<Scalars['String']['input']>;
  newState_ends_with?: InputMaybe<Scalars['String']['input']>;
  newState_gt?: InputMaybe<Scalars['String']['input']>;
  newState_gte?: InputMaybe<Scalars['String']['input']>;
  newState_in?: InputMaybe<Array<Scalars['String']['input']>>;
  newState_lt?: InputMaybe<Scalars['String']['input']>;
  newState_lte?: InputMaybe<Scalars['String']['input']>;
  newState_not?: InputMaybe<Scalars['String']['input']>;
  newState_not_contains?: InputMaybe<Scalars['String']['input']>;
  newState_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  newState_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  newState_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  newState_starts_with?: InputMaybe<Scalars['String']['input']>;
  previousState?: InputMaybe<Scalars['String']['input']>;
  previousStateDurationMs?: InputMaybe<Scalars['Float']['input']>;
  previousStateDurationMs_gt?: InputMaybe<Scalars['Float']['input']>;
  previousStateDurationMs_gte?: InputMaybe<Scalars['Float']['input']>;
  previousStateDurationMs_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  previousStateDurationMs_lt?: InputMaybe<Scalars['Float']['input']>;
  previousStateDurationMs_lte?: InputMaybe<Scalars['Float']['input']>;
  previousStateDurationMs_not?: InputMaybe<Scalars['Float']['input']>;
  previousStateDurationMs_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  previousState_contains?: InputMaybe<Scalars['String']['input']>;
  previousState_ends_with?: InputMaybe<Scalars['String']['input']>;
  previousState_gt?: InputMaybe<Scalars['String']['input']>;
  previousState_gte?: InputMaybe<Scalars['String']['input']>;
  previousState_in?: InputMaybe<Array<Scalars['String']['input']>>;
  previousState_lt?: InputMaybe<Scalars['String']['input']>;
  previousState_lte?: InputMaybe<Scalars['String']['input']>;
  previousState_not?: InputMaybe<Scalars['String']['input']>;
  previousState_not_contains?: InputMaybe<Scalars['String']['input']>;
  previousState_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  previousState_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  previousState_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  previousState_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type StateTransitionEventWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum SupportedPageEnumType {
  ChannelDetails = 'channelDetails',
  Dashboard = 'dashboard',
  OutputReview = 'outputReview',
  Rewrite = 'rewrite',
  Scoping = 'scoping',
  Speech = 'speech',
  Studio = 'studio',
  StudioTunedMode = 'studioTunedMode',
  Transcription = 'transcription',
  Translation = 'translation',
  VideoDetails = 'videoDetails',
  Voice = 'voice'
}

export enum TvStudioStageState {
  Full = 'Full',
  Speech = 'Speech',
  Transcription = 'Transcription',
  Translation = 'Translation'
}

export type ThirdPartyTtsData = {
  __typename?: 'ThirdPartyTtsData';
  audioFile?: Maybe<File>;
  chatterboxStorageKey?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  request: Scalars['Json']['output'];
  requestHash: Scalars['String']['output'];
  ttsSourceEnum: TtsSource;
  updatedAt: Scalars['DateTime']['output'];
  workflowId?: Maybe<Scalars['String']['output']>;
  workflowRunId?: Maybe<Scalars['String']['output']>;
};

export type ThirdPartyTtsDataCreateInput = {
  audioFile?: InputMaybe<FileCreateOneInput>;
  chatterboxStorageKey?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  request: Scalars['Json']['input'];
  requestHash: Scalars['String']['input'];
  ttsSourceEnum: TtsSource;
  workflowId?: InputMaybe<Scalars['String']['input']>;
  workflowRunId?: InputMaybe<Scalars['String']['input']>;
};

export type ThirdPartyTtsDataCreateOneInput = {
  connect?: InputMaybe<ThirdPartyTtsDataWhereUniqueInput>;
  create?: InputMaybe<ThirdPartyTtsDataCreateInput>;
};

export type ThirdPartyTtsDataUpdateDataInput = {
  audioFile?: InputMaybe<FileUpdateOneInput>;
  chatterboxStorageKey?: InputMaybe<Scalars['String']['input']>;
  request?: InputMaybe<Scalars['Json']['input']>;
  requestHash?: InputMaybe<Scalars['String']['input']>;
  ttsSourceEnum?: InputMaybe<TtsSource>;
  workflowId?: InputMaybe<Scalars['String']['input']>;
  workflowRunId?: InputMaybe<Scalars['String']['input']>;
};

export type ThirdPartyTtsDataUpdateOneInput = {
  connect?: InputMaybe<ThirdPartyTtsDataWhereUniqueInput>;
  create?: InputMaybe<ThirdPartyTtsDataCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ThirdPartyTtsDataUpdateDataInput>;
  upsert?: InputMaybe<ThirdPartyTtsDataUpsertNestedInput>;
};

export type ThirdPartyTtsDataUpsertNestedInput = {
  create: ThirdPartyTtsDataCreateInput;
  update: ThirdPartyTtsDataUpdateDataInput;
};

export type ThirdPartyTtsDataWhereInput = {
  AND?: InputMaybe<Array<ThirdPartyTtsDataWhereInput>>;
  NOT?: InputMaybe<Array<ThirdPartyTtsDataWhereInput>>;
  OR?: InputMaybe<Array<ThirdPartyTtsDataWhereInput>>;
  audioFile?: InputMaybe<FileWhereInput>;
  chatterboxStorageKey?: InputMaybe<Scalars['String']['input']>;
  chatterboxStorageKey_contains?: InputMaybe<Scalars['String']['input']>;
  chatterboxStorageKey_ends_with?: InputMaybe<Scalars['String']['input']>;
  chatterboxStorageKey_gt?: InputMaybe<Scalars['String']['input']>;
  chatterboxStorageKey_gte?: InputMaybe<Scalars['String']['input']>;
  chatterboxStorageKey_in?: InputMaybe<Array<Scalars['String']['input']>>;
  chatterboxStorageKey_lt?: InputMaybe<Scalars['String']['input']>;
  chatterboxStorageKey_lte?: InputMaybe<Scalars['String']['input']>;
  chatterboxStorageKey_not?: InputMaybe<Scalars['String']['input']>;
  chatterboxStorageKey_not_contains?: InputMaybe<Scalars['String']['input']>;
  chatterboxStorageKey_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  chatterboxStorageKey_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  chatterboxStorageKey_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  chatterboxStorageKey_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  requestHash?: InputMaybe<Scalars['String']['input']>;
  requestHash_contains?: InputMaybe<Scalars['String']['input']>;
  requestHash_ends_with?: InputMaybe<Scalars['String']['input']>;
  requestHash_gt?: InputMaybe<Scalars['String']['input']>;
  requestHash_gte?: InputMaybe<Scalars['String']['input']>;
  requestHash_in?: InputMaybe<Array<Scalars['String']['input']>>;
  requestHash_lt?: InputMaybe<Scalars['String']['input']>;
  requestHash_lte?: InputMaybe<Scalars['String']['input']>;
  requestHash_not?: InputMaybe<Scalars['String']['input']>;
  requestHash_not_contains?: InputMaybe<Scalars['String']['input']>;
  requestHash_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  requestHash_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  requestHash_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  requestHash_starts_with?: InputMaybe<Scalars['String']['input']>;
  ttsSourceEnum?: InputMaybe<TtsSource>;
  ttsSourceEnum_in?: InputMaybe<Array<TtsSource>>;
  ttsSourceEnum_not?: InputMaybe<TtsSource>;
  ttsSourceEnum_not_in?: InputMaybe<Array<TtsSource>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  workflowId?: InputMaybe<Scalars['String']['input']>;
  workflowId_contains?: InputMaybe<Scalars['String']['input']>;
  workflowId_ends_with?: InputMaybe<Scalars['String']['input']>;
  workflowId_gt?: InputMaybe<Scalars['String']['input']>;
  workflowId_gte?: InputMaybe<Scalars['String']['input']>;
  workflowId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  workflowId_lt?: InputMaybe<Scalars['String']['input']>;
  workflowId_lte?: InputMaybe<Scalars['String']['input']>;
  workflowId_not?: InputMaybe<Scalars['String']['input']>;
  workflowId_not_contains?: InputMaybe<Scalars['String']['input']>;
  workflowId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  workflowId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  workflowId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  workflowId_starts_with?: InputMaybe<Scalars['String']['input']>;
  workflowRunId?: InputMaybe<Scalars['String']['input']>;
  workflowRunId_contains?: InputMaybe<Scalars['String']['input']>;
  workflowRunId_ends_with?: InputMaybe<Scalars['String']['input']>;
  workflowRunId_gt?: InputMaybe<Scalars['String']['input']>;
  workflowRunId_gte?: InputMaybe<Scalars['String']['input']>;
  workflowRunId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  workflowRunId_lt?: InputMaybe<Scalars['String']['input']>;
  workflowRunId_lte?: InputMaybe<Scalars['String']['input']>;
  workflowRunId_not?: InputMaybe<Scalars['String']['input']>;
  workflowRunId_not_contains?: InputMaybe<Scalars['String']['input']>;
  workflowRunId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  workflowRunId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  workflowRunId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  workflowRunId_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type ThirdPartyTtsDataWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  requestHash?: InputMaybe<Scalars['String']['input']>;
};

export type Track = {
  __typename?: 'Track';
  createdAt: Scalars['DateTime']['output'];
  file: File;
  id: Scalars['ID']['output'];
  includeInOutput: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sourceVideo: SourceVideo;
  splitAudioProvider: SplitAudioProvider;
  trackType: TrackType;
  updatedAt: Scalars['DateTime']['output'];
  uploadedBy?: Maybe<User>;
  volumeAdj: Scalars['Float']['output'];
};

export type TrackCreateManyWithoutSourceVideoInput = {
  connect?: InputMaybe<Array<TrackWhereUniqueInput>>;
  create?: InputMaybe<Array<TrackCreateWithoutSourceVideoInput>>;
};

export type TrackCreateWithoutSourceVideoInput = {
  file: FileCreateOneInput;
  id?: InputMaybe<Scalars['ID']['input']>;
  includeInOutput?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  trackType?: InputMaybe<TrackType>;
  uploadedBy?: InputMaybe<UserCreateOneInput>;
  volumeAdj?: InputMaybe<Scalars['Float']['input']>;
};

export enum TrackOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IncludeInOutputAsc = 'includeInOutput_ASC',
  IncludeInOutputDesc = 'includeInOutput_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SplitAudioProviderAsc = 'splitAudioProvider_ASC',
  SplitAudioProviderDesc = 'splitAudioProvider_DESC',
  TrackTypeAsc = 'trackType_ASC',
  TrackTypeDesc = 'trackType_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VolumeAdjAsc = 'volumeAdj_ASC',
  VolumeAdjDesc = 'volumeAdj_DESC'
}

export type TrackScalarWhereInput = {
  AND?: InputMaybe<Array<TrackScalarWhereInput>>;
  NOT?: InputMaybe<Array<TrackScalarWhereInput>>;
  OR?: InputMaybe<Array<TrackScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  includeInOutput?: InputMaybe<Scalars['Boolean']['input']>;
  includeInOutput_not?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProvider_in?: InputMaybe<Array<SplitAudioProvider>>;
  splitAudioProvider_not?: InputMaybe<SplitAudioProvider>;
  splitAudioProvider_not_in?: InputMaybe<Array<SplitAudioProvider>>;
  trackType?: InputMaybe<TrackType>;
  trackType_in?: InputMaybe<Array<TrackType>>;
  trackType_not?: InputMaybe<TrackType>;
  trackType_not_in?: InputMaybe<Array<TrackType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  volumeAdj?: InputMaybe<Scalars['Float']['input']>;
  volumeAdj_gt?: InputMaybe<Scalars['Float']['input']>;
  volumeAdj_gte?: InputMaybe<Scalars['Float']['input']>;
  volumeAdj_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  volumeAdj_lt?: InputMaybe<Scalars['Float']['input']>;
  volumeAdj_lte?: InputMaybe<Scalars['Float']['input']>;
  volumeAdj_not?: InputMaybe<Scalars['Float']['input']>;
  volumeAdj_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export enum TrackType {
  Dialogue = 'Dialogue',
  MusicAndEffects = 'MusicAndEffects'
}

export type TrackUpdateInput = {
  file?: InputMaybe<FileUpdateOneRequiredInput>;
  includeInOutput?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sourceVideo?: InputMaybe<SourceVideoUpdateOneRequiredWithoutTracksInput>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  trackType?: InputMaybe<TrackType>;
  uploadedBy?: InputMaybe<UserUpdateOneInput>;
  volumeAdj?: InputMaybe<Scalars['Float']['input']>;
};

export type TrackUpdateManyDataInput = {
  includeInOutput?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  trackType?: InputMaybe<TrackType>;
  volumeAdj?: InputMaybe<Scalars['Float']['input']>;
};

export type TrackUpdateManyWithWhereNestedInput = {
  data: TrackUpdateManyDataInput;
  where: TrackScalarWhereInput;
};

export type TrackUpdateManyWithoutSourceVideoInput = {
  connect?: InputMaybe<Array<TrackWhereUniqueInput>>;
  create?: InputMaybe<Array<TrackCreateWithoutSourceVideoInput>>;
  delete?: InputMaybe<Array<TrackWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TrackScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TrackWhereUniqueInput>>;
  set?: InputMaybe<Array<TrackWhereUniqueInput>>;
  update?: InputMaybe<Array<TrackUpdateWithWhereUniqueWithoutSourceVideoInput>>;
  updateMany?: InputMaybe<Array<TrackUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<TrackUpsertWithWhereUniqueWithoutSourceVideoInput>>;
};

export type TrackUpdateWithWhereUniqueWithoutSourceVideoInput = {
  data: TrackUpdateWithoutSourceVideoDataInput;
  where: TrackWhereUniqueInput;
};

export type TrackUpdateWithoutSourceVideoDataInput = {
  file?: InputMaybe<FileUpdateOneRequiredInput>;
  includeInOutput?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  trackType?: InputMaybe<TrackType>;
  uploadedBy?: InputMaybe<UserUpdateOneInput>;
  volumeAdj?: InputMaybe<Scalars['Float']['input']>;
};

export type TrackUpsertWithWhereUniqueWithoutSourceVideoInput = {
  create: TrackCreateWithoutSourceVideoInput;
  update: TrackUpdateWithoutSourceVideoDataInput;
  where: TrackWhereUniqueInput;
};

export type TrackWhereInput = {
  AND?: InputMaybe<Array<TrackWhereInput>>;
  NOT?: InputMaybe<Array<TrackWhereInput>>;
  OR?: InputMaybe<Array<TrackWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  file?: InputMaybe<FileWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  includeInOutput?: InputMaybe<Scalars['Boolean']['input']>;
  includeInOutput_not?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  sourceVideo?: InputMaybe<SourceVideoWhereInput>;
  splitAudioProvider?: InputMaybe<SplitAudioProvider>;
  splitAudioProvider_in?: InputMaybe<Array<SplitAudioProvider>>;
  splitAudioProvider_not?: InputMaybe<SplitAudioProvider>;
  splitAudioProvider_not_in?: InputMaybe<Array<SplitAudioProvider>>;
  trackType?: InputMaybe<TrackType>;
  trackType_in?: InputMaybe<Array<TrackType>>;
  trackType_not?: InputMaybe<TrackType>;
  trackType_not_in?: InputMaybe<Array<TrackType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  uploadedBy?: InputMaybe<UserWhereInput>;
  volumeAdj?: InputMaybe<Scalars['Float']['input']>;
  volumeAdj_gt?: InputMaybe<Scalars['Float']['input']>;
  volumeAdj_gte?: InputMaybe<Scalars['Float']['input']>;
  volumeAdj_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  volumeAdj_lt?: InputMaybe<Scalars['Float']['input']>;
  volumeAdj_lte?: InputMaybe<Scalars['Float']['input']>;
  volumeAdj_not?: InputMaybe<Scalars['Float']['input']>;
  volumeAdj_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type TrackWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum TranscriptionProvider {
  Deepgram = 'Deepgram',
  DeepgramNova2 = 'DeepgramNova2',
  GoogleAsr = 'GoogleASR',
  HappyScribeTranscriptionAuto = 'HappyScribeTranscriptionAuto',
  HappyScribeTranscriptionHitl = 'HappyScribeTranscriptionHitl',
  HappyScribeTranscriptionUpload = 'HappyScribeTranscriptionUpload',
  ManualTranscriptionUpload = 'ManualTranscriptionUpload'
}

export type TranslatedChannel = {
  __typename?: 'TranslatedChannel';
  QAer?: Maybe<User>;
  audioRefinementParams?: Maybe<Scalars['Json']['output']>;
  audioRefinementProvider: AudioRefinementProvider;
  characterMappingEnabled: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  defaultSpeaker?: Maybe<Speaker>;
  editor?: Maybe<User>;
  editorQC?: Maybe<User>;
  featureFlags?: Maybe<Scalars['Json']['output']>;
  generatingPriority?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  initialAutoStretchRateEnabled: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  /** Metadata for the Translated Channel */
  metadata?: Maybe<Scalars['Json']['output']>;
  prepareSegmentsMethod: PrepareSegmentsMethod;
  qc?: Maybe<User>;
  requiresAnEditor: Scalars['Boolean']['output'];
  scheduleDays: Array<DaysOfWeek>;
  scheduleFrequency?: Maybe<Frequency>;
  schedulePublishingTime?: Maybe<Scalars['DateTime']['output']>;
  scheduleUploads?: Maybe<Scalars['Int']['output']>;
  shouldTranscribeAudio: Scalars['Boolean']['output'];
  sourceChannel: SourceChannel;
  speakerUpdateRetroactivity: SpeakerUpdateRetroactivity;
  speakers?: Maybe<Array<Speaker>>;
  targetMinutePerMinute?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  translatedVideos?: Maybe<Array<TranslatedVideo>>;
  updatedAt: Scalars['DateTime']['output'];
  voiceActor?: Maybe<User>;
  voiceActorPerSpeakerEnabled: Scalars['Boolean']['output'];
  /** Channel Information from YouTube data API */
  ytChannel?: Maybe<YtChannel>;
  ytChannelId?: Maybe<Scalars['String']['output']>;
};


export type TranslatedChannelSpeakersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpeakerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpeakerWhereInput>;
};


export type TranslatedChannelTranslatedVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TranslatedVideoOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TranslatedVideoWhereInput>;
};

export type TranslatedChannelCreateInput = {
  QAer?: InputMaybe<UserCreateOneInput>;
  audioRefinementParams?: InputMaybe<Scalars['Json']['input']>;
  audioRefinementProvider?: InputMaybe<AudioRefinementProvider>;
  characterMappingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  defaultSpeaker?: InputMaybe<SpeakerCreateOneInput>;
  editor?: InputMaybe<UserCreateOneInput>;
  editorQC?: InputMaybe<UserCreateOneInput>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  initialAutoStretchRateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['Json']['input']>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  qc?: InputMaybe<UserCreateOneInput>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleDays?: InputMaybe<TranslatedChannelCreatescheduleDaysInput>;
  scheduleFrequency?: InputMaybe<Frequency>;
  schedulePublishingTime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduleUploads?: InputMaybe<Scalars['Int']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  sourceChannel: SourceChannelCreateOneWithoutTranslatedChannelsInput;
  speakerUpdateRetroactivity?: InputMaybe<SpeakerUpdateRetroactivity>;
  speakers?: InputMaybe<SpeakerCreateManyWithoutChannelInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
  translatedVideos?: InputMaybe<TranslatedVideoCreateManyWithoutChannelInput>;
  voiceActor?: InputMaybe<UserCreateOneInput>;
  voiceActorPerSpeakerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedChannelCreateManyWithoutSourceChannelInput = {
  connect?: InputMaybe<Array<TranslatedChannelWhereUniqueInput>>;
  create?: InputMaybe<Array<TranslatedChannelCreateWithoutSourceChannelInput>>;
};

export type TranslatedChannelCreateOneInput = {
  connect?: InputMaybe<TranslatedChannelWhereUniqueInput>;
  create?: InputMaybe<TranslatedChannelCreateInput>;
};

export type TranslatedChannelCreateOneWithoutSpeakersInput = {
  connect?: InputMaybe<TranslatedChannelWhereUniqueInput>;
  create?: InputMaybe<TranslatedChannelCreateWithoutSpeakersInput>;
};

export type TranslatedChannelCreateOneWithoutTranslatedVideosInput = {
  connect?: InputMaybe<TranslatedChannelWhereUniqueInput>;
  create?: InputMaybe<TranslatedChannelCreateWithoutTranslatedVideosInput>;
};

export type TranslatedChannelCreateWithoutSourceChannelInput = {
  QAer?: InputMaybe<UserCreateOneInput>;
  audioRefinementParams?: InputMaybe<Scalars['Json']['input']>;
  audioRefinementProvider?: InputMaybe<AudioRefinementProvider>;
  characterMappingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  defaultSpeaker?: InputMaybe<SpeakerCreateOneInput>;
  editor?: InputMaybe<UserCreateOneInput>;
  editorQC?: InputMaybe<UserCreateOneInput>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  initialAutoStretchRateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['Json']['input']>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  qc?: InputMaybe<UserCreateOneInput>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleDays?: InputMaybe<TranslatedChannelCreatescheduleDaysInput>;
  scheduleFrequency?: InputMaybe<Frequency>;
  schedulePublishingTime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduleUploads?: InputMaybe<Scalars['Int']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  speakerUpdateRetroactivity?: InputMaybe<SpeakerUpdateRetroactivity>;
  speakers?: InputMaybe<SpeakerCreateManyWithoutChannelInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
  translatedVideos?: InputMaybe<TranslatedVideoCreateManyWithoutChannelInput>;
  voiceActor?: InputMaybe<UserCreateOneInput>;
  voiceActorPerSpeakerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedChannelCreateWithoutSpeakersInput = {
  QAer?: InputMaybe<UserCreateOneInput>;
  audioRefinementParams?: InputMaybe<Scalars['Json']['input']>;
  audioRefinementProvider?: InputMaybe<AudioRefinementProvider>;
  characterMappingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  defaultSpeaker?: InputMaybe<SpeakerCreateOneInput>;
  editor?: InputMaybe<UserCreateOneInput>;
  editorQC?: InputMaybe<UserCreateOneInput>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  initialAutoStretchRateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['Json']['input']>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  qc?: InputMaybe<UserCreateOneInput>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleDays?: InputMaybe<TranslatedChannelCreatescheduleDaysInput>;
  scheduleFrequency?: InputMaybe<Frequency>;
  schedulePublishingTime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduleUploads?: InputMaybe<Scalars['Int']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  sourceChannel: SourceChannelCreateOneWithoutTranslatedChannelsInput;
  speakerUpdateRetroactivity?: InputMaybe<SpeakerUpdateRetroactivity>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
  translatedVideos?: InputMaybe<TranslatedVideoCreateManyWithoutChannelInput>;
  voiceActor?: InputMaybe<UserCreateOneInput>;
  voiceActorPerSpeakerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedChannelCreateWithoutTranslatedVideosInput = {
  QAer?: InputMaybe<UserCreateOneInput>;
  audioRefinementParams?: InputMaybe<Scalars['Json']['input']>;
  audioRefinementProvider?: InputMaybe<AudioRefinementProvider>;
  characterMappingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  defaultSpeaker?: InputMaybe<SpeakerCreateOneInput>;
  editor?: InputMaybe<UserCreateOneInput>;
  editorQC?: InputMaybe<UserCreateOneInput>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  initialAutoStretchRateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['Json']['input']>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  qc?: InputMaybe<UserCreateOneInput>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleDays?: InputMaybe<TranslatedChannelCreatescheduleDaysInput>;
  scheduleFrequency?: InputMaybe<Frequency>;
  schedulePublishingTime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduleUploads?: InputMaybe<Scalars['Int']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  sourceChannel: SourceChannelCreateOneWithoutTranslatedChannelsInput;
  speakerUpdateRetroactivity?: InputMaybe<SpeakerUpdateRetroactivity>;
  speakers?: InputMaybe<SpeakerCreateManyWithoutChannelInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
  voiceActor?: InputMaybe<UserCreateOneInput>;
  voiceActorPerSpeakerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedChannelCreatescheduleDaysInput = {
  set?: InputMaybe<Array<DaysOfWeek>>;
};

export enum TranslatedChannelOrderByInput {
  AudioRefinementParamsAsc = 'audioRefinementParams_ASC',
  AudioRefinementParamsDesc = 'audioRefinementParams_DESC',
  AudioRefinementProviderAsc = 'audioRefinementProvider_ASC',
  AudioRefinementProviderDesc = 'audioRefinementProvider_DESC',
  CharacterMappingEnabledAsc = 'characterMappingEnabled_ASC',
  CharacterMappingEnabledDesc = 'characterMappingEnabled_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FeatureFlagsAsc = 'featureFlags_ASC',
  FeatureFlagsDesc = 'featureFlags_DESC',
  GeneratingPriorityAsc = 'generatingPriority_ASC',
  GeneratingPriorityDesc = 'generatingPriority_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InitialAutoStretchRateEnabledAsc = 'initialAutoStretchRateEnabled_ASC',
  InitialAutoStretchRateEnabledDesc = 'initialAutoStretchRateEnabled_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  PrepareSegmentsMethodAsc = 'prepareSegmentsMethod_ASC',
  PrepareSegmentsMethodDesc = 'prepareSegmentsMethod_DESC',
  RequiresAnEditorAsc = 'requiresAnEditor_ASC',
  RequiresAnEditorDesc = 'requiresAnEditor_DESC',
  ScheduleFrequencyAsc = 'scheduleFrequency_ASC',
  ScheduleFrequencyDesc = 'scheduleFrequency_DESC',
  SchedulePublishingTimeAsc = 'schedulePublishingTime_ASC',
  SchedulePublishingTimeDesc = 'schedulePublishingTime_DESC',
  ScheduleUploadsAsc = 'scheduleUploads_ASC',
  ScheduleUploadsDesc = 'scheduleUploads_DESC',
  ShouldTranscribeAudioAsc = 'shouldTranscribeAudio_ASC',
  ShouldTranscribeAudioDesc = 'shouldTranscribeAudio_DESC',
  SpeakerUpdateRetroactivityAsc = 'speakerUpdateRetroactivity_ASC',
  SpeakerUpdateRetroactivityDesc = 'speakerUpdateRetroactivity_DESC',
  TargetMinutePerMinuteAsc = 'targetMinutePerMinute_ASC',
  TargetMinutePerMinuteDesc = 'targetMinutePerMinute_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VoiceActorPerSpeakerEnabledAsc = 'voiceActorPerSpeakerEnabled_ASC',
  VoiceActorPerSpeakerEnabledDesc = 'voiceActorPerSpeakerEnabled_DESC',
  YtChannelIdAsc = 'ytChannelId_ASC',
  YtChannelIdDesc = 'ytChannelId_DESC'
}

export type TranslatedChannelScalarWhereInput = {
  AND?: InputMaybe<Array<TranslatedChannelScalarWhereInput>>;
  NOT?: InputMaybe<Array<TranslatedChannelScalarWhereInput>>;
  OR?: InputMaybe<Array<TranslatedChannelScalarWhereInput>>;
  audioRefinementProvider?: InputMaybe<AudioRefinementProvider>;
  audioRefinementProvider_in?: InputMaybe<Array<AudioRefinementProvider>>;
  audioRefinementProvider_not?: InputMaybe<AudioRefinementProvider>;
  audioRefinementProvider_not_in?: InputMaybe<Array<AudioRefinementProvider>>;
  characterMappingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  characterMappingEnabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_gt?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_gte?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  generatingPriority_lt?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_lte?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_not?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  initialAutoStretchRateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  initialAutoStretchRateEnabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  language_contains?: InputMaybe<Scalars['String']['input']>;
  language_ends_with?: InputMaybe<Scalars['String']['input']>;
  language_gt?: InputMaybe<Scalars['String']['input']>;
  language_gte?: InputMaybe<Scalars['String']['input']>;
  language_in?: InputMaybe<Array<Scalars['String']['input']>>;
  language_lt?: InputMaybe<Scalars['String']['input']>;
  language_lte?: InputMaybe<Scalars['String']['input']>;
  language_not?: InputMaybe<Scalars['String']['input']>;
  language_not_contains?: InputMaybe<Scalars['String']['input']>;
  language_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  language_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  language_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  language_starts_with?: InputMaybe<Scalars['String']['input']>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsMethod_in?: InputMaybe<Array<PrepareSegmentsMethod>>;
  prepareSegmentsMethod_not?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsMethod_not_in?: InputMaybe<Array<PrepareSegmentsMethod>>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  requiresAnEditor_not?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleFrequency?: InputMaybe<Frequency>;
  scheduleFrequency_in?: InputMaybe<Array<Frequency>>;
  scheduleFrequency_not?: InputMaybe<Frequency>;
  scheduleFrequency_not_in?: InputMaybe<Array<Frequency>>;
  schedulePublishingTime?: InputMaybe<Scalars['DateTime']['input']>;
  schedulePublishingTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  schedulePublishingTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  schedulePublishingTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  schedulePublishingTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  schedulePublishingTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  schedulePublishingTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  schedulePublishingTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  scheduleUploads?: InputMaybe<Scalars['Int']['input']>;
  scheduleUploads_gt?: InputMaybe<Scalars['Int']['input']>;
  scheduleUploads_gte?: InputMaybe<Scalars['Int']['input']>;
  scheduleUploads_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  scheduleUploads_lt?: InputMaybe<Scalars['Int']['input']>;
  scheduleUploads_lte?: InputMaybe<Scalars['Int']['input']>;
  scheduleUploads_not?: InputMaybe<Scalars['Int']['input']>;
  scheduleUploads_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio_not?: InputMaybe<Scalars['Boolean']['input']>;
  speakerUpdateRetroactivity?: InputMaybe<SpeakerUpdateRetroactivity>;
  speakerUpdateRetroactivity_in?: InputMaybe<Array<SpeakerUpdateRetroactivity>>;
  speakerUpdateRetroactivity_not?: InputMaybe<SpeakerUpdateRetroactivity>;
  speakerUpdateRetroactivity_not_in?: InputMaybe<Array<SpeakerUpdateRetroactivity>>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_gt?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_gte?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  targetMinutePerMinute_lt?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_lte?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_not?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_gt?: InputMaybe<Scalars['String']['input']>;
  title_gte?: InputMaybe<Scalars['String']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_lt?: InputMaybe<Scalars['String']['input']>;
  title_lte?: InputMaybe<Scalars['String']['input']>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  voiceActorPerSpeakerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  voiceActorPerSpeakerEnabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_contains?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_ends_with?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_gt?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_gte?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ytChannelId_lt?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_lte?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_not?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_not_contains?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ytChannelId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedChannelUpdateDataInput = {
  QAer?: InputMaybe<UserUpdateOneInput>;
  audioRefinementParams?: InputMaybe<Scalars['Json']['input']>;
  audioRefinementProvider?: InputMaybe<AudioRefinementProvider>;
  characterMappingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  defaultSpeaker?: InputMaybe<SpeakerUpdateOneInput>;
  editor?: InputMaybe<UserUpdateOneInput>;
  editorQC?: InputMaybe<UserUpdateOneInput>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  initialAutoStretchRateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  qc?: InputMaybe<UserUpdateOneInput>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleDays?: InputMaybe<TranslatedChannelUpdatescheduleDaysInput>;
  scheduleFrequency?: InputMaybe<Frequency>;
  schedulePublishingTime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduleUploads?: InputMaybe<Scalars['Int']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  sourceChannel?: InputMaybe<SourceChannelUpdateOneRequiredWithoutTranslatedChannelsInput>;
  speakerUpdateRetroactivity?: InputMaybe<SpeakerUpdateRetroactivity>;
  speakers?: InputMaybe<SpeakerUpdateManyWithoutChannelInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedVideos?: InputMaybe<TranslatedVideoUpdateManyWithoutChannelInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
  voiceActorPerSpeakerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedChannelUpdateInput = {
  QAer?: InputMaybe<UserUpdateOneInput>;
  audioRefinementParams?: InputMaybe<Scalars['Json']['input']>;
  audioRefinementProvider?: InputMaybe<AudioRefinementProvider>;
  characterMappingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  defaultSpeaker?: InputMaybe<SpeakerUpdateOneInput>;
  editor?: InputMaybe<UserUpdateOneInput>;
  editorQC?: InputMaybe<UserUpdateOneInput>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  initialAutoStretchRateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  qc?: InputMaybe<UserUpdateOneInput>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleDays?: InputMaybe<TranslatedChannelUpdatescheduleDaysInput>;
  scheduleFrequency?: InputMaybe<Frequency>;
  schedulePublishingTime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduleUploads?: InputMaybe<Scalars['Int']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  sourceChannel?: InputMaybe<SourceChannelUpdateOneRequiredWithoutTranslatedChannelsInput>;
  speakerUpdateRetroactivity?: InputMaybe<SpeakerUpdateRetroactivity>;
  speakers?: InputMaybe<SpeakerUpdateManyWithoutChannelInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedVideos?: InputMaybe<TranslatedVideoUpdateManyWithoutChannelInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
  voiceActorPerSpeakerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedChannelUpdateManyDataInput = {
  audioRefinementParams?: InputMaybe<Scalars['Json']['input']>;
  audioRefinementProvider?: InputMaybe<AudioRefinementProvider>;
  characterMappingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  initialAutoStretchRateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleDays?: InputMaybe<TranslatedChannelUpdatescheduleDaysInput>;
  scheduleFrequency?: InputMaybe<Frequency>;
  schedulePublishingTime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduleUploads?: InputMaybe<Scalars['Int']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  speakerUpdateRetroactivity?: InputMaybe<SpeakerUpdateRetroactivity>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  voiceActorPerSpeakerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedChannelUpdateManyWithWhereNestedInput = {
  data: TranslatedChannelUpdateManyDataInput;
  where: TranslatedChannelScalarWhereInput;
};

export type TranslatedChannelUpdateManyWithoutSourceChannelInput = {
  connect?: InputMaybe<Array<TranslatedChannelWhereUniqueInput>>;
  create?: InputMaybe<Array<TranslatedChannelCreateWithoutSourceChannelInput>>;
  delete?: InputMaybe<Array<TranslatedChannelWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TranslatedChannelScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TranslatedChannelWhereUniqueInput>>;
  set?: InputMaybe<Array<TranslatedChannelWhereUniqueInput>>;
  update?: InputMaybe<Array<TranslatedChannelUpdateWithWhereUniqueWithoutSourceChannelInput>>;
  updateMany?: InputMaybe<Array<TranslatedChannelUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<TranslatedChannelUpsertWithWhereUniqueWithoutSourceChannelInput>>;
};

export type TranslatedChannelUpdateOneInput = {
  connect?: InputMaybe<TranslatedChannelWhereUniqueInput>;
  create?: InputMaybe<TranslatedChannelCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TranslatedChannelUpdateDataInput>;
  upsert?: InputMaybe<TranslatedChannelUpsertNestedInput>;
};

export type TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput = {
  connect?: InputMaybe<TranslatedChannelWhereUniqueInput>;
  create?: InputMaybe<TranslatedChannelCreateWithoutTranslatedVideosInput>;
  update?: InputMaybe<TranslatedChannelUpdateWithoutTranslatedVideosDataInput>;
  upsert?: InputMaybe<TranslatedChannelUpsertWithoutTranslatedVideosInput>;
};

export type TranslatedChannelUpdateOneWithoutSpeakersInput = {
  connect?: InputMaybe<TranslatedChannelWhereUniqueInput>;
  create?: InputMaybe<TranslatedChannelCreateWithoutSpeakersInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TranslatedChannelUpdateWithoutSpeakersDataInput>;
  upsert?: InputMaybe<TranslatedChannelUpsertWithoutSpeakersInput>;
};

export type TranslatedChannelUpdateWithWhereUniqueWithoutSourceChannelInput = {
  data: TranslatedChannelUpdateWithoutSourceChannelDataInput;
  where: TranslatedChannelWhereUniqueInput;
};

export type TranslatedChannelUpdateWithoutSourceChannelDataInput = {
  QAer?: InputMaybe<UserUpdateOneInput>;
  audioRefinementParams?: InputMaybe<Scalars['Json']['input']>;
  audioRefinementProvider?: InputMaybe<AudioRefinementProvider>;
  characterMappingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  defaultSpeaker?: InputMaybe<SpeakerUpdateOneInput>;
  editor?: InputMaybe<UserUpdateOneInput>;
  editorQC?: InputMaybe<UserUpdateOneInput>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  initialAutoStretchRateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  qc?: InputMaybe<UserUpdateOneInput>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleDays?: InputMaybe<TranslatedChannelUpdatescheduleDaysInput>;
  scheduleFrequency?: InputMaybe<Frequency>;
  schedulePublishingTime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduleUploads?: InputMaybe<Scalars['Int']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  speakerUpdateRetroactivity?: InputMaybe<SpeakerUpdateRetroactivity>;
  speakers?: InputMaybe<SpeakerUpdateManyWithoutChannelInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedVideos?: InputMaybe<TranslatedVideoUpdateManyWithoutChannelInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
  voiceActorPerSpeakerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedChannelUpdateWithoutSpeakersDataInput = {
  QAer?: InputMaybe<UserUpdateOneInput>;
  audioRefinementParams?: InputMaybe<Scalars['Json']['input']>;
  audioRefinementProvider?: InputMaybe<AudioRefinementProvider>;
  characterMappingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  defaultSpeaker?: InputMaybe<SpeakerUpdateOneInput>;
  editor?: InputMaybe<UserUpdateOneInput>;
  editorQC?: InputMaybe<UserUpdateOneInput>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  initialAutoStretchRateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  qc?: InputMaybe<UserUpdateOneInput>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleDays?: InputMaybe<TranslatedChannelUpdatescheduleDaysInput>;
  scheduleFrequency?: InputMaybe<Frequency>;
  schedulePublishingTime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduleUploads?: InputMaybe<Scalars['Int']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  sourceChannel?: InputMaybe<SourceChannelUpdateOneRequiredWithoutTranslatedChannelsInput>;
  speakerUpdateRetroactivity?: InputMaybe<SpeakerUpdateRetroactivity>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedVideos?: InputMaybe<TranslatedVideoUpdateManyWithoutChannelInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
  voiceActorPerSpeakerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedChannelUpdateWithoutTranslatedVideosDataInput = {
  QAer?: InputMaybe<UserUpdateOneInput>;
  audioRefinementParams?: InputMaybe<Scalars['Json']['input']>;
  audioRefinementProvider?: InputMaybe<AudioRefinementProvider>;
  characterMappingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  defaultSpeaker?: InputMaybe<SpeakerUpdateOneInput>;
  editor?: InputMaybe<UserUpdateOneInput>;
  editorQC?: InputMaybe<UserUpdateOneInput>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  initialAutoStretchRateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  qc?: InputMaybe<UserUpdateOneInput>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleDays?: InputMaybe<TranslatedChannelUpdatescheduleDaysInput>;
  scheduleFrequency?: InputMaybe<Frequency>;
  schedulePublishingTime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduleUploads?: InputMaybe<Scalars['Int']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  sourceChannel?: InputMaybe<SourceChannelUpdateOneRequiredWithoutTranslatedChannelsInput>;
  speakerUpdateRetroactivity?: InputMaybe<SpeakerUpdateRetroactivity>;
  speakers?: InputMaybe<SpeakerUpdateManyWithoutChannelInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
  voiceActorPerSpeakerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedChannelUpdatescheduleDaysInput = {
  set?: InputMaybe<Array<DaysOfWeek>>;
};

export type TranslatedChannelUpsertNestedInput = {
  create: TranslatedChannelCreateInput;
  update: TranslatedChannelUpdateDataInput;
};

export type TranslatedChannelUpsertWithWhereUniqueWithoutSourceChannelInput = {
  create: TranslatedChannelCreateWithoutSourceChannelInput;
  update: TranslatedChannelUpdateWithoutSourceChannelDataInput;
  where: TranslatedChannelWhereUniqueInput;
};

export type TranslatedChannelUpsertWithoutSpeakersInput = {
  create: TranslatedChannelCreateWithoutSpeakersInput;
  update: TranslatedChannelUpdateWithoutSpeakersDataInput;
};

export type TranslatedChannelUpsertWithoutTranslatedVideosInput = {
  create: TranslatedChannelCreateWithoutTranslatedVideosInput;
  update: TranslatedChannelUpdateWithoutTranslatedVideosDataInput;
};

export type TranslatedChannelWhereInput = {
  AND?: InputMaybe<Array<TranslatedChannelWhereInput>>;
  NOT?: InputMaybe<Array<TranslatedChannelWhereInput>>;
  OR?: InputMaybe<Array<TranslatedChannelWhereInput>>;
  QAer?: InputMaybe<UserWhereInput>;
  audioRefinementProvider?: InputMaybe<AudioRefinementProvider>;
  audioRefinementProvider_in?: InputMaybe<Array<AudioRefinementProvider>>;
  audioRefinementProvider_not?: InputMaybe<AudioRefinementProvider>;
  audioRefinementProvider_not_in?: InputMaybe<Array<AudioRefinementProvider>>;
  characterMappingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  characterMappingEnabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  defaultSpeaker?: InputMaybe<SpeakerWhereInput>;
  editor?: InputMaybe<UserWhereInput>;
  editorQC?: InputMaybe<UserWhereInput>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_gt?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_gte?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  generatingPriority_lt?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_lte?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_not?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  initialAutoStretchRateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  initialAutoStretchRateEnabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  language_contains?: InputMaybe<Scalars['String']['input']>;
  language_ends_with?: InputMaybe<Scalars['String']['input']>;
  language_gt?: InputMaybe<Scalars['String']['input']>;
  language_gte?: InputMaybe<Scalars['String']['input']>;
  language_in?: InputMaybe<Array<Scalars['String']['input']>>;
  language_lt?: InputMaybe<Scalars['String']['input']>;
  language_lte?: InputMaybe<Scalars['String']['input']>;
  language_not?: InputMaybe<Scalars['String']['input']>;
  language_not_contains?: InputMaybe<Scalars['String']['input']>;
  language_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  language_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  language_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  language_starts_with?: InputMaybe<Scalars['String']['input']>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsMethod_in?: InputMaybe<Array<PrepareSegmentsMethod>>;
  prepareSegmentsMethod_not?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsMethod_not_in?: InputMaybe<Array<PrepareSegmentsMethod>>;
  qc?: InputMaybe<UserWhereInput>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  requiresAnEditor_not?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleFrequency?: InputMaybe<Frequency>;
  scheduleFrequency_in?: InputMaybe<Array<Frequency>>;
  scheduleFrequency_not?: InputMaybe<Frequency>;
  scheduleFrequency_not_in?: InputMaybe<Array<Frequency>>;
  schedulePublishingTime?: InputMaybe<Scalars['DateTime']['input']>;
  schedulePublishingTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  schedulePublishingTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  schedulePublishingTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  schedulePublishingTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  schedulePublishingTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  schedulePublishingTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  schedulePublishingTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  scheduleUploads?: InputMaybe<Scalars['Int']['input']>;
  scheduleUploads_gt?: InputMaybe<Scalars['Int']['input']>;
  scheduleUploads_gte?: InputMaybe<Scalars['Int']['input']>;
  scheduleUploads_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  scheduleUploads_lt?: InputMaybe<Scalars['Int']['input']>;
  scheduleUploads_lte?: InputMaybe<Scalars['Int']['input']>;
  scheduleUploads_not?: InputMaybe<Scalars['Int']['input']>;
  scheduleUploads_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio_not?: InputMaybe<Scalars['Boolean']['input']>;
  sourceChannel?: InputMaybe<SourceChannelWhereInput>;
  speakerUpdateRetroactivity?: InputMaybe<SpeakerUpdateRetroactivity>;
  speakerUpdateRetroactivity_in?: InputMaybe<Array<SpeakerUpdateRetroactivity>>;
  speakerUpdateRetroactivity_not?: InputMaybe<SpeakerUpdateRetroactivity>;
  speakerUpdateRetroactivity_not_in?: InputMaybe<Array<SpeakerUpdateRetroactivity>>;
  speakers_every?: InputMaybe<SpeakerWhereInput>;
  speakers_none?: InputMaybe<SpeakerWhereInput>;
  speakers_some?: InputMaybe<SpeakerWhereInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_gt?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_gte?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  targetMinutePerMinute_lt?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_lte?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_not?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_gt?: InputMaybe<Scalars['String']['input']>;
  title_gte?: InputMaybe<Scalars['String']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_lt?: InputMaybe<Scalars['String']['input']>;
  title_lte?: InputMaybe<Scalars['String']['input']>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedVideos_every?: InputMaybe<TranslatedVideoWhereInput>;
  translatedVideos_none?: InputMaybe<TranslatedVideoWhereInput>;
  translatedVideos_some?: InputMaybe<TranslatedVideoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  voiceActor?: InputMaybe<UserWhereInput>;
  voiceActorPerSpeakerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  voiceActorPerSpeakerEnabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  ytChannelId?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_contains?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_ends_with?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_gt?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_gte?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ytChannelId_lt?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_lte?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_not?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_not_contains?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ytChannelId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  ytChannelId_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedChannelWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type TranslatedVideo = {
  __typename?: 'TranslatedVideo';
  addedBy?: Maybe<User>;
  asrModel: Scalars['String']['output'];
  audibleOverlaps: Scalars['Boolean']['output'];
  audioEngineer?: Maybe<User>;
  audioEngineerDueDate?: Maybe<Scalars['DateTime']['output']>;
  availableMLConfigs?: Maybe<Scalars['Json']['output']>;
  /**
   * availableVoiceControls defined in the chatterbox
   *         config for the first TTS config in availableMLConfigs
   */
  availableVoiceControls?: Maybe<Scalars['Json']['output']>;
  channel: TranslatedChannel;
  channelMetadata?: Maybe<Scalars['Json']['output']>;
  context?: Maybe<Context>;
  createdAt: Scalars['DateTime']['output'];
  /** The number of unarchived segments in this translatedVideo */
  doneSegmentsCount: Scalars['Int']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  editor?: Maybe<User>;
  editorDueDate?: Maybe<Scalars['DateTime']['output']>;
  editorQC?: Maybe<User>;
  editorQCDueDate?: Maybe<Scalars['DateTime']['output']>;
  featureFlags?: Maybe<Scalars['Json']['output']>;
  hasInitialAutoStretchRate: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invoiceItem?: Maybe<InvoiceItem>;
  isDatamodelV3: Scalars['Boolean']['output'];
  isStudioStagesEnabled: Scalars['Boolean']['output'];
  /** The most recent OutputGroup generated for this video */
  latestOutputGroup?: Maybe<OutputGroup>;
  mappedSpeakersResponse?: Maybe<File>;
  markedCompleteBy?: Maybe<User>;
  metadata?: Maybe<Scalars['Json']['output']>;
  notes?: Maybe<Array<Note>>;
  onScreenTexts?: Maybe<Array<OnScreenTextTranslation>>;
  outputGroups?: Maybe<Array<OutputGroup>>;
  prepareSegmentsMethod: PrepareSegmentsMethod;
  prepareSegmentsResponse?: Maybe<File>;
  qaer?: Maybe<User>;
  qc?: Maybe<User>;
  qcDueDate?: Maybe<Scalars['DateTime']['output']>;
  requiresAnEditor: Scalars['Boolean']['output'];
  reviewedBy?: Maybe<User>;
  s3root: Scalars['String']['output'];
  /** The number of unarchived segments in this translatedVideo */
  segmentCount: Scalars['Int']['output'];
  segments?: Maybe<Array<Segment>>;
  /** Summary of segments grouped by speaker, including completion status and mimic file presence */
  segmentsSummary: Scalars['Json']['output'];
  shouldAutoGenerateInitialOutput: Scalars['Boolean']['output'];
  shouldTranscribeAudio: Scalars['Boolean']['output'];
  snapshots?: Maybe<Array<TranslatedVideoSnapshot>>;
  sourceVideo: SourceVideo;
  stateValue: TranslatedVideoState;
  tags: Array<Scalars['String']['output']>;
  targetMinutePerMinute?: Maybe<Scalars['Float']['output']>;
  targetTranscriptFile?: Maybe<File>;
  title?: Maybe<Scalars['String']['output']>;
  translatedDescription?: Maybe<Scalars['String']['output']>;
  translatedTitle?: Maybe<Scalars['String']['output']>;
  tvStudioStageState: TvStudioStageState;
  updatedAt: Scalars['DateTime']['output'];
  videoComplexityCalculated?: Maybe<Scalars['Int']['output']>;
  videoComplexityQA?: Maybe<Scalars['Int']['output']>;
  videoComplexityQC?: Maybe<Scalars['Int']['output']>;
  videoComplexityRating?: Maybe<Array<VideoComplexityRating>>;
  voiceActor?: Maybe<User>;
  voiceActorDueDate?: Maybe<Scalars['DateTime']['output']>;
  voiceActorQC?: Maybe<User>;
  voiceActorQCDueDate?: Maybe<Scalars['DateTime']['output']>;
  wasMigratedToV3: Scalars['Boolean']['output'];
  workableDurationSec?: Maybe<Scalars['Int']['output']>;
  /** The number of unarchived segments in this translatedVideo, within the workable duration */
  workableDurationSegmentCount: Scalars['Int']['output'];
  /** Published Video Information from YouTube data API */
  ytVideo?: Maybe<YtVideo>;
  ytVideoId?: Maybe<Scalars['String']['output']>;
};


export type TranslatedVideoDoneSegmentsCountArgs = {
  isTunedSpeechMode?: InputMaybe<Scalars['Boolean']['input']>;
  studioStage?: InputMaybe<TvStudioStageState>;
};


export type TranslatedVideoNotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<NoteOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoteWhereInput>;
};


export type TranslatedVideoOnScreenTextsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OnScreenTextTranslationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OnScreenTextTranslationWhereInput>;
};


export type TranslatedVideoOutputGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OutputGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutputGroupWhereInput>;
};


export type TranslatedVideoSegmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SegmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SegmentWhereInput>;
};


export type TranslatedVideoSnapshotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TranslatedVideoSnapshotOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TranslatedVideoSnapshotWhereInput>;
};


export type TranslatedVideoVideoComplexityRatingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<VideoComplexityRatingOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VideoComplexityRatingWhereInput>;
};

export type TranslatedVideoCreateInput = {
  addedBy?: InputMaybe<UserCreateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserCreateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel: TranslatedChannelCreateOneWithoutTranslatedVideosInput;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextCreateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserCreateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserCreateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemCreateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileCreateOneInput>;
  markedCompleteBy?: InputMaybe<UserCreateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteCreateManyWithoutTranslatedVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationCreateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileCreateOneInput>;
  qaer?: InputMaybe<UserCreateOneInput>;
  qc?: InputMaybe<UserCreateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  segments?: InputMaybe<SegmentCreateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotCreateManyWithoutTranslatedVideoInput>;
  sourceVideo: SourceVideoCreateOneWithoutTranslatedVideosInput;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoCreatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingCreateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserCreateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserCreateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoCreateManyWithoutChannelInput = {
  connect?: InputMaybe<Array<TranslatedVideoWhereUniqueInput>>;
  create?: InputMaybe<Array<TranslatedVideoCreateWithoutChannelInput>>;
};

export type TranslatedVideoCreateManyWithoutSourceVideoInput = {
  connect?: InputMaybe<Array<TranslatedVideoWhereUniqueInput>>;
  create?: InputMaybe<Array<TranslatedVideoCreateWithoutSourceVideoInput>>;
};

export type TranslatedVideoCreateOneInput = {
  connect?: InputMaybe<TranslatedVideoWhereUniqueInput>;
  create?: InputMaybe<TranslatedVideoCreateInput>;
};

export type TranslatedVideoCreateOneWithoutNotesInput = {
  connect?: InputMaybe<TranslatedVideoWhereUniqueInput>;
  create?: InputMaybe<TranslatedVideoCreateWithoutNotesInput>;
};

export type TranslatedVideoCreateOneWithoutOnScreenTextsInput = {
  connect?: InputMaybe<TranslatedVideoWhereUniqueInput>;
  create?: InputMaybe<TranslatedVideoCreateWithoutOnScreenTextsInput>;
};

export type TranslatedVideoCreateOneWithoutOutputGroupsInput = {
  connect?: InputMaybe<TranslatedVideoWhereUniqueInput>;
  create?: InputMaybe<TranslatedVideoCreateWithoutOutputGroupsInput>;
};

export type TranslatedVideoCreateOneWithoutSegmentsInput = {
  connect?: InputMaybe<TranslatedVideoWhereUniqueInput>;
  create?: InputMaybe<TranslatedVideoCreateWithoutSegmentsInput>;
};

export type TranslatedVideoCreateOneWithoutSnapshotsInput = {
  connect?: InputMaybe<TranslatedVideoWhereUniqueInput>;
  create?: InputMaybe<TranslatedVideoCreateWithoutSnapshotsInput>;
};

export type TranslatedVideoCreateWithoutChannelInput = {
  addedBy?: InputMaybe<UserCreateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserCreateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextCreateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserCreateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserCreateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemCreateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileCreateOneInput>;
  markedCompleteBy?: InputMaybe<UserCreateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteCreateManyWithoutTranslatedVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationCreateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileCreateOneInput>;
  qaer?: InputMaybe<UserCreateOneInput>;
  qc?: InputMaybe<UserCreateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  segments?: InputMaybe<SegmentCreateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotCreateManyWithoutTranslatedVideoInput>;
  sourceVideo: SourceVideoCreateOneWithoutTranslatedVideosInput;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoCreatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingCreateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserCreateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserCreateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoCreateWithoutNotesInput = {
  addedBy?: InputMaybe<UserCreateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserCreateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel: TranslatedChannelCreateOneWithoutTranslatedVideosInput;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextCreateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserCreateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserCreateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemCreateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileCreateOneInput>;
  markedCompleteBy?: InputMaybe<UserCreateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationCreateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileCreateOneInput>;
  qaer?: InputMaybe<UserCreateOneInput>;
  qc?: InputMaybe<UserCreateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  segments?: InputMaybe<SegmentCreateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotCreateManyWithoutTranslatedVideoInput>;
  sourceVideo: SourceVideoCreateOneWithoutTranslatedVideosInput;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoCreatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingCreateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserCreateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserCreateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoCreateWithoutOnScreenTextsInput = {
  addedBy?: InputMaybe<UserCreateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserCreateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel: TranslatedChannelCreateOneWithoutTranslatedVideosInput;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextCreateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserCreateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserCreateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemCreateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileCreateOneInput>;
  markedCompleteBy?: InputMaybe<UserCreateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteCreateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileCreateOneInput>;
  qaer?: InputMaybe<UserCreateOneInput>;
  qc?: InputMaybe<UserCreateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  segments?: InputMaybe<SegmentCreateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotCreateManyWithoutTranslatedVideoInput>;
  sourceVideo: SourceVideoCreateOneWithoutTranslatedVideosInput;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoCreatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingCreateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserCreateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserCreateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoCreateWithoutOutputGroupsInput = {
  addedBy?: InputMaybe<UserCreateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserCreateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel: TranslatedChannelCreateOneWithoutTranslatedVideosInput;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextCreateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserCreateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserCreateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemCreateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileCreateOneInput>;
  markedCompleteBy?: InputMaybe<UserCreateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteCreateManyWithoutTranslatedVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationCreateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileCreateOneInput>;
  qaer?: InputMaybe<UserCreateOneInput>;
  qc?: InputMaybe<UserCreateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  segments?: InputMaybe<SegmentCreateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotCreateManyWithoutTranslatedVideoInput>;
  sourceVideo: SourceVideoCreateOneWithoutTranslatedVideosInput;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoCreatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingCreateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserCreateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserCreateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoCreateWithoutSegmentsInput = {
  addedBy?: InputMaybe<UserCreateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserCreateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel: TranslatedChannelCreateOneWithoutTranslatedVideosInput;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextCreateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserCreateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserCreateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemCreateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileCreateOneInput>;
  markedCompleteBy?: InputMaybe<UserCreateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteCreateManyWithoutTranslatedVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationCreateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileCreateOneInput>;
  qaer?: InputMaybe<UserCreateOneInput>;
  qc?: InputMaybe<UserCreateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotCreateManyWithoutTranslatedVideoInput>;
  sourceVideo: SourceVideoCreateOneWithoutTranslatedVideosInput;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoCreatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingCreateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserCreateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserCreateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoCreateWithoutSnapshotsInput = {
  addedBy?: InputMaybe<UserCreateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserCreateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel: TranslatedChannelCreateOneWithoutTranslatedVideosInput;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextCreateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserCreateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserCreateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemCreateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileCreateOneInput>;
  markedCompleteBy?: InputMaybe<UserCreateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteCreateManyWithoutTranslatedVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationCreateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileCreateOneInput>;
  qaer?: InputMaybe<UserCreateOneInput>;
  qc?: InputMaybe<UserCreateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  segments?: InputMaybe<SegmentCreateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  sourceVideo: SourceVideoCreateOneWithoutTranslatedVideosInput;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoCreatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingCreateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserCreateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserCreateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoCreateWithoutSourceVideoInput = {
  addedBy?: InputMaybe<UserCreateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserCreateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel: TranslatedChannelCreateOneWithoutTranslatedVideosInput;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextCreateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserCreateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserCreateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemCreateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileCreateOneInput>;
  markedCompleteBy?: InputMaybe<UserCreateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteCreateManyWithoutTranslatedVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationCreateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupCreateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileCreateOneInput>;
  qaer?: InputMaybe<UserCreateOneInput>;
  qc?: InputMaybe<UserCreateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserCreateOneInput>;
  s3root: Scalars['String']['input'];
  segments?: InputMaybe<SegmentCreateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotCreateManyWithoutTranslatedVideoInput>;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoCreatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileCreateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingCreateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserCreateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserCreateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoCreatetagsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum TranslatedVideoEventEnumType {
  Complete = 'Complete',
  GeneratingOutput = 'GeneratingOutput',
  InProgressQa = 'InProgressQA',
  InProgressReview = 'InProgressReview',
  InProgressVoiceActing = 'InProgressVoiceActing',
  OutputGenerated = 'OutputGenerated',
  PreparingSegmentsComplete = 'PreparingSegmentsComplete',
  ReadyForPostProduction = 'ReadyForPostProduction',
  ReadyForReview = 'ReadyForReview',
  ReadyForVoiceActing = 'ReadyForVoiceActing',
  ReopenVideo = 'ReopenVideo',
  SendForRevision = 'SendForRevision',
  UndoApprove = 'UndoApprove',
  VoiceActingComplete = 'VoiceActingComplete'
}

export enum TranslatedVideoOrderByInput {
  AsrModelAsc = 'asrModel_ASC',
  AsrModelDesc = 'asrModel_DESC',
  AudibleOverlapsAsc = 'audibleOverlaps_ASC',
  AudibleOverlapsDesc = 'audibleOverlaps_DESC',
  AudioEngineerDueDateAsc = 'audioEngineerDueDate_ASC',
  AudioEngineerDueDateDesc = 'audioEngineerDueDate_DESC',
  AvailableMlConfigsAsc = 'availableMLConfigs_ASC',
  AvailableMlConfigsDesc = 'availableMLConfigs_DESC',
  ChannelMetadataAsc = 'channelMetadata_ASC',
  ChannelMetadataDesc = 'channelMetadata_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DueDateAsc = 'dueDate_ASC',
  DueDateDesc = 'dueDate_DESC',
  EditorDueDateAsc = 'editorDueDate_ASC',
  EditorDueDateDesc = 'editorDueDate_DESC',
  EditorQcDueDateAsc = 'editorQCDueDate_ASC',
  EditorQcDueDateDesc = 'editorQCDueDate_DESC',
  FeatureFlagsAsc = 'featureFlags_ASC',
  FeatureFlagsDesc = 'featureFlags_DESC',
  HasInitialAutoStretchRateAsc = 'hasInitialAutoStretchRate_ASC',
  HasInitialAutoStretchRateDesc = 'hasInitialAutoStretchRate_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsDatamodelV3Asc = 'isDatamodelV3_ASC',
  IsDatamodelV3Desc = 'isDatamodelV3_DESC',
  IsStudioStagesEnabledAsc = 'isStudioStagesEnabled_ASC',
  IsStudioStagesEnabledDesc = 'isStudioStagesEnabled_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  PrepareSegmentsMethodAsc = 'prepareSegmentsMethod_ASC',
  PrepareSegmentsMethodDesc = 'prepareSegmentsMethod_DESC',
  QcDueDateAsc = 'qcDueDate_ASC',
  QcDueDateDesc = 'qcDueDate_DESC',
  RequiresAnEditorAsc = 'requiresAnEditor_ASC',
  RequiresAnEditorDesc = 'requiresAnEditor_DESC',
  S3rootAsc = 's3root_ASC',
  S3rootDesc = 's3root_DESC',
  ShouldAutoGenerateInitialOutputAsc = 'shouldAutoGenerateInitialOutput_ASC',
  ShouldAutoGenerateInitialOutputDesc = 'shouldAutoGenerateInitialOutput_DESC',
  ShouldTranscribeAudioAsc = 'shouldTranscribeAudio_ASC',
  ShouldTranscribeAudioDesc = 'shouldTranscribeAudio_DESC',
  StateValueAsc = 'stateValue_ASC',
  StateValueDesc = 'stateValue_DESC',
  TargetMinutePerMinuteAsc = 'targetMinutePerMinute_ASC',
  TargetMinutePerMinuteDesc = 'targetMinutePerMinute_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TranslatedDescriptionAsc = 'translatedDescription_ASC',
  TranslatedDescriptionDesc = 'translatedDescription_DESC',
  TranslatedTitleAsc = 'translatedTitle_ASC',
  TranslatedTitleDesc = 'translatedTitle_DESC',
  TvStudioStageStateAsc = 'tvStudioStageState_ASC',
  TvStudioStageStateDesc = 'tvStudioStageState_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoComplexityCalculatedAsc = 'videoComplexityCalculated_ASC',
  VideoComplexityCalculatedDesc = 'videoComplexityCalculated_DESC',
  VideoComplexityQaAsc = 'videoComplexityQA_ASC',
  VideoComplexityQaDesc = 'videoComplexityQA_DESC',
  VideoComplexityQcAsc = 'videoComplexityQC_ASC',
  VideoComplexityQcDesc = 'videoComplexityQC_DESC',
  VoiceActorDueDateAsc = 'voiceActorDueDate_ASC',
  VoiceActorDueDateDesc = 'voiceActorDueDate_DESC',
  VoiceActorQcDueDateAsc = 'voiceActorQCDueDate_ASC',
  VoiceActorQcDueDateDesc = 'voiceActorQCDueDate_DESC',
  WasMigratedToV3Asc = 'wasMigratedToV3_ASC',
  WasMigratedToV3Desc = 'wasMigratedToV3_DESC',
  WorkableDurationSecAsc = 'workableDurationSec_ASC',
  WorkableDurationSecDesc = 'workableDurationSec_DESC',
  YtVideoIdAsc = 'ytVideoId_ASC',
  YtVideoIdDesc = 'ytVideoId_DESC'
}

export type TranslatedVideoScalarWhereInput = {
  AND?: InputMaybe<Array<TranslatedVideoScalarWhereInput>>;
  NOT?: InputMaybe<Array<TranslatedVideoScalarWhereInput>>;
  OR?: InputMaybe<Array<TranslatedVideoScalarWhereInput>>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  asrModel_contains?: InputMaybe<Scalars['String']['input']>;
  asrModel_ends_with?: InputMaybe<Scalars['String']['input']>;
  asrModel_gt?: InputMaybe<Scalars['String']['input']>;
  asrModel_gte?: InputMaybe<Scalars['String']['input']>;
  asrModel_in?: InputMaybe<Array<Scalars['String']['input']>>;
  asrModel_lt?: InputMaybe<Scalars['String']['input']>;
  asrModel_lte?: InputMaybe<Scalars['String']['input']>;
  asrModel_not?: InputMaybe<Scalars['String']['input']>;
  asrModel_not_contains?: InputMaybe<Scalars['String']['input']>;
  asrModel_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  asrModel_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  asrModel_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  asrModel_starts_with?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audibleOverlaps_not?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  audioEngineerDueDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  audioEngineerDueDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  audioEngineerDueDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  audioEngineerDueDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  audioEngineerDueDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  audioEngineerDueDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  audioEngineerDueDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  dueDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorDueDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  editorDueDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  editorDueDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  editorDueDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  editorDueDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  editorDueDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  editorDueDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQCDueDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  editorQCDueDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  editorQCDueDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  editorQCDueDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  editorQCDueDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  editorQCDueDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  editorQCDueDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  hasInitialAutoStretchRate_not?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isDatamodelV3_not?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsMethod_in?: InputMaybe<Array<PrepareSegmentsMethod>>;
  prepareSegmentsMethod_not?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsMethod_not_in?: InputMaybe<Array<PrepareSegmentsMethod>>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  qcDueDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  qcDueDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  qcDueDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  qcDueDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  qcDueDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  qcDueDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  qcDueDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  requiresAnEditor_not?: InputMaybe<Scalars['Boolean']['input']>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  s3root_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_gt?: InputMaybe<Scalars['String']['input']>;
  s3root_gte?: InputMaybe<Scalars['String']['input']>;
  s3root_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_lt?: InputMaybe<Scalars['String']['input']>;
  s3root_lte?: InputMaybe<Scalars['String']['input']>;
  s3root_not?: InputMaybe<Scalars['String']['input']>;
  s3root_not_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  s3root_starts_with?: InputMaybe<Scalars['String']['input']>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldAutoGenerateInitialOutput_not?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio_not?: InputMaybe<Scalars['Boolean']['input']>;
  stateValue?: InputMaybe<TranslatedVideoState>;
  stateValue_in?: InputMaybe<Array<TranslatedVideoState>>;
  stateValue_not?: InputMaybe<TranslatedVideoState>;
  stateValue_not_in?: InputMaybe<Array<TranslatedVideoState>>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_gt?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_gte?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  targetMinutePerMinute_lt?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_lte?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_not?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_gt?: InputMaybe<Scalars['String']['input']>;
  title_gte?: InputMaybe<Scalars['String']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_lt?: InputMaybe<Scalars['String']['input']>;
  title_lte?: InputMaybe<Scalars['String']['input']>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_contains?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_gt?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_gte?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedDescription_lt?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_lte?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_not?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_contains?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_gt?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_gte?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedTitle_lt?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_lte?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_not?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_starts_with?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  tvStudioStageState_in?: InputMaybe<Array<TvStudioStageState>>;
  tvStudioStageState_not?: InputMaybe<TvStudioStageState>;
  tvStudioStageState_not_in?: InputMaybe<Array<TvStudioStageState>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityCalculated_gt?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityCalculated_gte?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityCalculated_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  videoComplexityCalculated_lt?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityCalculated_lte?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityCalculated_not?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityCalculated_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA_gt?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA_gte?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  videoComplexityQA_lt?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA_lte?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA_not?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC_gt?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC_gte?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  videoComplexityQC_lt?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC_lte?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC_not?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorDueDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorDueDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorDueDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  voiceActorDueDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorDueDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorDueDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorDueDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQCDueDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQCDueDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQCDueDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  voiceActorQCDueDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQCDueDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQCDueDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQCDueDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  wasMigratedToV3_not?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  workableDurationSec_gt?: InputMaybe<Scalars['Int']['input']>;
  workableDurationSec_gte?: InputMaybe<Scalars['Int']['input']>;
  workableDurationSec_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  workableDurationSec_lt?: InputMaybe<Scalars['Int']['input']>;
  workableDurationSec_lte?: InputMaybe<Scalars['Int']['input']>;
  workableDurationSec_not?: InputMaybe<Scalars['Int']['input']>;
  workableDurationSec_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_contains?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_ends_with?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_gt?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_gte?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ytVideoId_lt?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_lte?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not_contains?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ytVideoId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoSnapshot = {
  __typename?: 'TranslatedVideoSnapshot';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Number of Segments in the Snapshot where endpoint is overlapping another Segment or the end of the video */
  overlappingSegmentCount: Scalars['Int']['output'];
  /** Number of Segments in the Snapshot */
  segmentCount: Scalars['Int']['output'];
  segmentSnapshots?: Maybe<Array<SegmentSnapshot>>;
  snapshotType: VideoSnapshotType;
  totalOverlapDurationSec: Scalars['Float']['output'];
  transcriptionProvider: TranscriptionProvider;
  translatedVideo: TranslatedVideo;
  updatedAt: Scalars['DateTime']['output'];
};


export type TranslatedVideoSnapshotSegmentSnapshotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SegmentSnapshotOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SegmentSnapshotWhereInput>;
};

export type TranslatedVideoSnapshotCreateManyWithoutTranslatedVideoInput = {
  connect?: InputMaybe<Array<TranslatedVideoSnapshotWhereUniqueInput>>;
  create?: InputMaybe<Array<TranslatedVideoSnapshotCreateWithoutTranslatedVideoInput>>;
};

export type TranslatedVideoSnapshotCreateOneWithoutSegmentSnapshotsInput = {
  connect?: InputMaybe<TranslatedVideoSnapshotWhereUniqueInput>;
  create?: InputMaybe<TranslatedVideoSnapshotCreateWithoutSegmentSnapshotsInput>;
};

export type TranslatedVideoSnapshotCreateWithoutSegmentSnapshotsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  snapshotType: VideoSnapshotType;
  totalOverlapDurationSec: Scalars['Float']['input'];
  transcriptionProvider: TranscriptionProvider;
  translatedVideo: TranslatedVideoCreateOneWithoutSnapshotsInput;
};

export type TranslatedVideoSnapshotCreateWithoutTranslatedVideoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  segmentSnapshots?: InputMaybe<SegmentSnapshotCreateManyWithoutVideoSnapshotInput>;
  snapshotType: VideoSnapshotType;
  totalOverlapDurationSec: Scalars['Float']['input'];
  transcriptionProvider: TranscriptionProvider;
};

export enum TranslatedVideoSnapshotOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SnapshotTypeAsc = 'snapshotType_ASC',
  SnapshotTypeDesc = 'snapshotType_DESC',
  TotalOverlapDurationSecAsc = 'totalOverlapDurationSec_ASC',
  TotalOverlapDurationSecDesc = 'totalOverlapDurationSec_DESC',
  TranscriptionProviderAsc = 'transcriptionProvider_ASC',
  TranscriptionProviderDesc = 'transcriptionProvider_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TranslatedVideoSnapshotScalarWhereInput = {
  AND?: InputMaybe<Array<TranslatedVideoSnapshotScalarWhereInput>>;
  NOT?: InputMaybe<Array<TranslatedVideoSnapshotScalarWhereInput>>;
  OR?: InputMaybe<Array<TranslatedVideoSnapshotScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  snapshotType?: InputMaybe<VideoSnapshotType>;
  snapshotType_in?: InputMaybe<Array<VideoSnapshotType>>;
  snapshotType_not?: InputMaybe<VideoSnapshotType>;
  snapshotType_not_in?: InputMaybe<Array<VideoSnapshotType>>;
  totalOverlapDurationSec?: InputMaybe<Scalars['Float']['input']>;
  totalOverlapDurationSec_gt?: InputMaybe<Scalars['Float']['input']>;
  totalOverlapDurationSec_gte?: InputMaybe<Scalars['Float']['input']>;
  totalOverlapDurationSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  totalOverlapDurationSec_lt?: InputMaybe<Scalars['Float']['input']>;
  totalOverlapDurationSec_lte?: InputMaybe<Scalars['Float']['input']>;
  totalOverlapDurationSec_not?: InputMaybe<Scalars['Float']['input']>;
  totalOverlapDurationSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  transcriptionProvider?: InputMaybe<TranscriptionProvider>;
  transcriptionProvider_in?: InputMaybe<Array<TranscriptionProvider>>;
  transcriptionProvider_not?: InputMaybe<TranscriptionProvider>;
  transcriptionProvider_not_in?: InputMaybe<Array<TranscriptionProvider>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type TranslatedVideoSnapshotUpdateManyDataInput = {
  snapshotType?: InputMaybe<VideoSnapshotType>;
  totalOverlapDurationSec?: InputMaybe<Scalars['Float']['input']>;
  transcriptionProvider?: InputMaybe<TranscriptionProvider>;
};

export type TranslatedVideoSnapshotUpdateManyWithWhereNestedInput = {
  data: TranslatedVideoSnapshotUpdateManyDataInput;
  where: TranslatedVideoSnapshotScalarWhereInput;
};

export type TranslatedVideoSnapshotUpdateManyWithoutTranslatedVideoInput = {
  connect?: InputMaybe<Array<TranslatedVideoSnapshotWhereUniqueInput>>;
  create?: InputMaybe<Array<TranslatedVideoSnapshotCreateWithoutTranslatedVideoInput>>;
  delete?: InputMaybe<Array<TranslatedVideoSnapshotWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TranslatedVideoSnapshotScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TranslatedVideoSnapshotWhereUniqueInput>>;
  set?: InputMaybe<Array<TranslatedVideoSnapshotWhereUniqueInput>>;
  update?: InputMaybe<Array<TranslatedVideoSnapshotUpdateWithWhereUniqueWithoutTranslatedVideoInput>>;
  updateMany?: InputMaybe<Array<TranslatedVideoSnapshotUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<TranslatedVideoSnapshotUpsertWithWhereUniqueWithoutTranslatedVideoInput>>;
};

export type TranslatedVideoSnapshotUpdateOneRequiredWithoutSegmentSnapshotsInput = {
  connect?: InputMaybe<TranslatedVideoSnapshotWhereUniqueInput>;
  create?: InputMaybe<TranslatedVideoSnapshotCreateWithoutSegmentSnapshotsInput>;
  update?: InputMaybe<TranslatedVideoSnapshotUpdateWithoutSegmentSnapshotsDataInput>;
  upsert?: InputMaybe<TranslatedVideoSnapshotUpsertWithoutSegmentSnapshotsInput>;
};

export type TranslatedVideoSnapshotUpdateWithWhereUniqueWithoutTranslatedVideoInput = {
  data: TranslatedVideoSnapshotUpdateWithoutTranslatedVideoDataInput;
  where: TranslatedVideoSnapshotWhereUniqueInput;
};

export type TranslatedVideoSnapshotUpdateWithoutSegmentSnapshotsDataInput = {
  snapshotType?: InputMaybe<VideoSnapshotType>;
  totalOverlapDurationSec?: InputMaybe<Scalars['Float']['input']>;
  transcriptionProvider?: InputMaybe<TranscriptionProvider>;
  translatedVideo?: InputMaybe<TranslatedVideoUpdateOneRequiredWithoutSnapshotsInput>;
};

export type TranslatedVideoSnapshotUpdateWithoutTranslatedVideoDataInput = {
  segmentSnapshots?: InputMaybe<SegmentSnapshotUpdateManyWithoutVideoSnapshotInput>;
  snapshotType?: InputMaybe<VideoSnapshotType>;
  totalOverlapDurationSec?: InputMaybe<Scalars['Float']['input']>;
  transcriptionProvider?: InputMaybe<TranscriptionProvider>;
};

export type TranslatedVideoSnapshotUpsertWithWhereUniqueWithoutTranslatedVideoInput = {
  create: TranslatedVideoSnapshotCreateWithoutTranslatedVideoInput;
  update: TranslatedVideoSnapshotUpdateWithoutTranslatedVideoDataInput;
  where: TranslatedVideoSnapshotWhereUniqueInput;
};

export type TranslatedVideoSnapshotUpsertWithoutSegmentSnapshotsInput = {
  create: TranslatedVideoSnapshotCreateWithoutSegmentSnapshotsInput;
  update: TranslatedVideoSnapshotUpdateWithoutSegmentSnapshotsDataInput;
};

export type TranslatedVideoSnapshotWhereInput = {
  AND?: InputMaybe<Array<TranslatedVideoSnapshotWhereInput>>;
  NOT?: InputMaybe<Array<TranslatedVideoSnapshotWhereInput>>;
  OR?: InputMaybe<Array<TranslatedVideoSnapshotWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  segmentSnapshots_every?: InputMaybe<SegmentSnapshotWhereInput>;
  segmentSnapshots_none?: InputMaybe<SegmentSnapshotWhereInput>;
  segmentSnapshots_some?: InputMaybe<SegmentSnapshotWhereInput>;
  snapshotType?: InputMaybe<VideoSnapshotType>;
  snapshotType_in?: InputMaybe<Array<VideoSnapshotType>>;
  snapshotType_not?: InputMaybe<VideoSnapshotType>;
  snapshotType_not_in?: InputMaybe<Array<VideoSnapshotType>>;
  totalOverlapDurationSec?: InputMaybe<Scalars['Float']['input']>;
  totalOverlapDurationSec_gt?: InputMaybe<Scalars['Float']['input']>;
  totalOverlapDurationSec_gte?: InputMaybe<Scalars['Float']['input']>;
  totalOverlapDurationSec_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  totalOverlapDurationSec_lt?: InputMaybe<Scalars['Float']['input']>;
  totalOverlapDurationSec_lte?: InputMaybe<Scalars['Float']['input']>;
  totalOverlapDurationSec_not?: InputMaybe<Scalars['Float']['input']>;
  totalOverlapDurationSec_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  transcriptionProvider?: InputMaybe<TranscriptionProvider>;
  transcriptionProvider_in?: InputMaybe<Array<TranscriptionProvider>>;
  transcriptionProvider_not?: InputMaybe<TranscriptionProvider>;
  transcriptionProvider_not_in?: InputMaybe<Array<TranscriptionProvider>>;
  translatedVideo?: InputMaybe<TranslatedVideoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type TranslatedVideoSnapshotWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum TranslatedVideoState {
  Complete = 'Complete',
  Failed = 'Failed',
  GeneratingInitialOutput = 'GeneratingInitialOutput',
  GeneratingOutput = 'GeneratingOutput',
  InProgressQa = 'InProgressQA',
  InProgressReview = 'InProgressReview',
  InProgressRevision = 'InProgressRevision',
  InProgressVoiceActing = 'InProgressVoiceActing',
  Initial = 'Initial',
  InitialAutoStretchRate = 'InitialAutoStretchRate',
  OutputGenerated = 'OutputGenerated',
  PreparingSegments = 'PreparingSegments',
  PreparingSegmentsProcessing = 'PreparingSegmentsProcessing',
  ReadyForPostProduction = 'ReadyForPostProduction',
  ReadyForReview = 'ReadyForReview',
  ReadyForRevision = 'ReadyForRevision',
  ReadyForVoiceActing = 'ReadyForVoiceActing',
  ReadyToQa = 'ReadyToQA'
}

export type TranslatedVideoUpdateDataInput = {
  addedBy?: InputMaybe<UserUpdateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserUpdateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel?: InputMaybe<TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput>;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextUpdateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserUpdateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserUpdateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemUpdateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileUpdateOneInput>;
  markedCompleteBy?: InputMaybe<UserUpdateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteUpdateManyWithoutTranslatedVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationUpdateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileUpdateOneInput>;
  qaer?: InputMaybe<UserUpdateOneInput>;
  qc?: InputMaybe<UserUpdateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  segments?: InputMaybe<SegmentUpdateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotUpdateManyWithoutTranslatedVideoInput>;
  sourceVideo?: InputMaybe<SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput>;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoUpdatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingUpdateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserUpdateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoUpdateInput = {
  addedBy?: InputMaybe<UserUpdateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserUpdateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel?: InputMaybe<TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput>;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextUpdateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserUpdateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserUpdateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemUpdateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileUpdateOneInput>;
  markedCompleteBy?: InputMaybe<UserUpdateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteUpdateManyWithoutTranslatedVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationUpdateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileUpdateOneInput>;
  qaer?: InputMaybe<UserUpdateOneInput>;
  qc?: InputMaybe<UserUpdateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  segments?: InputMaybe<SegmentUpdateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotUpdateManyWithoutTranslatedVideoInput>;
  sourceVideo?: InputMaybe<SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput>;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoUpdatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingUpdateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserUpdateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoUpdateManyDataInput = {
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoUpdatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoUpdateManyWithWhereNestedInput = {
  data: TranslatedVideoUpdateManyDataInput;
  where: TranslatedVideoScalarWhereInput;
};

export type TranslatedVideoUpdateManyWithoutChannelInput = {
  connect?: InputMaybe<Array<TranslatedVideoWhereUniqueInput>>;
  create?: InputMaybe<Array<TranslatedVideoCreateWithoutChannelInput>>;
  delete?: InputMaybe<Array<TranslatedVideoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TranslatedVideoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TranslatedVideoWhereUniqueInput>>;
  set?: InputMaybe<Array<TranslatedVideoWhereUniqueInput>>;
  update?: InputMaybe<Array<TranslatedVideoUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: InputMaybe<Array<TranslatedVideoUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<TranslatedVideoUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type TranslatedVideoUpdateManyWithoutSourceVideoInput = {
  connect?: InputMaybe<Array<TranslatedVideoWhereUniqueInput>>;
  create?: InputMaybe<Array<TranslatedVideoCreateWithoutSourceVideoInput>>;
  delete?: InputMaybe<Array<TranslatedVideoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TranslatedVideoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TranslatedVideoWhereUniqueInput>>;
  set?: InputMaybe<Array<TranslatedVideoWhereUniqueInput>>;
  update?: InputMaybe<Array<TranslatedVideoUpdateWithWhereUniqueWithoutSourceVideoInput>>;
  updateMany?: InputMaybe<Array<TranslatedVideoUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<TranslatedVideoUpsertWithWhereUniqueWithoutSourceVideoInput>>;
};

export type TranslatedVideoUpdateOneInput = {
  connect?: InputMaybe<TranslatedVideoWhereUniqueInput>;
  create?: InputMaybe<TranslatedVideoCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TranslatedVideoUpdateDataInput>;
  upsert?: InputMaybe<TranslatedVideoUpsertNestedInput>;
};

export type TranslatedVideoUpdateOneRequiredWithoutOnScreenTextsInput = {
  connect?: InputMaybe<TranslatedVideoWhereUniqueInput>;
  create?: InputMaybe<TranslatedVideoCreateWithoutOnScreenTextsInput>;
  update?: InputMaybe<TranslatedVideoUpdateWithoutOnScreenTextsDataInput>;
  upsert?: InputMaybe<TranslatedVideoUpsertWithoutOnScreenTextsInput>;
};

export type TranslatedVideoUpdateOneRequiredWithoutOutputGroupsInput = {
  connect?: InputMaybe<TranslatedVideoWhereUniqueInput>;
  create?: InputMaybe<TranslatedVideoCreateWithoutOutputGroupsInput>;
  update?: InputMaybe<TranslatedVideoUpdateWithoutOutputGroupsDataInput>;
  upsert?: InputMaybe<TranslatedVideoUpsertWithoutOutputGroupsInput>;
};

export type TranslatedVideoUpdateOneRequiredWithoutSegmentsInput = {
  connect?: InputMaybe<TranslatedVideoWhereUniqueInput>;
  create?: InputMaybe<TranslatedVideoCreateWithoutSegmentsInput>;
  update?: InputMaybe<TranslatedVideoUpdateWithoutSegmentsDataInput>;
  upsert?: InputMaybe<TranslatedVideoUpsertWithoutSegmentsInput>;
};

export type TranslatedVideoUpdateOneRequiredWithoutSnapshotsInput = {
  connect?: InputMaybe<TranslatedVideoWhereUniqueInput>;
  create?: InputMaybe<TranslatedVideoCreateWithoutSnapshotsInput>;
  update?: InputMaybe<TranslatedVideoUpdateWithoutSnapshotsDataInput>;
  upsert?: InputMaybe<TranslatedVideoUpsertWithoutSnapshotsInput>;
};

export type TranslatedVideoUpdateOneWithoutNotesInput = {
  connect?: InputMaybe<TranslatedVideoWhereUniqueInput>;
  create?: InputMaybe<TranslatedVideoCreateWithoutNotesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TranslatedVideoUpdateWithoutNotesDataInput>;
  upsert?: InputMaybe<TranslatedVideoUpsertWithoutNotesInput>;
};

export type TranslatedVideoUpdateWithWhereUniqueWithoutChannelInput = {
  data: TranslatedVideoUpdateWithoutChannelDataInput;
  where: TranslatedVideoWhereUniqueInput;
};

export type TranslatedVideoUpdateWithWhereUniqueWithoutSourceVideoInput = {
  data: TranslatedVideoUpdateWithoutSourceVideoDataInput;
  where: TranslatedVideoWhereUniqueInput;
};

export type TranslatedVideoUpdateWithoutChannelDataInput = {
  addedBy?: InputMaybe<UserUpdateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserUpdateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextUpdateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserUpdateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserUpdateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemUpdateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileUpdateOneInput>;
  markedCompleteBy?: InputMaybe<UserUpdateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteUpdateManyWithoutTranslatedVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationUpdateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileUpdateOneInput>;
  qaer?: InputMaybe<UserUpdateOneInput>;
  qc?: InputMaybe<UserUpdateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  segments?: InputMaybe<SegmentUpdateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotUpdateManyWithoutTranslatedVideoInput>;
  sourceVideo?: InputMaybe<SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput>;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoUpdatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingUpdateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserUpdateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoUpdateWithoutNotesDataInput = {
  addedBy?: InputMaybe<UserUpdateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserUpdateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel?: InputMaybe<TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput>;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextUpdateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserUpdateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserUpdateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemUpdateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileUpdateOneInput>;
  markedCompleteBy?: InputMaybe<UserUpdateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationUpdateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileUpdateOneInput>;
  qaer?: InputMaybe<UserUpdateOneInput>;
  qc?: InputMaybe<UserUpdateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  segments?: InputMaybe<SegmentUpdateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotUpdateManyWithoutTranslatedVideoInput>;
  sourceVideo?: InputMaybe<SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput>;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoUpdatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingUpdateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserUpdateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoUpdateWithoutOnScreenTextsDataInput = {
  addedBy?: InputMaybe<UserUpdateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserUpdateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel?: InputMaybe<TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput>;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextUpdateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserUpdateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserUpdateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemUpdateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileUpdateOneInput>;
  markedCompleteBy?: InputMaybe<UserUpdateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteUpdateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileUpdateOneInput>;
  qaer?: InputMaybe<UserUpdateOneInput>;
  qc?: InputMaybe<UserUpdateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  segments?: InputMaybe<SegmentUpdateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotUpdateManyWithoutTranslatedVideoInput>;
  sourceVideo?: InputMaybe<SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput>;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoUpdatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingUpdateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserUpdateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoUpdateWithoutOutputGroupsDataInput = {
  addedBy?: InputMaybe<UserUpdateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserUpdateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel?: InputMaybe<TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput>;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextUpdateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserUpdateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserUpdateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemUpdateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileUpdateOneInput>;
  markedCompleteBy?: InputMaybe<UserUpdateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteUpdateManyWithoutTranslatedVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationUpdateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileUpdateOneInput>;
  qaer?: InputMaybe<UserUpdateOneInput>;
  qc?: InputMaybe<UserUpdateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  segments?: InputMaybe<SegmentUpdateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotUpdateManyWithoutTranslatedVideoInput>;
  sourceVideo?: InputMaybe<SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput>;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoUpdatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingUpdateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserUpdateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoUpdateWithoutSegmentsDataInput = {
  addedBy?: InputMaybe<UserUpdateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserUpdateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel?: InputMaybe<TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput>;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextUpdateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserUpdateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserUpdateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemUpdateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileUpdateOneInput>;
  markedCompleteBy?: InputMaybe<UserUpdateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteUpdateManyWithoutTranslatedVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationUpdateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileUpdateOneInput>;
  qaer?: InputMaybe<UserUpdateOneInput>;
  qc?: InputMaybe<UserUpdateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotUpdateManyWithoutTranslatedVideoInput>;
  sourceVideo?: InputMaybe<SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput>;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoUpdatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingUpdateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserUpdateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoUpdateWithoutSnapshotsDataInput = {
  addedBy?: InputMaybe<UserUpdateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserUpdateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel?: InputMaybe<TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput>;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextUpdateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserUpdateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserUpdateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemUpdateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileUpdateOneInput>;
  markedCompleteBy?: InputMaybe<UserUpdateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteUpdateManyWithoutTranslatedVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationUpdateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileUpdateOneInput>;
  qaer?: InputMaybe<UserUpdateOneInput>;
  qc?: InputMaybe<UserUpdateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  segments?: InputMaybe<SegmentUpdateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  sourceVideo?: InputMaybe<SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput>;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoUpdatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingUpdateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserUpdateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoUpdateWithoutSourceVideoDataInput = {
  addedBy?: InputMaybe<UserUpdateOneInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserUpdateOneInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  availableMLConfigs?: InputMaybe<Scalars['Json']['input']>;
  channel?: InputMaybe<TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput>;
  channelMetadata?: InputMaybe<Scalars['Json']['input']>;
  context?: InputMaybe<ContextUpdateOneInput>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editor?: InputMaybe<UserUpdateOneInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQC?: InputMaybe<UserUpdateOneInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  featureFlags?: InputMaybe<Scalars['Json']['input']>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemUpdateOneWithoutTranslatedVideoInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileUpdateOneInput>;
  markedCompleteBy?: InputMaybe<UserUpdateOneInput>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  notes?: InputMaybe<NoteUpdateManyWithoutTranslatedVideoInput>;
  onScreenTexts?: InputMaybe<OnScreenTextTranslationUpdateManyWithoutTranslatedVideoInput>;
  outputGroups?: InputMaybe<OutputGroupUpdateManyWithoutTranslatedVideoInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsResponse?: InputMaybe<FileUpdateOneInput>;
  qaer?: InputMaybe<UserUpdateOneInput>;
  qc?: InputMaybe<UserUpdateOneInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserUpdateOneInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  segments?: InputMaybe<SegmentUpdateManyWithoutVideoInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots?: InputMaybe<TranslatedVideoSnapshotUpdateManyWithoutTranslatedVideoInput>;
  stateValue?: InputMaybe<TranslatedVideoState>;
  tags?: InputMaybe<TranslatedVideoUpdatetagsInput>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetTranscriptFile?: InputMaybe<FileUpdateOneInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityRating?: InputMaybe<VideoComplexityRatingUpdateManyWithoutTranslatedVideoInput>;
  voiceActor?: InputMaybe<UserUpdateOneInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQC?: InputMaybe<UserUpdateOneInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoUpdatetagsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TranslatedVideoUpsertNestedInput = {
  create: TranslatedVideoCreateInput;
  update: TranslatedVideoUpdateDataInput;
};

export type TranslatedVideoUpsertWithWhereUniqueWithoutChannelInput = {
  create: TranslatedVideoCreateWithoutChannelInput;
  update: TranslatedVideoUpdateWithoutChannelDataInput;
  where: TranslatedVideoWhereUniqueInput;
};

export type TranslatedVideoUpsertWithWhereUniqueWithoutSourceVideoInput = {
  create: TranslatedVideoCreateWithoutSourceVideoInput;
  update: TranslatedVideoUpdateWithoutSourceVideoDataInput;
  where: TranslatedVideoWhereUniqueInput;
};

export type TranslatedVideoUpsertWithoutNotesInput = {
  create: TranslatedVideoCreateWithoutNotesInput;
  update: TranslatedVideoUpdateWithoutNotesDataInput;
};

export type TranslatedVideoUpsertWithoutOnScreenTextsInput = {
  create: TranslatedVideoCreateWithoutOnScreenTextsInput;
  update: TranslatedVideoUpdateWithoutOnScreenTextsDataInput;
};

export type TranslatedVideoUpsertWithoutOutputGroupsInput = {
  create: TranslatedVideoCreateWithoutOutputGroupsInput;
  update: TranslatedVideoUpdateWithoutOutputGroupsDataInput;
};

export type TranslatedVideoUpsertWithoutSegmentsInput = {
  create: TranslatedVideoCreateWithoutSegmentsInput;
  update: TranslatedVideoUpdateWithoutSegmentsDataInput;
};

export type TranslatedVideoUpsertWithoutSnapshotsInput = {
  create: TranslatedVideoCreateWithoutSnapshotsInput;
  update: TranslatedVideoUpdateWithoutSnapshotsDataInput;
};

export type TranslatedVideoWhereInput = {
  AND?: InputMaybe<Array<TranslatedVideoWhereInput>>;
  NOT?: InputMaybe<Array<TranslatedVideoWhereInput>>;
  OR?: InputMaybe<Array<TranslatedVideoWhereInput>>;
  addedBy?: InputMaybe<UserWhereInput>;
  asrModel?: InputMaybe<Scalars['String']['input']>;
  asrModel_contains?: InputMaybe<Scalars['String']['input']>;
  asrModel_ends_with?: InputMaybe<Scalars['String']['input']>;
  asrModel_gt?: InputMaybe<Scalars['String']['input']>;
  asrModel_gte?: InputMaybe<Scalars['String']['input']>;
  asrModel_in?: InputMaybe<Array<Scalars['String']['input']>>;
  asrModel_lt?: InputMaybe<Scalars['String']['input']>;
  asrModel_lte?: InputMaybe<Scalars['String']['input']>;
  asrModel_not?: InputMaybe<Scalars['String']['input']>;
  asrModel_not_contains?: InputMaybe<Scalars['String']['input']>;
  asrModel_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  asrModel_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  asrModel_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  asrModel_starts_with?: InputMaybe<Scalars['String']['input']>;
  audibleOverlaps?: InputMaybe<Scalars['Boolean']['input']>;
  audibleOverlaps_not?: InputMaybe<Scalars['Boolean']['input']>;
  audioEngineer?: InputMaybe<UserWhereInput>;
  audioEngineerDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  audioEngineerDueDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  audioEngineerDueDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  audioEngineerDueDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  audioEngineerDueDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  audioEngineerDueDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  audioEngineerDueDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  audioEngineerDueDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  channel?: InputMaybe<TranslatedChannelWhereInput>;
  context?: InputMaybe<ContextWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  dueDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  editor?: InputMaybe<UserWhereInput>;
  editorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorDueDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  editorDueDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  editorDueDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  editorDueDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  editorDueDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  editorDueDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  editorDueDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  editorQC?: InputMaybe<UserWhereInput>;
  editorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  editorQCDueDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  editorQCDueDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  editorQCDueDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  editorQCDueDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  editorQCDueDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  editorQCDueDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  editorQCDueDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  hasInitialAutoStretchRate?: InputMaybe<Scalars['Boolean']['input']>;
  hasInitialAutoStretchRate_not?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  invoiceItem?: InputMaybe<InvoiceItemWhereInput>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isDatamodelV3_not?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isStudioStagesEnabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  mappedSpeakersResponse?: InputMaybe<FileWhereInput>;
  markedCompleteBy?: InputMaybe<UserWhereInput>;
  notes_every?: InputMaybe<NoteWhereInput>;
  notes_none?: InputMaybe<NoteWhereInput>;
  notes_some?: InputMaybe<NoteWhereInput>;
  onScreenTexts_every?: InputMaybe<OnScreenTextTranslationWhereInput>;
  onScreenTexts_none?: InputMaybe<OnScreenTextTranslationWhereInput>;
  onScreenTexts_some?: InputMaybe<OnScreenTextTranslationWhereInput>;
  outputGroups_every?: InputMaybe<OutputGroupWhereInput>;
  outputGroups_none?: InputMaybe<OutputGroupWhereInput>;
  outputGroups_some?: InputMaybe<OutputGroupWhereInput>;
  prepareSegmentsMethod?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsMethod_in?: InputMaybe<Array<PrepareSegmentsMethod>>;
  prepareSegmentsMethod_not?: InputMaybe<PrepareSegmentsMethod>;
  prepareSegmentsMethod_not_in?: InputMaybe<Array<PrepareSegmentsMethod>>;
  prepareSegmentsResponse?: InputMaybe<FileWhereInput>;
  qaer?: InputMaybe<UserWhereInput>;
  qc?: InputMaybe<UserWhereInput>;
  qcDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  qcDueDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  qcDueDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  qcDueDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  qcDueDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  qcDueDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  qcDueDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  qcDueDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  requiresAnEditor?: InputMaybe<Scalars['Boolean']['input']>;
  requiresAnEditor_not?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<UserWhereInput>;
  s3root?: InputMaybe<Scalars['String']['input']>;
  s3root_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_gt?: InputMaybe<Scalars['String']['input']>;
  s3root_gte?: InputMaybe<Scalars['String']['input']>;
  s3root_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_lt?: InputMaybe<Scalars['String']['input']>;
  s3root_lte?: InputMaybe<Scalars['String']['input']>;
  s3root_not?: InputMaybe<Scalars['String']['input']>;
  s3root_not_contains?: InputMaybe<Scalars['String']['input']>;
  s3root_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3root_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3root_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  s3root_starts_with?: InputMaybe<Scalars['String']['input']>;
  segments_every?: InputMaybe<SegmentWhereInput>;
  segments_none?: InputMaybe<SegmentWhereInput>;
  segments_some?: InputMaybe<SegmentWhereInput>;
  shouldAutoGenerateInitialOutput?: InputMaybe<Scalars['Boolean']['input']>;
  shouldAutoGenerateInitialOutput_not?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio?: InputMaybe<Scalars['Boolean']['input']>;
  shouldTranscribeAudio_not?: InputMaybe<Scalars['Boolean']['input']>;
  snapshots_every?: InputMaybe<TranslatedVideoSnapshotWhereInput>;
  snapshots_none?: InputMaybe<TranslatedVideoSnapshotWhereInput>;
  snapshots_some?: InputMaybe<TranslatedVideoSnapshotWhereInput>;
  sourceVideo?: InputMaybe<SourceVideoWhereInput>;
  stateValue?: InputMaybe<TranslatedVideoState>;
  stateValue_in?: InputMaybe<Array<TranslatedVideoState>>;
  stateValue_not?: InputMaybe<TranslatedVideoState>;
  stateValue_not_in?: InputMaybe<Array<TranslatedVideoState>>;
  targetMinutePerMinute?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_gt?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_gte?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  targetMinutePerMinute_lt?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_lte?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_not?: InputMaybe<Scalars['Float']['input']>;
  targetMinutePerMinute_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  targetTranscriptFile?: InputMaybe<FileWhereInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_gt?: InputMaybe<Scalars['String']['input']>;
  title_gte?: InputMaybe<Scalars['String']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_lt?: InputMaybe<Scalars['String']['input']>;
  title_lte?: InputMaybe<Scalars['String']['input']>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedDescription?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_contains?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_gt?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_gte?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedDescription_lt?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_lte?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_not?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedDescription_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedTitle?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_contains?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_gt?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_gte?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedTitle_lt?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_lte?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_not?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  translatedTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  translatedTitle_starts_with?: InputMaybe<Scalars['String']['input']>;
  tvStudioStageState?: InputMaybe<TvStudioStageState>;
  tvStudioStageState_in?: InputMaybe<Array<TvStudioStageState>>;
  tvStudioStageState_not?: InputMaybe<TvStudioStageState>;
  tvStudioStageState_not_in?: InputMaybe<Array<TvStudioStageState>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  videoComplexityCalculated?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityCalculated_gt?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityCalculated_gte?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityCalculated_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  videoComplexityCalculated_lt?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityCalculated_lte?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityCalculated_not?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityCalculated_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  videoComplexityQA?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA_gt?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA_gte?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  videoComplexityQA_lt?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA_lte?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA_not?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQA_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  videoComplexityQC?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC_gt?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC_gte?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  videoComplexityQC_lt?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC_lte?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC_not?: InputMaybe<Scalars['Int']['input']>;
  videoComplexityQC_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  videoComplexityRating_every?: InputMaybe<VideoComplexityRatingWhereInput>;
  videoComplexityRating_none?: InputMaybe<VideoComplexityRatingWhereInput>;
  videoComplexityRating_some?: InputMaybe<VideoComplexityRatingWhereInput>;
  voiceActor?: InputMaybe<UserWhereInput>;
  voiceActorDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorDueDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorDueDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorDueDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  voiceActorDueDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorDueDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorDueDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorDueDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  voiceActorQC?: InputMaybe<UserWhereInput>;
  voiceActorQCDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQCDueDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQCDueDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQCDueDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  voiceActorQCDueDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQCDueDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQCDueDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  voiceActorQCDueDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  wasMigratedToV3?: InputMaybe<Scalars['Boolean']['input']>;
  wasMigratedToV3_not?: InputMaybe<Scalars['Boolean']['input']>;
  workableDurationSec?: InputMaybe<Scalars['Int']['input']>;
  workableDurationSec_gt?: InputMaybe<Scalars['Int']['input']>;
  workableDurationSec_gte?: InputMaybe<Scalars['Int']['input']>;
  workableDurationSec_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  workableDurationSec_lt?: InputMaybe<Scalars['Int']['input']>;
  workableDurationSec_lte?: InputMaybe<Scalars['Int']['input']>;
  workableDurationSec_not?: InputMaybe<Scalars['Int']['input']>;
  workableDurationSec_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_contains?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_ends_with?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_gt?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_gte?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ytVideoId_lt?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_lte?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not_contains?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ytVideoId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  ytVideoId_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedVideoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  ytVideoId?: InputMaybe<Scalars['String']['input']>;
};

export type Translation = {
  /** translated channel id */
  channelId: Scalars['String']['input'];
  /** Due date accepted any format that is used by moment: https://momentjs.com/guides/#/parsing/ */
  dueDate?: InputMaybe<Scalars['String']['input']>;
  /** was a target transcript uploaded */
  hasTargetTranscript?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TtsSource {
  Google = 'Google',
  Microsoft = 'Microsoft',
  Papercup = 'Papercup'
}

export enum TypicalVideoDuration {
  Long = 'Long',
  Medium = 'Medium',
  Short = 'Short'
}

export type User = {
  __typename?: 'User';
  auth0Id?: Maybe<Scalars['String']['output']>;
  cognitoSub: Scalars['String']['output'];
  cognitoUsername: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** Email address */
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  mimicActorId?: Maybe<Scalars['String']['output']>;
  /** Name */
  name: Scalars['String']['output'];
  nativeLanguage?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Role>>;
  updatedAt: Scalars['DateTime']['output'];
};


export type UserRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoleWhereInput>;
};

export type UserCreateInput = {
  auth0Id?: InputMaybe<Scalars['String']['input']>;
  cognitoSub: Scalars['String']['input'];
  cognitoUsername: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  mimicActorId?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<RoleCreateManyInput>;
};

export type UserCreateOneInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<UserCreateInput>;
};

export enum UserOrderByInput {
  Auth0IdAsc = 'auth0Id_ASC',
  Auth0IdDesc = 'auth0Id_DESC',
  CognitoSubAsc = 'cognitoSub_ASC',
  CognitoSubDesc = 'cognitoSub_DESC',
  CognitoUsernameAsc = 'cognitoUsername_ASC',
  CognitoUsernameDesc = 'cognitoUsername_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsArchivedAsc = 'isArchived_ASC',
  IsArchivedDesc = 'isArchived_DESC',
  MimicActorIdAsc = 'mimicActorId_ASC',
  MimicActorIdDesc = 'mimicActorId_DESC',
  NativeLanguageAsc = 'nativeLanguage_ASC',
  NativeLanguageDesc = 'nativeLanguage_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export enum UserRole {
  Admin = 'Admin',
  AudioEngineer = 'AudioEngineer',
  ChannelManager = 'ChannelManager',
  ChannelOwner = 'ChannelOwner',
  Editor = 'Editor',
  EditorQc = 'EditorQC',
  QAer = 'QAer',
  Qc = 'QC',
  SuperUser = 'SuperUser',
  Unknown = 'Unknown',
  VoiceActor = 'VoiceActor',
  VoiceActorQc = 'VoiceActorQC'
}

export type UserUpdateDataInput = {
  auth0Id?: InputMaybe<Scalars['String']['input']>;
  cognitoSub?: InputMaybe<Scalars['String']['input']>;
  cognitoUsername?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  mimicActorId?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<RoleUpdateManyInput>;
};

export type UserUpdateInput = {
  auth0Id?: InputMaybe<Scalars['String']['input']>;
  cognitoSub?: InputMaybe<Scalars['String']['input']>;
  cognitoUsername?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  mimicActorId?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<RoleUpdateManyInput>;
};

export type UserUpdateOneInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<UserCreateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateDataInput>;
  upsert?: InputMaybe<UserUpsertNestedInput>;
};

export type UserUpdateOneRequiredInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<UserCreateInput>;
  update?: InputMaybe<UserUpdateDataInput>;
  upsert?: InputMaybe<UserUpsertNestedInput>;
};

export type UserUpsertNestedInput = {
  create: UserCreateInput;
  update: UserUpdateDataInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  auth0Id?: InputMaybe<Scalars['String']['input']>;
  auth0Id_contains?: InputMaybe<Scalars['String']['input']>;
  auth0Id_ends_with?: InputMaybe<Scalars['String']['input']>;
  auth0Id_gt?: InputMaybe<Scalars['String']['input']>;
  auth0Id_gte?: InputMaybe<Scalars['String']['input']>;
  auth0Id_in?: InputMaybe<Array<Scalars['String']['input']>>;
  auth0Id_lt?: InputMaybe<Scalars['String']['input']>;
  auth0Id_lte?: InputMaybe<Scalars['String']['input']>;
  auth0Id_not?: InputMaybe<Scalars['String']['input']>;
  auth0Id_not_contains?: InputMaybe<Scalars['String']['input']>;
  auth0Id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  auth0Id_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  auth0Id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  auth0Id_starts_with?: InputMaybe<Scalars['String']['input']>;
  cognitoSub?: InputMaybe<Scalars['String']['input']>;
  cognitoSub_contains?: InputMaybe<Scalars['String']['input']>;
  cognitoSub_ends_with?: InputMaybe<Scalars['String']['input']>;
  cognitoSub_gt?: InputMaybe<Scalars['String']['input']>;
  cognitoSub_gte?: InputMaybe<Scalars['String']['input']>;
  cognitoSub_in?: InputMaybe<Array<Scalars['String']['input']>>;
  cognitoSub_lt?: InputMaybe<Scalars['String']['input']>;
  cognitoSub_lte?: InputMaybe<Scalars['String']['input']>;
  cognitoSub_not?: InputMaybe<Scalars['String']['input']>;
  cognitoSub_not_contains?: InputMaybe<Scalars['String']['input']>;
  cognitoSub_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  cognitoSub_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  cognitoSub_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  cognitoSub_starts_with?: InputMaybe<Scalars['String']['input']>;
  cognitoUsername?: InputMaybe<Scalars['String']['input']>;
  cognitoUsername_contains?: InputMaybe<Scalars['String']['input']>;
  cognitoUsername_ends_with?: InputMaybe<Scalars['String']['input']>;
  cognitoUsername_gt?: InputMaybe<Scalars['String']['input']>;
  cognitoUsername_gte?: InputMaybe<Scalars['String']['input']>;
  cognitoUsername_in?: InputMaybe<Array<Scalars['String']['input']>>;
  cognitoUsername_lt?: InputMaybe<Scalars['String']['input']>;
  cognitoUsername_lte?: InputMaybe<Scalars['String']['input']>;
  cognitoUsername_not?: InputMaybe<Scalars['String']['input']>;
  cognitoUsername_not_contains?: InputMaybe<Scalars['String']['input']>;
  cognitoUsername_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  cognitoUsername_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  cognitoUsername_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  cognitoUsername_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived_not?: InputMaybe<Scalars['Boolean']['input']>;
  mimicActorId?: InputMaybe<Scalars['String']['input']>;
  mimicActorId_contains?: InputMaybe<Scalars['String']['input']>;
  mimicActorId_ends_with?: InputMaybe<Scalars['String']['input']>;
  mimicActorId_gt?: InputMaybe<Scalars['String']['input']>;
  mimicActorId_gte?: InputMaybe<Scalars['String']['input']>;
  mimicActorId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  mimicActorId_lt?: InputMaybe<Scalars['String']['input']>;
  mimicActorId_lte?: InputMaybe<Scalars['String']['input']>;
  mimicActorId_not?: InputMaybe<Scalars['String']['input']>;
  mimicActorId_not_contains?: InputMaybe<Scalars['String']['input']>;
  mimicActorId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  mimicActorId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  mimicActorId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  mimicActorId_starts_with?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage_contains?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage_ends_with?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage_gt?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage_gte?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  nativeLanguage_lt?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage_lte?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage_not?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage_not_contains?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  nativeLanguage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  nativeLanguage_starts_with?: InputMaybe<Scalars['String']['input']>;
  roles_every?: InputMaybe<RoleWhereInput>;
  roles_none?: InputMaybe<RoleWhereInput>;
  roles_some?: InputMaybe<RoleWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type UserWhereUniqueInput = {
  auth0Id?: InputMaybe<Scalars['String']['input']>;
  cognitoSub?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type VideoComplexityRating = {
  __typename?: 'VideoComplexityRating';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  maxRating: Scalars['Int']['output'];
  minRating: Scalars['Int']['output'];
  qaer: User;
  rating: Scalars['Int']['output'];
  reason: Scalars['String']['output'];
  reviewerRole: ReviewerRole;
  translatedVideo: TranslatedVideo;
  updatedAt: Scalars['DateTime']['output'];
};

export type VideoComplexityRatingCreateManyWithoutTranslatedVideoInput = {
  connect?: InputMaybe<Array<VideoComplexityRatingWhereUniqueInput>>;
  create?: InputMaybe<Array<VideoComplexityRatingCreateWithoutTranslatedVideoInput>>;
};

export type VideoComplexityRatingCreateWithoutTranslatedVideoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  maxRating: Scalars['Int']['input'];
  minRating: Scalars['Int']['input'];
  qaer: UserCreateOneInput;
  rating: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
  reviewerRole: ReviewerRole;
};

export type VideoComplexityRatingInput = {
  rating: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
};

export enum VideoComplexityRatingOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MaxRatingAsc = 'maxRating_ASC',
  MaxRatingDesc = 'maxRating_DESC',
  MinRatingAsc = 'minRating_ASC',
  MinRatingDesc = 'minRating_DESC',
  RatingAsc = 'rating_ASC',
  RatingDesc = 'rating_DESC',
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC',
  ReviewerRoleAsc = 'reviewerRole_ASC',
  ReviewerRoleDesc = 'reviewerRole_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type VideoComplexityRatingScalarWhereInput = {
  AND?: InputMaybe<Array<VideoComplexityRatingScalarWhereInput>>;
  NOT?: InputMaybe<Array<VideoComplexityRatingScalarWhereInput>>;
  OR?: InputMaybe<Array<VideoComplexityRatingScalarWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  maxRating?: InputMaybe<Scalars['Int']['input']>;
  maxRating_gt?: InputMaybe<Scalars['Int']['input']>;
  maxRating_gte?: InputMaybe<Scalars['Int']['input']>;
  maxRating_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  maxRating_lt?: InputMaybe<Scalars['Int']['input']>;
  maxRating_lte?: InputMaybe<Scalars['Int']['input']>;
  maxRating_not?: InputMaybe<Scalars['Int']['input']>;
  maxRating_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  minRating?: InputMaybe<Scalars['Int']['input']>;
  minRating_gt?: InputMaybe<Scalars['Int']['input']>;
  minRating_gte?: InputMaybe<Scalars['Int']['input']>;
  minRating_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  minRating_lt?: InputMaybe<Scalars['Int']['input']>;
  minRating_lte?: InputMaybe<Scalars['Int']['input']>;
  minRating_not?: InputMaybe<Scalars['Int']['input']>;
  minRating_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  rating_gt?: InputMaybe<Scalars['Int']['input']>;
  rating_gte?: InputMaybe<Scalars['Int']['input']>;
  rating_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  rating_lt?: InputMaybe<Scalars['Int']['input']>;
  rating_lte?: InputMaybe<Scalars['Int']['input']>;
  rating_not?: InputMaybe<Scalars['Int']['input']>;
  rating_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  reason?: InputMaybe<Scalars['String']['input']>;
  reason_contains?: InputMaybe<Scalars['String']['input']>;
  reason_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_gt?: InputMaybe<Scalars['String']['input']>;
  reason_gte?: InputMaybe<Scalars['String']['input']>;
  reason_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_lt?: InputMaybe<Scalars['String']['input']>;
  reason_lte?: InputMaybe<Scalars['String']['input']>;
  reason_not?: InputMaybe<Scalars['String']['input']>;
  reason_not_contains?: InputMaybe<Scalars['String']['input']>;
  reason_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  reason_starts_with?: InputMaybe<Scalars['String']['input']>;
  reviewerRole?: InputMaybe<ReviewerRole>;
  reviewerRole_in?: InputMaybe<Array<ReviewerRole>>;
  reviewerRole_not?: InputMaybe<ReviewerRole>;
  reviewerRole_not_in?: InputMaybe<Array<ReviewerRole>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type VideoComplexityRatingUpdateManyDataInput = {
  maxRating?: InputMaybe<Scalars['Int']['input']>;
  minRating?: InputMaybe<Scalars['Int']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  reviewerRole?: InputMaybe<ReviewerRole>;
};

export type VideoComplexityRatingUpdateManyWithWhereNestedInput = {
  data: VideoComplexityRatingUpdateManyDataInput;
  where: VideoComplexityRatingScalarWhereInput;
};

export type VideoComplexityRatingUpdateManyWithoutTranslatedVideoInput = {
  connect?: InputMaybe<Array<VideoComplexityRatingWhereUniqueInput>>;
  create?: InputMaybe<Array<VideoComplexityRatingCreateWithoutTranslatedVideoInput>>;
  delete?: InputMaybe<Array<VideoComplexityRatingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VideoComplexityRatingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VideoComplexityRatingWhereUniqueInput>>;
  set?: InputMaybe<Array<VideoComplexityRatingWhereUniqueInput>>;
  update?: InputMaybe<Array<VideoComplexityRatingUpdateWithWhereUniqueWithoutTranslatedVideoInput>>;
  updateMany?: InputMaybe<Array<VideoComplexityRatingUpdateManyWithWhereNestedInput>>;
  upsert?: InputMaybe<Array<VideoComplexityRatingUpsertWithWhereUniqueWithoutTranslatedVideoInput>>;
};

export type VideoComplexityRatingUpdateWithWhereUniqueWithoutTranslatedVideoInput = {
  data: VideoComplexityRatingUpdateWithoutTranslatedVideoDataInput;
  where: VideoComplexityRatingWhereUniqueInput;
};

export type VideoComplexityRatingUpdateWithoutTranslatedVideoDataInput = {
  maxRating?: InputMaybe<Scalars['Int']['input']>;
  minRating?: InputMaybe<Scalars['Int']['input']>;
  qaer?: InputMaybe<UserUpdateOneRequiredInput>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  reviewerRole?: InputMaybe<ReviewerRole>;
};

export type VideoComplexityRatingUpsertWithWhereUniqueWithoutTranslatedVideoInput = {
  create: VideoComplexityRatingCreateWithoutTranslatedVideoInput;
  update: VideoComplexityRatingUpdateWithoutTranslatedVideoDataInput;
  where: VideoComplexityRatingWhereUniqueInput;
};

export type VideoComplexityRatingWhereInput = {
  AND?: InputMaybe<Array<VideoComplexityRatingWhereInput>>;
  NOT?: InputMaybe<Array<VideoComplexityRatingWhereInput>>;
  OR?: InputMaybe<Array<VideoComplexityRatingWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  maxRating?: InputMaybe<Scalars['Int']['input']>;
  maxRating_gt?: InputMaybe<Scalars['Int']['input']>;
  maxRating_gte?: InputMaybe<Scalars['Int']['input']>;
  maxRating_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  maxRating_lt?: InputMaybe<Scalars['Int']['input']>;
  maxRating_lte?: InputMaybe<Scalars['Int']['input']>;
  maxRating_not?: InputMaybe<Scalars['Int']['input']>;
  maxRating_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  minRating?: InputMaybe<Scalars['Int']['input']>;
  minRating_gt?: InputMaybe<Scalars['Int']['input']>;
  minRating_gte?: InputMaybe<Scalars['Int']['input']>;
  minRating_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  minRating_lt?: InputMaybe<Scalars['Int']['input']>;
  minRating_lte?: InputMaybe<Scalars['Int']['input']>;
  minRating_not?: InputMaybe<Scalars['Int']['input']>;
  minRating_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  qaer?: InputMaybe<UserWhereInput>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  rating_gt?: InputMaybe<Scalars['Int']['input']>;
  rating_gte?: InputMaybe<Scalars['Int']['input']>;
  rating_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  rating_lt?: InputMaybe<Scalars['Int']['input']>;
  rating_lte?: InputMaybe<Scalars['Int']['input']>;
  rating_not?: InputMaybe<Scalars['Int']['input']>;
  rating_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  reason?: InputMaybe<Scalars['String']['input']>;
  reason_contains?: InputMaybe<Scalars['String']['input']>;
  reason_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_gt?: InputMaybe<Scalars['String']['input']>;
  reason_gte?: InputMaybe<Scalars['String']['input']>;
  reason_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_lt?: InputMaybe<Scalars['String']['input']>;
  reason_lte?: InputMaybe<Scalars['String']['input']>;
  reason_not?: InputMaybe<Scalars['String']['input']>;
  reason_not_contains?: InputMaybe<Scalars['String']['input']>;
  reason_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  reason_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reason_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  reason_starts_with?: InputMaybe<Scalars['String']['input']>;
  reviewerRole?: InputMaybe<ReviewerRole>;
  reviewerRole_in?: InputMaybe<Array<ReviewerRole>>;
  reviewerRole_not?: InputMaybe<ReviewerRole>;
  reviewerRole_not_in?: InputMaybe<Array<ReviewerRole>>;
  translatedVideo?: InputMaybe<TranslatedVideoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type VideoComplexityRatingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Video Properties from ffprobe. */
export type VideoProperties = {
  __typename?: 'VideoProperties';
  /** Average frame rate. total duration / total # of frame */
  avg_frame_rate: Scalars['String']['output'];
  /** Returns a long representation of the stream codec. */
  codec_long_name: Scalars['String']['output'];
  /** Returns a string representation of the stream codec. */
  codec_name: Scalars['String']['output'];
  /** Returns a short representative tag of the stream codec. */
  codec_tag_string: Scalars['String']['output'];
  codec_type: Scalars['String']['output'];
  coded_height: Scalars['Int']['output'];
  coded_width: Scalars['Int']['output'];
  display_aspect_ratio: Scalars['String']['output'];
  duration: Scalars['String']['output'];
  duration_ts: Scalars['Int']['output'];
  height: Scalars['Int']['output'];
  nb_frames: Scalars['String']['output'];
  /**
   * Real base framerate of the stream.
   *
   * This is the lowest framerate with which all timestamps can be represented accurately (it is the least common multiple of all framerates in the stream). Note, this value is just a guess! For example, if the time base is 1/90000 and all frames have either approximately 3600 or 1800 timer ticks, then r_frame_rate will be 50/1.
   */
  r_frame_rate: Scalars['String']['output'];
  sample_aspect_ratio: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export enum VideoSnapshotType {
  InitialSegments = 'InitialSegments',
  PostInitialAutoStretch = 'PostInitialAutoStretch',
  PostStickyNotes = 'PostStickyNotes',
  Test = 'Test'
}

export type VocoderOutput = {
  __typename?: 'VocoderOutput';
  audioFile?: Maybe<File>;
  createdAt: Scalars['DateTime']['output'];
  effectUnits?: Maybe<Array<EffectUnit>>;
  failureMessage?: Maybe<Scalars['String']['output']>;
  featuresData: FeaturesData;
  generatingPriority: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  inputsHash: Scalars['String']['output'];
  modelName?: Maybe<Scalars['String']['output']>;
  processingDurationMs?: Maybe<Scalars['Float']['output']>;
  processingEndTime?: Maybe<Scalars['DateTime']['output']>;
  processingStartTime?: Maybe<Scalars['DateTime']['output']>;
  speechOutputs?: Maybe<Array<SpeechOutput>>;
  stateEnum: GeneratingState;
  updatedAt: Scalars['DateTime']['output'];
  vocoderType: VocoderType;
};


export type VocoderOutputEffectUnitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EffectUnitOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EffectUnitWhereInput>;
};


export type VocoderOutputSpeechOutputsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpeechOutputOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpeechOutputWhereInput>;
};

export type VocoderOutputCreateOneWithoutEffectUnitsInput = {
  connect?: InputMaybe<VocoderOutputWhereUniqueInput>;
  create?: InputMaybe<VocoderOutputCreateWithoutEffectUnitsInput>;
};

export type VocoderOutputCreateOneWithoutSpeechOutputsInput = {
  connect?: InputMaybe<VocoderOutputWhereUniqueInput>;
  create?: InputMaybe<VocoderOutputCreateWithoutSpeechOutputsInput>;
};

export type VocoderOutputCreateWithoutEffectUnitsInput = {
  audioFile?: InputMaybe<FileCreateOneInput>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  featuresData: FeaturesDataCreateOneWithoutVocoderOutputsInput;
  generatingPriority: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  inputsHash: Scalars['String']['input'];
  modelName?: InputMaybe<Scalars['String']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputCreateManyWithoutVocoderOutputInput>;
  stateEnum?: InputMaybe<GeneratingState>;
  vocoderType: VocoderType;
};

export type VocoderOutputCreateWithoutSpeechOutputsInput = {
  audioFile?: InputMaybe<FileCreateOneInput>;
  effectUnits?: InputMaybe<EffectUnitCreateManyWithoutVocoderOutputInput>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  featuresData: FeaturesDataCreateOneWithoutVocoderOutputsInput;
  generatingPriority: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  inputsHash: Scalars['String']['input'];
  modelName?: InputMaybe<Scalars['String']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  stateEnum?: InputMaybe<GeneratingState>;
  vocoderType: VocoderType;
};

export enum VocoderOutputOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FailureMessageAsc = 'failureMessage_ASC',
  FailureMessageDesc = 'failureMessage_DESC',
  GeneratingPriorityAsc = 'generatingPriority_ASC',
  GeneratingPriorityDesc = 'generatingPriority_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InputsHashAsc = 'inputsHash_ASC',
  InputsHashDesc = 'inputsHash_DESC',
  ModelNameAsc = 'modelName_ASC',
  ModelNameDesc = 'modelName_DESC',
  ProcessingDurationMsAsc = 'processingDurationMs_ASC',
  ProcessingDurationMsDesc = 'processingDurationMs_DESC',
  ProcessingEndTimeAsc = 'processingEndTime_ASC',
  ProcessingEndTimeDesc = 'processingEndTime_DESC',
  ProcessingStartTimeAsc = 'processingStartTime_ASC',
  ProcessingStartTimeDesc = 'processingStartTime_DESC',
  StateEnumAsc = 'stateEnum_ASC',
  StateEnumDesc = 'stateEnum_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VocoderTypeAsc = 'vocoderType_ASC',
  VocoderTypeDesc = 'vocoderType_DESC'
}

export type VocoderOutputUpdateOneRequiredWithoutEffectUnitsInput = {
  connect?: InputMaybe<VocoderOutputWhereUniqueInput>;
  create?: InputMaybe<VocoderOutputCreateWithoutEffectUnitsInput>;
  update?: InputMaybe<VocoderOutputUpdateWithoutEffectUnitsDataInput>;
  upsert?: InputMaybe<VocoderOutputUpsertWithoutEffectUnitsInput>;
};

export type VocoderOutputUpdateOneWithoutSpeechOutputsInput = {
  connect?: InputMaybe<VocoderOutputWhereUniqueInput>;
  create?: InputMaybe<VocoderOutputCreateWithoutSpeechOutputsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<VocoderOutputUpdateWithoutSpeechOutputsDataInput>;
  upsert?: InputMaybe<VocoderOutputUpsertWithoutSpeechOutputsInput>;
};

export type VocoderOutputUpdateWithoutEffectUnitsDataInput = {
  audioFile?: InputMaybe<FileUpdateOneInput>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  featuresData?: InputMaybe<FeaturesDataUpdateOneRequiredWithoutVocoderOutputsInput>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyWithoutVocoderOutputInput>;
  stateEnum?: InputMaybe<GeneratingState>;
  vocoderType?: InputMaybe<VocoderType>;
};

export type VocoderOutputUpdateWithoutSpeechOutputsDataInput = {
  audioFile?: InputMaybe<FileUpdateOneInput>;
  effectUnits?: InputMaybe<EffectUnitUpdateManyWithoutVocoderOutputInput>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  featuresData?: InputMaybe<FeaturesDataUpdateOneRequiredWithoutVocoderOutputsInput>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  stateEnum?: InputMaybe<GeneratingState>;
  vocoderType?: InputMaybe<VocoderType>;
};

export type VocoderOutputUpsertWithoutEffectUnitsInput = {
  create: VocoderOutputCreateWithoutEffectUnitsInput;
  update: VocoderOutputUpdateWithoutEffectUnitsDataInput;
};

export type VocoderOutputUpsertWithoutSpeechOutputsInput = {
  create: VocoderOutputCreateWithoutSpeechOutputsInput;
  update: VocoderOutputUpdateWithoutSpeechOutputsDataInput;
};

export type VocoderOutputWhereInput = {
  AND?: InputMaybe<Array<VocoderOutputWhereInput>>;
  NOT?: InputMaybe<Array<VocoderOutputWhereInput>>;
  OR?: InputMaybe<Array<VocoderOutputWhereInput>>;
  audioFile?: InputMaybe<FileWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  effectUnits_every?: InputMaybe<EffectUnitWhereInput>;
  effectUnits_none?: InputMaybe<EffectUnitWhereInput>;
  effectUnits_some?: InputMaybe<EffectUnitWhereInput>;
  failureMessage?: InputMaybe<Scalars['String']['input']>;
  failureMessage_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_gte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_lt?: InputMaybe<Scalars['String']['input']>;
  failureMessage_lte?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  failureMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  failureMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
  featuresData?: InputMaybe<FeaturesDataWhereInput>;
  generatingPriority?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_gt?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_gte?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  generatingPriority_lt?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_lte?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_not?: InputMaybe<Scalars['Float']['input']>;
  generatingPriority_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
  inputsHash_contains?: InputMaybe<Scalars['String']['input']>;
  inputsHash_ends_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_gt?: InputMaybe<Scalars['String']['input']>;
  inputsHash_gte?: InputMaybe<Scalars['String']['input']>;
  inputsHash_in?: InputMaybe<Array<Scalars['String']['input']>>;
  inputsHash_lt?: InputMaybe<Scalars['String']['input']>;
  inputsHash_lte?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_contains?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  inputsHash_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  inputsHash_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  modelName_contains?: InputMaybe<Scalars['String']['input']>;
  modelName_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelName_gt?: InputMaybe<Scalars['String']['input']>;
  modelName_gte?: InputMaybe<Scalars['String']['input']>;
  modelName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelName_lt?: InputMaybe<Scalars['String']['input']>;
  modelName_lte?: InputMaybe<Scalars['String']['input']>;
  modelName_not?: InputMaybe<Scalars['String']['input']>;
  modelName_not_contains?: InputMaybe<Scalars['String']['input']>;
  modelName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  modelName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  modelName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  modelName_starts_with?: InputMaybe<Scalars['String']['input']>;
  processingDurationMs?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_gte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingDurationMs_lt?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_lte?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not?: InputMaybe<Scalars['Float']['input']>;
  processingDurationMs_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  processingEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingEndTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingEndTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  processingStartTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  processingStartTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  speechOutputs_every?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_none?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_some?: InputMaybe<SpeechOutputWhereInput>;
  stateEnum?: InputMaybe<GeneratingState>;
  stateEnum_in?: InputMaybe<Array<GeneratingState>>;
  stateEnum_not?: InputMaybe<GeneratingState>;
  stateEnum_not_in?: InputMaybe<Array<GeneratingState>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  vocoderType?: InputMaybe<VocoderType>;
  vocoderType_in?: InputMaybe<Array<VocoderType>>;
  vocoderType_not?: InputMaybe<VocoderType>;
  vocoderType_not_in?: InputMaybe<Array<VocoderType>>;
};

export type VocoderOutputWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  inputsHash?: InputMaybe<Scalars['String']['input']>;
};

export enum VocoderType {
  PreviewVoice = 'PreviewVoice',
  TunedVoice = 'TunedVoice'
}

export type Voice = {
  __typename?: 'Voice';
  age?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  gender: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDatamodelV3: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  phonemizationVersion?: Maybe<Scalars['String']['output']>;
  phonemizedText: Array<Scalars['Int']['output']>;
  samples?: Maybe<Array<Sample>>;
  speakerId: Scalars['String']['output'];
  speechOutputs?: Maybe<Array<SpeechOutput>>;
  /** Stage of life based on age */
  stageOfLife: Scalars['String']['output'];
  state: Scalars['Json']['output'];
  stateValue: VoiceState;
  styles: Array<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  ttsSourceEnum: TtsSource;
  updatedAt: Scalars['DateTime']['output'];
};


export type VoiceSamplesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SampleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SampleWhereInput>;
};


export type VoiceSpeechOutputsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpeechOutputOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpeechOutputWhereInput>;
};

export enum VoiceConversionTypeEnum {
  Advc = 'ADVC',
  Tdvc = 'TDVC'
}

export type VoiceCreateInput = {
  age?: InputMaybe<Scalars['Int']['input']>;
  gender: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<VoiceCreatephonemizedTextInput>;
  samples?: InputMaybe<SampleCreateManyInput>;
  speakerId: Scalars['String']['input'];
  speechOutputs?: InputMaybe<SpeechOutputCreateManyWithoutVoiceInput>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<VoiceState>;
  styles?: InputMaybe<VoiceCreatestylesInput>;
  text: Scalars['String']['input'];
  ttsSourceEnum?: InputMaybe<TtsSource>;
};

export type VoiceCreateOneInput = {
  connect?: InputMaybe<VoiceWhereUniqueInput>;
  create?: InputMaybe<VoiceCreateInput>;
};

export type VoiceCreateOneWithoutSpeechOutputsInput = {
  connect?: InputMaybe<VoiceWhereUniqueInput>;
  create?: InputMaybe<VoiceCreateWithoutSpeechOutputsInput>;
};

export type VoiceCreateWithoutSpeechOutputsInput = {
  age?: InputMaybe<Scalars['Int']['input']>;
  gender: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<VoiceCreatephonemizedTextInput>;
  samples?: InputMaybe<SampleCreateManyInput>;
  speakerId: Scalars['String']['input'];
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<VoiceState>;
  styles?: InputMaybe<VoiceCreatestylesInput>;
  text: Scalars['String']['input'];
  ttsSourceEnum?: InputMaybe<TtsSource>;
};

export type VoiceCreatephonemizedTextInput = {
  set?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type VoiceCreatestylesInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum VoiceOrderByInput {
  AgeAsc = 'age_ASC',
  AgeDesc = 'age_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  GenderAsc = 'gender_ASC',
  GenderDesc = 'gender_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsDatamodelV3Asc = 'isDatamodelV3_ASC',
  IsDatamodelV3Desc = 'isDatamodelV3_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  PhonemizationVersionAsc = 'phonemizationVersion_ASC',
  PhonemizationVersionDesc = 'phonemizationVersion_DESC',
  SpeakerIdAsc = 'speakerId_ASC',
  SpeakerIdDesc = 'speakerId_DESC',
  StateValueAsc = 'stateValue_ASC',
  StateValueDesc = 'stateValue_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TtsSourceEnumAsc = 'ttsSourceEnum_ASC',
  TtsSourceEnumDesc = 'ttsSourceEnum_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export enum VoiceState {
  Complete = 'Complete',
  GeneratingPhones = 'GeneratingPhones',
  Initial = 'Initial'
}

export type VoiceUpdateDataInput = {
  age?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<VoiceUpdatephonemizedTextInput>;
  samples?: InputMaybe<SampleUpdateManyInput>;
  speakerId?: InputMaybe<Scalars['String']['input']>;
  speechOutputs?: InputMaybe<SpeechOutputUpdateManyWithoutVoiceInput>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<VoiceState>;
  styles?: InputMaybe<VoiceUpdatestylesInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  ttsSourceEnum?: InputMaybe<TtsSource>;
};

export type VoiceUpdateOneRequiredInput = {
  connect?: InputMaybe<VoiceWhereUniqueInput>;
  create?: InputMaybe<VoiceCreateInput>;
  update?: InputMaybe<VoiceUpdateDataInput>;
  upsert?: InputMaybe<VoiceUpsertNestedInput>;
};

export type VoiceUpdateOneWithoutSpeechOutputsInput = {
  connect?: InputMaybe<VoiceWhereUniqueInput>;
  create?: InputMaybe<VoiceCreateWithoutSpeechOutputsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<VoiceUpdateWithoutSpeechOutputsDataInput>;
  upsert?: InputMaybe<VoiceUpsertWithoutSpeechOutputsInput>;
};

export type VoiceUpdateWithoutSpeechOutputsDataInput = {
  age?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizedText?: InputMaybe<VoiceUpdatephonemizedTextInput>;
  samples?: InputMaybe<SampleUpdateManyInput>;
  speakerId?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['Json']['input']>;
  stateValue?: InputMaybe<VoiceState>;
  styles?: InputMaybe<VoiceUpdatestylesInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  ttsSourceEnum?: InputMaybe<TtsSource>;
};

export type VoiceUpdatephonemizedTextInput = {
  set?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type VoiceUpdatestylesInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type VoiceUpsertNestedInput = {
  create: VoiceCreateInput;
  update: VoiceUpdateDataInput;
};

export type VoiceUpsertWithoutSpeechOutputsInput = {
  create: VoiceCreateWithoutSpeechOutputsInput;
  update: VoiceUpdateWithoutSpeechOutputsDataInput;
};

export type VoiceWhereInput = {
  AND?: InputMaybe<Array<VoiceWhereInput>>;
  NOT?: InputMaybe<Array<VoiceWhereInput>>;
  OR?: InputMaybe<Array<VoiceWhereInput>>;
  age?: InputMaybe<Scalars['Int']['input']>;
  age_gt?: InputMaybe<Scalars['Int']['input']>;
  age_gte?: InputMaybe<Scalars['Int']['input']>;
  age_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  age_lt?: InputMaybe<Scalars['Int']['input']>;
  age_lte?: InputMaybe<Scalars['Int']['input']>;
  age_not?: InputMaybe<Scalars['Int']['input']>;
  age_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  gender?: InputMaybe<Scalars['String']['input']>;
  gender_contains?: InputMaybe<Scalars['String']['input']>;
  gender_ends_with?: InputMaybe<Scalars['String']['input']>;
  gender_gt?: InputMaybe<Scalars['String']['input']>;
  gender_gte?: InputMaybe<Scalars['String']['input']>;
  gender_in?: InputMaybe<Array<Scalars['String']['input']>>;
  gender_lt?: InputMaybe<Scalars['String']['input']>;
  gender_lte?: InputMaybe<Scalars['String']['input']>;
  gender_not?: InputMaybe<Scalars['String']['input']>;
  gender_not_contains?: InputMaybe<Scalars['String']['input']>;
  gender_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  gender_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  gender_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  gender_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isDatamodelV3?: InputMaybe<Scalars['Boolean']['input']>;
  isDatamodelV3_not?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  language_contains?: InputMaybe<Scalars['String']['input']>;
  language_ends_with?: InputMaybe<Scalars['String']['input']>;
  language_gt?: InputMaybe<Scalars['String']['input']>;
  language_gte?: InputMaybe<Scalars['String']['input']>;
  language_in?: InputMaybe<Array<Scalars['String']['input']>>;
  language_lt?: InputMaybe<Scalars['String']['input']>;
  language_lte?: InputMaybe<Scalars['String']['input']>;
  language_not?: InputMaybe<Scalars['String']['input']>;
  language_not_contains?: InputMaybe<Scalars['String']['input']>;
  language_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  language_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  language_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  language_starts_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_contains?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_gt?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_gte?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phonemizationVersion_lt?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_lte?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phonemizationVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  phonemizationVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  samples_every?: InputMaybe<SampleWhereInput>;
  samples_none?: InputMaybe<SampleWhereInput>;
  samples_some?: InputMaybe<SampleWhereInput>;
  speakerId?: InputMaybe<Scalars['String']['input']>;
  speakerId_contains?: InputMaybe<Scalars['String']['input']>;
  speakerId_ends_with?: InputMaybe<Scalars['String']['input']>;
  speakerId_gt?: InputMaybe<Scalars['String']['input']>;
  speakerId_gte?: InputMaybe<Scalars['String']['input']>;
  speakerId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  speakerId_lt?: InputMaybe<Scalars['String']['input']>;
  speakerId_lte?: InputMaybe<Scalars['String']['input']>;
  speakerId_not?: InputMaybe<Scalars['String']['input']>;
  speakerId_not_contains?: InputMaybe<Scalars['String']['input']>;
  speakerId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  speakerId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  speakerId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  speakerId_starts_with?: InputMaybe<Scalars['String']['input']>;
  speechOutputs_every?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_none?: InputMaybe<SpeechOutputWhereInput>;
  speechOutputs_some?: InputMaybe<SpeechOutputWhereInput>;
  stateValue?: InputMaybe<VoiceState>;
  stateValue_in?: InputMaybe<Array<VoiceState>>;
  stateValue_not?: InputMaybe<VoiceState>;
  stateValue_not_in?: InputMaybe<Array<VoiceState>>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_gt?: InputMaybe<Scalars['String']['input']>;
  text_gte?: InputMaybe<Scalars['String']['input']>;
  text_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_lt?: InputMaybe<Scalars['String']['input']>;
  text_lte?: InputMaybe<Scalars['String']['input']>;
  text_not?: InputMaybe<Scalars['String']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  text_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  text_starts_with?: InputMaybe<Scalars['String']['input']>;
  ttsSourceEnum?: InputMaybe<TtsSource>;
  ttsSourceEnum_in?: InputMaybe<Array<TtsSource>>;
  ttsSourceEnum_not?: InputMaybe<TtsSource>;
  ttsSourceEnum_not_in?: InputMaybe<Array<TtsSource>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type VoiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  speakerId?: InputMaybe<Scalars['String']['input']>;
};

/** A channel resource from the YouTube Data API. */
export type YtChannel = {
  __typename?: 'YtChannel';
  /** The contentDetails object encapsulates information about the channel's content. */
  contentDetails: YtChannelContentDetails;
  /**
   * The channel's custom URL.
   *
   * A channel does not always have a customUrl.
   */
  customUrl?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  /** Indicates whether the channel's subscriber count is publicly visible. */
  hiddenSubscriberCount: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** The number of subscribers that the channel has. */
  subscriberCount: Scalars['Int']['output'];
  thumbnails: YtThumbnails;
  title: Scalars['String']['output'];
  /** YouTube does not always provide topic details for a channel. The topicDetails object encapsulates information about topics associated with the channel. */
  topicDetails?: Maybe<YtTopicDetails>;
  /** The URL of the channel */
  url: Scalars['String']['output'];
  /** The number of videos uploaded to the channel. */
  videoCount: Scalars['Int']['output'];
  /** The number of times the channel has been viewed. */
  viewCount: Scalars['JsSafeInt']['output'];
};

export type YtChannelContentDetails = {
  __typename?: 'YtChannelContentDetails';
  /** The relatedPlaylists object is a map that identifies playlists associated with the channel, such as the channel's uploaded videos or liked videos. */
  relatedPlaylists?: Maybe<YtRelatedPlaylists>;
};

/** The lookup type for a YouTube channel. */
export enum YtChannelLookupType {
  Id = 'ID',
  Url = 'URL',
  Username = 'USERNAME'
}

export type YtPageInfo = {
  __typename?: 'YtPageInfo';
  resultsPerPage: Scalars['Int']['output'];
  totalResults: Scalars['Int']['output'];
};

export type YtPlaylist = {
  __typename?: 'YtPlaylist';
  /** The contentDetails object contains information about the playlist content, including the number of videos in the playlist. */
  contentDetails: YtPlaylistContentDetails;
  /** The Etag of this resource. */
  etag?: Maybe<Scalars['String']['output']>;
  /** The ID that YouTube uses to uniquely identify the playlist. */
  id: Scalars['ID']['output'];
  /** Identifies the API resource's type. The value will be youtube#playlist. */
  kind?: Maybe<Scalars['String']['output']>;
  /** The snippet object contains basic details about the playlist, such as its title and description. */
  snippet: YtPlaylistSnippet;
};

export type YtPlaylistContentDetails = {
  __typename?: 'YtPlaylistContentDetails';
  /** The number of videos in the playlist. */
  itemCount: Scalars['Int']['output'];
};

export type YtPlaylistItem = {
  __typename?: 'YtPlaylistItem';
  /** Spanish TranslatedVideo object if it exists. */
  _spanishTranslatedVideo?: Maybe<TranslatedVideo>;
  /** Note Binder adds this field from a separate API call to the Videos API, it does not come direct from YouTube. */
  _videoDetails: YtVideo;
  /** The contentDetails object is included in the resource if the included item is a YouTube video. The object contains additional information about the video. */
  contentDetails: YtPlaylistItemContentDetails;
  /** Video duration in seconds */
  durationInSeconds: Scalars['Float']['output'];
  /** The Etag of this resource. */
  etag?: Maybe<Scalars['String']['output']>;
  /** The ID that YouTube uses to uniquely identify the playlist item. */
  id: Scalars['ID']['output'];
  /** Identifies the API resource's type. The value will be youtube#playlistItem. */
  kind?: Maybe<Scalars['String']['output']>;
  /** The snippet object contains basic details about the playlist item, such as its title and position in the playlist. */
  snippet: YtPlaylistItemSnippet;
  /** TranslatedVideos associated with the video if they exist. */
  translatedVideos?: Maybe<Array<TranslatedVideo>>;
};

export type YtPlaylistItemContentDetails = {
  __typename?: 'YtPlaylistItemContentDetails';
  /** A user-generated note for this item. The property value has a maximum length of 280 characters. */
  note: Scalars['String']['output'];
  /** The ID that YouTube uses to uniquely identify a video. To retrieve the video resource, set the id query parameter to this value in your API request. */
  videoId: Scalars['String']['output'];
  /** The date and time that the video was published to YouTube. The value is specified in ISO 8601 (YYYY-MM-DDThh:mm:ss.sZ) format. */
  videoPublishedAt: Scalars['String']['output'];
  /** Custom field. Formatted version of videoPublishedAt. */
  videoPublishedAtFormatted: Scalars['String']['output'];
};

export type YtPlaylistItemSnippet = {
  __typename?: 'YtPlaylistItemSnippet';
  /** The ID that YouTube uses to uniquely identify the user that added the item to the playlist. */
  channelId: Scalars['ID']['output'];
  /** The channel title of the channel that the playlist item belongs to. */
  channelTitle: Scalars['String']['output'];
  /** The item's description. */
  description: Scalars['String']['output'];
  /** The ID that YouTube uses to uniquely identify the playlist that the playlist item is in. */
  playlistId: Scalars['String']['output'];
  /** The order in which the item appears in the playlist. The value uses a zero-based index, so the first item has a position of 0, the second item has a position of 1, and so forth. */
  position: Scalars['Int']['output'];
  /** The ID that YouTube uses to uniquely identify the user that added the item to the playlist. */
  publishedAt: Scalars['String']['output'];
  /** The id object contains information that can be used to uniquely identify the resource that is included in the playlist as the playlist item. */
  resourceId: YtResourceId;
  /** A map of thumbnail images associated with the playlist item. For each object in the map, the key is the name of the thumbnail image, and the value is an object that contains other information about the thumbnail. */
  thumbnails: YtThumbnails;
  /** The item's title. */
  title: Scalars['String']['output'];
};

export type YtPlaylistItems = {
  __typename?: 'YtPlaylistItems';
  /** The Etag of this resource. */
  etag?: Maybe<Scalars['String']['output']>;
  /** A list of playlist items that match the request criteria. */
  items?: Maybe<Array<YtPlaylistItem>>;
  /** Identifies the API resource's type. The value will be youtube#playlistItemListResponse. */
  kind?: Maybe<Scalars['String']['output']>;
  /** The token that can be used as the value of the pageToken parameter to retrieve the next page in the result set. */
  nextPageToken?: Maybe<Scalars['String']['output']>;
  /** The pageInfo object encapsulates paging information for the result set. */
  pageInfo?: Maybe<YtPageInfo>;
  /** The token that can be used as the value of the pageToken parameter to retrieve the previous page in the result set. */
  prevPageToken?: Maybe<Scalars['String']['output']>;
};

export type YtPlaylistSnippet = {
  __typename?: 'YtPlaylistSnippet';
  /** The ID that YouTube uses to uniquely identify the channel that published the playlist. */
  channelId: Scalars['ID']['output'];
  /** The channel title of the channel that the video belongs to. */
  channelTitle: Scalars['String']['output'];
  /** The playlist's description. */
  description: Scalars['String']['output'];
  /** The date and time that the playlist was created. The value is specified in ISO 8601 (YYYY-MM-DDThh:mm:ss.sZ) format. */
  publishedAt: Scalars['String']['output'];
  thumbnails: YtThumbnails;
  /** The playlist's title. */
  title: Scalars['String']['output'];
};

export type YtPlaylists = {
  __typename?: 'YtPlaylists';
  etag?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<YtPlaylist>>;
  kind?: Maybe<Scalars['String']['output']>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
  pageInfo?: Maybe<YtPageInfo>;
  prevPageToken?: Maybe<Scalars['String']['output']>;
};

export type YtRelatedPlaylists = {
  __typename?: 'YtRelatedPlaylists';
  /** The ID of the playlist that contains the channel's uploaded videos. */
  uploads?: Maybe<Scalars['String']['output']>;
  /** The property's value is always set to HL.  */
  watchHistory?: Maybe<Scalars['String']['output']>;
  /** The property's value is always set to WL. */
  watchLater?: Maybe<Scalars['String']['output']>;
};

export type YtResourceId = {
  __typename?: 'YtResourceId';
  kind: Scalars['String']['output'];
  videoId: Scalars['ID']['output'];
};

export type YtThumbnail = {
  __typename?: 'YtThumbnail';
  height?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type YtThumbnails = {
  __typename?: 'YtThumbnails';
  /** The default thumbnail image. The default thumbnail for a video – or a resource that refers to a video, such as a playlist item or search result – is 120px wide and 90px tall. The default thumbnail for a channel is 88px wide and 88px tall. */
  default: YtThumbnail;
  /** A high resolution version of the thumbnail image. For a video (or a resource that refers to a video), this image is 480px wide and 360px tall. For a channel, this image is 800px wide and 800px tall. */
  high: YtThumbnail;
  /** The highest resolution version of the thumbnail image. This image size is available for some videos and other resources that refer to videos, like playlist items or search results. This image is 1280px wide and 720px tall. */
  maxres?: Maybe<YtThumbnail>;
  /** A higher resolution version of the thumbnail image. For a video (or a resource that refers to a video), this image is 320px wide and 180px tall. For a channel, this image is 240px wide and 240px tall. */
  medium: YtThumbnail;
  /** An even higher resolution version of the thumbnail image than the high resolution image. This image is available for some videos and other resources that refer to videos, like playlist items or search results. This image is 640px wide and 480px tall. */
  standard?: Maybe<YtThumbnail>;
};

/** The topicDetails object encapsulates information about topics associated with the resource. */
export type YtTopicDetails = {
  __typename?: 'YtTopicDetails';
  /** A list of Wikipedia URLs that describe the resources' content. */
  topicCategories?: Maybe<Array<Scalars['String']['output']>>;
  /** The topic from the topicCategory URL */
  topicCategoryValues?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * A list of topic IDs associated with the resource.
   * @deprecated see https://developers.google.com/youtube/v3/docs/channels hash properties
   */
  topicIds?: Maybe<Array<Scalars['ID']['output']>>;
};

/** A video resource represents a YouTube video. */
export type YtVideo = {
  __typename?: 'YtVideo';
  channelId: Scalars['String']['output'];
  channelTitle: Scalars['String']['output'];
  /**
   * The number of comments for the video.
   * @deprecated use statistics field
   */
  commentCount?: Maybe<Scalars['Int']['output']>;
  /** The contentDetails object contains information about the video content, including the length of the video and an indication of whether captions are available for the video. */
  contentDetails: YtVideoContentDetails;
  /** The default_audio_language property specifies the language spoken in the video's default audio track. */
  defaultAudioLanguage?: Maybe<Scalars['String']['output']>;
  /** The language of the text in the video resource's snippet.title and snippet.description properties. */
  defaultLanguage?: Maybe<Scalars['String']['output']>;
  /** The video's description. The property value has a maximum length of 5000 bytes and may contain all valid UTF-8 characters except < and >. */
  description: Scalars['String']['output'];
  /** The ID that YouTube uses to uniquely identify the video. */
  id: Scalars['ID']['output'];
  /**
   * The number of users who have indicated that they liked the video.
   * @deprecated use statistics field
   */
  likeCount: Scalars['Int']['output'];
  /** The date and time that the video was published. Note that this time might be different than the time that the video was uploaded. For example, if a video is uploaded as a private video and then made public at a later time, this property will specify the time that the video was made public. */
  publishedAt: Scalars['String']['output'];
  /** The statistics object contains statistics about the video. */
  statistics: YtVideoStatistics;
  /** A list of keyword tags associated with the video. Tags may contain spaces. The property value has a maximum length of 500 characters. */
  tags?: Maybe<Array<Scalars['String']['output']>>;
  thumbnails: YtThumbnails;
  /** The video's title. The property value has a maximum length of 100 characters and may contain all valid UTF-8 characters except < and >. */
  title: Scalars['String']['output'];
  /** YouTube does not always provide topic details for a video. */
  topicDetails?: Maybe<YtTopicDetails>;
  /** The URL of the video */
  url: Scalars['String']['output'];
  /**
   * The number of times the video has been viewed.
   *
   * Is of type JsSafeInt because views of Katy Perry - Roar (2,815,294,046) > Prisma Int max value (2,147,483,647).
   *
   * In JavaScript, Number.MAX_SAFE_INTEGER is 9007199254740991
   * @deprecated use statistics field
   */
  viewCount: Scalars['JsSafeInt']['output'];
};

export type YtVideoContentDetails = {
  __typename?: 'YtVideoContentDetails';
  /**
   * Indicates whether captions are available for the video.
   *
   * Valid values for this property are:
   * - `false`
   * - `true`
   */
  caption: Scalars['String']['output'];
  /**
   * Indicates whether the video is available in high definition (HD) or only in standard definition.
   * Valid values for this property are:
   * - `hd`
   * - `sd`
   */
  definition: Scalars['String']['output'];
  /** Indicates whether the video is available in 3D or in 2D. */
  dimension: Scalars['String']['output'];
  /**
   * The length of the video. The property value is an ISO 8601 duration. For example, for a video that is at least one minute long and less than one hour long, the duration is in the format PT#M#S, in which the letters PT indicate that the value specifies a period of time, and the letters M and S refer to length in minutes and seconds, respectively. The # characters preceding the M and S letters are both integers that specify the number of minutes (or seconds) of the video. For example, a value of PT15M33S indicates that the video is 15 minutes and 33 seconds long.
   *
   * If the video is at least one hour long, the duration is in the format PT#H#M#S, in which the # preceding the letter H specifies the length of the video in hours and all of the other details are the same as described above. If the video is at least one day long, the letters P and T are separated, and the value's format is P#DT#H#M#S. Please refer to the ISO 8601 specification for complete details.
   */
  duration: Scalars['String']['output'];
  /** Custom field to return a string that matches the duration YouTube displays on cards */
  durationFormatted: Scalars['String']['output'];
  /** Indicates whether the video represents licensed content, which means that the content was uploaded to a channel linked to a YouTube content partner and then claimed by that partner. */
  licensedContent: Scalars['Boolean']['output'];
  /**
   * Specifies the projection format of the video.
   *
   * Valid values for this property are:
   * - `360`
   * - `rectangular`
   */
  projection: Scalars['String']['output'];
};

/** The lookup type for a YouTube video. */
export enum YtVideoLookupType {
  Id = 'ID',
  Url = 'URL'
}

export type YtVideoStatistics = {
  __typename?: 'YtVideoStatistics';
  /** The number of comments for the video. */
  commentCount: Scalars['JsSafeInt']['output'];
  /** The number of users who have indicated that they liked the video. */
  likeCount: Scalars['JsSafeInt']['output'];
  /**
   * The number of times the video has been viewed.
   *
   * Is of type JsSafeInt because views of Katy Perry - Roar (2,815,294,046) > Prisma Int max value (2,147,483,647).
   *
   * In JavaScript, Number.MAX_SAFE_INTEGER is 9007199254740991
   */
  viewCount: Scalars['JsSafeInt']['output'];
  /** Custom field to display a short string version of the viewCount. */
  viewCountFormatted: Scalars['String']['output'];
};
