import { GetTokenSilentlyOptions, useAuth0, User } from "@auth0/auth0-react";
import { camelCase, mapKeys, pick } from "lodash";
import React, { useEffect, useRef } from "react";
import { Profile } from "../../@types/data";
import { extractGroups, FIELDS_TO_EXTRACT } from "./utils";

/**
 * Generates deterministic complementary colors based on a user ID and name.
 * We use the colours to indicate where the user is on the page
 * @param userId - The user ID to generate colors from
 * @param name - The user's name
 * @returns An object with light and dark complementary colors
 */
const generateUserColors = (userId: string, name: string) => {
  const userIdWithoutProvider = userId.split("|")[1];
  // Create a hash from the userId and name
  const hash = (userIdWithoutProvider + name).split("").reduce((acc, char) => {
    return (acc * 31 + char.charCodeAt(0)) & 0xffffffff;
  }, 0);

  // Generate a hue value between 0 and 360 based on the hash
  const hue = hash % 360;

  // Create complementary colors with different saturations and lightness
  return {
    light: `hsl(${hue}, 70%, 85%)`, // Light background color
    dark: `hsl(${hue}, 70%, 40%)` // Darker border color
  };
};

/**
 * Get user initials from name
 * @param name - The user's name
 * @returns The user's initials (up to 2 characters)
 */
const getUserInitials = (name: string): string => {
  if (!name) return "";

  const parts = name.split(/\s+/).filter(Boolean);
  if (parts.length === 0) return "";

  if (parts.length === 1) {
    return parts[0].charAt(0).toUpperCase();
  }

  return (parts[0].charAt(0) + parts[parts.length - 1].charAt(0)).toUpperCase();
};

export const parseAuth0Profile = (user: User): Profile => {
  // AWS TypeScript definition is not complete or wrong
  const profile = mapKeys(
    pick(user, FIELDS_TO_EXTRACT),
    (_: string, k: string) => camelCase(k)
  ) as Profile;

  profile["groups"] = extractGroups(user["https://origami.papercup.com/roles"]);
  profile["canEdit"] =
    profile.groups.superuser ||
    profile.groups.channelmanager ||
    profile.groups.admin;

  profile["ably"] = {
    token: user["https://origami.papercup.com/ably_token"]["token"]
  };
  profile["colors"] = generateUserColors(profile.sub, profile.name);
  profile["initials"] = getUserInitials(profile.name);
  return profile;
};

export const useAccessToken = (
  {
    shouldRefreshToken,
    auth0Options,
    onToken
  }: {
    shouldRefreshToken: boolean;
    auth0Options: GetTokenSilentlyOptions;
    onToken?: (token: string) => void;
  },
  deps: any[]
): [React.MutableRefObject<string | undefined>, () => Promise<void>] => {
  const token = useRef<string | undefined>();
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const requestRefreshToken = async () => {
    try {
      try {
        token.current = await getAccessTokenSilently(auth0Options);
      } catch (e) {
        console.log(e);
      }
      if (!token.current) {
        token.current = await getAccessTokenWithPopup(auth0Options);
      }
    } catch (e) {
      console.log(e.message);
    }
    if (onToken && token.current) {
      onToken(token.current);
    }
  };
  useEffect(() => {
    if (shouldRefreshToken) {
      requestRefreshToken();
    }
  }, [...deps, shouldRefreshToken]);

  return [token, requestRefreshToken];
};
